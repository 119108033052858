import {PropertyValue, Report} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {getShiftName, getTypeName} from "../../common";

export interface ViewGrindingReportProperties {
    report: Report,
    shifts: PropertyValue[],
}

interface GrindingReportState {
}

export class ViewGrindingReportComponent extends React.Component<ViewGrindingReportProperties, GrindingReportState> {

    constructor(props: ViewGrindingReportProperties) {
        super(props);
        this.state = {}
    }


    render() {
        const rd = JSON.parse(this.props.report.request_data);

        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>
                            </div>
                            <div className="col">
                                Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>
                            </div>
                            <div className="col-auto">
                                Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Зареждане с пелети"}/>
                                <table className={"table-sm table-bordered w-100"}>
                                    <thead>
                                    <th className={"w-20"}>Начало</th>
                                    <th className={"w-20"}>Край</th>
                                    <th className={"w-20"}>Брой кофи глина</th>
                                    <th className={"w-20"}>Брой кофи шлам</th>
                                    <th className={"w-20"}>Светъл отвор валове</th>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{rd.periodFrom}</td>
                                        <td>{rd.periodTo}</td>
                                        <td>{rd.clayQty}</td>
                                        <td>{rd.sludgeQty}</td>
                                        <td>{rd.valsFuga}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div>
                                    {rd.comments || "Няма бележки към отчета"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
