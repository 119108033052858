import React, {useEffect, useState} from "react";
import {API_CALL, Client, PARAMETERS, Property} from "../types";
import {API} from "../api";
import {isError, isSuccess, showErrorsInToast, zeroToEmptyStringAsCurrency} from "../common";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Table} from "react-bootstrap";
import {EditClientAccountModal} from "../components/clients/EditClientAccountModal";


function BrickPrice(props: { brick: Property, data: any, variant: "inv"|"cash" }) {
    for (let i = 0; i < props.data.length; i++) {
        if (props.data[i].brick_id ==props.brick.id) {
            return (
                <td className={"text-end"}>{
                    zeroToEmptyStringAsCurrency(props.variant === "inv" ? props.data[i].price_inv :  props.data[i].price_cash)}</td>
            )
        }
    }

    return <td></td>
}

export default function ClientsPricelist() {

    const [loading, setLoading] = useState(2);
    const [data, setData] = useState<any>([]);
    const [brickTypes, setBrickTypes] = useState<Property[]>([]);
    const [editClientAccount, setEditClientAccount] = useState<Client|null>(null);

    useEffect(() => {
        loadData();
    }, []);


    const loadData = function () {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setLoading(loading => loading - 1);
                    setBrickTypes(apiCall.data.data.items?.values.map(
                        (v: any) => {
                            return {id: v.id, name: v.value} as Property
                        }
                    ));
                }
            }, PARAMETERS.BRICKS
        );

        API.getClientPriceList(
            (apiCall: API_CALL) => {
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    setLoading(loading => loading - 1);
                    setData(apiCall.data.data.pricelist);
                }
            }, -1
        )
    }

    return (
        <div className="container" style={{maxWidth: "100%"}}>

            <div className="card shadow">

                <div className="card-header text-center row">
                    <div className="col-2 text-start">
                        <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                            <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                    </div>
                    <h2 className="col">Ценоразпис по клиенти</h2>
                    <div className="col-2"></div>
                </div>

                <div className="card-body">
                    {
                        (loading > 0 || !data) ?
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            :
                            <>
                                {
                                    data.length === 0 ?
                                        <div className="w-100 text-center fw-bold">
                                            Няма данни.
                                        </div>
                                        :
                                        <Table size={"sm"} bordered striped >
                                            <thead>
                                            <tr>
                                                <th rowSpan={2}>Име на клиента</th>
                                                {
                                                    brickTypes.map(
                                                        b => <th key={b.id} className={"text-center"} colSpan={2}>
                                                            {b.name}
                                                        </th>
                                                    )
                                                }
                                            </tr>

                                            <tr>
                                                {
                                                    brickTypes.map(
                                                        b =>
                                                            <>
                                                                <th key={b.id+'_1'} className={"text-end small fst-normal"}>
                                                                    С ФАКТУРА
                                                                </th>
                                                                <th key={b.id+'_2'} className={"text-end small fst-normal"}>
                                                                    С КАСОВ БОН
                                                                </th>
                                                            </>
                                                    )
                                                }
                                            </tr>

                                            </thead>
                                            <tbody>
                                            {
                                                Object.keys(data).map(
                                                    (i: any, idx: number) => {
                                                        return <tr key={idx}>
                                                            <td>
                                                                <a href="#"  onClick={() => setEditClientAccount(
                                                                    {id: data[i][0].client_id, name: i} as Client)}>
                                                                    {i}
                                                                </a>
                                                            </td>
                                                            {
                                                                brickTypes.map(
                                                                    (b, idx1) =>
                                                                        <>
                                                                            <BrickPrice key={idx + '_i_' + idx1} brick={b} data={data[i]} variant={"inv"}/>
                                                                            <BrickPrice key={idx + '_c_' + idx1} brick={b} data={data[i]} variant={"cash"}/>
                                                                        </>

                                                                )
                                                            }
                                                        </tr>
                                                    }
                                                )
                                            }

                                            </tbody>
                                        </Table>
                                }
                            </>
                    }
                </div>

            </div>


            {
                editClientAccount &&
                <EditClientAccountModal client={editClientAccount}
                                        onClose={() => {
                                            setEditClientAccount(null);
                                            loadData();
                                        }}/>
            }
        </div>
    )
}