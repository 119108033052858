import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ProgressBar, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {currencyWithSpaces,} from "../../common";
import {BONUS_ADMIN_USERS, BonusUser, Report, ReportItem} from "./BonusesControlModal";
import {API} from "../../api";




function CurrentBonus({minPerc, perc, minBonus, maxBonus}: { minPerc: number | undefined,  perc: number | undefined, minBonus: number, maxBonus: number }) {
    const _mp = minPerc || 0;
    minBonus *= 1;
    maxBonus *= 1;
    const deltaBonus = maxBonus - minBonus;

    if (!perc || (perc < _mp) || !minPerc) return <>0</>;

    const step = deltaBonus / (100 - _mp);
    const bonus = minBonus + (deltaBonus - (step * (100 - perc)));

    return <>{currencyWithSpaces(bonus) }</>;
}


function DrawValue({value}: { value: number }) {
    return <ProgressBar now={value} style={{ margin: "0.5rem 0.2rem", minWidth: 180, height: "2rem"}}
                        label={value.toFixed(0)}
                        title={value.toFixed(0)} />
}

export function BonusCurrentComponent({reports}: {reports: Array<Report>}) {
    const [p1, setPe1] = useState(0);
    const [p2, setPe2] = useState(0);
    const [p3, setPe3] = useState(0);
    const [p4, setPe4] = useState(0);
    const [p5, setPe5] = useState(0);
    const [p6, setPe6] = useState(0);

    const [currentReport, setCurrentReport] = useState<Report | null>(reports?.length > 0 ? reports[0] : null);
    const [current, setCurrent] = useState<ReportItem|null>(null);

    useEffect(() => {
        let _p1 = 0;
        let _p2 = 0;
        let _p3 = 0;
        let _p4 = 0;
        let _p5 = 0;
        let _p6 = 0;

        const items = currentReport?.report;
        let cnt = 0;

        if(items) {
            Object.keys(items).forEach(
                (k: string) => {
                    if(k === "current") {
                        setCurrent(items[k]);
                    } else {
                        const i = items[k];
                        _p1 += i.p1 * 1;
                        _p2 += i.p2 * 1;
                        _p3 += i.p3 * 1;
                        _p6 += i.p6 * 1;
                        _p4 += i.p4 * 1;
                        _p5 += i.bonus * 1;
                        cnt++;
                    }
                }
            )
        }

        if(cnt === 0) cnt = 1;

        setPe1(Math.round(_p1 / cnt));
        setPe2(Math.round(_p2 / cnt));
        setPe3(Math.round(_p3 / cnt));
        setPe6(Math.round(_p6 / cnt));
        setPe4(Math.round(_p4 / cnt));
        setPe5(Math.round(_p5 / cnt));

    }, [currentReport]);

    return (
        <div>
                    <Table size={"sm"}>
                        <thead>
                        <tr>
                            <th>Служител</th>
                            <th>Длъжност</th>
                            {
                                (BONUS_ADMIN_USERS.indexOf(API.user?.id || -1) > -1) && <>
                                    <th className={"text-end w-160px"}>Минимален бонус</th>
                                    <th className={"text-end w-160px"}>Максимален бонус</th>
                                </>
                            }
                            <th className={"text-end"}>Мин.усп.</th>
                            <th className={"text-end"}>Обща ефективност</th>
                            <th className={"text-end"}>Текущ бонус</th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            reports
                                .filter(value => ((BONUS_ADMIN_USERS.indexOf(API.user?.id || -1) > -1) ||
                                    (value.id === (API.user?.id || -1))))
                                .map(
                                u => {
                                    return <tr key={u.id}
                                               className={u.id === currentReport?.id ? "bg-primary text-white fw-bold" : ""}
                                               onClick={() => setCurrentReport(u) }
                                    >
                                        <td>{u.name}</td>
                                        <td>{u.position}</td>
                                        {

                                            (BONUS_ADMIN_USERS.indexOf(API.user?.id || -1) > -1) && <>
                                            <td className={"text-end"}>{currencyWithSpaces(u.minBonus)} лв.</td>
                                            <td className={"text-end"}>{currencyWithSpaces(u.maxBonus)} лв.</td>
                                            </>
                                        }
                                        <td className={"text-end"}>{currencyWithSpaces(u.report['current']?.minPerc)} %</td>
                                        <td className={"text-end"}>{currencyWithSpaces(u.report['current']?.bonus)} %</td>
                                        <td className={"text-end fw-bold"}>
                                            <CurrentBonus
                                                minPerc={u.report['current']?.minPerc}
                                                perc={u.report['current']?.bonus}
                                                minBonus={u.minBonus} maxBonus={u.maxBonus}/> лв.
                                        </td>
                                    </tr>
                                }
                            )
                        }
                        </tbody>
                    </Table>
                    <BlockTitleComponent title={"Детайли"} />
                    <Table className={"table table-sm table-bordered d-block overflow-auto w-100"}
                           style={{maxHeight: "40vh", fontSize: 14}}>
                        <thead className={"bg-secondary text-light"}>
                        <tr className={"text-center"}>
                            <th rowSpan={2}>Ден</th>
                            <th colSpan={2}>КГ пелети в печка и пещ</th>
                            <th colSpan={2}>Брой вагони в разтоварване</th>
                            <th colSpan={2}>% брак от разтоварването</th>
                            <th colSpan={2}>% брак от реденето</th>
                            <th colSpan={2}>Брой хора</th>
                            <th rowSpan={2}>Обща ефективност<br/>(%)</th>
                        </tr>
                        <tr className={"text-end"}>
                            <th>Стойност (кг)</th>
                            <th>Рентабилност<br/>(%)</th>
                            <th>Стойност (бр)</th>
                            <th>Рентабилност<br/>(%)</th>
                            <th>Стойност (%)</th>
                            <th>Рентабилност<br/>(%)</th>
                            <th>Стойност (%)</th>
                            <th>Рентабилност<br/>(%)</th>
                            <th>Стойност (човека)</th>
                            <th>Рентабилност<br/>(%)</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr className={"bg-c-yellow fw-bold"}>
                            <td style={{width: 100, lineHeight: "3rem"}}>За месеца</td>
                            <td colSpan={2}><DrawValue value={p1} /></td>
                            <td colSpan={2}><DrawValue value={p2} /></td>
                            <td colSpan={2}><DrawValue value={p3} /></td>
                            <td colSpan={2}><DrawValue value={p6} /></td>
                            <td colSpan={2}><DrawValue value={p4} /></td>
                            <td><DrawValue value={p5} /></td>
                        </tr>
                        {
                            currentReport?.report &&
                            Object.keys(currentReport?.report).map(
                                (d, dIdx) => {
                                    const item = currentReport?.report[d];
                                    return (d !== "current") && <tr key={d}>
                                        <td>{ d }</td>
                                        <td className={"text-end"}>{(item.v1)}</td>
                                        <td className={"text-end"}>{currencyWithSpaces(item.p1)}</td>
                                        <td className={"text-end"}>{(item.v2)}</td>
                                        <td className={"text-end"}>{currencyWithSpaces(item?.p2)}</td>
                                        <td className={"text-end"}>{currencyWithSpaces(item.v3)}</td>
                                        <td className={"text-end"}>{currencyWithSpaces(item.p3)}</td>
                                        <td className={"text-end"}>{currencyWithSpaces(item.v6)}</td>
                                        <td className={"text-end"}>{currencyWithSpaces(item.p6)}</td>
                                        <td className={"text-end"} title={"Мелене + Екструдер + Печка + Редене + Пещ + Разтоварване"}>
                                            {item.vGrinding}+
                                            {item.vExtruder}+
                                            {item.vHeater}+
                                            {item.vOrdering}+
                                            {item.vOven}+
                                            {item.vUnloading}
                                        </td>
                                        <td className={"text-end"}>{currencyWithSpaces(item.p4)}</td>
                                        <td className={"text-end fw-bold"}>{currencyWithSpaces(item.bonus)}</td>
                                    </tr>
                                }
                            )
                        }
                        </tbody>

                    </Table>
        </div>
    )
}