import { BlockTitleComponent } from "../common/BlockTitleComponent";
import {Button, Col, Form, FormSelect, Row, Table} from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { API } from "../../api";
import {Account, API_CALL} from "../../types";
import { isSuccess, numberToCurrencyString} from "../../common";
import { BonusUser } from "./BonusesControlModal";
import { CurrencyInput } from "../../common/CurrencyInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";


export function BonusConfigComponent({ users, onChangeData }: { users: Array<BonusUser>, onChangeData: (newData: any) => any }) {
    const API_PATH = 'admin/bonuses/config';

    const [localUsers, setLocalUsers] = useState<Array<BonusUser>>([]);
    const [selected, setSelected] = useState<BonusUser | null>(null);
    const [config, setConfig] = useState<Map<string, string>>(new Map<string, string>());
    const [allUsers, setAllUsers] = useState<any>([]);
    const [newUser, setNewUser] = useState<any>(null);

    const processKeys = ['grinding', 'extruder', 'heater', 'ordering', 'oven', 'unloading'];
    const processTitles = ['Мелене', 'Екструдер', 'Печка', 'Редене', 'Пещ', 'Разтоварване'];

    const loadData = () => {
        const m = new Map<string, string>();
        if(selected && localUsers.length > 0) {
            selected?.config.forEach((v, k) => m.set(k, v));
        }
        setConfig(m);
    }


    useEffect(() => {
        API.getAllAccounts(
            (apiCall: API_CALL) => {
                if(isSuccess(apiCall)) {
                    setAllUsers(
                        apiCall.data.data.users.map((u: Account) =>  {return {'id' : u.id, 'name' : u.name, 'department' : u.department}})
                            .sort((i1: Account, i2: Account) => i1.name > i2.name ? 1 : (i1.name < i2.name ? -1 : 0))
                    )
                }
            }
        );
        setConfig(new Map<string, string>());
    }, []);

    useEffect(
        () => {
            const _users = [...users];
            setLocalUsers(_users);
            if (_users.length > 0) {
                setSelected(_users[0]);
            } else setSelected(null);
        }, [users]
    );
    useEffect(() => {
        loadData()
    }, [selected]);

    function selectUser(u: any) {
        setSelected(u);
    }

    function updateData() {
        onChangeData(localUsers);
    }

    useEffect(() => {
        if(selected) {
            selected.config = config;
            updateData();
        }
    }, [config]);

    function removeUser(id: number) {
        const newList = localUsers.filter(i => i.id != id);
        setLocalUsers(newList);
        onChangeData(newList);
    }

    function addUser() {
        if(!newUser) return;

        const _newUser = allUsers.find((u: any) => u.id == (newUser || -1));
        if(!_newUser) return;

        const m = new Map<string, string>();
        if(selected && localUsers.length > 0) {
            selected?.config.forEach((v, k) => m.set(k, v));
        }
        const newList = [...localUsers,
            {id: _newUser.id,
                name: _newUser.name,
                position: _newUser.department,
                minBonus: 0,
                maxBonus: 0,
            config: m} as BonusUser
        ];

        setLocalUsers(newList);
        onChangeData(newList);
        selectUser(newList[newList.length-1]);
    }

    return (
        <div className={"overflow-auto"}>
            <BlockTitleComponent title={"Включени в бонусната система служители"} />
            <Table size={"sm"}>
                <thead>
                    <tr>
                        <th>Има на служителя</th>
                        <th>Длъжност</th>
                        <th className="text-end w-160px">Минимален бонус</th>
                        <th className="text-end w-160px">Максимален бонус</th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        localUsers.length === 0 &&
                        <tr>
                            <td colSpan={3}>Няма регистрирани служители в бонусната система.</td>
                        </tr>
                    }
                    {
                        localUsers.map(
                            (u: any, i: number) => <tr key={u.id} className={u.id === selected?.id ? "bg-primary text-white fw-bold" : ""}>
                                <td onClick={() => selectUser(u)}>{u.name}</td>
                                <td onClick={() => selectUser(u)}>{u.position}</td>
                                {u.id === selected?.id ?
                                    <>
                                        <td className="text-end">
                                            <CurrencyInput className="form-control small text-end p-1 m-0"
                                                onChange={(v: number) => { u.minBonus = v }}
                                                value={u.minBonus} />
                                        </td>
                                        <td className={"text-end justify-content-end justify-content-end"}>
                                            <CurrencyInput className="form-control small text-end p-1 m-0"
                                                onChange={(v: number) => { u.maxBonus = v; updateData() }}
                                                value={u.maxBonus} />
                                        </td>
                                    </>
                                    :
                                    <>
                                        <td className="text-end" onClick={() => selectUser(u)}>{numberToCurrencyString(u.minBonus)}</td>
                                        <td className="text-end" onClick={() => selectUser(u)}>{numberToCurrencyString(u.maxBonus)}</td>
                                    </>
                                }
                                <td className={"text-center w-50px"}>
                                    <Button size={"sm"} onClick={() => removeUser(u.id)}><FontAwesomeIcon icon={faTrash}/></Button>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>

            {
                localUsers.length > 0 &&
                <>
                    <BlockTitleComponent title={"Показатели"} />
                    <Table bordered={true} size={"sm"}>
                        <thead className={"bg-secondary text-light"}>
                        <tr className={"text-center"}>
                            <th colSpan={3} rowSpan={2}>КГ пелети в печка и пещ</th>
                            <th colSpan={6}>Брой вагони в разтоварване</th>
                            <th colSpan={3} rowSpan={2}>% брак от разтоварването</th>
                            <th colSpan={3} rowSpan={2}>Брой хора по процеси</th>
                            <th colSpan={3} rowSpan={2}>% брак от реденето</th>
                        </tr>
                        <tr className={"text-center"}>
                            <th colSpan={3}>Плътна</th>
                            <th colSpan={3}>Термо</th>
                        </tr>
                        <tr className={"text-end"}>
                            <th>Стойност</th>
                            <th>%</th>
                            <th>Тежест</th>
                            <th>Стойност</th>
                            <th>%</th>
                            <th>Тежест</th>
                            <th>Стойност</th>
                            <th>%</th>
                            <th>Тежест</th>
                            <th>Стойност</th>
                            <th>%</th>
                            <th>Тежест</th>
                            <th>Процес</th>
                            <th>Стойност</th>
                            <th>Тежест</th>
                            <th>Стойност</th>
                            <th>%</th>
                            <th>Тежест</th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            [0, 1, 2, 3, 4, 5, 6].map(
                                idx => <tr key={idx}>
                                    {
                                        [1, 2, 5, 3, 4, 6].map(
                                            pIdx => {
                                                if (pIdx === 4) {
                                                    const key = processKeys[idx];
                                                    return <>
                                                        <td><span className="border-0 form-control small min-width-50px w-100 text-start">{processTitles[idx]}</span></td>
                                                        <td style={{ backgroundColor: pIdx % 2 == 0 ? 'InfoBackground' : 'inherit' }}>
                                                            <input type="text"
                                                                   className="border-0 form-control small min-width-50px w-100 text-end "
                                                                   value={config.get(key + "_value")}
                                                                   style={{ backgroundColor: 'inherit', lineHeight: '0.75rem' }}
                                                                   onChange={el => setConfig(new Map(config.set(key + "_value", el.target.value)))}
                                                            />
                                                        </td>
                                                        <td style={{ backgroundColor: idx === 0 ? (pIdx % 2 == 0 ? 'silver' : 'silver') : 'gray' }}>
                                                            {
                                                                idx === 0 ?
                                                                    <input type="text"
                                                                           className="border-0 form-control small min-width-50px w-100 text-end "
                                                                           value={config.get(key + "_weight")}
                                                                           style={{ backgroundColor: 'inherit', lineHeight: '0.75rem' }}
                                                                           onChange={el => setConfig(new Map(config.set(key + "_weight", el.target.value)))}
                                                                    /> :
                                                                    null
                                                            }
                                                        </td>
                                                    </>

                                                } else {
                                                    const key = "p" + pIdx;
                                                    return <>
                                                        <td style={{ backgroundColor: pIdx % 2 == 0 ? 'InfoBackground' : 'inherit' }}>
                                                            <input type="text"
                                                                   name={key + "_value_" + idx}
                                                                   className="border-0 form-control small min-width-50px w-100 text-end "
                                                                   value={config.get(key + "_value_" + idx)}
                                                                   style={{ backgroundColor: 'inherit', lineHeight: '0.75rem'  }}
                                                                   onChange={el => setConfig(new Map(config.set(key + "_value_" + idx, el.target.value)))}
                                                            />
                                                        </td>
                                                        <td style={{ backgroundColor: pIdx % 2 == 0 ? 'InfoBackground' : 'inherit' }}>
                                                            <input type="text"
                                                                   name={key + "_value_" + idx}
                                                                   className="border-0 form-control small min-width-50px w-100 text-end "
                                                                   value={config.get(key + "_perc_" + idx)}
                                                                   style={{ backgroundColor: 'inherit', lineHeight: '0.75rem'  }}
                                                                   onChange={el => setConfig(new Map(config.set(key + "_perc_" + idx, el.target.value)))}
                                                            />
                                                        </td>
                                                        <td style={{ backgroundColor: idx === 0 ? (pIdx % 2 == 0 ? 'silver' : 'silver') : 'gray' }}>
                                                            {
                                                                idx === 0 ?
                                                                    <input type="text"
                                                                           name={key + "_value_" + idx}
                                                                           className="border-0 form-control small min-width-50px w-100 text-end "
                                                                           value={config.get(key + "_weight")}
                                                                           style={{ backgroundColor: 'inherit', lineHeight: '0.75rem'  }}
                                                                           onChange={el => setConfig(new Map(config.set(key + "_weight", el.target.value)))}
                                                                    /> :
                                                                    null
                                                            }
                                                        </td>
                                                    </>
                                                }
                                            }
                                        )
                                    }
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </>
            }

            <BlockTitleComponent title={"Добави нов служител"} />
            <Row className={"mw-100"}>
                <Col>
                    <Form.Control as="select" onChange={u  => setNewUser(u.target.value)} value={newUser}>
                        <option></option>
                        {
                            allUsers.map(
                                (u: any) => <option value={u.id}>{u.name}, {u.department}</option>
                            )
                        }
                    </Form.Control>
                </Col>
                <Col xs={"auto"}>
                    <Button size={"sm"} onClick={() => addUser()} disabled={!newUser}><FontAwesomeIcon icon={faPlus}/></Button>
                </Col>
            </Row>
        </div>
    )
}