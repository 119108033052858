import {Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {PropertyDescriptor} from "../../types";
import {showErrorsListInToast} from "../../common";

export interface BankExpensesEditModalProps {
    typeId: number;
    year: number;
    month: number;
    value: number;
    onSave: (newValue: number) => any;
    onClose: any;
}


export function BankExpensesEditModal(props: BankExpensesEditModalProps) {

    const [newValue, setNewValue] = useState<string>(props.value.toString());

    function handleChange(e: any) {
        setNewValue(e.target.value);
    }

    function saveValue() {
        const nv = Number(newValue);
        if (!isNaN(nv)) {
            props.onSave(nv);
        } else {
            showErrorsListInToast('Внимание', ['"' + newValue + '" не е валидна стойност!']);
        }
    }

    return (
        <Modal
            show={true}
            centered
            size={"sm"}
            onHide={() => props.onClose()}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>Въведете сума</Modal.Title>
                    </div>
                </div>
            </Modal.Header>

            <div className="modal-body">

                <form>
                    <div className="mb-3">
                        <label className="form-label">Стойност</label>
                        <input type="text" placeholder={"Нова стойност"} className="form-control" onChange={handleChange}
                               value={newValue}/>
                    </div>
                </form>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => saveValue()}>Запази</button>
                <button type="button" className="btn btn-secondary" onClick={() => props.onClose()}>Откажи</button>
            </div>
        </Modal>
    )
}
