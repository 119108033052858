import React from "react";

export interface BlockTitleProps {
    title: string
    rightTitle?: string
    actionTitle?: string;
    onAction?: () => any;
}

export const BlockTitleComponent = function (props: BlockTitleProps) {
    const rT = props.rightTitle || '';
    return (
        <div className={"col-12 row card-subtitle border m-0 mb-2 bg-secondary text-light p-1"} style={{lineHeight: "2em"}}>
            <div className="col" dangerouslySetInnerHTML={{__html: props.title}}></div>
            {
                props.rightTitle && <div className="col-auto" dangerouslySetInnerHTML={{__html: rT}}></div>
            }
            {
                props.actionTitle && props.onAction && <div className="col-auto p-0">
                    <button className={"btn btn-light text-dark"} onClick={props.onAction} type={"button"}
                            style={{lineHeight: "1em"}}
                    > {props.actionTitle} </button>
                </div>
            }
        </div>
    )
}
