import React from "react";

export default  function useInterval(callback: () => any, delay: number) {
    const intervalRef = React.useRef<number|null>(null);
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    React.useEffect(() => {
        const tick = () => savedCallback.current();
        intervalRef.current = window.setInterval(tick, delay);
        return () => window.clearInterval(intervalRef.current || -1);
    }, [delay]);
    return intervalRef;
}
