import {Modal} from "react-bootstrap";
import React from "react";
import {Logistic} from "../../types";
import { EditLogisticDetails } from "./EditLogisticDetails";

export interface EditLogisticModalProps {
    logistic: Logistic;
    onSave: (logistic: Logistic) => any;
    onClose: any;
}

export function EditLogisticModal(props: EditLogisticModalProps) {

    return (
        <Modal
            show={true}
            centered
            onHide={props.onClose}
            style={{zIndex: 10016}}
        >
            <Modal.Header closeButton>
                <Modal.Title>{(props.logistic.id == -1 ? 'Добавяне' : 'Корекция')} на транспортна фирма</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"modal-90 m-3"} style={{maxWidth: '30em'}}>
                    <EditLogisticDetails
                        logistic={props.logistic}
                        onSave={props.onSave}
                        onCancel={props.onClose}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}
