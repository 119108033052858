import React from "react";
import {AccountOperation, API_CALL, Client, OPERATION_TYPE, PAY_TYPE} from "../../types";
import moment from "moment";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {Modal} from "react-bootstrap";
import {EditAccountOperation} from "./EditAccountOperation";

export interface EditClientAccountOperationProps {
    client: Client;
    onSaved: () => any;
    onCanceled: () => any;
    pay_mode: PAY_TYPE;
    editOperation: AccountOperation;
    isNew: boolean;
}

export interface EditClientAccountOperationState {
    loading: boolean,
    balance_bank: number,
    balance_cash: number,
    balance_credit: number,
    formValues: Map<string, any>,
}

export class EditClientAccountOperation extends React.Component<EditClientAccountOperationProps, EditClientAccountOperationState> {
    constructor(props: EditClientAccountOperationProps) {
        super(props);
        this.state = {
            loading: false,
            balance_bank: 0,
            balance_cash: 0,
            balance_credit: 0,
            formValues: new Map<string, any>(),
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.saveItem = this.saveItem.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({formValues: this.state.formValues.set(name, value)});
    }

    saveItem(operation: AccountOperation, isNew: boolean) {
        API.saveClientOperation(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.props.onSaved();
                }
            }, operation, this.props.client.id, operation.id
        )
    }

    removeOperation(operation: AccountOperation | null) {
        if (operation)
            API.deleteClientOperation(
                (apiCall: API_CALL) => {
                    this.setState({loading: isLoading(apiCall)});

                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                    if (isSuccess(apiCall)) {
                        this.props.onSaved();
                    }
                }, operation.id
            )
    }


    render() {
        return (
            <Modal
                show={true}
                onHide={() => {this.props.onCanceled()}}
                style={{zIndex: 10025}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{(this.props.isNew ? 'Добавяне' : 'Корекция')} на операция</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"modal-90 m-3"}>
                        {
                            this.state.loading ?
                                <>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </>
                                :
                                <EditAccountOperation
                                    operation={this.props.editOperation}
                                    onSave={operation => this.saveItem(operation, this.props.isNew)}
                                    onRemove={() => this.removeOperation(this.props.editOperation)}
                                    onCancel={() => this.props.onCanceled()}
                                />
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
