import React from "react";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface TimePickerComponentProps {
    onSelect: any,
    value: string,
}

export interface TimePickerComponentState {
    hours: number,
    minutes: number,
}

export class TimePickerComponent extends React.Component<TimePickerComponentProps, TimePickerComponentState> {
    constructor(props: TimePickerComponentProps) {
        super(props);

        let now = moment();
        if (this.props.value) {
            now = moment();
            now.set({hours: Number(this.props.value.substr(0, 2)), minutes: Number(this.props.value.substr(-2))});
        }

        this.state = {
            hours: now.hour(),
            minutes: now.minute() - Math.ceil(now.minute() % 5),
        }

        this.selectHour = this.selectHour.bind(this);
        this.selectMinute = this.selectMinute.bind(this);
        this.done = this.done.bind(this);
        this.clear = this.clear.bind(this);
    }

    selectHour(h: number) {
        this.setState({hours: h});
    }

    selectMinute(m: number) {
        this.setState({minutes: m});
    }

    done() {
        if (this.props.onSelect) {
            if (isNaN(this.state.hours) || isNaN(this.state.minutes)) this.props.onSelect("");
            else this.props.onSelect(('0' + this.state.hours.toString()).substr(-2) + ":" + ('0' + this.state.minutes.toString()).substr(-2));
        }
    }

    clear() {
        if (this.props.onSelect) {
            this.props.onSelect("");
        }
    }

    renderRows() {
        const rows: any = [];

        for (let i = 0; i < 12; i++) {
            let c1: any;
            let c2: any;
            c1 = <td>
                <span onClick={() => this.selectHour(i)}
                      className={"btn btn-sm btn-" + (i === this.state.hours ? "primary" : "outline-secondary")}>{('0' + i.toString()).substr(-2)}</span>&nbsp;
                <span onClick={() => this.selectHour(i + 12)}
                      className={"btn btn-sm btn-" + ((i + 12) === this.state.hours ? "primary" : "outline-secondary")}>{('0' + (i + 12).toString()).substr(-2)}</span>
            </td>;
            c2 = <td><span onClick={() => this.selectMinute(i * 5)}
                           className={"btn btn-sm btn-" + ((i * 5) === this.state.minutes ? "primary" : "outline-secondary")}>{('0' + (i * 5).toString()).substr(-2)}</span></td>;

            rows.push(<tr className={"border-bottom"}>{c1}
                <td>:</td>
                {c2}</tr>)
        }

        return rows;
    }

    renderTable() {
        return (
            <table className={"table table-sm table-hover border"} style={{height: 250, display: "block", overflow: "auto", width: "170px"}}>
                <thead>
                <tr className={"text-center"}>
                    <th>Час</th>
                    <th></th>
                    <th>Мин</th>
                </tr>
                </thead>
                <tbody>
                {this.renderRows()}
                </tbody>
            </table>
        )
    }

    renderDivs() {
        const me = this;
        const renderHours = function (from: number) {
            const cells: any = [];
            for (let i = from; i < from + 6; i++) {
                cells.push(
                    <button
                        key={i*60+1}
                        type={"button"}
                        onClick={() => me.selectHour(i)}
                        className={"btn btn-sm w-35px btn-" + (i === me.state.hours ? "primary" : "outline-secondary")}
                    >{('0' + i.toString()).substr(-2)}</button>);
            }
            return cells;
        }

        const renderMinutes = function (from: number) {
            const cells: any = [];
            for (let i = from; i < from + 6; i++) {
                cells.push(<button
                    key={i}
                    type={"button"}
                    onClick={() => me.selectMinute(i * 5)}
                    className={"btn btn-sm w-35px btn-" + ((i * 5) === me.state.minutes ? "primary" : "outline-secondary")}
                >{('0' + (i * 5).toString()).substr(-2)}</button>);
            }
            return cells;
        }

        return (
            <>
                <div className={"row"}>
                    <div className={"col-12 text-center font-weight-bold"}>
                        Час:
                    </div>
                </div>
                <div className={"row flex-nowrap"}>
                    {renderHours(0)}
                </div>
                <div className={"row"}>
                    {renderHours(6)}
                </div>
                <div className={"row"}>
                    {renderHours(12)}
                </div>
                <div className={"row"}>
                    {renderHours(18)}
                </div>
                <div className={"row"}>
                    <div className={"col-12 text-center font-weight-bold"}>
                        Минути:
                    </div>
                </div>
                <div className={"row"}>
                    {renderMinutes(0)}
                </div>
                <div className={"row mb-2"}>
                    {renderMinutes(6)}
                </div>
            </>
        )
    }


    render() {
        return (
            <>
                <div className={"border shadow p-1 bg-light"}
                     style={{
                         zIndex: 999, width: "240px", position: "absolute", display: "block",
                         backgroundColor: "white", overflow: "hidden"
                     }}
                >
                    <div className={"container w-100"}>
                        {this.renderDivs()}
                    </div>
                    <div>
                        <button className={"btn btn-secondary w-75"} onClick={this.done}>
                            <FontAwesomeIcon icon={"check"}/> Готово
                        </button>
                        <button className={"btn btn-secondary w-25"} onClick={this.clear}>
                            <FontAwesomeIcon icon={"times"}/></button>
                    </div>
                </div>
                {/*<div style={{position: "fixed", top: 0, left: 0, bottom: 0, right: 0, width: '100vw', height: '100vh', zIndex: 998}} onClick={this.clear}/>*/}
            </>
        );
    }
}
