import {HeaterReport, PropertyValue} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {getShiftName, getTypeName} from "../../common";

export interface ViewHeaterReportProperties {
    report: HeaterReport,
    shifts: PropertyValue[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
}

interface HeaterReportState {
}

export class ViewHeaterReportComponent extends React.Component<ViewHeaterReportProperties, HeaterReportState> {

    constructor(props: ViewHeaterReportProperties) {
        super(props);
        this.state = {}
    }


    render() {
        const rd = JSON.parse(this.props.report.request_data);
        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>
                            </div>
                            <div className="col">
                                Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>
                            </div>
                            <div className="col-auto">
                                Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Почистване"}/>
                                <div className={"row ml-2 mr-2"}>
                                    {
                                        (!rd.cleanups || rd.cleanups.length === 0) &&
                                        <div>Не е извършено</div>
                                    }
                                    {
                                        rd.cleanups.map(
                                            (i: string) => <div className={"col-1 border mr-2"}>{i}</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Зареждане с пелети"}/>
                                <table className={"table-sm table-bordered w-100"}>
                                    <thead>
                                    <th>Час</th>
                                    <th>Вид пелети</th>
                                    <th>Вид баг</th>
                                    <th>Брой багове</th>
                                    </thead>
                                    <tbody>
                                    {
                                        rd.chargings.map(
                                            (i: any, idx: number) =>
                                                <tr key={idx}>
                                                    <td>{i.chargeTime}</td>
                                                    <td>{ getTypeName(this.props.pelet_types, i.pelet) }</td>
                                                    <td>{ getTypeName(this.props.bag_types, i.bagType)}</td>
                                                    <td>{i.bagQty}</td>
                                                </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                                {
                                    (!rd.chargings || rd.chargings.length === 0) &&
                                    <div>Не е извършено</div>
                                }
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div>
                                    { rd.comments || "Няма бележки към отчета" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
