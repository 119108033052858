import React, {useState} from "react";
import {City, Client} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ClientsFilterProps {
    filter: any,
    loading: boolean;
    onApplyFilter: (filter: Map<string, any>) => any;
}

export function ClientsFilter(props: ClientsFilterProps) {
    const [formValues, setFormValues] = useState(props.filter);

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
        props.onApplyFilter(formValues);
    }

    const clearFilter = function () {
        formValues.set('name', '');
        formValues.set('cities', '');
        setFormValues(new Map<string, any>(formValues));
        props.onApplyFilter(formValues);
    }

    const renderData = function () {
        const filter = <tr className={"table-filter-row"}>
            <td>
                <input className={"w-100"} name={"name"} value={formValues.get("name")} onChange={handleFormChange} autoComplete={"off"}/>
            </td>
            <td>
                <input className={"w-100"} name={"cities"} value={formValues.get("cities")} onChange={handleFormChange} autoComplete={"off"}/>
            </td>
            <td colSpan={7}>

            </td>
            <td className={"text-end"}>
                <button type={"button"} className={"btn btn-secondary btn-sm"} onClick={() => clearFilter()}>
                    <FontAwesomeIcon icon={"times"}/>
                </button>
            </td>
        </tr>;

        return filter;
    }

    return renderData();
}
