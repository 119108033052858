import React from "react";
import moment, {Moment} from "moment";
import {API} from "../../api";
import {API_CALL, MONTHS_NAMES} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";
import * as XLSX from "xlsx";
import {InputGroup} from "react-bootstrap";

interface Form76MonthProps {
}

interface Form76MonthState {
    loading: boolean,
    fromDate: Date;
    toDate: Date;
    editMode: boolean,
    editTypeId: number,
    editDate: Date,
    editValue: number,
    form76Data: any,
    formValues: Map<string, any>,
    selections: Map<string, boolean>,
    otherSelection: number,
}

export class Form76Month extends React.Component<Form76MonthProps, Form76MonthState> {
    yearsList: number[] = [];

    constructor(props: any) {
        super(props);

        for (let i = new Date().getFullYear(); i > 2018; i--) this.yearsList.push(i);

        const fv = new Map<string, any>();
        fv.set('fromDate', moment().startOf('month').toDate());
        fv.set('toDate', moment().endOf('month').toDate());

        this.state = {
            loading: true,
            fromDate: fv.get('fromDate'),
            toDate: fv.get('toDate'),
            editMode: false,
            editDate: new Date(),
            editTypeId: 0,
            editValue: 0,
            form76Data: {},
            formValues: fv,
            selections: new Map<string, boolean>(),
            otherSelection: 1,
        }

        this.loadReport = this.loadReport.bind(this);
        this.exportReport = this.exportReport.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.selectAllInDivision = this.selectAllInDivision.bind(this);
        this.selectEmpl = this.selectEmpl.bind(this);
        this.updateRates = this.updateRates.bind(this);
        this.handleMonthChange = this.handleMonthChange.bind(this);
        this.handleYearChange = this.handleYearChange.bind(this);
    }

    componentDidMount() {
        this.loadReport();
    }

    loadReport() {
        this.setState({fromDate: this.state.formValues.get('fromDate'), toDate: this.state.formValues.get('toDate')});

        API.getForm76Month(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.setState({form76Data: apiCall.data.data.form76});
                }
            },
            moment(this.state.formValues.get('fromDate')).format('DD.MM.YYYY'),
            moment(this.state.formValues.get('toDate')).format('DD.MM.YYYY')
        );
    }

    exportReport() {
        var elt = document.getElementById('tableForm76');
        var wb = XLSX.utils.table_to_book(elt, {raw: true, cellStyles: true,});

        XLSX.writeFile(wb, 'SheetJSTableExport.xlsx', {bookSST: true});
    }

    renderHeader() {
        const days: string[] = [];
        const firstDay = moment(this.state.fromDate);
        const lastDay = moment(this.state.toDate);

        while (firstDay <= lastDay) {
            days.push(firstDay.format('DD.MM.YYYY'));
            firstDay.add('1', "day");
        }

        return (
            <thead>
            <tr>
                <th>Служител</th>
                {API.user?.is_owner === 'Y' && <>
                <th className={"text-end"}>Ставка ПС&nbsp;<br/>
                    (лв/ч)</th>
                <th className={"text-end"}>Ставка НС&nbsp;<br/>
                    (лв/ч)</th>
                </>
                }
                {
                    days.map(
                        d => <th className={"cursor-pointer text-center "} key={"d_" + d} onClick={() => this.selectAll(d)}>
                            {d.slice(0, 2)}.<br/>
                            {d.slice(3, 5)}
                        </th>
                    )
                }
                <th className={"text-end"}>Всичко&nbsp;<br/>
                    часове
                </th>
                {API.user?.is_owner === 'Y' && <th className={"text-end"}>Всичко&nbsp;<br/>
                    лева</th>}
            </tr>
            </thead>
        )
    }

    renderRows() {
        const days: string[] = [];
        let currentDivision = '';
        const firstDay = moment(this.state.fromDate);
        const lastDay = moment(this.state.toDate);

        while (firstDay <= lastDay) {
            days.push(firstDay.format('DD.MM.YYYY'));
            firstDay.add('1', "day");
        }

        const renderDivisionRow = function (division: string, divisionId: number, days: string[], me: Form76Month) {
            return (
                <tr className="cursor-pointer bg-secondary-light font-weight-bold" key={"dev_" + divisionId}>
                    <td colSpan={3}>{division}</td>
                    {
                        days.map(
                            d => <td key={"e_" + d} onClick={() => me.selectAllInDivision(d, divisionId)}>&nbsp;</td>
                        )
                    }
                    {API.user?.is_owner === 'Y' && <td colSpan={2}>&nbsp;</td>}
                </tr>
            )
        }

        const renderEmplRow = function (item: any, days: string[], me: Form76Month) {
            let totalH = 0;
            let total$ = 0;

            days.forEach(
                d => {
                    if (item[d]) {
                        const day = item[d];

                        const h = Number(day['code'].replace('N', '').replace('R', ''));
                        const r = day['rate'];
                        const rNS = day['rateNS'];

                        if (!isNaN(h)) {
                            totalH += h;
                            if(day['code'].indexOf('N') > 0) {
                                total$ += (h * rNS);
                            } else {
                                total$ += (h * r);
                            }
                        }
                    }
                }
            );

            return (
                <tr className="" key={"empl_" + item.id}>
                    <td>{item.name}</td>
                    {API.user?.is_owner === 'Y' && <td className={"text-end"}>{item.rate}</td>}
                    {API.user?.is_owner === 'Y' && <td className={"text-end"}>{zeroToEmptyStringAsCurrency(item.rateNS.toString())}</td>}
                    {
                        days.map(
                            d => <td key={d} onClick={() => me.selectEmpl(d, item.id)}
                                     className={"cursor-pointer text-center " +
                                         (me.state.selections.get(item.id + "_" + d) ? "bg-primary" :
                                             (item[d] && item[d]['code']?.indexOf('N') > 0 ? " bg-secondary text-light " : ""))}
                                     style={{border: ((item[d] && item[d]['code']?.indexOf('R') > 0) ? "solid 3px red" : "none" )}}
                            >
                                {
                                    item[d] ? item[d]['code'].replace('N', '').replace('R', '') : ''
                                }
                            </td>
                        )
                    }
                    <td className={"text-end font-weight-bold"}>{zeroToEmptyString(totalH.toString())}</td>
                    {API.user?.is_owner === 'Y' && <td className={"text-end font-weight-bold"}>{zeroToEmptyStringAsCurrency(total$.toString())}</td>}
                </tr>
            )
        }

        const rows: any[] = [];

        Object.keys(this.state.form76Data).forEach(
            (key: string) => {
                const item = this.state.form76Data[key];
                if (currentDivision != item.division.name) {
                    currentDivision = item.division.name;
                    rows.push(renderDivisionRow(currentDivision, item.division_id, days, this));
                }
                rows.push(renderEmplRow(item, days, this));
            }
        );

        return rows;
    }

    selectAll(day: string) {
        Object.keys(this.state.form76Data).forEach(
            (k: string) => {
                const item = this.state.form76Data[k];
                const key = item.id + "_" + day;
                this.setState({selections: this.state.selections.set(key, this.state.selections.has(key) ? !this.state.selections.get(key) : true)});
            }
        )
    }

    deselectAll() {
        this.setState({selections: new Map<string, boolean>()});
    }

    setTo(code: string) {
        const positions: string[] = [];
        this.state.selections.forEach(
            (v: boolean, k: string) => {
                if (v) positions.push(k);
            }
        )

        const data = {
            dates: positions,
            code: code
        };

        this.updateForm76(data);
    }

    updateRates() {
        const positions: string[] = [];
        this.state.selections.forEach(
            (v: boolean, k: string) => {
                if (v) positions.push(k);
            }
        )

        const data = {
            dates: positions
        };

        API.updateForm76Rates(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.loadReport();
                    this.deselectAll();
                }
            },
            data
        );
    }

    selectAllInDivision(day: string, divisionId: number) {
        Object.keys(this.state.form76Data).forEach(
            (k: string) => {
                const item = this.state.form76Data[k];
                if (item.division_id === divisionId) {
                    const key = item.id + "_" + day;
                    this.setState({selections: this.state.selections.set(key, this.state.selections.has(key) ? !this.state.selections.get(key) : true)});
                }
            }
        )
    }

    selectEmpl(day: string, emplId: number) {
        const key = emplId + "_" + day;
        this.setState({selections: this.state.selections.set(key, this.state.selections.has(key) ? !this.state.selections.get(key) : true)});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName, newDate)});
    }

    setEndOfTheMonth(date: Moment) {
        const fv = this.state.formValues;
        const cd = moment(date.endOf('month'));
        fv.set('toDate', cd.toDate());
        this.setState({formValues: fv});
    }

    handleMonthChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const fv = this.state.formValues;
        const cd = moment(fv.get('fromDate')).set('month', value).set('date', 1);
        fv.set('fromDate', cd.toDate());
        this.setState({formValues: fv});

        this.setEndOfTheMonth(cd);
    }

    handleYearChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        const fv = this.state.formValues;
        const cd = moment(fv.get('fromDate')).set('year', value).set('date', 1);
        fv.set('fromDate', cd.toDate());
        this.setState({formValues: fv});

        this.setEndOfTheMonth(cd);
    }

    render() {
        return (
            <>
                <div className="card-body">
                    <div className={"row mb-3"}>
                        <div className={"col-auto ml-0 mr-0"}>
                            <form className="form-inline">
                                <div className={"text-nowrap row lh-2em "}>
                                    <div className={"col-12"}>От дата:&nbsp;</div>
                                    <div className={"col-auto"}>
                                        <ReadOnlyDatePickerComponent
                                            onChange={(newDate: Date) => this.selectDate('fromDate', newDate)}
                                            value={moment(this.state.formValues.get('fromDate')).format('DD.MM.YYYY')}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"col-auto mr-0"}>
                            <form className="form-inline">
                                <div className={"text-nowrap row lh-2em "}>
                                    <div className={"col-12"}>До дата:&nbsp;</div>
                                    <div className={"col-auto"}>
                                        <ReadOnlyDatePickerComponent
                                            value={moment(this.state.formValues.get('toDate')).format('DD.MM.YYYY')}
                                            onChange={(newDate: Date) => this.selectDate('toDate', newDate)}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"col-auto mr-0"}>
                            <form className="form-inline">
                                <div className={"text-nowrap row lh-2em "}>
                                    <div className={"col-12"}>Изберете месец/година:&nbsp;</div>
                                    <div className={"col-auto"}>
                                        <InputGroup>
                                            <select className={"form-control text-center w-120px"} onChange={this.handleMonthChange}
                                                    value={moment(this.state.formValues.get('fromDate')).toDate().getMonth()}>
                                                {MONTHS_NAMES.map((m, idx) => <option key={'m_' + idx} value={idx}>{m}</option>)}
                                            </select>
                                            <select className={"form-control text-center w-100px"} onChange={this.handleYearChange}
                                                    value={moment(this.state.formValues.get('fromDate')).toDate().getFullYear()}>
                                                {this.yearsList.map(y => <option key={"yl_" + y} value={y}>{y}</option>)}
                                            </select>
                                        </InputGroup>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className={"col-auto "}>
                            {
                                this.state.loading ?
                                    <>
                                        <label className={"col-form-label w-100 pb-0"}>&nbsp;</label>
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <label className={"col-form-label w-100 pb-0"}>&nbsp;</label>
                                        <button className="btn btn-primary" onClick={() => this.loadReport()}>Калкулирай</button>
                                        &nbsp;
                                        <button className="btn btn-primary" onClick={() => this.exportReport()}>Експорт</button>
                                    </>
                            }
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">

                            <div className={"w-100 overflow-auto"} style={{maxHeight: "50vh"}}>
                                <table className={"table table-bordered w-100 table-sm "} id="tableForm76"
                                       style={{opacity: (this.state.loading ? 0.3 : 1)}}
                                >
                                    {
                                        this.renderHeader()
                                    }

                                    <tbody>
                                    {
                                        this.renderRows()
                                    }
                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="card-footer">
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("8")}>8 часа</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("12")}>12 часа</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setToDayShift()} title="Първа смяна">ПС</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setToNightShift()} title="Нощна смяна">НС</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setToRed()} title="Маркирай в червено">Ч</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("С")}>Самоотлъчка</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("Б")}>Болничен</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("О")}>Отпуска</button>
                    &nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo("")}>Празно</button>
                    {
                        API.user?.is_owner === 'Y' &&
                        <>
                            &nbsp;
                            <button type={"button"} className="btn btn-primary" title={"Актуализира часовата ставка на избраните служители с тази от досиетата им"}
                                    onClick={() => this.updateRates()}>Ставка
                            </button>
                        </>
                    }
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;
                    Друго:&nbsp;
                    <select className="form-control-sm" onChange={(e: any) => this.setState({otherSelection: e.target.value})}>
                        <option value={1}>1 час</option>
                        <option value={2}>2 часа</option>
                        <option value={3}>3 часа</option>
                        <option value={4}>4 часа</option>
                        <option value={5}>5 часа</option>
                        <option value={6}>6 часа</option>
                        <option value={7}>7 часа</option>
                        <option value={9}>9 часа</option>
                        <option value={10}>10 часа</option>
                        <option value={11}>11 часа</option>
                        <option value={13}>13 часа</option>
                        <option value={14}>14 часа</option>
                        <option value={15}>15 часа</option>
                        <option value={16}>16 часа</option>
                        <option value={17}>17 часа</option>
                        <option value={18}>18 часа</option>
                    </select>&nbsp;
                    <button type={"button"} className="btn btn-primary" onClick={() => this.setTo(this.state.otherSelection.toString())}>Приложи</button>
                    <button type={"button"} className="btn btn-secondary float-end " onClick={() => this.deselectAll()}>Изчисти избора</button>

                </div>
            </>
        )
    }

    setToNightShift() {
        let positions: string[] ;
        this.state.selections.forEach(
            (v: boolean, k: string) => {
                if (v) {
                    positions = [k];
                    const [id, date] = k.split('_');

                    if(this.state.form76Data[id] && this.state.form76Data[id][date]) {
                        const newCode = this.state.form76Data[id][date].code.replace('N', '') + 'N';
                        if(newCode !== this.state.form76Data[id][date].code) {
                            const data = {
                                dates: positions,
                                code: newCode
                            };
                            this.updateForm76(data);
                        }
                    }
                }
            }
        )
    }

    setToRed() {
        let positions: string[] ;
        this.state.selections.forEach(
            (v: boolean, k: string) => {
                if (v) {
                    positions = [k];
                    const [id, date] = k.split('_');

                    if(this.state.form76Data[id] && this.state.form76Data[id][date]) {
                        const idx = this.state.form76Data[id][date].code.indexOf('R');
                        const newCode = this.state.form76Data[id][date].code.replace('R', '') + (idx > 0 ? '' : 'R');
                        if(newCode !== this.state.form76Data[id][date].code) {
                            const data = {
                                dates: positions,
                                code: newCode
                            };
                            this.updateForm76(data);
                        }
                    }
                }
            }
        )
    }

    setToDayShift() {
        let positions: string[] ;
        this.state.selections.forEach(
            (v: boolean, k: string) => {
                if (v) {
                    positions = [k];
                    const [id, date] = k.split('_');

                    if(this.state.form76Data[id] && this.state.form76Data[id][date]) {
                        const newCode = this.state.form76Data[id][date].code.replace('N', '');
                        if(newCode !== this.state.form76Data[id][date].code) {
                            const data = {
                                dates: positions,
                                code: newCode
                            };
                            this.updateForm76(data);
                        }
                    }
                }
            }
        )
    }

    updateForm76(data: any) {
        API.updateForm76(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.loadReport();
                    this.deselectAll();
                }
            },
            data
        );
    }

}
