import {Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {CalendarExecutor} from "../../../pages/CalendarPage";
import {API} from "../../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../../common";
import {API_CALL} from "../../../types";

export interface CalendarExecutorEditModalProps {
    title: string;
    executor: CalendarExecutor;
    onClose: () => any;
    onReload: () => any;
}


export function CalendarExecutorEditModal({title, executor, onReload, onClose}: CalendarExecutorEditModalProps) {

    const [working, setWorking] = useState(false);
    const [formValues, setFormValues] = useState<Map<string, any>>(new Map<string, any>());

    useState(() => {
        setFormValues(new Map<string, any>(
            [["id", executor.id], ["name", executor.name], ["description", executor.description],
                ["executor_type", executor.executor_type], ["phone1", executor.phone1],
                ["phone2", executor.phone2], ["email", executor.email]
            ]));
    });

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    function saveValue() {
        API.saveCalendarExecutor(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    onReload();
                    onClose();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {
                id: executor.id,
                name: formValues.get('name'),
                mode: executor.mode,
                description: formValues.get('description'),
                phone1: formValues.get('phone1'),
                phone2: formValues.get('phone2'),
                email: formValues.get('email'),
            } as CalendarExecutor
        );
    }

    return (
        <Modal
            show={true}
            centered
            onHide={() => onClose()}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>{title}</Modal.Title>
                    </div>
                </div>
            </Modal.Header>

            <div className="modal-body">

                <form className={"form"}>
                    <div className="form-row">
                        <label className="form-label mb-0">Име</label>
                        <input type="text" className="form-control" onChange={handleFormChange} name={"name"}
                               value={formValues.get('name')}/>
                    </div>
                    <div className="form-row mt-2">
                        <label className="form-label mb-0">Описание</label>
                        <textarea className="form-control" onChange={handleFormChange} name={"description"} rows={5}>
                            {formValues.get('description')}</textarea>
                    </div>
                    <div className="form-row">
                        <label className="form-label mb-0">Телефон 1</label>
                        <input type="text" className="form-control" onChange={handleFormChange} name={"phone1"}
                               value={formValues.get('phone1')}/>
                    </div>
                    <div className="form-row">
                        <label className="form-label mb-0">Телефон 2</label>
                        <input type="text" className="form-control" onChange={handleFormChange} name={"phone2"}
                               value={formValues.get('phone2')}/>
                    </div>
                    <div className="form-row">
                        <label className="form-label mb-0">E-mail</label>
                        <input type="text" className="form-control" onChange={handleFormChange} name={"email"}
                               value={formValues.get('email')}/>
                    </div>
                </form>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => saveValue()}>Запази</button>
                <button type="button" className="btn btn-secondary" onClick={() => onClose()}>Откажи</button>
            </div>
        </Modal>
    )
}
