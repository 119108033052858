import {
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import {AccountPage} from "../pages/AccountPage";

export default function AccountPageWrapper(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <AccountPage
                router={{ location, navigate, params }}
            />
        );
}
