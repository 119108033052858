import React from "react";
import {API_CALL, BaseReportInfo, HeaterReport, PARAMETERS, PropertyValue} from "../types";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {EditHeaterReportComponent} from "../components/heater/EditHeaterReportComponent";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import {AUTOSAVE_INTERVAL} from "../index";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {ReportBaseInfoComponent} from "../components/common/ReportBaseInfoComponent";
import {ReportsList} from "../components/common/ReportsListComponent";
import {ViewHeaterReportComponent} from "../components/heater/ViewHeaterReportComponent";

interface HeaterPageState {
    working: boolean,
    loading: number,
    selectedDate: moment.Moment,
    shiftId: number,
    report: HeaterReport | null,
    reports: HeaterReport[],
    shifts: PropertyValue[];
    pelet_types: PropertyValue[];
    bag_types: PropertyValue[];
    formValues: Map<string, any>,
    dialogConfirmDeleteVisible: boolean;
    isPreviewMode: boolean;
}

export class HeaterPage extends React.Component<any, HeaterPageState> {
    reportForDeleting?: HeaterReport | null = null;
    autoSaveTrigger: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            working: true,
            loading: 0,
            selectedDate: moment(),
            report: null,
            shiftId: -1,
            reports: [],
            shifts: [],
            pelet_types: [],
            bag_types: [],
            formValues: new Map<string, any>(),
            dialogConfirmDeleteVisible: false,
            isPreviewMode: false,
        }

        this.addReport = this.addReport.bind(this);
        this.editReport = this.editReport.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.autoSaveReport = this.autoSaveReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.doDeleteReport = this.doDeleteReport.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    setAutoSaveInterval() {
        this.autoSaveTrigger = window.setInterval(this.autoSaveReport, AUTOSAVE_INTERVAL);
    }

    clearAutoSaveInterval() {
        if (this.autoSaveTrigger) {
            window.clearInterval(this.autoSaveTrigger)
        }
    }

    componentDidMount() {
        this.loadParameters();
        this.loadReports();
        this.setAutoSaveInterval();
    }

    componentWillUnmount() {
        this.clearAutoSaveInterval();
    }


    loadParameters() {
        this.setState({loading: this.state.loading + 3});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        shifts: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.SHIFTS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        pelet_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bag_types: apiCall.data.data.items?.values.filter(
                            (i: PropertyValue) => {
                                return i.description.usedInProduction;
                            }
                        )
                    });
                }
            }, PARAMETERS.BAG_TYPE
        )
    }

    loadReports(newDate?: Moment) {
        API.getHeater(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                } as BaseReportInfo
                            } as HeaterReport;
                        }
                    );
                    this.setState({reports: reports})
                }

            }, newDate ? newDate : this.state.selectedDate
        )
    }

    addReport() {
        this.loadReports();

        const report = {
            id: -1,
            base_info: {
                report_date: this.state.selectedDate.format('DD.MM.YYYY'),
                shift_id: -1,
                man_cnt: 1,
                from_time: '',
                to_time: ''
            }
        } as HeaterReport;

        this.setState({report: report, formValues: new Map<string, any>(), isPreviewMode: false})
    }

    editReport(report: HeaterReport, isPrint: boolean = false) {
        let isPreviewMode = false;
        if (isPrint || (report.user.id !== API.user?.id && !(API.user?.is_admin === 'Y'))) {
            isPreviewMode = true;
        }
        this.setState({report: report, formValues: new Map<string, any>(), isPreviewMode: isPreviewMode});
    }

    saveReport() {
        this.doSaveReport(false);
    }

    autoSaveReport() {
        if (this.state && this.state.report) this.doSaveReport(true);
    }

    doSaveReport(isAutosave: boolean = false) {
        if (
            !(this.state.formValues?.get('base_info')?.shift_id > -1 &&
                this.state.formValues?.get('base_info')?.man_cnt &&
                this.state.formValues?.get('base_info')?.man_cnt > 0)
        ) return;

        const values = {...mapToObject(this.state.formValues)};

        API.saveHeater(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    if (!isAutosave) {
                        this.setState({report: null});
                        this.loadReports();
                    } else {
                        // @ts-ignore
                        this.state.report.id = apiCall.data.data.report.id;
                    }
                }
                if (isError(apiCall)) {
                    if (!isAutosave) showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }

                this.setState({working: isLoading(apiCall)});
            }, {
                ...values
            }, this.state.report?.id
        );
    }


    deleteReport(report: HeaterReport) {
        this.reportForDeleting = report;
        this.setState({dialogConfirmDeleteVisible: true});
    }

    doDeleteReport() {
        API.deleteHeater(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна следната грешка:');
                }
                if (isSuccess(apiCall)) {
                    this.loadReports();
                    this.setState({dialogConfirmDeleteVisible: false});
                }
            }, this.reportForDeleting?.id
        );
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({formValues: this.state.formValues.set(name, value)});
    }

    render() {

        return (
            <div className="container">

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        {
                                            this.state.report ?
                                                <span className="btn btn-secondary"
                                                      onClick={() => {
                                                          this.setState({report: null});
                                                          this.loadReports();
                                                      }}><FontAwesomeIcon icon={faArrowLeft}/></span>
                                                :
                                                <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                                    <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                        }
                                    </>
                            }

                        </div>
                        <h2 className="col">Печка</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <h4 className="card-title">
                            {
                                this.state.report ?
                                    <>
                                        {
                                            this.state.report.id === -1 ? 'Добавяне' : 'Корекция'
                                        }
                                        &nbsp;на отчет
                                    </>
                                    :
                                    <>Печка отчети</>
                            }
                        </h4>
                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div>Зареждам данните ...</div>
                                }
                                {
                                    this.state.loading === 0 && !this.state.report &&
                                    <>
                                        <div className="card bg-light p-3 mb-3">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto"}>Отчети за дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            value={this.state.selectedDate.format('DD.MM.YYYY')}
                                                            onChange={(date: Date) => {
                                                                this.setState({selectedDate: moment(date)});
                                                                this.loadReports(moment(date));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }

                                <ReportsList
                                    working={!(this.state.loading === 0 && moment.isMoment(this.state.selectedDate) && !this.state.working && !this.state.report)}
                                    reports={this.state.reports}
                                    shifts={this.state.shifts}
                                    editReport={this.editReport}
                                    deleteReport={this.deleteReport}/>

                                {
                                    this.state.working &&
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }

                                {
                                    this.state.report && !this.state.isPreviewMode &&
                                    <>
                                        <ReportBaseInfoComponent
                                            onChange={(data: BaseReportInfo) => {
                                                this.setState({formValues: this.state.formValues.set('base_info', data)});
                                            }}
                                            shifts={this.state.shifts.filter(
                                                s => {
                                                    return this.state.reports.filter(
                                                        r => {
                                                            return r.base_info.shift_id == s.id &&
                                                                s.id != this.state.report?.base_info.shift_id
                                                        }
                                                    ).length === 0
                                                }
                                            )}
                                            reportInfo={this.state.report.base_info}
                                            reportName={"heater_reports"}
                                            reportId={this.state.report.id}
                                            addMiddleShift={false}
                                        />

                                        {
                                            this.state.formValues?.get('base_info')?.shift_id > -1 &&
                                            this.state.formValues?.get('base_info')?.man_cnt &&
                                            this.state.formValues?.get('base_info')?.man_cnt > 0 ?

                                                <EditHeaterReportComponent
                                                    report={this.state.report}
                                                    shifts={this.state.shifts}
                                                    pelet_types={this.state.pelet_types}
                                                    bag_types={this.state.bag_types}
                                                    onChange={(key: string, data: any) => {
                                                        if (key !== 'base_info')
                                                            this.setState({formValues: this.state.formValues.set(key, data)})
                                                    }}
                                                />
                                                :
                                                <div><strong>Моля, изберете работна смяна и въведете броя на служителите, за да продължите с отчета...</strong></div>
                                        }

                                    </>
                                }

                                {
                                    this.state.report && this.state.isPreviewMode &&
                                    <>
                                        <ViewHeaterReportComponent
                                            report={this.state.report}
                                            shifts={this.state.shifts}
                                            pelet_types={this.state.pelet_types}
                                            bag_types={this.state.bag_types}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        {
                            moment.isMoment(this.state.selectedDate) ?
                                <>
                                    {
                                        this.state.report ?
                                            <>
                                                {
                                                    this.state.working ?
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        !this.state.isPreviewMode &&
                                                        <>
                                                            <button className="btn btn-primary" onClick={this.saveReport}>Запази</button>
                                                            &nbsp;
                                                            <button className="btn btn-secondary" onClick={() => {
                                                                this.setState({report: null});
                                                                this.loadReports();
                                                            }}>Откажи
                                                            </button>
                                                        </>
                                                }
                                            </>
                                            :
                                            this.state.reports.length < 2 &&
                                            <>
                                                <button className="btn btn-primary" onClick={() => {
                                                    this.addReport()
                                                }}>Добави нов
                                                </button>
                                            </>
                                    }
                                </>
                                :
                                <><span className="text-info">Моля, изберете дата...</span></>
                        }
                    </div>
                </div>

                <Modal
                    show={this.state.dialogConfirmDeleteVisible}
                    onHide={() => this.setState({dialogConfirmDeleteVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Внимание</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Моля, потвърдете изтриването!</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    <button type={"button"} className={"btn btn-secondary"} onClick={() => this.setState({dialogConfirmDeleteVisible: false})}>Откажи</button>
                                    <button type={"button"} className={"btn btn-primary"} onClick={this.doDeleteReport}>Изтрий</button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>


            </div>
        );
    }

}
