import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, zeroToEmptyString} from "../../common";
import moment, {Moment} from "moment";


interface FuelReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    shiftId?: number,
    data: any
}


export class FuelReportPage extends React.Component<ReportsPageProps, FuelReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
            periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            shiftId: props.shiftId,
            loading: false,
            data: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.calcFuelReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shift: this.props.shiftId,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data} );
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<FuelReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
                periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
                shiftId: this.props.shiftId
            });
            this.loadData();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    this.state.data &&
                    <>
                        <BlockTitleComponent title={"ФАДРОМИ - заредено гориво по превозни средства по дни"}/>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                {
                                    this.state.data.fadroms.map(
                                        (f: any, idx: any) => <th key={idx} style={{textAlign: "right"}}>{ f.value }</th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(this.state.data.refuel).map(
                                    (date: string, idx: number) => <tr key={idx} className={date === "Всичко" ? "bg-light" : ""}>
                                        <td>{date}</td>
                                        {
                                            this.state.data.fadroms.map(
                                                (f: any, idx: any) => <td key={idx} align={"right"}>{zeroToEmptyString(this.state.data.refuel[date]['fadroms'][f.value], ' л.')}</td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>

                        <BlockTitleComponent title={"МОТОКАРИ - заредено гориво по превозни средства по дни"}/>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                {
                                    this.state.data.motocars.map(
                                        (f: any, idx: any) => <th key={idx} style={{textAlign: "right"}}>{ f.value }</th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                Object.keys(this.state.data.refuel).map(
                                    (date: string, idx: number) => <tr key={idx} className={date === "Всичко" ? "bg-light" : ""}>
                                        <td>{date}</td>
                                        {
                                            this.state.data.motocars.map(
                                                (f: any, idx: any) => <td key={idx} align={"right"}>{zeroToEmptyString(this.state.data.refuel[date]['motocars'][f.value], ' бут.')}</td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </>

                }
            </>
        );
    }

}
