import {ReportsPage} from "./ReportsPage";
import {FinancialReportsPage} from "./FinancialReportsPage";
import {Tab, Tabs} from "react-bootstrap";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {API} from "../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export function ReportFinancialPage(props: any) {
    return <>
        <div className="container" style={{maxWidth: '100%'}}>

            <div className="card shadow">

                <div className="card-header text-center row">
                    <div className="col-2 text-start">
                        <Link to={PATHS.home + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                    </div>
                    <h2 className="col">Справки Тайфун</h2>
                    <div className="col-2">
                    </div>
                </div>

                <div className="card-body">
                    <Tabs className={"mb-3"}>
                        <Tab eventKey={"reports"} title={"Статистика на дейността"}>
                            <ReportsPage hideCard={true}/>
                        </Tab>
                        <Tab eventKey={"fin"} title={"Финансови справки"}>
                            <FinancialReportsPage hideCard={true}/>
                        </Tab>
                    </Tabs>
                </div>

                <div className="card-footer">{<></>}</div></div>

        </div>
    </>
}
