import React from "react";
import {API_CALL, OrderingReport, PARAMETERS, PAY_TYPE, PropertyValue, Sale, SaleItem} from "../types";
import {
    bagTypeTitle,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    showMsgInToast,
    translateError,
    zeroToEmptyString,
    zeroToEmptyStringAsCurrency
} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ExpNotesList} from "../components/daily-reports/ExpNotesList";
import {BankExpensesEditModal} from "../components/bank-exp/BankExpensesEditModal";
import {InputGroup} from "react-bootstrap";

interface FuelBalancePageProps {
    selectedDate?: Moment;
}

interface FuelBalancePageState {
    loading: boolean,
    editMode: boolean,
    editTypeId: number,
    editYear: number,
    editMonth: number,
    editValue: number,
    expenses_fuel: any,
    expenses_repairs: any,
    incomes: any,
    balance: any,
    formValues: Map<string, any>,
}

export class FuelBalancePage extends React.Component<FuelBalancePageProps, FuelBalancePageState> {
    yearsList: number[] = [];
    monthsList: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    monthsCount: number[] = [6, 12, 24, 36, 48];

    constructor(props: any) {
        super(props);

        for (let i = new Date().getFullYear(); i > 2018; i--) this.yearsList.push(i);

        const fv = new Map<string, any>();
        fv.set('fromYear', moment().year());
        fv.set('fromMonth', 12);
        fv.set('monthCount', 12);

        this.state = {
            loading: true,
            editMode: false,
            editYear: 0,
            editMonth: 0,
            editTypeId: 0,
            editValue: 0,
            expenses_fuel: {},
            expenses_repairs: {},
            incomes: {},
            balance: {},
            formValues: fv,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.editValue = this.editValue.bind(this);
        this.saveValue = this.saveValue.bind(this);
    }

    componentDidMount() {
        this.loadReports();
    }

    loadReports() {
        API.getFuelBalance(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {

                    this.setState({
                        expenses_fuel: apiCall.data.data.expenses_fuel,
                        expenses_repairs: apiCall.data.data.expenses_repairs,
                        incomes: apiCall.data.data.incomes,
                        balance: apiCall.data.data.balance,
                    });
                }
            },
            this.state.formValues.get('fromYear'),
            this.state.formValues.get('fromMonth'),
            this.state.formValues.get('monthCount')
        );
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    editValue(expName: string, y: string, m: string, value: string) {
        this.setState({
            editMode: true,
            editYear: Number(y),
            editMonth: Number(m),
            editTypeId: Number(expName),
            editValue: Number(value)
        })
    }

    saveValue(newValue: number) {
        this.setState({editMode: false});
        API.setBankExpenses(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.loadReports();
                }
            },
            {
                'exp_type': this.state.editTypeId,
                'exp_year': this.state.editYear,
                'exp_month': this.state.editMonth,
                'exp_value': newValue
            }
        );
    }

    render() {

        const renderHeader = function (expenses: any) {
            if (!expenses || Object.keys(expenses).length === 0) return '';

            const years = Object.keys(expenses);

            return (
                <>
                    <thead className={"text-center"}>
                    <tr>
                        <th rowSpan={2}>Група</th>
                        {
                            years.map(
                                (y: any) => <th key={y} colSpan={Object.keys(expenses[y]).length}>{y}</th>
                            )
                        }

                        <th rowSpan={2}>Всичко</th>
                    </tr>
                    <tr>
                        {
                            years.map(
                                (y: any) => {
                                    return Object.keys(expenses[y]).sort((a, b) => {
                                        return a.localeCompare(b);
                                    }).map(
                                        (m: any) => <th key={y + '.' + m}>{m}</th>
                                    )
                                }
                            )
                        }
                    </tr>
                    </thead>
                </>
            )
        }

        const renderExpYears = function (exp: any) {
            let expTotal = 0;
            const cells: any = [];
            const years = Object.keys(exp);

            years.forEach(
                (y: any) => {
                    const months = Object.keys(exp[y]);
                    months.sort((a, b) => a.localeCompare(b))
                        .forEach(
                            (m: any) => {
                                cells.push(
                                    <td className={"text-end"} key={'cell_' + y + '.' + m}>
                                        {zeroToEmptyStringAsCurrency(exp[y][m])}
                                    </td>);
                                expTotal += exp[y][m] * 1;
                            }
                        )
                }
            );
            cells.push(<td
                className={"font-weight-bold text-end"}>{zeroToEmptyStringAsCurrency(expTotal.toString())}</td>)
            return cells;
        }

        return (
            <div className="container" style={{maxWidth: "100%"}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                this.state.loading ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Приходи и разходи транспорт</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                {
                                    <>
                                        <div className="card bg-light p-3 mb-3">
                                            <form className="form-inline">
                                                <div className={"lh-2em row text-nowrap"}>
                                                    <div className={"col-auto"}>От месец/година:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <InputGroup>
                                                            <select className="form-control w-50px"
                                                                    onChange={e => this.state.formValues.set('fromMonth', Number(e.target.value))}>
                                                                {
                                                                    this.monthsList.map(m =>
                                                                        <option value={m} key={m}
                                                                                selected={Number(m) === this.state.formValues.get('fromMonth')}>{m}</option>
                                                                    )
                                                                }
                                                            </select>
                                                            <select className="form-control w-100px"
                                                                    onChange={e => this.state.formValues.set('fromYear', Number(e.target.value))}>
                                                                {this.yearsList.map(y =>
                                                                    <option value={y} key={y}
                                                                            selected={Number(y) === this.state.formValues.get('fromYear')}>{y}</option>)}
                                                            </select>
                                                        </InputGroup>
                                                    </div>
                                                    <div className={"col-auto w-160px"}>Брой месеци назад:&nbsp;</div>
                                                    <div className={"col-auto w-120px"}>
                                                        <select className="form-control"
                                                                onChange={e => this.state.formValues.set('monthCount', Number(e.target.value))}>
                                                            {
                                                                this.monthsCount.map(m =>
                                                                    <option value={m} key={m}
                                                                            selected={Number(m) === this.state.formValues.get('monthCount')}>{m}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className={"col-auto w-120px"}>
                                                        <button className="btn btn-primary"
                                                                onClick={() => this.loadReports()}
                                                                type={"button"}>Покажи
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }

                                <div className={"w-100 overflow-auto"}>
                                    <table className={"table table-bordered w-100 " +
                                        (this.state.formValues.get('monthCount') > 24 ? 'table-sm' : '')}
                                           style={{opacity: (this.state.loading ? 0.3 : 1)}}
                                    >
                                        {
                                            renderHeader(this.state.expenses_fuel)
                                        }

                                        <tbody>
                                        <tr
                                            className={"bg-danger text-light"}>
                                            <td>Разходи за гориво</td>
                                            {
                                                renderExpYears(this.state.expenses_fuel)
                                            }
                                        </tr>
                                        <tr
                                            className={"bg-danger text-light"}>
                                            <td>Разходи за ремонти</td>
                                            {
                                                renderExpYears(this.state.expenses_repairs)
                                            }
                                        </tr>
                                        <tr
                                            className={"bg-success text-light"}>
                                            <td>Приходи</td>
                                            {
                                                renderExpYears(this.state.incomes)
                                            }
                                        </tr>
                                        <tr
                                            className={"bg-info text-dark fw-bold"}>
                                            <td>Баланс</td>
                                            {
                                                renderExpYears(this.state.balance)
                                            }
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="card-footer">
                    </div>
                </div>
            </div>
        );
    }

}
