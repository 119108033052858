import {Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {Division} from "./Form76Divisions";

export interface Form76DivisionEditModalProps {
    d: Division|null;
    onSave: (newValue: string) => any;
    onClose: any;
}


export function Form76DivisionEditModal(props: Form76DivisionEditModalProps) {

    const [newValue, setNewValue] = useState<string>(props.d ? props.d.name : '');

    function handleChange(e: any) {
        setNewValue(e.target.value);
    }

    function saveValue() {
        props.onSave(newValue);
    }

    return (
        <Modal
            show={true}
            centered
            size={"sm"}
            onHide={() => props.onClose()}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>{props.d ? 'Kорекция' : 'Добавяне'} на отдел</Modal.Title>
                    </div>
                </div>
            </Modal.Header>

            <div className="modal-body">

                <form>
                    <div className="mb-3">
                        <label className="form-label">Име на отдела</label>
                        <input type="text" className="form-control" onChange={handleChange}
                               value={newValue}/>
                    </div>
                </form>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => saveValue()}>Запази</button>
                <button type="button" className="btn btn-secondary" onClick={() => props.onClose()}>Откажи</button>
            </div>
        </Modal>
    )
}
