import ReactPlayer from "react-player";
import React, {useState} from "react";
import {MultimediaVideoProps} from "./MultimediaVideo";
import moment from "moment";


export default function MultimediaVideoTaifun({onEnded}: MultimediaVideoProps) {
    // const urlToVideo = (moment().isBefore(moment().year(2023).dayOfYear(1)) ? 'https://youtu.be/uTUlliscCiA' : 'https://youtu.be/0zQGMSiaJ2Y');
    // const urlToVideo = "https://youtu.be/WgXH-Twb2wE";
    // const urlToVideo = "https://youtu.be/3H-pqfCpI4k";
    const urlToVideo = "https://youtu.be/_BLDhpmSOJE";

    return (
        <div className={"media-screen-card-outer"} style={{marginTop: '0'}}>
            <div className={"media-screen-card-innner"}>
                <div className={"media-screen-card"} id={"employees-screen"}>
                    <ReactPlayer
                        loop={false}
                        playing={true}
                        controls={true}
                        url={urlToVideo}
                        width='100%'
                        height='950px'
                        style={{overflow: "hidden"}}
                        onEnded={onEnded}
                    />
                </div>
            </div>
        </div>
    )
}
