import React, {useState} from "react";
import {Account} from "../../types";
import {getUserByName} from "../../common";
import {StatusPageRateProps} from "../../pages/StatusPage";
import RenderRate from "./RenderRate";

export default function RenderPercentRate({reports, users}: StatusPageRateProps) {
    if (!reports) return null;

    const results = new Map<string, number>();
    reports.forEach(
        (r: any) => {
            let oldVal = 0;
            if (results.has(r.user)) {
                oldVal = results.get(r.user) || 0;
            }
            results.set(r.user, r.percentage + oldVal);
        }
    )
    let sorted: Array<any> = [];
    // @ts-ignore
    for (const i of results) {
        if(sorted.length < 3) sorted.push({name: i[0], value: i[1]})
        else break;
    }

    sorted = sorted.sort((a: any, b: any) => {
        return b.value - a.value
    });

    const rate: Array<Account> = [];
    sorted.forEach(u => {
        const _a = getUserByName(u.name, users);
        if(_a) rate.push(_a);
    });

    return <RenderRate rate={rate} />
}
