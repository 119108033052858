import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isLoading, isSuccess} from "../../common";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";



export class UserNameComponent extends React.Component {

    render() {
        return (
            <>
                {
                    !API.user &&
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
                {
                    API.user &&
                    <>
                        <strong style={{ color: "#bb332c", paddingLeft: 10, paddingRight: 10 }}><FontAwesomeIcon icon={faUserCircle} size={"lg"}/>
                            <span className={"text-light"}>&nbsp;&nbsp;&nbsp;{API.user.name}</span></strong>
                    </>
                }
            </>
        );
    }

}
