import {Col, Row} from "react-bootstrap";

export default function MediaScreenHeader(props: any) {
    return (
        <Row className={"flex-fill ms-5"}>
            {
                props.tabs && props.tabs.map(
                    (t: string, idx: number) =>
                        (idx == props.tabs.length-1) ?
                        <Col key={idx}
                             xs={"auto"}>
                            <div className={"media-screen-header-item media-screen-header-item-active "}> {t} </div>
                        </Col> :
                        <Col key={idx}>
                            <div className={"media-screen-header-item"}> {t} </div>
                        </Col>
                )
            }
        </Row>
    )
}