import React from "react";
import {API_CALL} from "../../types";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, mapToObject} from "../../common";
import moment, {Moment} from "moment";
import {MaterialsChart} from "./MaterialsChart";


interface MaterialsReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    shiftId?: number,
    data: any | null
}


export class MaterialsReportPage extends React.Component<ReportsPageProps, MaterialsReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
            periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            shiftId: props.shiftId,
            loading: false,
            data: null
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({data: null});

        API.calcMaterialsReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shift: this.props.shiftId,
            }
        )
    }

    processData(data: any) {
        const totals = new Map<string, any>();
        totals.set('value', 'Всичко пелети');

        const fromPeriod = moment(this.props.periodFrom, 'DD.MM.YYYY');
        const toPeriod = moment(this.props.periodTo, 'DD.MM.YYYY');
        toPeriod.add(1, 'days');

        while (fromPeriod.isBefore(toPeriod)) {
            let dayTotal = (data.heater[fromPeriod.format('DD.MM.YYYY')] || 0) + (data.oven[fromPeriod.format('DD.MM.YYYY')] || 0);
            totals.set(fromPeriod.format('DD.MM.YYYY'), dayTotal);
            fromPeriod.add(1, 'days');
        }

        data.totals = mapToObject(totals);

        console.log(data);

        this.setState({data: data});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<MaterialsReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
                periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
                shiftId: this.props.shiftId
            });
            this.loadData();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data &&
                    <>
                        <MaterialsChart title={"Разход пелети в отдел ПЕЧКА"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.heater}/>

                        <MaterialsChart title={"Разход пелети в отдел ПЕЩ"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.oven}/>

                        <MaterialsChart title={"Всичко разход пелети"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.totals}/>

                        <MaterialsChart title={"Разход глина"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.clay}/>

                        <MaterialsChart title={"Разход шлам"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.sludge}/>

                        <MaterialsChart title={"Разход гориво - газ"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.fuel['gas']}/>

                        <MaterialsChart title={"Разход гориво - дизел"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.fuel['diesel']}/>

                        <MaterialsChart title={"Разход стретч"}
                                        periodFrom={this.state.periodFrom}
                                        periodTo={this.state.periodTo}
                                        shiftId={this.state.shiftId}
                                        data={this.state.data.stretch}/>

                    </>

                }

            </>
        );
    }

}
