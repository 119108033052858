import {PropertyValue} from "../../types";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import StatusCardHeader from "./StatusCardHeader";
import MediaCardProductivityChart from "./MediaCardProductivityChart";

export default function RenderHeaterShift({
                                              reports,
                                              idx,
                                              shifts
                                          }: { idx: number, reports: any, shifts: Array<PropertyValue> }) {
    if (!reports || !reports[idx]) return null;

    const report = reports[idx];
    return (
        <>
            <StatusCardHeader report={report} shifts={shifts}/>
            <table className={"w-100"}>
                <tr>
                    <td rowSpan={3}>
                        <MediaCardProductivityChart productivity={report.percent} width={100} height={100}/>
                    </td>
                    <td>Разх.норма:</td>
                    <td className={"text-end fw-bold "}>{report.percent} %</td>
                </tr>
                <tr>
                    <td>Пелети</td>
                    <td className={"text-end fw-bold "}>{report.kg} кг.</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td className={"text-end fw-bold "}></td>
                </tr>
            </table>
            <div className={"media-screen-card-content-footer"}>
                <Row><Col className={"text-center fw-bold"}>{report.user}</Col></Row>
            </div>
        </>
    )
}