import React from "react";
import {API_CALL, Client, PARAMETERS, PropertyValue, REPAIR_TYPE} from "../../types";
import {API} from "../../api";
import {
    isError,
    isLoading,
    isSuccess,
    minutesToHours,
    numberWithCommas,
    showErrorsInToast,
    translateError,
    zeroToEmptyStringAsNumber
} from "../../common";
import {EditClientAccountModal} from "../clients/EditClientAccountModal";
import {Accordion} from "react-bootstrap";
import {ClientNotPaidItemCard} from "./ClientNotPaidItemCard";
import {totalmem} from "os";
import {isWorker} from "cluster";


interface ClientsNotPaidReportPageState {
    loading: number,
    working: boolean,
    data: any,
    total: number,
    editClientAccount: Client | null,
    bricks: PropertyValue[],
    pelet_types: PropertyValue[];
    bag_types: PropertyValue[];
}

interface ClientsNotPaidReportPageProps {
    periodTo: string,
}

export class ClientsNotPaidReportPage extends React.Component<ClientsNotPaidReportPageProps, ClientsNotPaidReportPageState> {
    constructor(props: ClientsNotPaidReportPageProps) {
        super(props);
        this.state = {
            loading: 0,
            working: false,
            data: null,
            total: 0,
            editClientAccount: null,
            bricks: [],
            pelet_types: [],
            bag_types: [],
        }

        this.loadData = this.loadData.bind(this);
        this.loadParams();
    }

    async componentDidMount() {
        this.loadData();
    }

    shouldComponentUpdate(nextProps: Readonly<ClientsNotPaidReportPageProps>, nextState: Readonly<ClientsNotPaidReportPageState>, nextContext: any): boolean {
        if(nextProps.periodTo !== this.props.periodTo) {
            this.loadData();
        };

        return (nextState.data !== this.state.data) || (nextState.total !== this.state.total);
    }

    loadData() {
        if(this.state.working) return;

        API.getAccountWithNotPaid(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    let total = 0;
                    const list = apiCall.data.data.list;
                    list.forEach((i: any) => {
                        const invPrice: number = parseFloat(i.inv_price || 0);
                        const paletPrice: number = parseFloat(i.inv_palets_price || 0);
                        const logisticPrice: number = parseFloat(i.inv_logistic_price || 0);
                        const paletPayed: number = parseFloat(i.inv_palets_payed || 0);
                        const logisticPayed: number = parseFloat(i.inv_logistic_payed || 0);
                        const totalPayments: number = parseFloat(i.total_payments || 0);

                        i.inv_price = invPrice + paletPrice + logisticPrice;
                        i.inv_payed = paletPayed + logisticPayed + totalPayments;

                        total += i.inv_price - i.inv_payed;
                    });

                    this.setState({total: total});
                    this.setState({data: list});
                    this.setState({loading: this.state.loading - 1});
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка!', translateError);
                    this.setState({loading: this.state.loading - 1});
                }
            }, {
                periodTo: this.props.periodTo,
            }
        )
    }

    loadParams() {
        this.setState({loading: 3});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    bricks.push({
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II качество'
                        }
                    });
                    this.setState({
                        loading: this.state.loading - 1,
                        bricks: bricks
                    });
                }
            }, PARAMETERS.BRICKS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        pelet_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bag_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BAG_TYPE
        )
    }

    renderReports() {
        const data = this.state.data;
        if (data.length === 0) {
            return [];
        }

        let clientId: number = -1;
        let items: any = [];
        const cards: any = [];

        for (let idx = 0; idx <= data.length; idx++) {
            if(idx < data.length) {
                const item = data[idx];

                if (clientId !== item.client_id) {
                    if (clientId !== -1) {
                        cards.push(items);
                    }

                    clientId = item.client_id;
                    items = [item];
                } else {
                    items.push(item);
                }
            } else {
                cards.push(items);
            }
        }

        return cards;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            ((this.state.loading > 0) || this.state.working) &&
                            <div className={"m-3"}>Зареждам данните ... {this.state.loading}</div>
                        }
                    </div>
                </div>
                {
                    this.state.data &&
                    <>
                        <div className="row p-2">
                            <div className="col p-3 text-end bg-danger text-light fw-bold">
                                ВСИЧКО:&nbsp;&nbsp;&nbsp; -{zeroToEmptyStringAsNumber(this.state.total, ' лв.')}
                            </div>
                        </div>
                        <Accordion>
                            {this.renderReports().map(
                                (i: any, idx: number) =>
                                    <ClientNotPaidItemCard key={idx}
                                                           items={i}
                                                           bricks={this.state.bricks}
                                                           bag_types={this.state.bag_types}
                                                           pelet_types={this.state.pelet_types}
                                    />
                            )}
                        </Accordion>
                    </>
                }

                {
                    this.state.editClientAccount &&
                    <EditClientAccountModal client={this.state.editClientAccount}
                                            onClose={() => {
                                                this.setState({editClientAccount: null})
                                            }}/>
                }
            </>
        );
    }
}
