import React from "react";
import {OvenProduction, PropertyValue} from "../../types";
import {mapToObject} from "../../common";

export interface OvenProductionComponentProps {
    production: OvenProduction[],
    brick_types: PropertyValue[],
    onChange: any,
}

export interface OvenProductionComponentState {
    formValues: Map<string, any>,
}

const ROW_COUNT = 22;

export class OvenProductionComponent extends React.Component<OvenProductionComponentProps, OvenProductionComponentState> {
    constructor(props: OvenProductionComponentProps) {
        super(props);
        this.state = {
            formValues: new Map<string, any>(),
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.notifyParent = this.notifyParent.bind(this);

        this.props.production?.forEach(
            p => this.state.formValues.set('wagonQty_' + p.brick_id, p.wagon_qty)
        );

        this.notifyParent(this.state.formValues);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        );

        this.notifyParent(fv);
    }

    notifyParent(fv: Map<string, any>) {
        const items: OvenProduction[] = [];

        if (this.props.onChange) {
            fv.forEach(
                (i, idx) => {
                    items.push({
                        brick_id: Number.parseInt(idx.replace('wagonQty_', '')),
                        wagon_qty: i
                    } as OvenProduction);
                }
            )
            this.props.onChange('production', items);
        }
    }

    render() {
        return (
            <>
                <table className="table table-hover table-sm table-bordered table-no-padding" id="table-oven-temps">
                    <thead>
                    <tr className={"text-center"}>
                        {
                            Array.from(this.props.brick_types).map(
                                (bt: PropertyValue) => <th key={bt.id}>{bt.description.shortName}</th>
                            )
                        }
                    </tr>
                    </thead>
                    <tbody>
                    <tr className={"text-center"}>
                        {
                            Array.from(this.props.brick_types).map(
                                (bt: PropertyValue) =>
                                    <td key={bt.id}><input className="form-control border-0" type="number" name={"wagonQty_" + bt.id}
                                           value={this.state.formValues.get('wagonQty_' + bt.id)}
                                               onChange={this.handleFormChange} /> </td>

                            )
                        }
                    </tr>
                    </tbody>
                </table>
            </>
        );
    }
}
