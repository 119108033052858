import {Button, Table} from "react-bootstrap";
import React, {useState} from "react";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CalendarExecutor} from "../../../pages/CalendarPage";
import {CalendarExecutorEditModal} from "./CalendarExecutorEditModal";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import {API} from "../../../api";
import {API_CALL} from "../../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../../common";

export interface CalendarExecutorsListProps {
    loading: boolean;
    executors: CalendarExecutor[];
    onReload: () => any;
}


export function CalendarExecutorsList({loading, executors, onReload}: CalendarExecutorsListProps) {

    const [working, setWorking] = useState(false);
    const [editExecutor, setEditExecutor] = useState<CalendarExecutor | null>(null);
    const [deleteExecutor, setDeleteExecutor] = useState<CalendarExecutor | null>(null);

    function doDelete() {
        if(deleteExecutor) {
            API.deleteCalendarExecutor(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));
                    if(isSuccess(apiCall)) { onReload(); setDeleteExecutor(null); }
                    if(isError(apiCall)) showErrorsInToast(apiCall, "Възникна грешка", translateError);
                }, deleteExecutor.id
            )
        }
    }

    return (
        <>
            <div className={"row"} style={{opacity: (loading ? 0.3 : 1)}}>
                <div className={"col-12"}>
                    <Table bordered>
                        <thead>
                        <tr className={"bg-secondary text-light font-weight-bold"}>
                            <td>Име / фирма</td>
                            <td>Описание</td>
                            <td>Телефон 1</td>
                            <td>Телефон 2</td>
                            <td>E-mail</td>
                            <td className={"w-120px text-end"}>Операции</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            executors.length === 0 &&
                            <tr>
                                <td colSpan={6}><strong>Няма добавени записи</strong></td>
                            </tr>
                        }
                        {
                            executors.map(
                                e =>
                                    <tr key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.description}</td>
                                        <td>{e.phone1}</td>
                                        <td>{e.phone2}</td>
                                        <td>{e.email}</td>
                                        <td className={"w-120px text-end"}>
                                            <Button size={"sm"} onClick={() => setEditExecutor(e)}><FontAwesomeIcon icon={faPen}/></Button>&nbsp;
                                            <Button size={"sm"} onClick={() => setDeleteExecutor(e)}><FontAwesomeIcon icon={faTrash}/></Button>
                                        </td>
                                    </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

            {
                editExecutor &&
                <CalendarExecutorEditModal title={"Корекция на изпълнител"}
                                           executor={editExecutor} onReload={() => onReload()} onClose={() => { setEditExecutor(null)}}/>
            }

            {
                deleteExecutor &&
                <ConfirmDeleteModal working={working} onConfirm={() => doDelete()} onReject={() => setDeleteExecutor(null)} />
            }
        </>
    )
}
