import React, {ChangeEvent} from "react";
import {API_CALL, Charging, City, Client, ExtruderProduction, HeaterReport, PropertyValue} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TimePickerComponent} from "../common/TimePickerComponent";
import {Modal} from "react-bootstrap";
import {EditClientCity} from "./EditClientCity";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast} from "../../common";

export interface EditClientDetailsProps {
    client: Client,
    onSave: any,
    onCancel: any;
}

export interface EditClientDetailsState {
    loading: boolean,
    editCity: City | null,
    isNewCity: boolean,
    formValues: Map<string, any>,
}

export class EditClientDetails extends React.Component<EditClientDetailsProps, EditClientDetailsState> {
    constructor(props: EditClientDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            editCity: null,
            isNewCity: false,
            formValues: new Map<string, any>(),
        }

        this.state.formValues.set('name', this.props.client.name);
        this.state.formValues.set('isForeign', this.props.client.isForeign);
        this.state.formValues.set('cities', this.props.client.cities);

        this.handleFormChange = this.handleFormChange.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.addCityItem = this.addCityItem.bind(this);
        this.editCityItem = this.editCityItem.bind(this);
        this.saveCityItem = this.saveCityItem.bind(this);
        this.removeCityItem = this.removeCityItem.bind(this);
        this.renderCityRows = this.renderCityRows.bind(this);
        this.saveClient = this.saveClient.bind(this);
        this.cancelClient = this.cancelClient.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({formValues: this.state.formValues.set(name, value)});
    }

    saveItem(event: any) {
    }


    renderCityRows() {
        return this.state.formValues.get('cities').length == 0 ?
            <tr>
                <td colSpan={3}><strong>Няма добавени градове</strong></td>
            </tr>
            :
            this.state.formValues.get('cities').map(
                (item: City, idx: number) => <tr key={idx}>
                    <td>{item.city}</td>
                    <td>{item.phone}</td>
                    <td className={"text-nowrap text-end"}>
                        <span className="btn btn-primary btn-light" onClick={() => this.editCityItem(idx)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                        <span className="btn btn-primary btn-light" onClick={() => this.removeCityItem(idx)}><FontAwesomeIcon icon="trash-alt"/></span>
                    </td>
                </tr>
            );
    }

    addCityItem() {
        this.setState({editCity: {city: '', phone: ''} as City, isNewCity: true});
    }

    editCityItem(idx: number) {
        this.setState({editCity: this.state.formValues.get('cities')[idx], isNewCity: false});
    }

    saveCityItem(city: City, isNew: boolean) {
        const cities = this.state.formValues.get('cities');
        if (isNew) cities.push(city);
        else {
            if (this.state.editCity) {
                this.state.editCity.city = city.city;
                this.state.editCity.phone = city.phone;
            }
        }

        this.setState({formValues: this.state.formValues.set('cities', cities), editCity: null});
    }

    removeCityItem(idx: number) {
        const cities = this.state.formValues.get('cities');
        cities.splice(idx, 1);

        this.setState({formValues: this.state.formValues.set('cities', cities)});
    }


    saveClient() {
        const c: Client = {
            id: this.props.client.id,
            name: this.state.formValues.get('name'),
            isForeign: this.state.formValues.get('isForeign') ? 1 : 0,
            cities: this.state.formValues.get('cities'),
            balance_avans_bank: 0,
            balance_avans_cash: 0,
            balance_credit: 0,
            balance_bank: 0,
            balance_cash: 0,
            balance_logistics: 0,
            balance_palet: 0,
            pricelist: []
        };

        API.saveClientData(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if(isSuccess(apiCall)) {
                    this.props.onSave(apiCall.data.data);
                }
                if(isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
            }, c
        );
    }

    cancelClient() {
        this.props.onCancel();
    }


    render() {
        return ( this.state.loading ?
                <>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </>
                :
                <div>
                    <div className={"row mb-3"}>
                        <div className={"col"}>
                            <label>Име на клиента:</label>
                            <input className="form-control" type="text" name="name" required
                                   value={this.state.formValues.get('name')}
                                   onChange={this.handleFormChange}/>
                        </div>
                    </div>

                    <div className={"row mb-3 ps-3"}>
                        <div className={"col-auto form-check"}>
                            <input className="form-check-input" type="checkbox" name="isForeign" required
                                   value={"1"} checked={this.state.formValues.get('isForeign') || false} id="isForeign"
                                   onChange={this.handleFormChange}/>
                            <label className="form-check-label" htmlFor="isForeign">
                                Клиента е чужденец
                            </label>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col">
                            <label>Градове:</label>
                            <table className={"table table-sm"}>
                                <thead>
                                <tr>
                                    <th>Град</th>
                                    <th>Телефон</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderCityRows()}
                                <tr>
                                    <td colSpan={3}>
                                        <button className={"btn btn-outline-primary w-100 font-weight-bold"} onClick={this.addCityItem}>
                                            Добави нов град
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <button type={"button"} className={"btn btn-primary"} onClick={this.saveClient}>Запази</button>&nbsp;
                            <button type={"button"} className={"btn btn-secondary"} onClick={this.cancelClient}>Откажи</button>
                        </div>
                    </div>

                    {
                        this.state.editCity &&
                        <Modal
                            show={true}
                            centered
                            onHide={() => {
                                this.setState({editCity: null})
                            }}
                            size={"sm"}
                            style={{zIndex: 10016}}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{(this.state.isNewCity ? 'Добавяне' : 'Корекция')} на град</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <EditClientCity
                                        city={this.state.editCity}
                                        onSave={city => this.saveCityItem(city, this.state.isNewCity)}
                                        onCancel={() => this.setState({editCity: null})}
                                    />
                                </div>
                            </Modal.Body>
                        </Modal>
                    }
                </div>
        );
    }

}
