import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import {MultimediaVideoProps} from "./MultimediaVideo";
import useInterval from "../../../hooks/useInterval";
import {Row} from "react-bootstrap";
import {Animated} from "react-animated-css";
import {API} from "../../../api";
import {Account, API_CALL} from "../../../types";
import {isError, isLoading, isSuccess} from "../../../common";
import moment from "moment";


export default function MultimediaVideoBirthday({onEnded}: MultimediaVideoProps) {

    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(-1);
    const [activeVideo, setActiveVideo] = useState(0);
    const [users, setUsers] = useState<Array<Account>>([]);


    useEffect(() => {
        API.getAllAccounts((apiCall: API_CALL) => {
            setLoading(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                const dm = moment().format('DD.MM');
                const _users = apiCall.data.data.users.filter(
                    (u: Account) => {
                        return u.birth_date?.startsWith(dm) && u.greeting_card;
                    }
                );

                if (_users.length === 0) onEnded();
                setUsers(_users);
            }
            if (isError(apiCall)) {
                onEnded();
            }
        })
    }, []);


    function nextSlide() {
        setActive(active + 1);
    }

    useInterval(() => nextSlide(), 3000);

    return (
        <div className={"media-screen-card-outer  overflow-hidden"}
             style={{marginTop: '0'}}>
            <div className={"media-screen-card-innner"}>
                <div className={"media-screen-card"} id={"employees-screen"}>

                    <div className={"content-fluid text-center"}>

                        {
                            active > -3 && active < 1 &&
                            <Row className={"align-content-center justify-content-center text-center"}>
                                <Animated animationIn={"bounceInDown"} animationOut={"bounceOutUp"}
                                          animationInDelay={200} animationOutDelay={200}
                                          animationInDuration={2500} animationOutDuration={2500}
                                          isVisible={active < 0} animateOnMount={true}>
                                    <h1 style={{
                                        fontFamily: "fantasy",
                                        paddingLeft: "5px",
                                        paddingTop: "5px",
                                        fontSize: "5rem",
                                        marginTop: "25vh",
                                        color: "rgba(187, 51, 44, 1)",
                                        position: "fixed",
                                        left: "28%",
                                        top: "50%"
                                    }}>ДНЕС ДА ПОЧЕРПЯТ !</h1>
                                </Animated>

                                <Animated animationIn={"bounceInUp"} animationOut={"bounceOutUp"}
                                          animationInDuration={3000} animationOutDuration={3000}
                                          isVisible={active < 0} animateOnMount={true}>
                                    <h1 style={{
                                        fontFamily: "fantasy",
                                        fontSize: "5rem",
                                        marginTop: "25vh",
                                        position: "fixed",
                                        left: "28%",
                                        top: "50%"
                                    }}>ДНЕС ДА ПОЧЕРПЯТ !</h1>
                                </Animated>
                            </Row>
                        }

                        {
                            active > 0 && users.map(
                                (u: Account, idx: number) =>

                                    <Animated key={idx}
                                              animationIn={"fadeIn"} animationOut={"fadeOut"}
                                              animationInDuration={1000} animationOutDuration={1000}
                                              isVisible={idx == activeVideo}
                                              animationInDelay={1000} animationOutDelay={1000}>
                                        {
                                            idx == activeVideo &&
                                            <Row>
                                                <ReactPlayer
                                                    loop={false}
                                                    playing={idx == activeVideo}
                                                    controls={true}
                                                    url={u.greeting_card}
                                                    width='100%'
                                                    height='950px'
                                                    style={{overflow: "hidden"}}
                                                    onEnded={() => {
                                                        activeVideo === users.length - 1 ? onEnded() : setActiveVideo(activeVideo + 1)
                                                    }}
                                                />
                                            </Row>
                                        }
                                    </Animated>
                            )
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}
