import {Button, Table} from "react-bootstrap";
import React, {useState} from "react";
import {CalendarElement} from "../../../pages/CalendarPage";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CalendarElementEditModal} from "./CalendarElementEditModal";
import {API} from "../../../api";
import {API_CALL} from "../../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../../common";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";

export interface CalendarElementsListProps {
    loading: boolean;
    elements: CalendarElement[];
    onReload: () => any;
}


export function CalendarElementsList({loading, elements, onReload}: CalendarElementsListProps) {

    const [working, setWorking] = useState(false);
    const [editElement, setEditElement] = useState<CalendarElement | null>(null);
    const [deleteElement, setDeleteElement] = useState<CalendarElement | null>(null);

    function doDelete() {
        if(deleteElement) {
            API.deleteCalendarElement(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));
                    if(isSuccess(apiCall)) { onReload(); setDeleteElement(null); }
                    if(isError(apiCall)) showErrorsInToast(apiCall, "Възникна грешка", translateError);
                }, deleteElement.id
            )
        }
    }

    return (
        <>
            <div className={"row"} style={{opacity: (loading ? 0.3 : 1)}}>
                <div className={"col-12"}>
                    <Table bordered>
                        <thead>
                        <tr className={"bg-secondary text-light font-weight-bold"}>
                            <td>Наименование</td>
                            <td>Описание</td>
                            <td className={"w-120px text-end"}>Операции</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            elements.length === 0 &&
                            <tr>
                                <td colSpan={3}><strong>Няма добавени записи</strong></td>
                            </tr>
                        }
                        {
                            elements.map(
                                e =>
                                    <tr key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.description}</td>
                                        <td className={"w-120px text-end"}>
                                            <Button size={"sm"} onClick={() => setEditElement(e)}><FontAwesomeIcon icon={faPen}/></Button>&nbsp;
                                            <Button size={"sm"} onClick={() => setDeleteElement(e)}><FontAwesomeIcon icon={faTrash}/></Button>
                                        </td>
                                    </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

            {
                editElement &&
                <CalendarElementEditModal title={"Корекция на машина/съоръжение"}
                                          element={editElement} onReload={onReload} onClose={() => { setEditElement(null)}}/>
            }

            {
                deleteElement &&
                <ConfirmDeleteModal working={working} onConfirm={() => doDelete()} onReject={() => setDeleteElement(null)} />
            }

        </>
    )
}
