import React from "react";

export default function ModalBackdrop({children}: any) {
    return (
        <div style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%",
            zIndex: 9998, backgroundColor: "gray", opacity: "0.5"
        }}>
            { children }
        </div>
    )
}