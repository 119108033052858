import React, {useEffect} from "react";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useLocation} from "react-router-dom";
import {UserNameComponent} from "../components/common/UserNameComponent";
import {API} from "../api";
import {API_CALL} from "../types";
import {isSuccess} from "../common";
import UserAlertsComponent from "../components/common/UserAlertsComponent";
import UserMessagesComponent from "../components/messages/UserMessagesComponent";
import MediaScreenHeader from "./MediaScreenHeader";
import UserBonusesComponent from "../components/bonuses/UserBonusesComponent";

export function HeaderComponent(props: any) {

    const logout = function () {
        API.getUserLogout(
            (api: API_CALL) => {
                if (isSuccess(api)) {
                    API.user = null;
                    window.location.href = "/login";
                }
            }
        )
    }

    const location = useLocation();

    return (
        <>
            <Link to={PATHS.home + API.getAPITokenAsQuery()} className={"text-dark"}>
                <img src={"/logo.svg"} style={{height: "3em"}}/>
            </Link>

            {
                location.pathname !== PATHS.status &&
                <span>
                    <a className={"btn btn-outline-primary home-btn-outline-primary text-light"}
                       href={"/home" + API.getAPITokenAsQuery()}>
                        <FontAwesomeIcon icon="home"/>
                    </a>
                    &nbsp;&nbsp;
                    {
                        location.pathname !== PATHS.login &&
                        <>
                            {/*{*/}
                            {/*    API.user?.is_admin === 'Y' &&*/}
                            {/*    <Link to={PATHS.home + API.getAPITokenAsQuery()}*/}
                            {/*          className={"btn btn-outline-primary home-btn-outline-primary text-light"}>*/}
                            {/*        Начален екран*/}
                            {/*    </Link>*/}
                            {/*}*/}
                            {/*&nbsp;&nbsp;*/}
                            {/*<Link to={PATHS.status + API.getAPITokenAsQuery()}*/}
                            {/*      className={"btn btn-outline-primary home-btn-outline-primary text-light"}>*/}
                            {/*    Медиен екран*/}
                            {/*</Link>*/}
                            &nbsp;&nbsp;
                            <UserMessagesComponent/>
                            &nbsp;&nbsp;
                            <UserBonusesComponent/>
                            &nbsp;&nbsp;
                            <UserAlertsComponent/>
                            &nbsp;&nbsp;
                            <a className={"btn btn-outline-primary home-btn-outline-primary text-light"} href={"#"}
                               onClick={logout}>
                                <FontAwesomeIcon icon="sign-out-alt" size="sm"/> Изход
                            </a>
                            &nbsp;&nbsp;
                            <UserNameComponent/>
                        </>
                    }
                </span>
            }

            {
                location.pathname === PATHS.status &&
                <MediaScreenHeader {...props} />
            }
        </>
    )
}
