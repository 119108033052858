import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import {MultimediaVideoProps} from "./MultimediaVideo";
import {API} from "../../../api";
import {isError, isLoading, isSuccess} from "../../../common";
import {Account, API_CALL} from "../../../types";
import {Col, Container, Row} from "react-bootstrap";
import {Animated} from "react-animated-css";
import moment from "moment";
import useInterval from "../../../hooks/useInterval";


interface DepartmentSlide {
    name: string;
    users: Account[];
}

export default function MultimediaVideoEmployees({onEnded}: MultimediaVideoProps) {

    // setInterval(() => { onEnded() }, 5000);

    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState(-3);
    const [departments, setDepartments] = useState<Array<DepartmentSlide>>([]);
    const [users, setUsers] = useState<Array<Account>>([]);

    const [prevVisible, setPrevVisible] = useState(true)

    useEffect(() => {
        API.getAllAccounts((apiCall: API_CALL) => {
            setLoading(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                // debugger
                prepareData(apiCall.data.data.users);
            }
            if (isError(apiCall)) {
                onEnded();
            }
        })
    }, []);

    function prepareData(data: any) {
        let _dep: Array<DepartmentSlide> = [];
        let _depNames: Array<string> = [];
        data.forEach(
            (item: Account) => {
                if (item.department &&
                    item.department.trim().length > 0 &&
                    _depNames.indexOf(item.department) === -1) _depNames.push(item.department)
            }
        )
// debugger
        _depNames.forEach(
            d => {
                const users = data.filter((u: Account) => u.department === d);
                const pageCnt = Math.ceil(users.length / 5);

                for(let i = 0; i < pageCnt; i++) {
                    _dep.push({name: d, users: users.slice(i*5, i*5+5)})
                }
            }
        )

        setDepartments(_dep);
        setUsers(data);
    }

    function nextSlide() {
        if (active > departments.length - 1) onEnded()
        else setActive(active + 1);
    }

    useInterval(() => nextSlide(), 5000);


    function getClassName(dep: DepartmentSlide) {
        const idx = departments.indexOf(dep);
        let _active = active;

        if (idx > _active + 2) return "d-none";
        if (idx < _active - 2) return "d-none";

        if (idx > _active + 1) return "media-department-wait";
        if (idx < _active - 1) return "media-department-out";

        if (idx === _active) return "media-department-current";
        if (idx === _active - 1) return "media-department-prev";
        if (idx === _active + 1) return "media-department-next";
    }

    return (
        <div className={"media-screen-card-outer  overflow-hidden"}
             style={{marginTop: '0'}}>
            <div className={"media-screen-card-innner"}>
                <div className={"media-screen-card"} id={"employees-screen"}>

                    <div className={"content-fluid text-center"}>

                        {
                            active > -3 && active < 1 &&
                            <Row className={"align-content-center justify-content-center text-center"}>
                                <Animated animationIn={"bounceInDown"} animationOut={"bounceOutUp"}
                                          animationInDelay={200} animationOutDelay={200}
                                          animationInDuration={2500} animationOutDuration={2500}
                                          isVisible={active < 0} animateOnMount={true}>
                                    <h1 style={{
                                        fontFamily: "fantasy",
                                        paddingLeft: "5px",
                                        paddingTop: "5px",
                                        fontSize: "5rem",
                                        marginTop: "25vh",
                                        color: "rgba(187, 51, 44, 1)",
                                        position: "fixed",
                                        left: "38%",
                                        top: "50%"
                                    }}>НАШИЯТ ЕКИП</h1>
                                </Animated>

                                <Animated animationIn={"bounceInUp"} animationOut={"bounceOutUp"}
                                          animationInDuration={3000} animationOutDuration={3000}
                                          isVisible={active < 0} animateOnMount={true}>
                                    <h1 style={{
                                        fontFamily: "fantasy",
                                        fontSize: "5rem",
                                        marginTop: "25vh",
                                        position: "fixed",
                                        left: "38%",
                                        top: "50%"
                                    }}>НАШИЯТ ЕКИП</h1>
                                </Animated>
                            </Row>
                        }


                            {
                                departments.map(
                                    d => <RenderMediaDepartmentWrapper department={d} containerClass={getClassName(d)}
                                                                key={d.name}/>
                                )
                            }

                    </div>

                </div>
            </div>
        </div>
    )
}

function RenderMediaDepartmentWrapper({department, containerClass}: { department: DepartmentSlide, containerClass: any }) {
    return <Row key={department.name}><RenderMediaDepartment department={department.name} containerClass={containerClass} users={department.users} /></Row>
}

function RenderMediaDepartment({users, department, containerClass}: { users: Array<Account>, department: string, containerClass: any }) {
    return (
        <div className={containerClass}>
            <Row className={"align-content-center justify-content-center text-center"} xs={12}>
                <h1> {department.toUpperCase() === 'ОБЩ РАБОТНИК' ? 'СЪТРУДНИК ПРОИЗВОДСТВО' : department} </h1>
                <Container fluid>
                    <Row className={"w-100 flex-wrap align-content-between justify-content-center mt-3"}
                         style={{zoom: 0.75}}>
                        {
                            users.map(u => <Col key={u.id} className={"media-department-user-card"}>
                                <RenderMediaDepartmentUser user={u}/>
                            </Col>)
                        }
                    </Row>
                </Container>
            </Row>
        </div>
    )
}

function RenderMediaDepartmentUser({user}: { user: Account }) {
    const src = 'http://erp.taifun-bg.com/api/user/' + user?.id + '/photo';

    return (
        <>
            <div>
                <img src={src} className={"media-department-user-image"}/>
            </div>
            <div className={"media-department-user-name"}>
                {user.name.toUpperCase()}
            </div>
            <div className={"media-department-user-data"}>
                {user.position.toLowerCase()}
            </div>
            {
                user.first_date &&
                <div className={"media-department-user-data"}>
                    {
                        moment().diff(moment(user.first_date, 'DD.MM.YYYY'), "years")
                    }
                    &nbsp;год. трудов стаж
                </div>
            }
        </>
    )
}