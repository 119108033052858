import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import {faCoins} from "@fortawesome/free-solid-svg-icons";
import BonusesControlModal from "./BonusesControlModal";
import {API} from "../../api";


export interface MessageContent {
    id?: number;
    send_date?: string;
    title: string;
    content: string;
}

export interface MessageUser {
    id: number;
    name: string;
    position: string;
}

export interface Message {
    id?: number;
    message_id?: number;
    sender_id?: number;
    receiver_id?: number;
    is_readed?: number;
    is_deleted?: number;
    message: MessageContent;
    sender?: MessageUser;
    receiver?: MessageUser;
}

export default function UserBonusesComponent() {
    const [dialogVisible, setDialogVisible] = useState(false)

    // const acceptableUsers = [4, 40, 42, 43, 45, 57, 50, 53, 55];

    // if(acceptableUsers.indexOf(API.user?.id || -1) == -1 ) return null;

    if(!API.user?.hasBonus) return null;

    return (
        <>
            <Button className={"btn btn-outline-primary home-btn-outline-primary text-light"} onClick={ () => setDialogVisible(true) }>
                <FontAwesomeIcon icon={faCoins}/> Бонус&nbsp;
            </Button>

            <BonusesControlModal visible={dialogVisible} onHide={() => setDialogVisible(false)} />
        </>
    );
}
