import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours, numberWithCommas, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import moment, {Moment} from "moment";


interface PeletsSludgeSaldoReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    data: any,
}

export class PeletsSludgeSaldoReportPage extends React.Component<ReportsPageProps, PeletsSludgeSaldoReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
            periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            loading: false,
            data: null,
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.calcSludgePeletsSaldoReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
            }
        )
    }

    processData(data: any) {
        this.setState({data: data});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<PeletsSludgeSaldoReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo)
        ) {
            this.setState({
                periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
                periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            });
            this.loadData();
        }
    }

    renderReports() {
        let total6i = 0;
        let total6w = 0;
        let total6c = 0;
        let total8i = 0;
        let total8w = 0;
        let total8c = 0;
        let totalSludgei = 0;
        let totalSludgew = 0;

        return <table className={"table table-sm align-top text-center table-bordered"}>
            <thead>
            <tr>
                <th rowSpan={3}>Дата</th>
                <th colSpan={8}>Пелети</th>
                <th colSpan={3} className={"bg-light"}>Шлам</th>
            </tr>
            <tr>
                <th colSpan={2}>Приход</th>
                <th colSpan={2}>Разход</th>
                <th colSpan={2}>Продажби</th>
                <th colSpan={2}>Разлика</th>
                <th className={"bg-light"}>Приход</th>
                <th className={"bg-light"}>Разход</th>
                <th className={"bg-light"}>Разлика</th>
            </tr>
            <tr>
                <th className={"bg-light"}>6 mm</th>
                <th>8 mm</th>
                <th className={"bg-light"}>6 mm</th>
                <th>8 mm</th>
                <th className={"bg-light"}>6 mm</th>
                <th>8 mm</th>
                <th className={"bg-light"}>6 mm</th>
                <th>8 mm</th>
                <th className={"bg-light"}></th>
                <th className={"bg-light"}></th>
                <th className={"bg-light"}></th>
            </tr>
            </thead>
            <tbody>
            {
                this.state.data.map(
                    (item: any) => {
                        item.pelets_6_withdraw = item.pelets_6_withdraw || 0;
                        item.pelets_8_withdraw = item.pelets_8_withdraw || 0;

                        total6i += item.pelets_6_income * 1;
                        total6w += item.pelets_6_withdraw * 1;
                        total6c += item.pelets_6_consumption * 1;
                        total8i += item.pelets_8_income * 1;
                        total8w += item.pelets_8_withdraw * 1;
                        total8c += item.pelets_8_consumption * 1;
                        totalSludgei += item.sludge_income * 1;
                        totalSludgew += item.sludge_withdraw * 1;

                        return <tr>
                            <td>{item.date}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_6_income), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_8_income), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_6_consumption), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_8_consumption), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_6_withdraw), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.pelets_8_withdraw), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(1*item.pelets_6_income - 1*item.pelets_6_withdraw - 1*item.pelets_6_consumption), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(1*item.pelets_8_income - 1*item.pelets_8_withdraw - 1*item.pelets_8_consumption), ' кг')}</td>

                            <td>{zeroToEmptyString(numberWithCommas(item.sludge_income), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(item.sludge_withdraw), ' кг')}</td>
                            <td>{zeroToEmptyString(numberWithCommas(1*item.sludge_income - 1*item.sludge_withdraw), ' кг')}</td>
                        </tr>
                    }
                )
            }
            <tr className={"bg-secondary text-light font-weight-bold"}>
                <td>Всичко</td>
                <td>{zeroToEmptyString(numberWithCommas(total6i), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total8i), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(1*total6c), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(1*total8c), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total6w), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(total8w), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(1*total6i - 1*total6w - 1*total6c), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(1*total8i - 1*total8w - 1*total8c), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(totalSludgei), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(totalSludgew), ' кг')}</td>
                <td>{zeroToEmptyString(numberWithCommas(1*totalSludgei - 1*totalSludgew), ' кг')}</td>
            </tr>
            </tbody>
        </table>;
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data && this.renderReports()
                }
            </>
        );
    }
}
