import React, {useEffect, useState} from "react";
import Highcharts from 'highcharts'
import highcharts3d from "highcharts/highcharts-3d";
import HighchartsReact from 'highcharts-react-official'
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {getShiftName, isLoading, isSuccess} from "../../common";
import {API} from "../../api";
import moment, {Moment} from "moment";
import {Card, Col, Row} from "react-bootstrap";
import {isNullOrUndefined} from "util";

highcharts3d(Highcharts)

const defaultOptions = {
    chart: {
        type: 'column',
        height: '420px',
        options3d: {
            enabled: false,
            alpha: 15,
            beta: 15,
            viewDistance: 100,
            depth: 50
        }
    },
    title: null,
    subtitle: null,
    colors: ['#2f7ed8', '#f50000', '#f2f200', '#00f2f2'],
    tooltip: {
        enabled: true,
    },
    xAxis: {
        categories: ['Мелене(глина, шлам)', 'Екструдиране', 'Печка(пелети)', 'Редене', 'Пещ(пелети)', 'Разтоварване'],
        title: {
            text: null
        },
    },
    yAxis: {
        min: 0,
        max: 150,
        title: {
            text: 'Производителност (%)',
            align: 'high'
        },
        labels: {
            overflow: 'justify',
            style: {
                fontSize: "12px"
            }
        },
        plotLines: [{
            value: 100,
            width: 2,
            color: 'rgba(0, 0, 0)',
            label: {useHTML: true, text: 'Стандарт', align: 'right'}
        }],
    },
    plotOptions: {
        column: {
            depth: 50
        },
        series: {
            dataLabels: {
                useHTML: true,
                enabled: false,
                inside: false,
                overflow: 'allow',
                crop: true,
                backgroundColor: 'rgba(0,0,0,0.8)',
                borderColor: 'rgba(0,0,0,0.9)',
                color: 'rgba(255,255,255,0.75)',
                borderWidth: .5,
                borderRadius: 5,
                // x: 60,
                y: -10,
                style: {
                    fontFamily: 'Helvetica, sans-serif',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    textShadow: 'none'
                },
                formatter: function (): any {
                    // @ts-ignore
                    return this.point.label;
                }
            }
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Производство',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Разход',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Брак',
        data: [null, null, null, null, null, null]
    }]
}

interface ProductionCardProps {
    title: string | null;
    labelStyles?: string;
    periodFrom: string;
    periodTo: string;
    shift?: number;
}

export function ProductionCard(props: ProductionCardProps) {
    const shifts = ['Първа', 'Втора'];
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState<any>(defaultOptions);

    const loadData = function () {
        if(!API.user?.is_admin) return null;

        API.calcProductionReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    processData(apiCall.data.data.report);
                }
            }, props
        )
    }

    const processData = function (data: any) {
        let maxY = 125;
        let grindingClay = 0;
        let grindingSludge = 0;
        if (data.grindings.clay > 0) {
            grindingClay = data.grindings.clay > 0 ? Math.round(data.grindings.clay / data.grindings.standart * 100) : 0;
            grindingSludge = data.grindings.sludge > 0 ? Math.round(data.grindings.sludge / data.grindings.standart_sludge * 100) : 0;
        }

        // debugger
        if (grindingClay > maxY) maxY = grindingClay + 10;
        if (data.extruder.percent > maxY) maxY = data.extruder.percent + 10;
        if (data.extruder.waste_percent > maxY) maxY = data.extruder.waste_percent + 10;
        if (data.ordering.percent > maxY) maxY = data.ordering.percent + 10;
        if (data.ordering.waste_percent > maxY) maxY = data.ordering.waste_percent + 10;
        if (data.oven.percent > maxY) maxY = data.oven.percent + 10;
        if (data.oven.peleti_percent > maxY) maxY = data.oven.peleti_percent + 10;
        if (data.oven.peleti_heater_percent > maxY) maxY = data.oven.peleti_heater_percent + 10;
        if (data.unloading.percent > maxY) maxY = data.unloading.percent + 10;
        if (data.unloading.waste_percent > maxY) maxY = data.unloading.waste_percent + 10;
        if (data.unloading.not_good_percent > maxY) maxY = data.unloading.not_good_percent + 10;
        maxY += 75;


        // @ts-ignore
        const labelStyle =  props.labelStyles || "";
        const productionLabel = function () {
            let text =  'Произв.глина: ' + grindingClay + '%' + '<br/>Глина: ' + data.grindings.clay + 'кг<br/>Произв.шлам: ' +
                grindingSludge + '%<br/>Шлам: ' + data.grindings.sludge + 'кг<br/>' + 'Съотн: 1:' +
                (isNaN(Math.round(data.grindings.clay / data.grindings.sludge)) ? 0 : (data.grindings.clay / data.grindings.sludge).toFixed(1));
            return text;
        }
        const extruderLabel = function () {
            return 'Натовареност: ' + data.extruder.percent + '%' + '<br/>Тухли: ' + data.extruder.bricks + 'бр<br/>' +
                Object.keys(data.extruder.byType).filter(
                    key => data.extruder.byType[key] > 0
                ).map(
                    key => key + ': ' + data.extruder.byType[key] + 'бр'
                ).join('<br/>') +
                '<br/>Брак: ' + data.extruder['waste'] + 'бр - ' +
                (isNaN(Math.round((data.extruder['waste'] / data.extruder['bricks']) * 100)) ? 0 : ((data.extruder['waste'] / data.extruder['bricks']) * 100).toFixed(1)) + '%';
        }
        const ovenLabel = function () {
            return 'Произв: ' + data.oven.percent + '%' + '<br/>Тухли: ' + data.oven.total_bricks + 'бр' +
                '<br/>Пещ произв: ' + data.oven.peleti_percent + '%' + '<br/>Пелети: ' + data.oven.peleti_total + 'кг' +
                '<br/>Печка произв: ' + data.oven.peleti_heater_percent + '%' + '<br/>Пелети: ' + data.oven.peleti_heater_total + 'кг';
        }
        const unloadingLabel = function () {
            return 'Произв: ' + data.unloading.percent + '%' + '<br/>Тухли: ' + data.unloading.total_bricks + 'бр' +
                '<br/>Брак: ' + data.unloading.total_waste + 'бр - ' +
                (isNaN(Math.round((data.unloading['total_waste'] / data.unloading['total_bricks']) * 100)) ?
                    0 :
                    ((data.unloading['total_waste'] / data.unloading['total_bricks']) * 100).toFixed(1)) + '%<br/>' +
                'II кач.: ' + data.unloading.total_not_good + 'бр. - ' +
                (isNaN(Math.round((data.unloading['total_not_good'] / data.unloading['total_bricks']) * 100)) ?
                    0 :
                    ((data.unloading['total_not_good'] / data.unloading['total_bricks']) * 100).toFixed(1)) + '%'
        }

        const orderingLabel = function () {
            return 'Произв: ' + data.ordering.percent + '%' + '<br/>Тухли: ' + data.ordering.total_bricks + 'бр' +
                '<br/>Брак: ' + data.ordering.total_waste + 'бр - ' +
                (isNaN(Math.round((data.ordering.total_waste / data.ordering.total_bricks) * 100)) ?
                    0 :
                    (((data.ordering.total_waste / data.ordering.total_bricks) * 100).toFixed(1)) + '%')
        }


        const newOptions = {
            ...options,
            xAxis: {
                categories: [
                    ((data.grindings?.user !== "") ? '<strong>' + data.grindings?.user + '</strong><br/>' : '') +
                    '<span  style="' + labelStyle + '">Мелене(глина, шлам)<br/>Брой хора: ' + data.grindings?.mans + '</span>',

                    ((data.extruder?.user !== "") ? '<strong>' + data.extruder?.user + '</strong><br/>' : '') +
                    '<span  style="' + labelStyle + '">Екструдиране<br/>Брой хора: ' + data.extruder?.mans + '</span>',

                    ((data.heater?.user !== "") ? '<strong>' + data.heater?.user + '</strong><br/>' : '') +
                    '<span  style="' + labelStyle + '">Печка(пелети)<br/>Брой хора: ' + data.heater?.mans + '</span>',

                    ((data.ordering?.user !== "") ? '<strong>' + data.ordering?.user + '</strong><br/>' : '') +
                    '<span  style="' + labelStyle + '">Редене<br/>Брой хора: ' + data.ordering?.mans + '</span>',

                    ((data.oven?.user !== "") ? '<strong>' + data.oven?.user + '</strong><br/>' : '') +
                    '<span  style="' + labelStyle + '">Пещ(пелети)<br/>Брой хора: ' + data.oven?.mans + '</span>',

                    ((data.unloading?.user !== "") ? '<strong>' + data.unloading?.user + '</strong><br/>' : '') +
                    '<span  style="' + labelStyle + '">Разтоварване<br/>Брой хора: ' + data.unloading?.mans + '</span>'
                ],
            },
            series: [
                {
                    name: 'Производство',
                    data: [
                        {
                            y: grindingClay,
                            label: (grindingClay >= grindingSludge) ? productionLabel() : null
                        },
                        {
                            y: data.extruder.percent,
                            label: data.extruder.percent >= data.extruder.waste_perc ? extruderLabel() : null
                        },
                        null,
                        {
                            y: data.ordering.percent,
                            label: data.ordering.percent > data.ordering.waste_percent ? orderingLabel() : null
                        },
                        {
                            y: data.oven.percent,
                            label: ((data.oven.percent >= data.oven.peleti_percent) && (data.oven.percent >= data.oven.peleti_heater_percent)) ? ovenLabel() : null
                        },
                        {
                            y: data.unloading.percent,
                            label: (data.unloading.percent >= data.unloading.waste_percent) ? unloadingLabel() : null
                        }]
                },
                {
                    name: 'Разход (глина, пелети)',
                    data: [
                        {
                            y: grindingSludge,
                            label: grindingClay < grindingSludge ? productionLabel() : null
                        },
                        null,
                        {
                            y: data.heater?.percent,
                            label: 'Разх.норма: ' + data.heater?.percent + '%' + '<br/>Пелети: ' + data.heater?.kg + 'кг'
                        },
                        null,
                        {
                            y: data.oven.peleti_percent,
                            label: ((data.oven.peleti_percent >= data.oven.percent) && (data.oven.peleti_percent >= data.oven.peleti_heater_percent))  ? ovenLabel() : null
                        },
                        null
                    ]
                },
                {
                    name: 'Брак',
                    data: [
                        null,
                        {
                            y: data.extruder['waste_perc'],
                            label: data.extruder.percent >= data.extruder.waste_perc ? null : extruderLabel()
                        },
                        null,
                        {
                            y : data.ordering.waste_percent,
                            label: data.ordering.waste_percent > data.ordering.percent ? orderingLabel() : null,
                        },
                        {
                            color: '#f50000',
                            y: data.oven.peleti_heater_percent,
                            label: ((data.oven.peleti_heater_percent >= data.oven.peleti_percent) && (data.oven.peleti_heater_percent >= data.oven.percent))
                                ? ovenLabel() : null
                        },
                        {
                            y: data.unloading.waste_percent,
                            label: (data.unloading.waste_percent > data.unloading.percent) ? unloadingLabel() : null
                        }
                    ]
                },
                {
                    name: 'II кач',
                    data: [null,
                        null,
                        null,
                        null,
                        null,
                        {
                            y: data.unloading.not_good_percent,
                            label: data.unloading.not_good_percent + '%'
                        }
                    ]
                }
            ]
        };

        setOptions(newOptions);
    }

    useEffect(() => {
        loadData()
    }, [])


    const module = API.user?.modules.find(
        m => m.app_modules_id === 17
    );

    if(!API.user?.is_admin) return null;

    return (
        <Card>
            {
                props.title &&
                <Card.Header className={"home-card-header main-modules-header main-modules-header-primary-text"}>
                    <Row className={"fw-bold"}>
                        <Col>
                            <h2>
                                {props.title}
                            </h2>
                        </Col>
                    </Row>
                </Card.Header>
            }
            <Card.Body className={"home-card-body"}>
                <div className="row" >
                    <div className="col">
                        {
                            loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                        {
                            !loading &&
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                allowChartUpdate
                            />
                        }
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
