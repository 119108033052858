import React, {useState} from "react";
import {Animated} from "react-animated-css";
import {StatusSwitchTabInterval} from "../../App";

interface MediaScreenCardProps {
    children?: React.ReactNode;
}

export default function MediaScreenCard(props: MediaScreenCardProps) {
    const [inAnim, setInAnim] = useState(true);
    window.setTimeout(() => setInAnim(false), StatusSwitchTabInterval - 1000);

    return (

        <div className={"media-screen-card"}>
            <div className={"media-screen-card-outer"}>
                <div className={"media-screen-card-innner"}>
                    <div className={"media-screen-card-content"}>
                        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={inAnim}>
                            {props.children}
                        </Animated>
                    </div>
                </div>
            </div>
        </div>

    )

}