import {Accordion, Col, Row, Table} from "react-bootstrap";
import React from "react";
import {zeroToEmptyStringAsCurrency, zeroToEmptyStringAsNumber} from "../../common";
import {PropertyValue, SALE_TYPE} from "../../types";

export interface ClientNotPaidItemCard {
    items: any[];
    bricks: PropertyValue[],
    pelet_types: PropertyValue[];
    bag_types: PropertyValue[];
}

export function ClientNotPaidItemCard({items, bricks, pelet_types, bag_types}: ClientNotPaidItemCard) {

    let cTotal = 0;
    items.forEach(
        i => {
            cTotal += i.inv_price - i.inv_payed
        }
    );

    const Articuls = (props: { type: string; articuls: any[]; rd: string }) => {
        if (props.type === 'others') {
            const data = JSON.parse(props.rd);
            return (
                <Table borderless size={'sm'} className={"m-0"}>
                    {
                        [0, 1, 2, 3, 4, 5].map(
                            idx => {
                                return (data['others_' + idx]) ?
                                    <tr key={idx} className={"text-end small"}>
                                        <td className={"text-start"}>{data['others_' + idx]}</td>
                                        <td>{data['others_qty_' + idx]} {data['others_me_' + idx]}</td>
                                        <td>х {zeroToEmptyStringAsNumber(data['others_price_' + idx], ' лв.')}</td>
                                        <td>= {zeroToEmptyStringAsNumber(1 * data['others_qty_' + idx] * data['others_price_' + idx], ' лв.')}</td>
                                        <td></td>
                                    </tr> : null
                            }
                        )
                    }
                </Table>
            )

        }


        return (
            <Table borderless size={'sm'} className={"m-0"}>
                {
                    props.articuls.map(i => {
                            let name = '';
                            let me = '';
                            let meQty = 0;
                            let iQty = 0;
                            let itemPrice = 0;
                            let total = '';

                            if (i.property_id !== null) {
                                bricks.forEach(b => {
                                    if (b.id == i.property_id) name = b.value
                                });
                                me = 'пал.'
                                meQty = i.palet_qty * 1;
                                iQty = i.qty_in_palet * 1;
                                itemPrice = (1 * i.item_price) / (meQty * iQty);
                                total = (meQty * iQty) + " бр.";
                            } else {
                                let peletPropertyName = '';

                                bag_types.forEach(b => {
                                    if (b.id == i.bag_type) {
                                        me = b.value;
                                        peletPropertyName = b.description.peletPropertyName;
                                    }
                                });

                                pelet_types.forEach(b => {
                                    if (b.id == i.pelet_type) {
                                        name = "ПЕЛЕТИ " + b.value
                                        if (peletPropertyName !== '') {
                                            iQty = b.description[peletPropertyName] * 1;
                                        }
                                    }
                                });
                                meQty = i.bag_qty * 1;
                                itemPrice = (1 * i.item_price) / (meQty * iQty);
                                total = (meQty * iQty) + ' кг.';
                            }
                            return (
                                <tr key={i.id} className={"text-end small"}>
                                    <td className={"text-start"}>{name}</td>
                                    <td>{meQty} {me}</td>
                                    <td>х {iQty}</td>
                                    <td>= {total}</td>
                                    <td>x {zeroToEmptyStringAsCurrency(itemPrice.toString(), ' лв.')}</td>
                                    <td>{zeroToEmptyStringAsNumber(i.item_price, ' лв.')}</td>
                                </tr>
                            )
                        }
                    )
                }
            </Table>
        )
    }

    return items.length > 0 ?
        (
            <Accordion.Item eventKey={items[0].client_id}>
                <Accordion.Header>
                    <Table size={'sm'} borderless className={"mb-0"}>
                        <Row>
                            <Col>
                                {items[0].client}
                            </Col>
                            <Col>
                                {items[0].client_city}
                            </Col>
                            <Col>
                                {items[0].client_phone}
                            </Col>
                            <Col className={"text-end pe-5 text-danger"}>
                                -{zeroToEmptyStringAsNumber(cTotal, ' лв.')}
                            </Col>
                        </Row>
                    </Table>
                </Accordion.Header>
                <Accordion.Body>
                    <Table size={'sm'} bordered className={"w-100"} hover>
                        <thead>
                        <tr className={"bg-secondary-light"}>
                            <th>Дата</th>
                            <th>Експ.бележка</th>
                            <th>Тип</th>
                            <th>Закупени артикули</th>
                            <th className={"text-end"}>Сума</th>
                            <th className={"text-end"}>Платено</th>
                            <th className={"text-end"}>Остатък</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            items.map(i => {
                                    let total = i.inv_price - i.inv_payed;

                                    return (
                                        <tr key={i.id}>
                                            <td>{i.sales_date}</td>
                                            <td>{i.sales_number}</td>
                                            <td>{Object.values(SALE_TYPE)[Object.keys(SALE_TYPE).indexOf(i.sale_type as string)]}</td>
                                            <td>
                                                <Articuls type={i.sale_type} articuls={i.items} rd={i.request_data}/>
                                            </td>
                                            <td className={"text-end"}>{zeroToEmptyStringAsCurrency(i.inv_price, ' лв.')}</td>
                                            <td className={"text-end"}>{zeroToEmptyStringAsCurrency(i.inv_payed.toFixed(2), ' лв.')}</td>
                                            <td className={"text-end"}>{zeroToEmptyStringAsCurrency(total.toFixed(2), ' лв.')}</td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </Accordion.Body>
            </Accordion.Item>
        ) : null;
}
