import {Modal} from "react-bootstrap";
import React from "react";
import {Client} from "../../types";
import {EditClientDetails} from "./EditClientDetails";

export interface EditClientModalProps {
    client: Client;
    onSave: (client: Client) => any;
    onClose: any;
}

export function EditClientModal(props: EditClientModalProps) {

    return (
        <Modal
            show={true}
            centered
            onHide={props.onClose}
            style={{zIndex: 10016}}
        >
            <Modal.Header closeButton>
                <Modal.Title>{(props.client.id == -1 ? 'Добавяне' : 'Корекция')} на клиент</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className={"modal-90 m-3"} style={{maxWidth: '30em'}}>
                    <EditClientDetails
                        client={props.client}
                        onSave={props.onSave}
                        onCancel={props.onClose}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}
