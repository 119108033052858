import {PropertyValue} from "../../types";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import StatusCardHeader from "./StatusCardHeader";
import MediaCardProductivityChart from "./MediaCardProductivityChart";

export default function RenderGrindingShift({reports, idx, shifts}: { idx: number, reports: any, shifts: Array<PropertyValue> }) {
    if (!reports || !reports[idx]) return null;

    const report = reports[idx];
    let index = '1:' +
        (isNaN(Math.round(report.clay / report.sludge)) ? 0 : (report.clay / report.sludge).toFixed(1));
    return (
        <>
            <StatusCardHeader report={report} shifts={shifts} />
            <table className={"w-100"}>
                <tr>
                    <td rowSpan={3}>
                        <MediaCardProductivityChart productivity={100} width={100} height={100} />
                    </td>
                    <td>Глина</td>
                    <td className={"text-end fw-bold"}>{report.clay}</td>
                </tr>
                <tr>
                    <td>Шлам</td>
                    <td className={"text-end fw-bold"}>{report.sludge}</td>
                </tr>
                <tr>
                    <td>Съотношение</td>
                    <td className={"text-end fw-bold"}>{index}</td>
                </tr>
            </table>
            <div className={"media-screen-card-content-footer"}>
                <Row><Col className={"text-center fw-bold"}>{report.user}</Col></Row>
            </div>
        </>
    )
}