import React, {forwardRef} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import bg from "date-fns/locale/bg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {InputGroup} from "react-bootstrap";

export interface ReadOnlyDatePickerProps {
    classes?: string;
    height?: string;
    onChange: any;
    value: string;
    hideIcon?: boolean;
    hideDayControls?: boolean;
}

// @ts-ignore
const ExampleCustomInput = forwardRef(
// @ts-ignore
    ({value, onClick}, ref: any) => (
        <button className="form-control w-100 text-start" onClick={onClick} ref={ref} type={"button"} style={{minHeight: "38px"}}>
            {value}
        </button>
    ),
);


export const ReadOnlyDatePickerComponent = function (props: ReadOnlyDatePickerProps) {
    const handleChange = (date: any, e: any) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }

        const {onChange} = props;

        if (onChange) {
            onChange(date);
        }
    };

    const addDay = () => {
        handleChange(moment(props.value, 'DD.MM.YYYY').add(1, 'day'), null);
    }
    const subDay = () => {
        handleChange(moment(props.value, 'DD.MM.YYYY').subtract(1, 'day'), null);
    }

    registerLocale("bg", bg);
    return (
        <div className={"input-group m-0 p-0"}>

            <InputGroup>
                <label>
                    <InputGroup>
                        <DatePicker
                            locale="bg"
                            className="form-control w-100px h-100 justify-content-start"
                            id="selectedDate"
                            value={props.value}
                            onChange={handleChange}
                            customInput={<ExampleCustomInput/>}
                            shouldCloseOnSelect={true}
                            todayButton={true}
                        />
                        {
                            !props.hideIcon &&
                            <div className={"form-control"}>
                                <FontAwesomeIcon icon="calendar-alt" size="lg"/>
                            </div>
                        }
                    </InputGroup>
                </label>

                {
                    !props.hideDayControls &&
                    <div className={"col-auto ml-3"}>
                        <span>&nbsp;</span>
                        <a className={'btn btn-secondary btn-sm'} onClick={() => subDay()}>&#8882;</a>
                        <a className={'btn btn-secondary btn-sm'} onClick={() => addDay()}>&#8883;</a>
                    </div>
                }
            </InputGroup>
        </div>
    )
}
