import React, {useEffect, useRef, useState} from "react";
import useInterval from "../../hooks/useInterval";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {isLoading, isSuccess} from "../../common";
import {Col, Row,} from "react-bootstrap";
import moment from "moment";
import {STAY_DURATION, titleStyle} from "../../pages/StatusPage";
import StatusOneDepartmentNotes from "./StatusOneDepartmentNotes";

const _statusPeriodFrom = moment().subtract(1, 'day').format('DD.MM.YYYY');
const _statusPeriodTo = moment().subtract(1, 'day').format('DD.MM.YYYY');

export default function ProductionNotesPart(props: { onDone: () => void }) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    useInterval(() => props.onDone(), STAY_DURATION);
    const containerRef = useRef(null);

    const loadData = () => {
        API.calcCommentsReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setData(apiCall.data.data.report);
                }
            }, {
                periodFrom: _statusPeriodFrom,
                periodTo: _statusPeriodTo
            }
        )
    }

    useEffect(() => loadData(), []);

    let topPosition = -500;
    useInterval(() => {
        window.scrollTo(0, topPosition < 0 ? 0 : topPosition);
        topPosition += 1;
    }, 10);


    return <>
        <Row className={"align-items-start align-content-start"}>
            <Col xs={9} style={{height: "65px", marginBottom: "10px"}}
                 className={"border-bottom border-light "}>
                <span style={titleStyle}>БЕЛЕЖКИ ПО ОТДЕЛИ</span>
            </Col>

            <Col xs={3} style={{textAlign: "center", height: "65px", marginBottom: "10px"}}
                 className={"border-bottom border-light text-end"}>
                <span style={titleStyle}>{_statusPeriodFrom}</span>
            </Col>
        </Row>
        <Row className={"align-items-start align-content-start"} ref={containerRef}>
            <StatusOneDepartmentNotes title={"Мелене"} data={data?.grinding || []}/>
            <StatusOneDepartmentNotes title={"Печка"} data={data?.heater || []}/>
            <StatusOneDepartmentNotes title={"Пещ"} data={data?.oven || []}/>
            <StatusOneDepartmentNotes title={"Ремонти"} data={data?.repairs || []}/>
            <StatusOneDepartmentNotes title={"Екструдер"} data={data?.extruder || []}/>
            <StatusOneDepartmentNotes title={"Редене"} data={data?.ordering || []}/>
            <StatusOneDepartmentNotes title={"Разтов."} data={data?.unloading || []}/>
        </Row>
    </>;
}
