import MediaScreenCard from "./MediaScreenCard";
import RenderGrindingShift from "./RenderGrindingShift";
import RenderGrindingRate from "./RenderGrindingRate";
import RenderExtruderShift from "./RenderExtruderShift";
import RenderPercentRate from "./RenderPercentRate";
import RenderHeaterShift from "./RenderHeaterShift";
import RenderOrderingShift from "./RenderOrderingShift";
import RenderOvenShift from "./RenderOvenShift";
import RenderUnloadShift from "./RenderUnloadShift";
import React, {useEffect, useState} from "react";
import {Account, API_CALL, PARAMETERS, PropertyValue} from "../../types";
import {API} from "../../api";
import {isSuccess} from "../../common";
import useInterval from "../../hooks/useInterval";
import {StatusSwitchTabInterval} from "../../App";

export default function TeamsPerformanceSection(props: any) {

    const [users, setUsers] = useState<Array<Account>>([]);
    const [data, setData] = useState<any>(null);
    const [shifts, setShifts] = useState<Array<PropertyValue>>([]);
    const activeTab = props.tabs[props.tabs.length - 1];

    useEffect(() => {
        API.getAllAccounts(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setUsers(apiCall.data.data.users)
                }
            }
        );

        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setShifts(apiCall.data.data.items?.values);
                    loadData();
                }
            }, PARAMETERS.SHIFTS
        );
    }, []);

    useInterval(() => {
        loadData();
    }, StatusSwitchTabInterval)

    const loadData = () => {
        API.getCurrentStatus(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setData(apiCall.data.data.data);
                }
            }
        );
    }

    return <div style={{width: '540px', height: "100%", padding: "0", zoom: 0.86, overflow: "hidden"}}>
        {
            activeTab === 'МЕЛЕНЕ' &&
            <>
                {
                    [0, 1].map(
                        idx =>
                            <MediaScreenCard key={"grinding_" + idx}>
                                <RenderGrindingShift
                                    reports={data?.grindings.reports}
                                    idx={idx} shifts={shifts}/>
                            </MediaScreenCard>
                    )
                }
                <RenderGrindingRate reports={data?.grindings.reports} users={users} />
            </>
        }
        {
            activeTab === 'ЕКСТРУДИРАНЕ' &&
            <>
                {
                    [0, 1].map(
                        idx =>
                            <MediaScreenCard key={"extr_" + idx}>
                                <RenderExtruderShift reports={data?.extruder.reports}
                                                     idx={idx} shifts={shifts}/>
                            </MediaScreenCard>
                    )
                }
                <RenderPercentRate reports={data?.extruder.reports} users={users}/>
            </>
        }
        {
            activeTab === 'ГЕНЕРАТОР ТОПЪЛ ВЪЗДУХ' &&
            <>
                {
                    [0, 1].map(
                        idx =>
                            <MediaScreenCard key={"heater_" + idx}>
                                <RenderHeaterShift reports={data?.heater.reports}
                                                   idx={idx} shifts={shifts}/>
                            </MediaScreenCard>
                    )
                }
                <RenderPercentRate reports={data?.heater.reports} users={users}/>
            </>
        }
        {
            activeTab === 'РЕДЕНЕ' &&
            <>
                {
                    [0, 1].map(
                        idx =>
                            <MediaScreenCard key={"ordering_" + idx}>
                                <RenderOrderingShift reports={data?.ordering.reports}
                                                     idx={idx} shifts={shifts}/>
                            </MediaScreenCard>
                    )
                }
                <RenderPercentRate reports={data?.ordering.reports} users={users}/>
            </>

        }
        {
            activeTab === 'ПЕЩ' &&
            <>
                {
                    [0, 1].map(
                        idx =>
                            <MediaScreenCard key={"oven_" + idx}>
                                <RenderOvenShift reports={data?.ovens.reports}
                                                 idx={idx} shifts={shifts}/>
                            </MediaScreenCard>
                    )
                }
                <RenderPercentRate reports={data?.ovens.reports} users={users}/>
            </>
        }
        {
            activeTab === 'РАЗТОВАРВАНЕ' &&
            <>
                {
                    [0, 1].map(
                        idx =>
                            <MediaScreenCard key={"unloading_" + idx}>
                                <RenderUnloadShift reports={data?.unloading.reports}
                                                   idx={idx} shifts={shifts}/>
                            </MediaScreenCard>
                    )
                }
                <RenderPercentRate reports={data?.unloading.reports} users={users}/>
            </>
        }
    </div>
}
