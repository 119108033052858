import React from "react";
import {API_CALL, OrderingReport, PARAMETERS, PAY_TYPE, PropertyValue, Sale, SaleItem} from "../types";
import {bagTypeTitle, isError, isLoading, isSuccess, showErrorsInToast, showMsgInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ExpNotesList} from "../components/daily-reports/ExpNotesList";

interface DailyReportsPageProps {
    showOnlyData?: boolean;
    selectedDate?: Moment;
}

interface DailyReportsPageState {
    working: boolean,
    loading: number,
    selectedDate: moment.Moment,
    report: OrderingReport | null,
    reports: any[],
    peletTypes: PropertyValue[],
    brickTypes: PropertyValue[],
    bagTypes: PropertyValue[],
    formValues: Map<string, any>,
}

export class DailyReportsPage extends React.Component<DailyReportsPageProps, DailyReportsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            working: false,
            loading: 0,
            selectedDate: props.selectedDate ? props.selectedDate : moment(),
            report: null,
            reports: [],
            peletTypes: [],
            brickTypes: [],
            bagTypes: [],
            formValues: new Map<string, any>(),
        }
        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReports = this.loadReports.bind(this);
    }

    componentDidMount() {
        this.loadParameters();
        this.loadReports();
    }

    loadParameters() {
        this.setState({loading: this.state.loading + 3});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        peletTypes: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    bricks[999] = {
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II КАЧЕСТВО'
                        }
                    };

                    this.setState({
                        loading: this.state.loading - 1,
                        brickTypes: bricks
                    });
                }
            }, PARAMETERS.BRICKS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bagTypes: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BAG_TYPE
        );
    }

    loadReports(newDate: Moment | null = null) {
        API.getDailyReports(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports;

                    const fv = this.state.formValues;
                    reports.forEach(
                        (item: Sale) => {
                            fv.set('incl_logistic_' + item.id, item.incl_logistic);
                            fv.set('price_' + item.id, item.price);
                            fv.set('inv_number_' + item.id, item.inv_number);
                            fv.set('pay_type_' + item.id, item.pay_type);
                            fv.set('inv_price_' + item.id, item.inv_price);
                        }
                    )

                    this.setState({reports: reports, formValues: fv})
                }
            }, newDate ? newDate : this.state.selectedDate
        );
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }



    render() {

        if(this.props.showOnlyData)
            return (
                <>
                    {
                        this.state.loading === 0 && moment.isMoment(this.state.selectedDate) && !this.state.working && !this.state.report &&
                        <>
                            <BlockTitleComponent title={'ТУХЛИ'}/>
                            <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                                <thead className={"text-center"}>
                                <tr>
                                    <th rowSpan={2}>No:</th>
                                    <th rowSpan={2}>Фирма -&gt; дестинация</th>
                                    <th colSpan={2}>Палети (бр)</th>
                                    <th colSpan={2}>Тухли</th>
                                    <th>Транспорт</th>
                                    <th colSpan={3}>За плащане</th>
                                </tr>
                                <tr>
                                    <th>взети</th>
                                    <th>върн.</th>
                                    <th>вид</th>
                                    <th>брой</th>
                                    <th>цена</th>
                                    <th>Фактура №</th>
                                    <th>Тип</th>
                                    <th>Сума</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ExpNotesList reports={this.state.reports}
                                              peletTypes={this.state.peletTypes}
                                              brickTypes={this.state.brickTypes}
                                              bagTypes={this.state.bagTypes}
                                              type={'bricks'}
                                />
                                </tbody>
                            </table>

                            <BlockTitleComponent title={'ПЕЛЕТИ'}/>
                            <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                                <thead className={"text-center"}>
                                <tr>
                                    <th rowSpan={2}>No:</th>
                                    <th rowSpan={2}>Фирма -&gt; дестинация</th>
                                    <th colSpan={4}>Пелети</th>
                                    <th>Транспорт</th>
                                    <th colSpan={3}>За плащане</th>
                                </tr>
                                <tr>
                                    <th>вид</th>
                                    <th>баг</th>
                                    <th>брой</th>
                                    <th>кг</th>
                                    <th>цена</th>
                                    <th>Фактура №</th>
                                    <th>Тип</th>
                                    <th>Сума</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ExpNotesList reports={this.state.reports}
                                              peletTypes={this.state.peletTypes}
                                              brickTypes={this.state.brickTypes}
                                              bagTypes={this.state.bagTypes}
                                              type={'peleti'}
                                />
                                </tbody>
                            </table>

                            <BlockTitleComponent title={'ДРУГИ'}/>
                            <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                                <thead className={"text-center"}>
                                <tr>
                                    <th rowSpan={2}>No:</th>
                                    <th rowSpan={2}>Фирма -&gt; дестинация</th>
                                    <th colSpan={5}>Артикул</th>
                                    <th colSpan={3}>За плащане</th>
                                </tr>
                                <tr>
                                    <th>вид</th>
                                    <th>М/Е</th>
                                    <th>брой</th>
                                    <th>ед.цена</th>
                                    <th>всичко</th>
                                    <th>Фактура №</th>
                                    <th>Тип</th>
                                    <th>Сума</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ExpNotesList reports={this.state.reports}
                                              peletTypes={this.state.peletTypes}
                                              brickTypes={this.state.brickTypes}
                                              bagTypes={this.state.bagTypes}
                                              type={'others'}
                                />
                                </tbody>
                            </table>
                        </>
                    }
                </>
            )
        else
            return (
                <div className="container" style={this.state.report ? {maxWidth: "100%"} : {}}>

                    <div className="card shadow">

                        <div className="card-header text-center row">
                            <div className="col-2 text-start">
                                {
                                    (this.state.loading > 0) ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <>
                                            {
                                                this.state.report ?
                                                    <span className="btn btn-secondary" onClick={() => this.setState({report: null})}><FontAwesomeIcon icon={faArrowLeft}/></span>
                                                    :
                                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                            }
                                        </>
                                }

                            </div>
                            <h2 className="col">Обобщен отчет търговия</h2>
                            <div className="col-2">
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    {
                                        this.state.loading > 0 &&
                                        <div>Зареждам данните ...</div>
                                    }
                                    {
                                        this.state.loading === 0 &&
                                        <>
                                            <div className="card bg-light p-3 mb-3">
                                                <form className="form-inline">
                                                    <div className={"lh-2em "}>
                                                        <div className={"col-auto"}>Изберете дата:&nbsp;</div>
                                                        <div className={"col-auto"}>
                                                            <ReadOnlyDatePickerComponent
                                                                value={this.state.selectedDate.format('DD.MM.YYYY')}
                                                                onChange={(date: Date) => {
                                                                    this.setState({selectedDate: moment(date)});
                                                                    this.loadReports(moment(date));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </>
                                    }


                                    {
                                        this.state.loading === 0 && moment.isMoment(this.state.selectedDate) && !this.state.working && !this.state.report &&
                                        <>
                                            <BlockTitleComponent title={'ПРОДАЖБИ ТУХЛИ'}/>
                                            <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                                                <thead className={"text-center"}>
                                                <tr>
                                                    <th rowSpan={2}>No:</th>
                                                    <th rowSpan={2}>Фирма -&gt; дестинация</th>
                                                    <th colSpan={2}>Палети (бр)</th>
                                                    <th colSpan={2}>Тухли</th>
                                                    <th>Транспорт</th>
                                                    <th colSpan={3}>За плащане</th>
                                                </tr>
                                                <tr>
                                                    <th>взети</th>
                                                    <th>върн.</th>
                                                    <th>вид</th>
                                                    <th>брой</th>
                                                    <th>цена</th>
                                                    <th>Фактура №</th>
                                                    <th>Тип</th>
                                                    <th>Сума</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ExpNotesList reports={this.state.reports}
                                                              peletTypes={this.state.peletTypes}
                                                              brickTypes={this.state.brickTypes}
                                                              bagTypes={this.state.bagTypes}
                                                              type={'bricks'}
                                                />
                                                </tbody>
                                            </table>

                                            <BlockTitleComponent title={'ПРОДАЖБИ ПЕЛЕТИ'}/>
                                            <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                                                <thead className={"text-center"}>
                                                <tr>
                                                    <th rowSpan={2}>No:</th>
                                                    <th rowSpan={2}>Фирма -&gt; дестинация</th>
                                                    <th colSpan={4}>Пелети</th>
                                                    <th>Транспорт</th>
                                                    <th colSpan={3}>За плащане</th>
                                                </tr>
                                                <tr>
                                                    <th>вид</th>
                                                    <th>баг</th>
                                                    <th>брой</th>
                                                    <th>кг</th>
                                                    <th>цена</th>
                                                    <th>Фактура №</th>
                                                    <th>Тип</th>
                                                    <th>Сума</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ExpNotesList reports={this.state.reports}
                                                              peletTypes={this.state.peletTypes}
                                                              brickTypes={this.state.brickTypes}
                                                              bagTypes={this.state.bagTypes}
                                                              type={'peleti'}
                                                />
                                                </tbody>
                                            </table>

                                            <BlockTitleComponent title={'ДРУГИ'}/>
                                            <table className="table table-sm table-bordered table-no-padding w-100" style={{fontSize: "0.75rem"}}>
                                                <thead className={"text-center"}>
                                                <tr>
                                                    <th rowSpan={2}>No:</th>
                                                    <th rowSpan={2}>Фирма -&gt; дестинация</th>
                                                    <th colSpan={5}>Артикул</th>
                                                    <th colSpan={3}>За плащане</th>
                                                </tr>
                                                <tr>
                                                    <th>вид</th>
                                                    <th>М/Е</th>
                                                    <th>брой</th>
                                                    <th>ед.цена</th>
                                                    <th>всичко</th>
                                                    <th>Фактура №</th>
                                                    <th>Тип</th>
                                                    <th>Сума</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ExpNotesList reports={this.state.reports}
                                                              peletTypes={this.state.peletTypes}
                                                              brickTypes={this.state.brickTypes}
                                                              bagTypes={this.state.bagTypes}
                                                              type={'others'}
                                                />
                                                </tbody>
                                            </table>

                                        </>
                                    }

                                </div>

                            </div>

                        </div>

                        <div className="card-footer">
                            {
                                this.state.working ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        {
                                            moment.isMoment(this.state.selectedDate) ?
                                                <>
                                                    <Link to={PATHS.sales + API.getAPITokenAsQuery()} className={"btn btn-secondary float-right"}>Експедиционни бележки</Link>
                                                </>
                                                :
                                                <><span className="text-info">Моля, изберете дата...</span></>
                                        }
                                    </>
                            }
                        </div>
                    </div>

                </div>
            );
    }

}
