import {API_CALL, NET_STATUS} from "./types";
import {AxiosResponse} from "axios";

export const defaultAxiosResponse: AxiosResponse<any> = {
    data: null,
    config: {},
    headers: null,
    status: -1,
    statusText: ''
}

export const FACTORY_CLASS = class {
    createApiCall(status = NET_STATUS.NOT_STARTED, data = defaultAxiosResponse, error = null, onChange = null): API_CALL {
        return {
            status: status,
            data: data,
            error: error,
            onChange: onChange
        }
    }
}

export const FACTORY = new FACTORY_CLASS();
