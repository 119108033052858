import {API_CALL, City, Client, Driver, Logistic, PropertyValue} from "../../types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {Dropdown, Form, InputGroup, Modal} from "react-bootstrap";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {API} from "../../api";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, translateError} from "../../common";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";
import {SelectClient} from "../clients/SelectClient";
import {SelectLogistic} from "../logistics/SelectLogistic";


interface PlanningDetailsModalProps {
    id: number,
    copyId?: number,
    date: string,
    onAfterSave: any,
    onCancel: any,
    brick_types: PropertyValue[],
}

interface PlanningDetailsModalState {
    client_filter: Map<string, any>,
    logistic_filter: Map<string, any>,
    formValues: Map<string, any>,
    showClientList: boolean,
    showLogisticList: boolean,
    working: boolean,
}

export class PlanningDetailsModalComponent extends React.Component<PlanningDetailsModalProps, PlanningDetailsModalState> {
    QtyInPalet: PropertyValue[] = [
        {id: 150, value: '150', description: ''},
        {id: 180, value: '180', description: ''},
        {id: 330, value: '330', description: ''},
        {id: 456, value: '456', description: ''},
    ];

    constructor(props: PlanningDetailsModalProps) {
        super(props);

        this.state = {
            client_filter: new Map<string, any>(),
            logistic_filter: new Map<string, any>(),
            formValues: new Map<string, any>(),
            showClientList: false,
            showLogisticList: false,
            working: false,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.onSelectClient = this.onSelectClient.bind(this);
        this.onSelectLogistic = this.onSelectLogistic.bind(this);
        this.renderBrickTypeSelect = this.renderBrickTypeSelect.bind(this);
        this.renderQtyInPaletSelect = this.renderQtyInPaletSelect.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
        if (this.props.id > -1) {
            API.getPlanningDetails(
                (apiCall: API_CALL) => {

                    this.setState({working: isLoading(apiCall)});

                    if (isSuccess(apiCall)) {
                        console.log(apiCall.data.data);
                        const fv: Map<string, any> = this.state.formValues;

                        const requestValues = Object.entries(JSON.parse(apiCall.data.data.plan.request_data?.replaceAll('"null"', '""')));

                        for (let [key, value] of requestValues) {
                            fv.set(key, value || '');
                        }

                        this.setState({formValues: fv});
                    }

                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, "Възникна проблем.", translateError);
                        this.cancel();
                    }
                }, this.props.id
            )
        } else {
            if (this.props.copyId) {
                API.getPlanningDetails(
                    (apiCall: API_CALL) => {

                        this.setState({working: isLoading(apiCall)});

                        if (isSuccess(apiCall)) {
                            const fv: Map<string, any> = this.state.formValues;

                            const requestValues = Object.entries(JSON.parse(apiCall.data.data.plan.request_data?.replaceAll('"null"', '""')));

                            for (let [key, value] of requestValues) {
                                fv.set(key, value || '');
                            }

                            fv.set("status", "NEW");
                            fv.set("currier", "");
                            fv.set("driver", "");
                            fv.set("driver_phone", "");
                            fv.set("license_plate", "");
                            fv.set("logistic_pay_type", "");
                            fv.set("logtic_price", "");
                            this.setState({formValues: fv});
                        }

                        if (isError(apiCall)) {
                            showErrorsInToast(apiCall, "Възникна проблем.", translateError);
                            this.cancel();
                        }
                    }, this.props.copyId
                )
            } else {
                const fv = this.state.formValues;
                fv.set('plan_date', this.props.date);
                this.setState({formValues: fv});
            }
        }
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);

        for (let idx = 0; idx < 5; idx++) {
            if ((name.localeCompare('palet_qty_' + idx) === 0) || (name.localeCompare('qty_in_palet_' + idx)) === 0) {
                const v1 = fv.get('palet_qty_' + idx) * 1;
                let v2 = fv.get('qty_in_palet_' + idx) * 1;
                if (v2 === -1) v2 = 0;

                if (v1 === 0) {
                    v2 = 0;
                    fv.set('palet_qty_' + idx, '');
                    fv.set('qty_in_palet_' + idx, '');
                }

                let total = v1 * v2;
                if (isNaN(total)) total = 0;
                fv.set('total_' + idx, total);
            }


        }
        this.setState({formValues: fv});
    }

    save() {
        API.savePlanning(
            (apiCall: API_CALL) => {

                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.props.onAfterSave(apiCall);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {
                ...mapToObject(this.state.formValues),
                id: this.props.id,
            }
        );
    }


    cancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    renderSelect(key: string, values: PropertyValue[], isNumber = false) {
        return (
            <select name={key} value={this.state.formValues.get(key)} onChange={this.handleFormChange}
                    className={"form-control form-control-sm p-0 m-0"} dir={(isNumber ? 'rtl' : '')}>
                <option value={-1}></option>
                {
                    values.map(
                        (i: PropertyValue, idx: number) => <option key={idx} value={i.id}>{i.value}</option>
                    )
                }
            </select>
        )
    }

    renderBigSelect(key: string, values: PropertyValue[], isNumber = false) {
        return (
            <select name={key} value={this.state.formValues.get(key)} onChange={this.handleFormChange}
                    className={"form-control"} dir={(isNumber ? 'rtl' : '')}>
                <option value={-1}></option>
                {
                    values.map(
                        (i: PropertyValue, idx: number) => <option key={idx} value={i.id}>{i.value}</option>
                    )
                }
            </select>
        )
    }

    renderBrickTypeSelect(key: string) {
        return this.renderSelect(key, this.props.brick_types);
    }

    renderPayTypeSelect(key: string) {
        return this.renderBigSelect(key,
            [
                {id: 0, value: 'Касов бон', description: ''},
                {id: 1, value: 'Банка', description: ''}
            ]);
    }

    renderQtyInPaletSelect(key: string) {
        return this.renderSelect(key, this.QtyInPalet, true);
    }

    onSelectClient(client: Client, city: City) {
        const filter = new Map<string, any>();
        filter.set('name', client.name);
        filter.set('cities', city.city);

        const fv = this.state.formValues;
        fv.set('client_id', client.id);
        fv.set('client', client.name);
        fv.set('client_city', city.city);
        fv.set('client_phone', city.phone);

        this.setState({formValues: fv, client_filter: filter, showClientList: !this.state.showClientList});
    }

    onSelectLogistic(logistic: Logistic, driver: Driver) {
        const filter = new Map<string, any>();
        filter.set('name', logistic.name);
        filter.set('driver', driver.name);

        const fv = this.state.formValues;
        fv.set('currier', logistic.name);
        fv.set('driver', driver.name);
        fv.set('driver_phone', driver.phone);
        fv.set('license_plate', driver.reg_nomer);

        this.setState({formValues: fv, logistic_filter: filter, showLogisticList: !this.state.showLogisticList});
    }

    render() {
        return (
            <>
                <div className={"modal overflow-auto"} style={{display: "block"}}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content bg-light shadow">
                            <div className="modal-header">
                                <h5 className="modal-title">Планиране на клиент</h5>
                                <span className={"pointer"} onClick={this.cancel}><FontAwesomeIcon icon="window-close"/></span>
                            </div>
                            <div className="modal-body">

                                <form>
                                    <div className={"row"}>

                                        <div className="mb-3 pr-0 col-2">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto"}>Дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            classes={"ml-0 pl-0"}
                                                            value={this.state.formValues.get('plan_date')}
                                                            onChange={(date: Date) => {
                                                                this.setState({formValues: this.state.formValues.set('plan_date', moment(date).format('DD.MM.YYYY'))});
                                                            }}
                                                            hideDayControls={true}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="mb-3 pr-0 col-4">
                                            <label className="form-label">Клиент</label>
                                            <InputGroup>
                                                <input type="text" className="form-control" name={"client"}
                                                       onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("client")}/>

                                                <Dropdown show={this.state.showClientList}>
                                                    <DropdownToggle onClick={() => {
                                                        this.setState({showClientList: !this.state.showClientList})
                                                    }}>
                                                    </DropdownToggle>
                                                </Dropdown>

                                                {
                                                    this.state.showClientList &&
                                                    <SelectClient
                                                        filter={this.state.client_filter}
                                                        onSelect={this.onSelectClient}
                                                        onClose={() => this.setState({showClientList: !this.state.showClientList})}
                                                    />
                                                }

                                            </InputGroup>
                                        </div>

                                        <div className="mb-2 pr-0 col-2">
                                            <label className="form-label">Град</label>
                                            <input type="text" className="form-control" name={"client_city"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("client_city")}/>
                                        </div>

                                        <div className="col-2 mb-2">
                                            <label className="form-label">Телефон</label>
                                            <input type="text" className="form-control" name={"client_phone"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("client_phone")}/>
                                        </div>

                                        <div className="mb-3 col-2 pl-1">
                                            <label className="form-label">Начин на плащане</label>
                                            {this.renderPayTypeSelect('pay_type')}
                                        </div>

                                    </div>

                                    <div className={"row"}>
                                        <div className={"col"}>
                                            <table className={"table table-hover table-sm table-no-padding"}>
                                                <thead className={"bg-secondary text-light"}>
                                                <tr>
                                                    <th className={"w-100px text-end"}>No:</th>
                                                    <th>Наименование на стоката</th>
                                                    <th className={"w-180px text-end"}>Палети</th>
                                                    <th className={"w-180px text-end"}>Брой в палет</th>
                                                    <th className={"w-180px text-end"}>Количество(бр.)</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    [0, 1, 2, 3, 4].map(
                                                        idx =>
                                                            <tr key={idx}>
                                                                <td className={"text-end"}>{idx + 1}&nbsp;</td>
                                                                <td>
                                                                    {this.renderBrickTypeSelect('property_id_' + idx)}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        // (this.state.formValues.get('property_id_' + idx) !== '999') &&
                                                                        <input type="number"
                                                                               className="form-control form-control-sm text-end"
                                                                               name={"palet_qty_" + idx}
                                                                               value={this.state.formValues.get("palet_qty_" + idx)}
                                                                               onChange={this.handleFormChange}/>
                                                                    }
                                                                </td>
                                                                <td className={"text-end"}>
                                                                    {
                                                                        (this.state.formValues.get('property_id_' + idx) !== '997') &&
                                                                        (this.state.formValues.get('property_id_' + idx) !== '998') &&
                                                                        <>
                                                                            {
                                                                                ((this.state.formValues.get('property_id_' + idx) !== '999') ||
                                                                                    (this.state.formValues.get("palet_qty_" + idx) * 1 > 0)) &&
                                                                                this.renderQtyInPaletSelect('qty_in_palet_' + idx)
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                                <td className={"text-end"}>
                                                                    {
                                                                        (this.state.formValues.get('property_id_' + idx) !== '997') &&
                                                                        (this.state.formValues.get('property_id_' + idx) !== '998') &&
                                                                        <>
                                                                            {
                                                                                (this.state.formValues.get('property_id_' + idx) !== '999') ||
                                                                                (this.state.formValues.get("palet_qty_" + idx) * 1 > 0) ?
                                                                                    <span className={"pr-3"}
                                                                                          style={{lineHeight: "32px"}}>{this.state.formValues.get("total_" + idx)}</span>
                                                                                    :
                                                                                    <input type="number"
                                                                                           className="form-control form-control-sm text-end p-0 m-0"
                                                                                           name={"total_" + idx}
                                                                                           value={this.state.formValues.get("total_" + idx)}
                                                                                           onChange={this.handleFormChange}/>
                                                                            }
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                    )
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className="mb-3 pr-0 col-3">
                                            <label className="form-label">Фирма превозвач:</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" name={"currier"}
                                                       onChange={this.handleFormChange}
                                                       value={this.state.formValues.get("currier")}/>

                                                <Dropdown show={this.state.showLogisticList}>
                                                    <DropdownToggle onClick={() => {
                                                        this.setState({showLogisticList: !this.state.showLogisticList})
                                                    }}>
                                                    </DropdownToggle>
                                                </Dropdown>

                                                {
                                                    this.state.showLogisticList &&
                                                    <SelectLogistic
                                                        filter={this.state.logistic_filter}
                                                        onSelect={this.onSelectLogistic}
                                                        onClose={() => this.setState({showLogisticList: !this.state.showLogisticList})}
                                                    />
                                                }

                                            </div>
                                        </div>

                                        <div className="mb-3 col-2 pr-1">
                                            <label className="form-label">Шофьор:</label>
                                            <input type="text" className="form-control" name={"driver"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("driver")}/>
                                        </div>

                                        <div className="mb-3 col-2 pr-1 pl-1">
                                            <label className="form-label">Телефон</label>
                                            <input type="text" className="form-control" name={"driver_phone"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("driver_phone")}/>
                                        </div>

                                        <div className="mb-3 col-2 pr-1 pl-1">
                                            <label className="form-label">Кола номер</label>
                                            <input type="text" className="form-control" name={"license_plate"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("license_plate")}/>
                                        </div>

                                        <div className="mb-3 col-2 pr-1 pl-1">
                                            <label className="form-label">Начин на плащане</label>
                                            {this.renderPayTypeSelect('logistic_pay_type')}
                                        </div>

                                        <div className="mb-3 col-1 pl-1">
                                            <label className="form-label">Цена</label>
                                            <input type="text" className="form-control" name={"logtic_price"}
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get("logtic_price")}/>
                                        </div>

                                    </div>


                                </form>

                            </div>
                            <div className="modal-footer">
                                {
                                    this.state.working ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <>
                                            <button type="button" className="btn btn-primary"
                                                    onClick={this.save.bind(this)}>Запази
                                            </button>
                                            <button type="button" className="btn btn-secondary"
                                                    onClick={this.cancel.bind(this)}>Откажи
                                            </button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
