import React from "react";
import {
    AccountOperation,
    API_CALL,
    Client,
    OPERATION_TYPE,
    PARAMETERS,
    PAY_TYPE,
    PropertyValue,
    Sale
} from "../../types";
import moment from "moment";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {Modal} from "react-bootstrap";
import {EditAccountOperation} from "./EditAccountOperation";
import {forEach} from "react-bootstrap/ElementChildren";
import {SaleDetailsModalComponent} from "../sales/SaleDetailsModalComponent";

export interface EditClientLogisticDetailsProps {
    client: Client;
    onCancel: () => any;
    onRefresh?: () => any;
    onEditSale?: (saleId: number) => any;
}

export interface EditClientLogisticDetailsState {
    loading: boolean;
    report: any[];
    balance: number;
    startBalance: number;
    saving: boolean;
    editSale: Sale | null;
}

export class EditClientLogisticDetails extends React.Component<EditClientLogisticDetailsProps, EditClientLogisticDetailsState> {
    constructor(props: EditClientLogisticDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            saving: false,
            report: [],
            balance: 0,
            startBalance: 0,
            editSale: null,
        }
        this.cancelClient = this.cancelClient.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.getClientLogisticList(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
                if (isSuccess(apiCall)) {
                    this.setState({
                        report: apiCall.data.data.report,
                        balance: apiCall.data.data.balance,
                        startBalance: apiCall.data.data.start_balance,
                    });
                }
            }, this.props.client.id
        )
    }

    cancelClient() {
        this.props.onCancel();
    }

    renderReport() {
        const html: any[] = [];
        const dates = Object.keys(this.state.report);
        const datesContent = Object.values(this.state.report);

        dates.forEach(
            (date: string, idx: number) => {
                const notesNumbers = Object.keys(datesContent[idx]);

                notesNumbers.forEach(
                    (number: string, idx1: number) => {
                        const noteVals: any = Object.values(datesContent[idx]);
                        if (idx1 === 0) {
                            html.push(
                                <tr key={"palet_filter_" + idx + "_" + idx1}>
                                    <td rowSpan={notesNumbers.length}>{date}</td>
                                    <td>{number}</td>
                                    <td>{noteVals[idx1].type}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].price}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].payed.toFixed(2)}</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        color: (noteVals[idx1].balance < 0 ? 'red' : 'green')
                                    }}>{noteVals[idx1].balance.toFixed(2)}</td>
                                </tr>);
                        } else {
                            html.push(
                                <tr key={"palet_logistic_" + idx + "_" + idx1}>
                                    <td>
                                        {number}
                                    </td>
                                    <td>{noteVals[idx1].type}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].price}</td>
                                    <td style={{textAlign: 'right'}}>{noteVals[idx1].payed.toFixed(2)}</td>
                                    <td style={{
                                        textAlign: 'right',
                                        fontWeight: 'bold',
                                        color: (noteVals[idx1].balance < 0 ? 'red' : 'green')
                                    }}>{noteVals[idx1].balance.toFixed(2)}</td>
                                </tr>);
                        }
                    }
                )
            }
        )

        return html;
    }

    saveStartBalance() {
        API.setClientPaletStartBalance(
            (apiCall: API_CALL) => {
                this.setState({saving: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка', translateError);
                }
                if (isSuccess(apiCall)) {
                    this.loadData();
                    if (this.props.onRefresh) this.props.onRefresh();
                }
            }, this.props.client.id, this.state.startBalance
        );
    }

    render() {
        return (
            <>
                <div className={"col align-content-between"}>
                    <div className="row">
                        <div className="col-12 mr-0 pr-0">
                            <div className={"row w-100"}>
                                <div className="col-6"><label>ТЕКУЩ БАЛАНС:</label></div>
                                <div
                                    className={"col-6 text-end font-weight-bold text-light pt-1 " + (this.state.balance < 0 ? "bg-danger" : "bg-success")}>
                                    {this.state.balance} лв.
                                </div>
                            </div>

                            <div className={"row d-block w-100 col-12 m-0 p-0"}
                                 style={{maxHeight: "50vh", overflow: "auto"}}>
                                <table className={"table table-sm m-0 p-0"}>
                                    <thead>
                                    <tr>
                                        <th>Дата</th>
                                        <th>Номер</th>
                                        <th>Тип</th>
                                        <th className={"text-end"}>Задължение</th>
                                        <th className={"text-end"}>Платени</th>
                                        <th className={"text-end"}>Баланс</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.report.length == 0 &&
                                        <tr>
                                            <td colSpan={6}>
                                                {this.state.loading ? 'Зареждам ...' : 'Няма данни'}
                                            </td>
                                        </tr>
                                    }
                                    {
                                        this.renderReport()
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}
