import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isLoading, isSuccess} from "../../common";
import {API} from "../../api";
import {API_CALL} from "../../types";
import useInterval from "../../hooks/useInterval";
import {Badge, Button, CloseButton, Modal, Tabs} from "react-bootstrap";
import {faEnvelope, faMailBulk} from "@fortawesome/free-solid-svg-icons";
import MessageControlModal from "./MessageControlModal";


export interface MessageContent {
    id?: number;
    send_date?: string;
    title: string;
    content: string;
}

export interface MessageUser {
    id: number;
    name: string;
    position: string;
}

export interface Message {
    id?: number;
    message_id?: number;
    sender_id?: number;
    receiver_id?: number;
    is_readed?: number;
    is_deleted?: number;
    message: MessageContent;
    sender?: MessageUser;
    receiver?: MessageUser;
}

export default function UserMessagesComponent() {

    const [dataLoaded, setDataLoaded] = useState(false);
    const [messageCnt, setMessageCnt] = useState(0);
    const [messagesDialogVisible, setMessagesDialogVisible] = useState(false)

    useInterval(() => {
        API.getNewMessagesCount(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setMessageCnt(apiCall.data.data.count);
                }
            }
        )
    }, 30000);

    return (
        <>
            <Button className={"btn btn-outline-primary home-btn-outline-primary text-light"} onClick={ () => setMessagesDialogVisible(true) }>
                <FontAwesomeIcon icon={faMailBulk}/> Съобщения&nbsp;
                {
                    messageCnt > 0 && <Badge bg="success">{ messageCnt }</Badge>
                }
            </Button>

            <MessageControlModal visible={messagesDialogVisible} onHide={() => setMessagesDialogVisible(false)} />
        </>
    );
}
