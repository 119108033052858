import React from "react";
import {Link} from "react-router-dom";
import {AppModule} from "../../types";
import {API} from "../../api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ModuleProps {
    module: AppModule
}

export function ModuleComponent(props: ModuleProps) {
    if (props.module && props.module.url)
        return (
            <div className={"row"}>
                <div className="col pl-5">
                    {
                        props.module.url.startsWith("http") ?
                            <a href={props.module.url} target={"_blank"} className="btn btn-outline-secondary w-300px mb-1">
                                <span className="text-nowrap">{props.module.name}</span></a>
                            :
                            <Link to={{pathname: props.module.url, search: API.getAPITokenAsQuery()}}
                                  className="btn btn-outline-secondary w-300px mb-1">
                                <span className="text-nowrap">{props.module.name}</span>
                            </Link>
                    }
                </div>
            </div>
        );

    return null;
}
