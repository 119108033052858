import React from "react";
import {API_CALL, BaseReportInfo, OrderingReport, PARAMETERS, PropertyValue} from "../types";
import {getShiftName, isError, isLoading, isSuccess, mapToObject, showErrorsInToast, showErrorsListInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {AUTOSAVE_INTERVAL} from "../index";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {ReportBaseInfoComponent} from "../components/common/ReportBaseInfoComponent";
import {EditGrindingReportComponent} from "../components/grinding/EditGrindingReportComponent";
import {ReportsList} from "../components/common/ReportsListComponent";
import {ViewHeaterReportComponent} from "../components/heater/ViewHeaterReportComponent";
import {ViewOrderingReportComponent} from "../components/ordering/ViewOrderingReportComponent";

interface OrderingPageState {
    working: boolean,
    loading: number,
    selectedDate: moment.Moment,
    report: OrderingReport | null,
    reports: OrderingReport[],
    shifts: PropertyValue[],
    brickTypes: PropertyValue[],
    extrReasons: PropertyValue[],
    formValues: Map<string, any>,
    dialogConfirmDeleteVisible: boolean,
    isPreviewMode: boolean,
}

export class OrderingPage extends React.Component<any, OrderingPageState> {
    reportForDeleting?: OrderingReport | null = null;
    autoSaveTrigger: any = null;

    constructor(props: any) {
        super(props);
        this.state = {
            working: false,
            loading: 0,
            selectedDate: moment(),
            report: null,
            reports: [],
            shifts: [],
            brickTypes: [],
            extrReasons: [],
            formValues: new Map<string, any>(),
            dialogConfirmDeleteVisible: false,
            isPreviewMode: false,
        }
        this.addReport = this.addReport.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.autoSaveReport = this.autoSaveReport.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.editReport = this.editReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.doDeleteReport = this.doDeleteReport.bind(this);
    }

    setAutoSaveInterval() {
        this.autoSaveTrigger = window.setInterval(this.autoSaveReport, AUTOSAVE_INTERVAL);
    }

    clearAutoSaveInterval() {
        if (this.autoSaveTrigger) {
            window.clearInterval(this.autoSaveTrigger)
        }
    }

    componentDidMount() {
        this.loadParameters();
        this.loadReports();
        this.setAutoSaveInterval();
    }

    componentWillUnmount() {
        this.clearAutoSaveInterval();
    }

    loadParameters() {
        this.setState({loading: this.state.loading + 2});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        shifts: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.SHIFTS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        brickTypes: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BRICKS
        );
    }

    loadReports(newDate?: Moment) {
        API.getOrdering(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as OrderingReport;
                        }
                    );
                    this.setState({reports: reports})
                }
            }, newDate ? newDate : this.state.selectedDate
        )
    }

    addReport() {
        this.loadReports();

        const report = {
            id: -1,
            base_info: {
                report_date: this.state.selectedDate.format('DD.MM.YYYY'),
                shift_id: -1,
                man_cnt: 1,
                from_time: '',
                to_time: ''
            }
        } as OrderingReport;

        this.setState({report: report, formValues: new Map<string, any>(), isPreviewMode: false})
    }

    editReport(report: OrderingReport, isPrint: boolean = false) {
        const requestValues = report.request_data ? Object.entries(JSON.parse(report.request_data)) : [];

        const fv = new Map<string, any>();
        for (let [key, value] of requestValues) {
            fv.set(key, value);
            if (key === 'base_info') {
                report.base_info = value as BaseReportInfo;
            }
        }

        let isPreviewMode = false;
        if (isPrint || (report.user.id !== API.user?.id && !(API.user?.is_admin === 'Y'))) {
            isPreviewMode = true;
        }
        this.setState({report: report, formValues: fv, isPreviewMode: isPreviewMode});
    }

    saveReport() {
        this.doSaveReport(false);
    }

    autoSaveReport() {
        if (this.state && this.state.report) this.doSaveReport(true);
    }

    doSaveReport(isAutosave: boolean = false) {
        if (
            !(
                this.state.formValues?.get('base_info')?.shift_id > -1 &&
                this.state.formValues?.get('base_info')?.man_cnt &&
                this.state.formValues?.get('base_info')?.man_cnt > 0)
        ) return;


        let errors: string[] = [];
        for (let i = 0; i < 22; i++) {
            const w = this.state.formValues.get('waste_extr_' + i);
            const r = this.state.formValues.get('waste_robot_' + i);
            if (w && (w < 1 || w > 999)) errors.push('Попълнете правилно полето БРАК ЕКСТРУДЕР за вагон №' + (i + 1));
            if (r && (r < 1 || r > 999)) errors.push('Попълнете правилно полето БРАК РОБОТ за вагон №' + (i + 1));
        }

        if (errors.length > 0) {
            showErrorsListInToast('Открити грешки', errors);
            return;
        }

        const fv = mapToObject(this.state.formValues);

        API.saveOrdering(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    if (!isAutosave) {
                        this.setState({report: null});
                        this.loadReports();
                    } else {
                        // @ts-ignore
                        this.state.report.id = apiCall.data.data.report.id;
                    }
                }
                if (isError(apiCall)) {
                    if (!isAutosave) showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }

                this.setState({working: isLoading(apiCall)});
            }, {
                ...fv,
                reportId: this.state.report?.id
            }, this.state.report?.id
        );
    }


    deleteReport(report: OrderingReport) {
        this.reportForDeleting = report;
        this.setState({dialogConfirmDeleteVisible: true});
    }

    doDeleteReport() {
        API.deleteOrdering(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна следната грешка:');
                }
                if (isSuccess(apiCall)) {
                    this.loadReports();
                    this.setState({dialogConfirmDeleteVisible: false});
                }
            }, this.reportForDeleting?.id
        );
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    renderSelect(key: string, values: PropertyValue[]) {
        return (
            <select name={key} value={this.state.formValues.get(key)} onChange={this.handleFormChange} className={"form-control p-0 m-0"}>
                <option value={-1}></option>
                {
                    values.map(
                        (i: PropertyValue, idx: number) => <option key={idx} value={idx}>{i.description.shortName || i.value}</option>
                    )
                }
            </select>
        )
    }

    renderBrickTypeSelect(key: string) {
        return this.renderSelect(key, this.state.brickTypes);
    }

    renderExtruderReasons(idx: number) {
        return (
            <div className={"ml-2 pt-1 row"}>
                <div className="form-check-inline col w-100px">
                    <input className="form-check-input" type="checkbox" name={"extr_reason_suha_" + idx} id={"extr_reason_suha_" + idx}
                           onChange={this.handleFormChange} checked={this.state.formValues.get("extr_reason_suha_" + idx) === true}/>
                    <label className="form-check-label" htmlFor={"extr_reason_suha_" + idx}>
                        &nbsp;Сухи&nbsp;&nbsp;
                    </label>
                </div>

                <div className="form-check-inline col w-100px">
                    <input className="form-check-input" type="checkbox" name={"extr_reason_mokra_" + idx} id={"extr_reason_mokra_" + idx}
                           onChange={this.handleFormChange} checked={this.state.formValues.get("extr_reason_mokra_" + idx) === true}/>
                    <label className="form-check-label" htmlFor={"extr_reason_mokra_" + idx}>
                        &nbsp;Мокри&nbsp;&nbsp;
                    </label>
                </div>

                <div className="form-check-inline col w-100px">
                    <input className="form-check-input" type="checkbox" name={"extr_reason_spukana_" + idx} id={"extr_reason_spukana_" + idx}
                           onChange={this.handleFormChange} checked={this.state.formValues.get("extr_reason_spukana_" + idx) === true}/>
                    <label className="form-check-label" htmlFor={"extr_reason_spukana_" + idx}>
                        &nbsp;Спукани&nbsp;&nbsp;
                    </label>
                </div>

            </div>
        );
    }

    renderRows() {
        const rows = [];

        for (let idx = 0; idx < 22; idx++) {
            rows.push(
                <tr>
                    <td className={"text-end pt-1"}>{idx + 1}.&nbsp;</td>
                    <td style={{width: "14rem"}}>
                        {this.renderBrickTypeSelect('brickType1_' + idx)}
                    </td>
                    <td style={{width: "14rem"}}>
                        {this.renderBrickTypeSelect('brickType2_' + idx)}
                    </td>
                    <td align={"center"}>
                        {this.renderExtruderReasons(idx)}
                    </td>
                    <td>
                        <input name={'waste_extr_' + idx} value={this.state.formValues.get('waste_extr_' + idx)} type={"number"}
                               min={1} max={999}
                               disabled={
                                   !(
                                       this.state.formValues.get('extr_reason_suha_' + idx) ||
                                       this.state.formValues.get('extr_reason_mokra_' + idx) ||
                                       this.state.formValues.get('extr_reason_spukana_' + idx)
                                   )
                               }
                               onChange={this.handleFormChange}
                               className={"form-control p-0 m-0 text-end"}/>
                    </td>
                    <td>
                        <input name={'waste_robot_' + idx} value={this.state.formValues.get('waste_robot_' + idx)} type={"number"}
                               min={1} max={999}
                               onChange={this.handleFormChange}
                               className={"form-control p-0 m-0 text-end"}/>
                    </td>
                </tr>
            );
        }

        return rows;
    }

    render() {
        return (
            <div className="container" style={this.state.report ? {maxWidth: "100%"} : {}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        {
                                            this.state.report ?
                                                <span className="btn btn-secondary"
                                                      onClick={() => {
                                                          this.setState({report: null});
                                                          this.loadReports();
                                                      }}><FontAwesomeIcon icon={faArrowLeft}/></span>
                                                :
                                                <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                                    <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                        }
                                    </>
                            }

                        </div>
                        <h2 className="col">Редене</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <h4 className="card-title">
                            {
                                this.state.report ?
                                    <>
                                        {
                                            this.state.report.id === -1 ? 'Добавяне' : 'Корекция'
                                        }
                                        &nbsp;на отчет
                                    </>
                                    :
                                    <>Редене отчети</>
                            }
                        </h4>
                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div>Зареждам данните ...</div>
                                }
                                {
                                    this.state.loading === 0 && !this.state.report &&
                                    <>
                                        <div className="card bg-light p-3 mb-3">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto"}>Отчети за дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            value={this.state.selectedDate.format('DD.MM.YYYY')}
                                                            onChange={(date: Date) => {
                                                                this.setState({selectedDate: moment(date)});
                                                                this.loadReports(moment(date));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }

                                <ReportsList
                                    working={!(this.state.loading === 0 && moment.isMoment(this.state.selectedDate) && !this.state.working && !this.state.report)}
                                    reports={this.state.reports}
                                    shifts={this.state.shifts}
                                    editReport={this.editReport}
                                    deleteReport={this.deleteReport}/>

                                {
                                    this.state.report && !this.state.isPreviewMode &&
                                    <>
                                        <ReportBaseInfoComponent
                                            onChange={(data: BaseReportInfo) => {
                                                this.setState({formValues: this.state.formValues.set('base_info', data)});
                                            }}
                                            shifts={this.state.shifts.filter(
                                                s => {
                                                    return this.state.reports.filter(
                                                        r => {
                                                            return r.base_info.shift_id == s.id &&
                                                                s.id != this.state.report?.base_info.shift_id
                                                        }
                                                    ).length === 0
                                                }
                                            )}
                                            addMiddleShift={false}
                                            reportName={"ordering_reports"}
                                            reportId={this.state.report.id}
                                            reportInfo={this.state.report.base_info}
                                        />


                                        {
                                            this.state.formValues?.get('base_info')?.shift_id > -1 &&
                                            this.state.formValues?.get('base_info')?.man_cnt &&
                                            this.state.formValues?.get('base_info')?.man_cnt > 0 ?
                                                <>
                                                    <table className={"table table-hover table-sm border table-no-padding col-12"}>
                                                        <thead>
                                                        <tr className={"text-center"}>
                                                            <th style={{width: "3rem"}}>№ вагон</th>
                                                            <th colSpan={2}>Вид тухла</th>
                                                            <th>Причина екструдер</th>
                                                            <th style={{width: "9rem"}}>Брак (брой)<br/><small>причина екструдер</small></th>
                                                            <th style={{width: "9rem"}}>Брак (брой)<br/><small>причина робот</small></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {this.renderRows()}
                                                        </tbody>
                                                    </table>

                                                    <div className="form-row mb-3">
                                                        <BlockTitleComponent title={"Бележки към отчета"}/>

                                                        <div className={"col-12 mt-3 mb-3 pl-4"}>
                                                            <div className={"row"}>
                                                                <div className={"form-inline"}>
                                                                    <div className="form-check form-check-inline col-auto">
                                                                        <input className="form-check-input" type="checkbox" name={"cleaning"} id={"cleaning"}
                                                                               onChange={this.handleFormChange} checked={this.state.formValues.get("cleaning") === true}/>
                                                                        <label className="form-check-label" htmlFor={"cleaning"}>
                                                                            &nbsp;Почистване&nbsp;&nbsp;
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check form-check-inline col-auto">
                                                                        <input className="form-check-input" type="checkbox" name={"robot_helth"} id={"robot_helth"}
                                                                               onChange={this.handleFormChange} checked={this.state.formValues.get("robot_helth") === true}/>
                                                                        <label className="form-check-label" htmlFor={"robot_helth"}>
                                                                            &nbsp;Изправност на робота&nbsp;&nbsp;
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                <textarea className="form-control" rows={3}
                                                          name="comments" onChange={this.handleFormChange}
                                                          value={this.state.formValues.get('comments')}/>
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                <div><strong>Моля, изберете работна смяна и въведете броя на служителите, за да продължите с отчета...</strong></div>
                                        }


                                    </>
                                }

                                {
                                    this.state.report && this.state.isPreviewMode &&
                                    <>
                                        <ViewOrderingReportComponent
                                            report={this.state.report}
                                            shifts={this.state.shifts}
                                            bricks_types={this.state.brickTypes}
                                        />
                                    </>
                                }
                            </div>

                        </div>

                    </div>

                    <div className="card-footer">
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    {
                                        moment.isMoment(this.state.selectedDate) ?
                                            <>
                                                {
                                                    this.state.report ?
                                                        <>
                                                            {
                                                                this.state.working ?
                                                                    <div className="spinner-border" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                    :
                                                                    !this.state.isPreviewMode &&
                                                                    <>
                                                                        <button className="btn btn-primary" onClick={this.saveReport}>Запази</button>
                                                                        &nbsp;
                                                                        <button className="btn btn-secondary" onClick={() => {
                                                                            this.setState({report: null});
                                                                            this.loadReports();
                                                                        }}>Откажи
                                                                        </button>
                                                                    </>
                                                            }
                                                        </>
                                                        :
                                                        this.state.reports.length < 2 &&
                                                        <>
                                                            <button className="btn btn-primary" onClick={() => {
                                                                this.addReport()
                                                            }}>Добави нов
                                                            </button>
                                                        </>
                                                }
                                            </>
                                            :
                                            <><span className="text-info">Моля, изберете дата...</span></>
                                    }
                                </>
                        }
                    </div>
                </div>

                <Modal
                    show={this.state.dialogConfirmDeleteVisible}
                    onHide={() => this.setState({dialogConfirmDeleteVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Внимание</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Моля, потвърдете изтриването!</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    <button type={"button"} className={"btn btn-secondary"} onClick={() => this.setState({dialogConfirmDeleteVisible: false})}>Откажи</button>
                                    <button type={"button"} className={"btn btn-primary"} onClick={this.doDeleteReport}>Изтрий</button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

}
