import React from "react";
import {$enum} from "ts-enum-util";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {minutesToHours, numberWithCommas} from "../../common";
import moment, {Moment} from "moment";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


interface BricksWasteChartProps {
    chartFor: string,
    title: string,
    periodFrom: string,
    periodTo: string,
    shiftId: number | undefined,
    data: any,
}

interface ChartTotals {
    label: string,
    value: number
}

interface BricksWasteChartState {
    options: any,
    periodFrom: string,
    periodTo: string,
    daysCount: number,
    shiftId: number | undefined,
    totals: ChartTotals[],
}


export class BricksWasteChart extends React.Component<BricksWasteChartProps, BricksWasteChartState> {
    options = {
        chart: {
            type: 'column',
            height: '300px',
            zoom: 'x',
        },
        title: {
            align: 'left',
            useHTML: true,
            text: '',
        },
        subtitle: {
            floating: true,
            useHTML: true,
            align: 'left',
            text: ''
        },
        colors: ['#2f7ed8', '#f50000', '#f2f200'],
        tooltip: {
            enabled: true,
        },
        xAxis: {
            categories: ['Тухла'],
            title: {
                text: null
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Брой',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            },
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    // align: 'left',
                    border: true,
                    borderWidth: 1,
                    backgroundColor: 'rgba(252, 255, 197, 0.5)',
                    color: 0,
                    shadow: true,
                    // verticalAlign: 'top',
                    // useHTML: true,
                    // x: 10,
                    // y: -20,
                    formatter: function (): any {
                        // @ts-ignore
                        return this.point.y > 0 ? this.point.label : null;
                    }
                }
            }
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            borderWidth: 1,
            backgroundColor: '#FFFFFF',
            shadow: true,
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Производство',
            data: [0]
        }]
    }


    constructor(props: BricksWasteChartProps) {
        super(props);
        this.state = {
            periodFrom: props.periodFrom,
            periodTo: props.periodTo,
            daysCount: 1,
            shiftId: props.shiftId,
            options: {},
            totals: [],
        }

        this.processData = this.processData.bind(this);
    }

    componentDidMount() {
        this.processData(this.props.data);
    }

    processData(data: any) {
        const fromPeriod = moment(this.props.periodFrom, 'DD.MM.YYYY');
        const toPeriod = moment(this.props.periodTo, 'DD.MM.YYYY');
        const dates: string[] = [];
        const chartTotals: ChartTotals[] = [];

        toPeriod.add(1, 'days');

        while (fromPeriod.isBefore(toPeriod)) {
            dates.push(fromPeriod.format('DD.MM.YYYY'));
            fromPeriod.add(1, 'days');
        }

        if (this.props.chartFor === 'extruder') {

            const chartData: any[] = [];
            chartTotals.push({label: 'Брой тухли', value: 0});

            dates.forEach(d => {
                    const _d: number = data[d] || 0;
                    chartData.push({y: _d, label: _d});
                    chartTotals[0].value += _d;
                }
            );

            const _o = {
                ...this.options,
                xAxis: {
                    categories: dates
                },
                yAxis: {
                    plotLines: [{
                        value: chartTotals[0].value / (chartData.length), width: 2, color: 'rgba(0, 0, 0)',
                        label: {useHTML: true, text: 'Средно на ден', align: 'right'}
                    }]
                },
                series: [{
                    name: this.props.title,
                    data:
                        dates.map(d => {
                            return {
                                y: data[d] || 0,
                                label: data[d] || 0
                            }
                        })
                }]
            };

            this.setState({options: _o, totals: chartTotals, daysCount: dates.length});
        }


        if (this.props.chartFor === 'ordering') {

            const chartDataExtr: any[] = [];
            const chartDataRobot: any[] = [];
            chartTotals.push({label: 'Причина екструдер', value: 0}, {label: 'Причина робот', value: 0});

            dates.forEach(d => {
                    const _d: number = data[d]?.extruder || 0;
                    chartDataExtr.push({y: _d, label: _d});
                    chartTotals[0].value += _d;

                    const _d1: number = data[d]?.robot || 0;
                    chartDataRobot.push({y: _d1, label: _d1});
                    chartTotals[1].value += _d1;
                }
            );

            const _o = {
                ...this.options,
                xAxis: {
                    categories: dates
                },
                yAxis: {
                    plotLines: [{
                        value: (chartTotals[0].value + chartTotals[1].value) / (chartDataExtr.length + chartDataRobot.length), width: 2, color: 'rgba(0, 0, 0)',
                        label: {useHTML: true, text: 'Средно на ден', align: 'right'}
                    }]
                },
                series: [
                    {
                        name: 'причина екструдер',
                        data: chartDataExtr
                    },
                    {
                        name: 'причина робот',
                        data: chartDataRobot
                    }
                ]
            };

            this.setState({options: _o, totals: chartTotals, daysCount: dates.length});
        }

        if (this.props.chartFor === 'unloading') {

            const chartDataRobot: any[] = [];
            const chartDataLinia: any[] = [];
            const chartDataPechene: any[] = [];

            chartTotals.push({label: 'Причина робот', value: 0}, {label: 'Причина линия', value: 0}, {label: 'Причина печене', value: 0});

            dates.forEach(d => {
                    const _d: number = data[d]?.robot || 0;
                    chartDataRobot.push({y: _d, label: _d});
                    chartTotals[0].value += _d;

                    const _d1: number = data[d]?.linia || 0;
                    chartDataLinia.push({y: _d1, label: _d1});
                    chartTotals[1].value += _d1;

                    const _d2: number = data[d]?.pechene || 0;
                    chartDataPechene.push({y: _d2, label: _d2});
                    chartTotals[2].value += _d2;
                }
            );

            const _o = {
                ...this.options,
                xAxis: {
                    categories: dates
                },
                yAxis: {
                    plotLines: [{
                        value: (chartTotals[0].value + chartTotals[1].value + chartTotals[2].value) /
                            (chartDataRobot.length + chartDataLinia.length + chartDataPechene.length), width: 2, color: 'rgba(0, 0, 0)',
                        label: {useHTML: true, text: 'Средно на ден', align: 'right'}
                    }]
                },
                series: [
                    {
                        name: 'Причина робот',
                        data: chartDataRobot
                    },
                    {
                        name: 'Причина линия',
                        data: chartDataLinia
                    },
                    {
                        name: 'Причина печене',
                        data: chartDataPechene
                    }
                ]
            };

            this.setState({options: _o, totals: chartTotals, daysCount: dates.length});
        }


    }

    componentDidUpdate(prevProps: Readonly<BricksWasteChartProps>, prevState: Readonly<BricksWasteChartState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shiftId: this.props.shiftId
            });
            this.processData(this.props.data);
        }
    }

    render() {
        return (
            <>
                <BlockTitleComponent title={this.props.title}/>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    allowChartUpdate
                />
                <div>
                    <table className={"table table-bordered"}>
                        <tr>
                            <td className={"w-240px"}>Общо</td>
                            {
                                this.state.totals.map((i, idx) => <td key={idx}>{i.label}</td>)
                            }
                        </tr>
                        <tr>
                            <td className={"w-240px"}>Средно на ден:</td>
                            {
                                this.state.totals.map(
                                    (i, idx) => <td key={idx}><strong>{numberWithCommas(Math.round(i.value / (this.state.daysCount)))} тухли</strong></td>
                                )
                            }
                        </tr>
                        <tr>
                            <td className={"w-240px"}>Всичко за периода:</td>
                            {
                                this.state.totals.map(
                                    (i, idx) => <td key={idx}><strong>{numberWithCommas(i.value)} тухли</strong></td>
                                )
                            }
                        </tr>
                    </table>
                </div>
            </>
        );
    }

}
