import React, {useEffect, useState} from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {API_CALL, PARAMETERS, PropertyValue, REPAIR_TYPE, Sale} from "../../types";
import {getSalesTypeName, isLoading, isSuccess, numberWithCommas} from "../../common";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {API} from "../../api";
import moment, {Moment} from "moment";
import {Card, Col, Row} from "react-bootstrap";
import {SummaryItem, WeekDataItem} from "../../pages/PlanningPage";


export function PlanningCard() {
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState<Array<WeekDataItem>>([]);
    const [bricks, setBricks] = useState<Array<PropertyValue>>([]);

    const forDate = moment().format('DD.MM.YYYY');

    const renderDataRowItems = function (item: WeekDataItem) {
        if (item.request_data) {
            const rd = JSON.parse(item.request_data);
            return [0, 1, 2, 3, 4].map(
                idx => {
                    if (rd['property_id_' + idx]) {
                        const pId = rd['property_id_' + idx];
                        if (bricks[pId]) {
                            const name = bricks[pId].value;
                            const paletQty = rd['palet_qty_' + idx];
                            const qtyInPalet = rd['qty_in_palet_' + idx];
                            const total = rd['total_' + idx];

                            return <div key={idx}>{name} : {paletQty}п. х {qtyInPalet}бр.</div>
                        }
                    }
                }
            )
        }

        return <div>Няма данни</div>;
    }

    function ClientRow(props: { item: WeekDataItem }) {
        const p = props.item;
        return <>
            <tr>
                <td rowSpan={2} style={{backgroundColor: "#bb332cff", width: '5px' }}></td>
                <th>{p.client}</th>
                <td>{p.client_city}</td>
                <td>{p.client_phone}</td>
                <td>{p.currier}</td>
            </tr>
            <tr style={{borderBottom: "solid 5px #fff"}}>
                <td colSpan={4} className={"small pt-0 pb-0"}>
                    { renderDataRowItems(p) }
                </td>
            </tr>
        </>
    }

    const loadParams = () => {
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    const _bricks: PropertyValue[] = apiCall.data.data.items?.values;
                    _bricks[999] = {
                        id: 999,
                        value: 'ТУХЛА ВТОРО КАЧЕСТВО',
                        description: {
                            shortName: 'II качество'
                        }
                    };

                    _bricks[997] = {
                        id: 997,
                        value: 'ПЕЛЕТИ 6мм',
                        description: {
                            shortName: 'ПЕЛЕТИ 6мм'
                        }
                    };

                    _bricks[998] = {
                        id: 998,
                        value: 'ПЕЛЕТИ 8мм',
                        description: {
                            shortName: 'ПЕЛЕТИ 8мм'
                        }
                    };
                    setBricks(_bricks);
                    loadData()
                }
            }, PARAMETERS.BRICKS
        );
    }

    const loadData = function () {
        if (!API.user?.is_admin) return null;

        API.getPlanning(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setPlan(
                        apiCall.data.data.plan.filter(
                            (p: WeekDataItem) => {
                                return p.status !== 'COMPLETED' && p.plan_date === forDate
                            }
                        )
                    );
                }
            }, moment().format('Y'), true
        );
    }

    useEffect(() => {
        loadParams();
    }, [])

    if (!API.user?.is_admin) return null;

    return (
        <Card>
            <Card.Header className={"home-card-header main-modules-header main-modules-header-primary-text"}>
                <Row className={"fw-bold"}>
                    <Col><h2>Планиране</h2></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <div className="row">
                    <div className="col">
                        {
                            loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                        {
                            !loading &&
                            <table className={"table small"}>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th className={"th-dark"}>Клиент</th>
                                    <th className={"th-light"}>Град</th>
                                    <th className={"th-dark"}>Телефон</th>
                                    <th className={"th-light"}>Транспорт</th>
                                </tr>
                                </thead>
                                <tbody>
                                {plan.length === 0 && <tr>
                                    <th colSpan={4}>Няма планирани клиенти за днес</th>
                                </tr>}
                                {plan?.map(p => <ClientRow key={p.id} item={p}/>)}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
