import React, {useEffect, useState} from "react";
import {API_CALL} from "../../types";
import {isLoading, isSuccess} from "../../common";
import {API} from "../../api";
import {Card, Col, Row} from "react-bootstrap";
import moment from "moment";


interface CommentItem {
    modul: string;
    shift: string;
    comment: string;
}

export function IncidentsCard() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<Array<CommentItem>>([]);

    const forDate = moment().format('DD.MM.YYYY');

    const loadData = function () {
        if(!API.user?.is_admin) return null;

        API.calcCommentsReport(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    const _data: Array<CommentItem> = [];

                    apiCall.data.data.report.grinding.forEach(
                        (i: any) => _data.push({ modul: 'Мелене', shift: i.shift, comment: i.comment }) )

                    apiCall.data.data.report.extruder.forEach(
                        (i: any) => _data.push({ modul: 'Екструдер', shift: i.shift, comment: i.comment }) )

                    apiCall.data.data.report.heater.forEach(
                        (i: any) => _data.push({ modul: 'Печка', shift: i.shift, comment: i.comment }) )

                    apiCall.data.data.report.ordering.forEach(
                        (i: any) => _data.push({ modul: 'Подреждане', shift: i.shift, comment: i.comment }) )

                    apiCall.data.data.report.oven.forEach(
                        (i: any) => _data.push({ modul: 'Пещ', shift: i.shift, comment: i.comment }) )

                    apiCall.data.data.report.unloading.forEach(
                        (i: any) => _data.push({ modul: 'Разтоварване', shift: i.shift, comment: i.comment }) )

                    apiCall.data.data.report.repairs.forEach(
                        (i: any) => _data.push({ modul: 'Ремонти', shift: i.shift, comment: i.comment }) )

                    setData(_data);
                }
            }, { periodFrom: forDate, periodTo: forDate }
        )
    }

    useEffect(() => {
        loadData()
    }, [])

    if(!API.user?.is_admin) return null;

    return (
        <Card>
            <Card.Header className={"home-card-header main-modules-header main-modules-header-primary-text"}>
                <Row className={"fw-bold"}>
                    <Col><h2>Бележки към отчетите</h2></Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <div className="row" >
                    <div className="col">
                        {
                            loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                        {
                            !loading &&
                            <table className={"table small"}>
                                <thead>
                                    <tr>
                                        <th className={"th-dark"}>Отдел</th>
                                        <th className={"th-light"}>Смяна</th>
                                        <th className={"th-dark"}>Бележка</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data.length === 0 && <tr><th colSpan={3}>Няма бележки</th></tr> }
                                    {
                                        data.map(
                                            (item, idx) => <tr key={idx}>
                                                <th>{ item.modul } </th>
                                                <th>{ item.shift } </th>
                                                <th>{ item.comment } </th>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}
