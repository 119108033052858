import {Report, PropertyValue} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {TimePickerComponent} from "../common/TimePickerComponent";

export interface EditGrindingReportProperties {
    report: Report;
    onChange: any;
}

interface GrindingReportState {
    formValues: Map<string, any>,
    showFromPicker: boolean,
    showToPicker: boolean,
}


export class EditGrindingReportComponent extends React.Component<EditGrindingReportProperties, GrindingReportState> {

    constructor(props: EditGrindingReportProperties) {
        super(props);

        const requestValues = props.report?.request_data ? Object.entries(JSON.parse(props.report.request_data)) : [];

        this.state = {
            formValues: new Map<string, any>(),
            showFromPicker: false,
            showToPicker: false,
        }

        for (let [key, value] of requestValues) {
            this.state.formValues.set(key, value);
            if(this.props.onChange) this.props.onChange(key, value);
        }

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleFormChange(event: any) {
        if (event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState(
                prevState => {
                    return {formValues: prevState.formValues.set(name, value)}
                }
            );

            if(this.props.onChange) this.props.onChange(name, value);
        }
    }

    render() {
        return (
            <>
                <BlockTitleComponent title={"Зареждане"}/>
                <div className="row">

                    <div className="col-2">
                        <label className="form-label w-120px">
                            Час начало:
                            <input className="form-control" type="text" name="periodFrom" readOnly={true}
                                   required
                                   value={this.state.formValues?.get('periodFrom')}
                                   onChange={this.handleFormChange}
                                   onClick={() => this.setState({showFromPicker: !this.state.showFromPicker, showToPicker: false})}
                            /></label>
                        {
                            this.state.showFromPicker && <TimePickerComponent
                                onSelect={
                                    (time: string) => {
                                        this.setState({showFromPicker: false, formValues: this.state.formValues.set('periodFrom', time)});
                                        if(this.props.onChange) this.props.onChange('periodFrom', time);
                                    }
                                }
                                value={this.state.formValues?.get('periodFrom')}/>
                        }
                    </div>
                    <div className="col-2">
                        <label className="form-label w-120px">
                            Час край:
                            <input className="form-control" type="text" name="periodTo" readOnly={true}
                                   required
                                   value={this.state.formValues?.get('periodTo')}
                                   onChange={this.handleFormChange}
                                   onClick={() => this.setState({showToPicker: !this.state.showToPicker, showFromPicker: false})}
                            />
                        </label>
                        {
                            this.state.showToPicker && <TimePickerComponent
                                onSelect={
                                    (time: string) => {
                                        this.setState({showToPicker: false, formValues: this.state.formValues.set('periodTo', time)});
                                        if(this.props.onChange) this.props.onChange('periodTo', time);
                                    }
                                }
                                value={this.state.formValues?.get('periodTo')}/>
                        }
                    </div>

                    <div className="col-2">
                        <label className="form-label w-120px">
                            Глина (кофи):
                            <input className="form-control" type="number" name="clayQty"
                                   required
                                   value={this.state.formValues?.get('clayQty')}
                                   onChange={this.handleFormChange}
                            /></label>
                    </div>

                    <div className="col-2">
                        <label className="form-label w-120px">
                            Шлам (кофи):
                            <input className="form-control" type="number" name="sludgeQty"
                                   required
                                   value={this.state.formValues?.get('sludgeQty')}
                                   onChange={this.handleFormChange}
                            /></label>
                    </div>

                    <div className="col-4">
                        <label className="form-label w-160px">
                            Светъл отвор валове:
                            <input className="form-control" type="number" name="valsFuga"
                                   required
                                   value={this.state.formValues?.get('valsFuga')}
                                   onChange={this.handleFormChange}
                            /></label>
                    </div>

                </div>

                <BlockTitleComponent title={"Бележки към отчета"}/>
                <div className="col-12 p-0 m-0">
                                <textarea className="form-control" rows={3} name="comments" onChange={this.handleFormChange}
                                          value={this.state.formValues.get('comments')}/>
                </div>
            </>
        )
    }
}
