import React, {ChangeEvent} from "react";
import {OvenReport, OvenTempsRecord, PropertyValue} from "../../types";
import moment, {Moment} from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TimePickerComponent} from "../common/TimePickerComponent";

export interface OvenTempsProps {
    temps: OvenTempsRecord[],
    onChange: any,
}

export interface OvenTempsState {
    formValues: Map<string, any>,
}

const ROW_COUNT = 22;

export class OvenTempsComponent extends React.Component<OvenTempsProps, OvenTempsState> {
    constructor(props: OvenTempsProps) {
        super(props);
        this.state = {
            formValues: new Map<string, any>(),
        }

        if (this.props.temps) {
            this.props.temps.forEach(
                (i: OvenTempsRecord) => {
                    const idx = i.wagonNum;
                    this.state.formValues.set('time_' + idx, i.time);
                    this.state.formValues.set('temp_1_1_' + idx, i.temp_1_1);
                    this.state.formValues.set('temp_1_2_' + idx, i.temp_1_2);
                    this.state.formValues.set('temp_2_1_' + idx, i.temp_2_1);
                    this.state.formValues.set('temp_2_2_' + idx, i.temp_2_2);
                    this.state.formValues.set('temp_3_1_' + idx, i.temp_3_1);
                    this.state.formValues.set('temp_3_2_' + idx, i.temp_3_2);
                    this.state.formValues.set('temp_4_1_' + idx, i.temp_4_1);
                    this.state.formValues.set('temp_4_2_' + idx, i.temp_4_2);
                    this.state.formValues.set('temp_5_1_' + idx, i.temp_5_1);
                    this.state.formValues.set('temp_5_2_' + idx, i.temp_5_2);
                    this.state.formValues.set('temp_6_1_' + idx, i.temp_6_1);
                    this.state.formValues.set('temp_6_2_' + idx, i.temp_6_2);
                });
        }

        for (let idx = 0; idx < ROW_COUNT; idx++) {
            this.state.formValues.set('showTimePicker_' + idx, false);
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.notifyParent = this.notifyParent.bind(this);
        this.renderFormRow = this.renderFormRow.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.togleTimePicker = this.togleTimePicker.bind(this);

        this.notifyParent(this.state.formValues);
    }

    handleFormChange(event: any) {
        const fv = this.state.formValues;

        if(event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            fv.set(name, value);
            this.setState({formValues: fv});
        }

        this.notifyParent(fv);
    }

    notifyParent(fv: Map<string, any>) {
        if (this.props.onChange) {
            const items: OvenTempsRecord[] = [];

            for(let idx=0; idx<ROW_COUNT; idx++) {
                items.push(
                    {
                        wagonNum: idx,
                        time: fv.get('time_'+idx) || '',
                        temp_1_1: fv.get('temp_1_1_'+idx) || '',
                        temp_1_2: fv.get('temp_1_2_'+idx) || '',
                        temp_2_1: fv.get('temp_2_1_'+idx) || '',
                        temp_2_2: fv.get('temp_2_2_'+idx) || '',
                        temp_3_1: fv.get('temp_3_1_'+idx) || '',
                        temp_3_2: fv.get('temp_3_2_'+idx) || '',
                        temp_4_1: fv.get('temp_4_1_'+idx) || '',
                        temp_4_2: fv.get('temp_4_2_'+idx) || '',
                        temp_5_1: fv.get('temp_5_1_'+idx) || '',
                        temp_5_2: fv.get('temp_5_2_'+idx) || '',
                        temp_6_1: fv.get('temp_6_1_'+idx) || '',
                        temp_6_2: fv.get('temp_6_2_'+idx) || '',
                    } as OvenTempsRecord
                )
            }

            this.props.onChange('temps', items);
        }
    }

    togleTimePicker(idx: number, time?: string) {
        if (time !== null) this.state.formValues.set('time_' + idx, time);
        for (let i = 0; i < ROW_COUNT; i++) {
            if (i === idx) this.state.formValues.set('showTimePicker_' + i, !this.state.formValues.get('showTimePicker_' + i));
            else this.state.formValues.set('showTimePicker_' + i, false);
        }
        this.setState({formValues: this.state.formValues});
    }

    renderFormRow(idx: number) {
        return (
            <tr className={"border-0"} key={idx}>
                <td className={"w-100px text-end"}>
                    <span className={"pr-2"} style={{lineHeight: "2rem"}}>{idx + 1}</span>
                </td>
                <td className={"w-100px"}>
                    <input className="form-control m-0 p-0 border-0" type="text" readOnly={true} name={"time_" + idx} required
                           value={this.state.formValues.get('time_' + idx)}
                           onClick={() => this.togleTimePicker(idx)}
                    />
                    {
                        this.state.formValues.get('showTimePicker_' + idx) && <TimePickerComponent
                            onSelect={(time: string) => {
                                this.togleTimePicker(idx, time);
                                this.handleFormChange(null);
                            }}
                            value={this.state.formValues?.get('time_' + idx)}/>
                    }
                </td>
                {
                    [1, 2, 3, 4, 5, 6].map(
                        c =>
                            <td colSpan={2} key={idx * 10 + c}>
                                <table className={"table table-no-padding table-sm  mb-0"}>
                                    <tbody>
                                    <tr>
                                        <td className={"w-120px"}>
                                            <input className="form-control m-0 mr-1 p-0" type="text" name={"temp_" + c + "_1_" + idx} required
                                                   value={this.state.formValues.get("temp_" + c + "_1_" + idx)}
                                                   onChange={this.handleFormChange}/>
                                        </td>
                                        <td className={"w-120px"}>
                                            <input className="form-control m-0 mr-1 p-0" type="text" name={"temp_" + c + "_2_" + idx} required
                                                   value={this.state.formValues.get("temp_" + c + "_2_" + idx)}
                                                   onChange={this.handleFormChange}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                    )
                }

            </tr>
        )
    }

    renderForm() {
        const rows: any[] = [];

        for (let idx = 0; idx < ROW_COUNT; idx++) {
            rows.push(this.renderFormRow(idx));
        }

        return rows;
    }

    render() {
        return (
            <>
                <table className="table table-hover table-sm " id="table-oven-temps">
                    <thead>
                    <tr className={"text-center"}>
                        <th>Вагон #</th>
                        <th>Час</th>
                        <th colSpan={2}>1</th>
                        <th colSpan={2}>2</th>
                        <th colSpan={2}>3</th>
                        <th colSpan={2}>4</th>
                        <th colSpan={2}>5</th>
                        <th colSpan={2}>6</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.renderForm()
                    }
                    </tbody>
                </table>
            </>
        );
    }
}
