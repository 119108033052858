import React from "react";
import {API_CALL, PARAMETERS, PropertyValue} from "../types";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, showMsgInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {AUTOSAVE_INTERVAL} from "../index";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";


interface StandartsPageProps {
}

interface StandartsPageState {
    working: boolean,
    loading: number,
    formValues: Map<string, any>,
    shifts: PropertyValue[],
    bricks: PropertyValue[],
}

export class StandartsPage extends React.Component<StandartsPageProps, StandartsPageState> {

    constructor(props: StandartsPageProps) {
        super(props);
        this.state = {
            working: true,
            loading: 0,
            formValues: new Map<string, any>(),
            shifts: [],
            bricks: [],
        }

        this.saveData = this.saveData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() {
        this.loadParameters();
        this.loadData();
    }

    loadParameters() {
        this.setState({loading: this.state.loading + 1});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bricks: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BRICKS
        );
    }

    loadData() {
        API.getStandarts(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const fv = new Map<string, any>();

                    const data = JSON.parse(apiCall.data.data.standarts.data);

                    Object.keys(data).forEach(
                        key => fv.set(key, data[key])
                    );

                    this.setState({formValues: fv});
                }
            }
        )
    }

    saveData() {
        this.doSaveData(false);
    }

    autoSaveData() {
        this.doSaveData(true);
    }

    doSaveData(isAutosave: boolean = false) {
        API.postStandarts(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    if (!isAutosave) {
                        this.loadData();
                        showMsgInToast("Данните са запазени успешно.");
                    }
                }
                if (isError(apiCall)) {
                    if (!isAutosave) showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }

                this.setState({working: isLoading(apiCall)});
            }, mapToObject(this.state.formValues)
        );
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState({formValues: fv});
    }


    render() {
        return (
            <div className="container">

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}><span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Производствени стандарти</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">

                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div className={"m-3"}>Зареждам данните ...</div>
                                }
                            </div>
                        </div>

                        <BlockTitleComponent title={"Мелене"}/>
                        <div className={"row mb-5"}>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Кофа глина в <b>КГ</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'bucket_clay'}
                                        value={this.state.formValues.get('bucket_clay')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Кофа шлам в <b>КГ</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'bucket_sludge'}
                                        value={this.state.formValues.get('bucket_sludge')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Брой кофи глина за <b>8 часа</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'clay'}
                                        value={this.state.formValues.get('clay')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Брой кофи шлам за <b>8 часа</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'sludge'}
                                        value={this.state.formValues.get('sludge')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                        </div>

                        <BlockTitleComponent title={"Екструдер - <b class='text-uppercase'>брой вагонетки</b> по вид тухли за 8 часа"}/>
                        <div className={"row mb-5"}>
                            {
                                this.state.bricks.map(
                                    item => (
                                        <div className={"col-auto"}>
                                            <label className={"col-form-label"}>
                                                {item.description.shortName}
                                                <input key={item.id}
                                                       className={"form-control w-180px"}
                                                       name={'extruder_' + item.id}
                                                       value={this.state.formValues.get('extruder_' + item.id)}
                                                       onChange={this.handleFormChange}/>
                                            </label>
                                        </div>
                                    )
                                )
                            }
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Брой тухли брак за <b>8 часа</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'extruder_waste'}
                                        value={this.state.formValues.get('extruder_waste')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                        </div>


                        <BlockTitleComponent title={"Разход пелети в <b>КГ</b> за 8 часа"}/>
                        <div className={"row mb-5"}>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Разход за <b>Печка</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'heater_pelets'}
                                        value={this.state.formValues.get('heater_pelets')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Разход за <b>Пещ (пещ)</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'oven_pelets'}
                                        value={this.state.formValues.get('oven_pelets')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Разход за <b>Пещ (печка)</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'oven_heater_pelets'}
                                        value={this.state.formValues.get('oven_heater_pelets')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                        </div>


                        <BlockTitleComponent title={"Редене - <b class='text-uppercase'>брой вагони</b> по вид тухли за 8 часа"}/>
                        <div className={"row mb-5"}>
                            {
                                this.state.bricks.map(
                                    item => (
                                        <div className={"col-auto"}>
                                            <label className={"col-form-label"}>
                                                {item.description.shortName}
                                                <input key={item.id}
                                                       className={"form-control w-180px"}
                                                       name={'ordering_' + item.id}
                                                       value={this.state.formValues.get('ordering_' + item.id)}
                                                       onChange={this.handleFormChange}/>
                                            </label>
                                        </div>
                                    )
                                )
                            }
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Брой тухли брак за <b>8 часа</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'ordering_waste'}
                                        value={this.state.formValues.get('ordering_waste')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                        </div>

                        <BlockTitleComponent title={"Пещ - <b class='text-uppercase'>брой вагони</b> по вид тухли за 12 часа"}/>
                        <div className={"row mb-5"}>
                            {
                                this.state.bricks.map(
                                    item => (
                                        <div className={"col-auto"}>
                                            <label className={"col-form-label"}>
                                                {item.description.shortName}
                                                <input key={item.id}
                                                       className={"form-control w-180px"}
                                                       name={'oven_' + item.id}
                                                       value={this.state.formValues.get('oven_' + item.id)}
                                                       onChange={this.handleFormChange}/>
                                            </label>
                                        </div>
                                    )
                                )
                            }
                        </div>

                        <BlockTitleComponent title={"Разтоварване - <b class='text-uppercase'>брой вагони</b> по вид тухли за 8 часа"}/>
                        <div className={"row mb-1"}>
                            {
                                this.state.bricks.map(
                                    item => (
                                        <div className={"col-auto"}>
                                            <label className={"col-form-label"}>
                                                {item.description.shortName}
                                                <input key={item.id}
                                                       className={"form-control w-180px"}
                                                       name={'unload_' + item.id}
                                                       value={this.state.formValues.get('unload_' + item.id)}
                                                       onChange={this.handleFormChange}/>
                                            </label>
                                        </div>
                                    )
                                )
                            }
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Брой тухли брак за <b>8 часа</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'unload_waste'}
                                        value={this.state.formValues.get('unload_waste')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Брой тухли IIкач. за <b>8 часа</b>
                                    <input
                                        className={"form-control w-180px"}
                                        name={'unload_not_good'}
                                        value={this.state.formValues.get('unload_not_good')}
                                        onChange={this.handleFormChange}/>
                                </label>
                            </div>
                        </div>

                        <BlockTitleComponent title={"Други стандарти"}/>
                        <div className={"row mb-1"}>
                            <div className={"col-auto"}>
                                <label className={"col-form-label"}>
                                    Стреч за палет (кг)
                                    <input className={"form-control w-180px"}
                                           name={'stretch'}
                                           value={this.state.formValues.get('stretch')}
                                           onChange={this.handleFormChange}/>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        {
                            <>
                                <button className="btn btn-primary" onClick={this.saveData}>Запази</button>
                                &nbsp;
                                <button className="btn btn-secondary" onClick={() => window.location.href = "/home" + API.getAPITokenAsQuery()}>Откажи</button>
                            </>
                        }
                    </div>
                </div>

            </div>
        );
    }

}
