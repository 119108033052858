import {City, Client} from "../../types";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";

export interface EditClientCityProps {
    city: City;
    onSave: (city: City) => any;
    onCancel: () => any;
}

export function EditClientCity(props: EditClientCityProps) {
    console.log(props);
    const initMap = function () {
        const m = new Map<string, any>();
        m.set('city', props.city.city);
        m.set('phone', props.city.phone);
        return m;
    }
    const [formValues, setFormValues] = useState(initMap());

    const saveItem = function () {
        props.onSave(
            {
                city: formValues.get('city'),
                phone: formValues.get('phone')
            } as City
        );
    }

    const cancelItem = function () {
        props.onCancel();
    }

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    return (
        <>
            <div className="row mb-3">
                <div className="col">
                    <label>Град:</label>
                    <input className="form-control" type="text" name="city" required
                           value={formValues.get('city')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label>Телефон:</label>
                    <input className="form-control" type="text" name="phone" required
                           value={formValues.get('phone')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <button type={"button"} className={"btn btn-primary"} onClick={saveItem}>Запази</button>&nbsp;
                    <button type={"button"} className={"btn btn-secondary"} onClick={cancelItem}>Откажи</button>
                </div>
            </div>
        </>
    )
}
