import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faKey, faUserLock, faCog, faSearch, faAngleRight, faAngleLeft, faArrowLeft, faHome, faHamburger,
    faCalendarAlt, faTimesCircle, faCheckCircle, faPlus, faMinus, faTrashAlt, faPen, faWindowClose,
    faPrint, faCheck, faTimes, faUser, faSignOutAlt, faEye, faHandHoldingUsd,
} from '@fortawesome/free-solid-svg-icons'
// @ts-ignore
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {LoginPage} from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import {AdminPage} from './pages/AdminPage';
import {AccountsPage} from "./pages/AccountsPage";
import {AccountPage} from './pages/AccountPage';
import {PATHS} from './paths';
import {HeaterPage} from './pages/HeaterPage';
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {GrindingPage} from './pages/GrindingPage';
import {OrderingPage} from './pages/OrderingPage';
import {UnloadingPage} from './pages/UnloadingPage';
import {ExtruderPage} from './pages/ExtruderPage';
import {PropertiesPage} from "./pages/PropertiesPage";
import {OvenPage} from './pages/OvenPage';
import {SalesPage} from './pages/SalesPage';
import {DailyReportsPage} from './pages/DailyReportsPage';
import {RepairsPage} from './pages/RepairsPage';
import {RefuelingPage} from "./pages/RefuelingPage";
import {StandartsPage} from "./pages/StandartsPage";
import {API} from "./api";
import {API_CALL, CALENDAR_MODE} from "./types";
import {isSuccess} from "./common";
import {ReportsPage} from "./pages/ReportsPage";
import {ClientsPage} from "./pages/ClientsPage";
import {LogisticsPage} from "./pages/LogisticsPage";
import {DailyFullReportPage} from "./pages/DailyFullReportPage";
import {DailyProductionReportsPage} from "./pages/DailyProductionReportsPage";
import {FinancialReportsPage} from "./pages/FinancialReportsPage";
import {CommentsReportPage} from "./components/reports/CommentsReportPage";
import {BankExpensesPage} from "./pages/BankExpensesPage";
import {Form76Page} from "./pages/Form76Page";
import {PlanningPage} from "./pages/PlanningPage";
import {ReportFinancialPage} from "./pages/ReportFinancialPage";
import {CalendarPage} from "./pages/CalendarPage";

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import bg from 'date-fns/locale/bg';
import ClientsPricelist from "./pages/ClientsPricelist";
import StatusPage from "./pages/StatusPage";
import LayoutComponent from "./common/LayoutComponent";
import useInterval from "./hooks/useInterval";
import {FuelBalancePage} from "./pages/FuelBalancePage";
import WithRouter from "./common/WithRouter";
import AccountPageWrapper from "./common/WithRouter";


library.add(faKey, faUserLock, faCog, faSearch, faAngleRight, faAngleLeft, faArrowLeft, faHome, faHamburger, faCalendarAlt, faTimesCircle, faCheckCircle, faPlus,
    faTrashAlt, faMinus, faPen, faWindowClose, faHamburger, faPrint, faCheck, faTimes, faUser, faSignOutAlt, faEye, faHandHoldingUsd);

toast.configure({
    position: toast.POSITION.BOTTOM_CENTER
});

registerLocale('bg', bg);
setDefaultLocale('bg');

export const MediaTabsList = ['МЕЛЕНЕ', 'ЕКСТРУДИРАНЕ', 'ГЕНЕРАТОР ТОПЪЛ ВЪЗДУХ', 'РЕДЕНЕ', 'ПЕЩ', 'РАЗТОВАРВАНЕ'];
export const StatusSwitchTabInterval = 12000;

const App = () => {
    const [user, setUser] = useState(null);
    const isLogin = window.location.href.indexOf(PATHS.login) > -1

    const [tabs, setTabs] = useState<Array<string>>(MediaTabsList)
    const [activeMediaTab, setActiveMediaTab] = useState<number>(MediaTabsList.length - 1);

    useInterval(() => {
        let newTab = activeMediaTab - 1;
        if(newTab < 0) newTab = tabs.length - 1;
        setActiveMediaTab(newTab);

        const newTabs: Array<string> = []; let tabIdx = newTab;
        while (newTabs.length < MediaTabsList.length) {
            newTabs.push(MediaTabsList[tabIdx]);
            tabIdx--;
            if(tabIdx < 0) tabIdx = MediaTabsList.length - 1;
        }

        setTabs(newTabs);
    }, StatusSwitchTabInterval);

    useEffect(() => {
        if (!API.user && !isLogin) {
            API.getUserData((apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    API.user = apiCall.data.data.user;
                    setUser(apiCall.data.data.user);
                }
            })
        }
    })

    if (user || isLogin) return (
        <Router>
            <LayoutComponent tabs={tabs}>
                <Routes>
                    <Route path={PATHS.home} element={<HomePage/>} />
                    <Route path={PATHS.status} element={<StatusPage tabs={tabs} activeTab={activeMediaTab} />} />
                    <Route path="/home" element={<HomePage/>} />
                    <Route path={PATHS.login} element={<LoginPage/>} />

                    <Route path={PATHS.admin} element={<AdminPage/>} />
                    <Route path={PATHS.accounts} element={<AccountsPage/>} />
                    <Route path={PATHS.properties} element={<PropertiesPage/>} />
                    <Route path={PATHS.account + ":accountId"} element={<AccountPageWrapper />} />
                    <Route path={PATHS.heater} element={<HeaterPage/>} />
                    <Route path={PATHS.grinding} element={<GrindingPage/>} />
                    <Route path={PATHS.ordering} element={<OrderingPage/>} />
                    <Route path={PATHS.unloading} element={<UnloadingPage/>} />
                    <Route path={PATHS.extruder} element={<ExtruderPage/>} />
                    <Route path={PATHS.oven} element={<OvenPage/>} />
                    <Route path={PATHS.sales} element={<SalesPage/>} />
                    <Route path={PATHS.daily_reports} element={<DailyReportsPage/>} />
                    <Route path={PATHS.repairs} element={<RepairsPage/>} />
                    <Route path={PATHS.refueling} element={<RefuelingPage/>} />
                    <Route path={PATHS.comments} element={<CommentsReportPage/>} />
                    <Route path={PATHS.standarts} element={<StandartsPage/>} />
                    <Route path={PATHS.clients} element={<ClientsPage/>} />
                    <Route path={PATHS.logistics} element={<LogisticsPage/>} />
                    <Route path={PATHS.bank_expenses} element={<BankExpensesPage/>} />
                    <Route path={PATHS.fuel_balance} element={<FuelBalancePage/>} />
                    <Route path={PATHS.form76} element={<Form76Page/>} />
                    <Route path={PATHS.planning} element={<PlanningPage/>} />
                    <Route path="/reports/production" element={<ReportsPage />}/>
                    <Route path="/reports/financial" element={<FinancialReportsPage />}/>
                    <Route path="/reports/prod-fin" element={<ReportFinancialPage />}/>
                    <Route path="/reports/daily" element={<DailyProductionReportsPage />}/>
                    <Route path="/reports/dailyfullreport" element={<DailyFullReportPage />}/>
                    <Route path={PATHS.calendar_machines} element={<CalendarPage mode={CALENDAR_MODE.MACHINES}/>} />
                    <Route path={PATHS.calendar_administrative} element={<CalendarPage mode={CALENDAR_MODE.ADMINISTRATIVE}/>}/>
                    <Route path={PATHS.clients_pricelist} element={<ClientsPricelist />} />
                </Routes>
            </LayoutComponent>
        </Router>
    ); else return <></>;
}

export default App;
