import React, {useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {Account} from "../types";
import MultimediaVideo from "../components/status/videos/MultimediaVideo";
import TeamsPerformanceSection from "../components/status/TeamsPerformanceSection";
import ProductionPerformancePart from "../components/status/ProductionPerformancePart";
import ProductionNotesPart from "../components/status/ProductionNotesPart";
import CalendarEventsPart from "../components/status/CalendarEventsPart";
import useInterval from "../hooks/useInterval";

export const titleStyle = {fontSize: "42px", fontWeight: "bold", lineHeight: "56px", color: "white", letterSpacing: "5px" };

function VideoPart(props: any) {
    return <Row>
        <Col>
            <MultimediaVideo onDone={props.onDone} />
        </Col>
        <Col xs={"auto"}>
            <TeamsPerformanceSection {...props} />
        </Col>
    </Row>;
}

export const STAY_DURATION = 15000;
export const VIDEO_INTERVAL = 15 * 60 * 1000;

export default function StatusPage(props: any) {
    const [activePart, setActivePart] = useState(0);

    let showVideoPart = useRef(false);
    useInterval(() => {
        showVideoPart.current = true;
    }, VIDEO_INTERVAL);

    function showNextPart() {
        let _ap = activePart + 1;
        if(_ap > 2) {
            if(showVideoPart.current) {
                _ap = 3;
                showVideoPart.current = false;
            } else {
                if(activePart === 3) window.location.href = window.location.href;
                else _ap = 0;
            }
        }

        setActivePart(_ap);
    }

    let part = null;
    switch (activePart) {
        case 0: part = <ProductionPerformancePart onDone={showNextPart} />; break;
        case 1: part = <ProductionNotesPart onDone={showNextPart} />; break;
        case 2: part = <CalendarEventsPart onDone={showNextPart} />; break;
        case 3: part = <VideoPart {...props} onDone={showNextPart} />; break;
    }

    return (
        <div className="container mw-100 overflow-hidden">
            { part }
        </div>
    );
}

export interface StatusPageRateProps {
    reports: any,
    users: Array<Account>
}
