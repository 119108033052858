import {UserModule} from "../../types";
import {ModuleComponent} from "./ModuleComponent";
import React, {useEffect, useState} from "react";
import {Accordion} from "react-bootstrap";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faCalendarAlt,
    faChartBar,
    faDollarSign,
    faIndustry,
    faUserFriends, faWarehouse
} from "@fortawesome/free-solid-svg-icons";


export interface ModulesComponentProps {
    modules: UserModule[];
}

interface GroupIconProps {
    groupName: string
}


export function ModulesComponent({modules}: ModulesComponentProps) {
    const [groups, setGroups] = useState<Map<string, Array<UserModule>>>(new Map<string, Array<UserModule>>());
    const [groupNames, setGroupNames] = useState<Array<string>>([]);

    const GroupIcon = function ({groupName}: GroupIconProps) {
        switch (groupName) {
            case "Администрация" : return <FontAwesomeIcon icon={faUserFriends} size={"lg"} style={{width: 85, marginLeft: -90}} />
            case "Производство" : return <FontAwesomeIcon icon={faIndustry} size={"lg"} style={{width: 85, marginLeft: -90}} />
            case "Търговска част" : return <FontAwesomeIcon icon={faDollarSign} size={"lg"} style={{width: 85, marginLeft: -90}} />
            case "Справки и отчети" : return <FontAwesomeIcon icon={faChartBar} size={"lg"} style={{width: 85, marginLeft: -90}} />
            case "Календар" : return <FontAwesomeIcon icon={faCalendarAlt} size={"lg"} style={{width: 85, marginLeft: -90}} />
            case "Склад" : return <FontAwesomeIcon icon={faWarehouse} size={"lg"} style={{width: 85, marginLeft: -90}} />
        }

        return null
    }

    useEffect(() => {
        const g = groups;
        const gN: Array<string> = [];
        modules.forEach(
            m => {
                if(g.has(m.module.group_name)) {
                    g.get(m.module.group_name)?.push(m)
                } else {
                    const arr: Array<UserModule> = [m];
                    g.set(m.module.group_name, arr);
                    gN.push(m.module.group_name);
                }
            }
        )

        setGroups(g);
        setGroupNames(gN);
    }, [modules])

    return (
        <>
            <Accordion flush className={"w-400px"}>
            {
                groupNames.map(
                    gN => <Accordion.Item key={gN} eventKey={gN}>
                        <Accordion.Header>
                            <GroupIcon groupName={gN} />
                            <span>{gN}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                            {
                                (groups.get(gN) || []).map(
                                    (m, idx) =>
                                        <ModuleComponent key={idx} module={m.module} />
                                )
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                )
            }
            </Accordion>
        </> )
}
