import React from "react";
import {FACTORY} from "../factories";
import {API} from "../api";
import {API_CALL, AppModule, Department, NET_STATUS, UserModule} from "../types";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, translateError} from "../common";
import {Link, Navigate} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Modal, Row} from 'react-bootstrap';
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

interface AccountPageState {
    api: API_CALL,
    formValues: Map<string, any>,
    modules: AppModule[],
    departments: Department[],
    user: any,
    dialogConfirmDeleteVisible: boolean,
    working: boolean,
}

export class AccountPage extends React.Component<any, AccountPageState> {
    accountId: number | null = null;

    constructor(props: any) {
        super(props);

        console.log(props);

        this.state = {
            api: FACTORY.createApiCall(NET_STATUS.LOADING),
            formValues: new Map<string, any>(),
            modules: [],
            departments: [],
            user: null,
            dialogConfirmDeleteVisible: false,
            working: false,
        }
        this.accountId = props.router?.params.accountId;
        this.handleFormChange = this.handleFormChange.bind(this);
        this.doDeleteReport = this.doDeleteReport.bind(this);
    }

    componentDidMount() {
        this.loadModules();
    }

    loadModules() {
        const me = this;
        API.getDepartments(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({departments: apiCall.data.data.departments});
                }
            }
        );

        API.getAppModules(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({modules: apiCall.data.data.modules});
                    me.loadAccount();
                }
            }
        )
    }

    loadAccount() {
        const id = this.accountId || -1;
        if (!isNaN(id)) {
            API.getAccount(
                (apiCall: API_CALL) => {
                    if (isSuccess(apiCall)) {
                        const formValues = new Map<string, any>();
                        formValues.set('name', apiCall.data.data.user.name);
                        formValues.set('key', apiCall.data.data.user.key);
                        formValues.set('email', apiCall.data.data.user.email);
                        formValues.set('department_id', apiCall.data.data.user.department_id);
                        formValues.set('position', apiCall.data.data.user.position);
                        formValues.set('birth_date', apiCall.data.data.user.birth_date);
                        formValues.set('greeting_card', apiCall.data.data.user.greeting_card);
                        formValues.set('first_date', apiCall.data.data.user.first_date);
                        formValues.set('last_date', apiCall.data.data.user.last_date);
                        formValues.set('is_admin', apiCall.data.data.user.is_admin === 'Y');
                        formValues.set('is_executor', apiCall.data.data.user.is_executor === 'Y');
                        apiCall.data.data.user.modules.forEach(
                            (item: UserModule) => {
                                formValues.set('mr_' + item.module.id, item.access_rights === 'READ');
                                formValues.set('mw_' + item.module.id, item.access_rights === 'WRITE');
                            }
                        );
                        this.setState({api: apiCall, user: apiCall.data?.data?.user, formValues: formValues});
                    }
                }, id
            )
        } else {
            const _api = {...this.state.api, status: NET_STATUS.SUCCESS};
            this.setState({api: _api, user: null});
        }
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    private doSave() {
        API.saveAccount(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isSuccess(apiCall)) {
                    window.location.href = PATHS.accounts + API.getAPITokenAsQuery();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {...mapToObject(this.state.formValues), userId: this.state.user ? this.state.user.id : -1}
        )

    }

    doDeleteReport() {
        this.setState({dialogConfirmDeleteVisible: false});
        API.deleteAccount(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    window.location.href = PATHS.accounts + API.getAPITokenAsQuery();
                }
            }, this.state.user.id
        );
    }


    render() {
        if (isError(this.state.api)) return (<Navigate to={PATHS.accounts}/>);

        return (
            <div className="container">
                <div className="card shadow">
                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                isLoading(this.state.api) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.accounts + API.getAPITokenAsQuery()}><span
                                        className="btn btn-secondary"><FontAwesomeIcon
                                        icon={faArrowLeft}/></span></Link>
                            }
                        </div>
                        <h2 className="col">{this.state.user?.name || 'Нов служител'}</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body p-5">
                        {
                            (isSuccess(this.state.api) || (!this.state.user)) &&
                            <form className={"w-100"}>
                                <Row className={"flex-nowrap"}>
                                    <Col>
                                        <div className="form-group mb-2">
                                            <label>Име на служителя:</label>
                                            <input className="form-control" type="text" name="name"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('name') || ''}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Отдел:</label>
                                            <select className="form-control" name="department_id"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('department_id') || '-1'}>
                                                <option value={-1}>Не е в никой отдел</option>
                                                {
                                                    this.state.departments.map(
                                                        d => <option key={d.id} value={d.id}>{ d.name }</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Длъжност:</label>
                                            <input className="form-control" type="text" name="position"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('position') || ''}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Дата на раждане:</label>
                                            <input className="form-control" type="text" name="birth_date"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('birth_date') || ''}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Постъпил на работа на:</label>
                                            <input className="form-control" type="text" name="first_date"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('first_date') || ''}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Напуснал на:</label>
                                            <input className="form-control" type="text" name="last_date"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('last_date') || ''}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>Линк към поздравителна картичка:</label>
                                            <input className="form-control" type="text" name="greeting_card"
                                                   onChange={this.handleFormChange}
                                                   value={this.state.formValues.get('greeting_card') || ''}/>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>
                                                <input type="checkbox" name="is_admin" onChange={this.handleFormChange}
                                                       value={'Y'}
                                                       checked={this.state.formValues.get('is_admin') || false}/> Потребителя
                                                е администратор? {this.state.formValues.get('is_admin')}</label>
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>
                                                <input type="checkbox" name="is_executor"
                                                       onChange={this.handleFormChange}
                                                       value={'Y'}
                                                       checked={this.state.formValues.get('is_executor') || false}/> Потребителя
                                                е изпълнител на задачи от
                                                календара? {this.state.formValues.get('is_executor')}</label>
                                        </div>
                                        {
                                            this.state.user &&
                                            <div className="form-group mb-2">
                                                <label>
                                                    <input type="checkbox" name="newPassword"
                                                           onChange={this.handleFormChange}
                                                           value={this.state.formValues.get('newPassword') || ''}/> Нова
                                                    парола?</label>
                                            </div>
                                        }
                                        {
                                            (this.state.formValues.get('newPassword') || (!this.state.user)) &&
                                            <div className="form-group mb-2">
                                                <label>Парола:</label>
                                                <input className="form-control" type="text" name="password"
                                                       onChange={this.handleFormChange}
                                                       value={this.state.formValues.get('password') || ''}/>
                                            </div>
                                        }

                                        <div className="form-group mb-2">
                                            <label>Линк за директен вход:</label>
                                            <div
                                                className={"w-100 bg-secondary p-3 text-light"}>{window.location.host + PATHS.login + '?key=' + this.state.formValues.get('key') || ''}</div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <label>Права за достъп:</label>
                                        <div className="bg-secondary-light p-2">
                                            <table className="table">
                                                <tr>
                                                    <th>Модул</th>
                                                    <th>Четене</th>
                                                    <th>Писане</th>
                                                </tr>
                                                {
                                                    this.state.modules?.map(
                                                        (i: any) => <tr key={i.id}>
                                                            <td>{i.name}</td>
                                                            <td>
                                                                <input type="checkbox"
                                                                       name={"mr_" + i.id}
                                                                       checked={this.state.formValues.get("mr_" + i.id) || false}
                                                                       onChange={this.handleFormChange}/>
                                                            </td>
                                                            <td>
                                                                <input type="checkbox"
                                                                       name={"mw_" + i.id}
                                                                       checked={this.state.formValues.get("mw_" + i.id) || false}
                                                                       onChange={this.handleFormChange}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        }
                    </div>

                    <div className="card-footer">
                        {
                            this.state.working ?
                                <>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </>
                                :
                                <>
                                    <button type="button" onClick={this.doSave.bind(this)}
                                            className="btn btn-primary mr-1">Запази промените
                                    </button>
                                    &nbsp;
                                    {/*{*/}
                                    {/*    this.state.user &&*/}
                                    {/*    <button type="button" className="btn btn-secondary"*/}
                                    {/*            onClick={() => this.setState({dialogConfirmDeleteVisible: true})}*/}
                                    {/*    >Изтрий служителя</button>*/}
                                    {/*}*/}
                                </>
                        }
                    </div>

                </div>

                <Modal
                    show={this.state.dialogConfirmDeleteVisible}
                    onHide={() => this.setState({dialogConfirmDeleteVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Внимание</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Моля, потвърдете изтриването!</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    <button type={"button"} className={"btn btn-secondary"}
                                            onClick={() => this.setState({dialogConfirmDeleteVisible: false})}>Откажи
                                    </button>
                                    &nbsp;
                                    <button type={"button"} className={"btn btn-primary"}
                                            onClick={this.doDeleteReport}>Изтрий
                                    </button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}


