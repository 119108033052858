import {Button, CloseButton, Form, Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {Message} from "./UserMessagesComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPaperPlane, faSave} from "@fortawesome/free-solid-svg-icons";
import {MessageRecipient} from "./MessageControlModal";

export interface MessageContentModalProps {
    recipients: Array<MessageRecipient>;
    message: Message | null;
    onChange: (newMessage: Message | null) => any;
    onSave?: () => any;
    working: boolean;
}

export default function MessageContentModal({
                                                recipients,
                                                message,
                                                onSave,
                                                onChange,
                                                working
                                            }: MessageContentModalProps) {

    if (!message) return null;
    if (!message.receiver_id) {
        message.receiver_id = recipients[0].id;
        onChange({...message} as Message);
    }

    return (
        <>
            <Modal animation={true} show={message ? true : false}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faEnvelope}/> Съобщение</Modal.Title>
                    <CloseButton onClick={() => onChange(null)}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => onSave}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Тема</Form.Label>
                            <Form.Control value={message?.message.title}
                                          onChange={(e) => {
                                              // @ts-ignore
                                              message.message.title = e.target.value;
                                              onChange({...message} as Message);
                                          }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Съдържание</Form.Label>
                            <Form.Control value={message?.message.content} type={"textarea"} as={"textarea"}
                                          style={{height: "100px"}}
                                          onChange={(e) => {
                                              // @ts-ignore
                                              message.message.content = e.target.value;
                                              onChange({...message} as Message);
                                          }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Получател</Form.Label>
                            <Form.Control as={"select"}
                                          value={message?.receiver_id}
                                          onChange={(e) => {
                                              // @ts-ignore
                                              message.receiver_id = e.target.value;
                                              onChange({...message} as Message);
                                          }}
                            >
                                {
                                    recipients.map(
                                        r => <option value={r.id} key={r.id}>{r.user}</option>
                                    )
                                }

                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {
                        onSave &&
                        <Button variant="primary" onClick={() => onSave()}>
                            {
                                (message?.id) ?
                                    <><FontAwesomeIcon icon={faSave}/>&nbsp;Промени</>
                                    :
                                    <><FontAwesomeIcon icon={faPaperPlane}/>&nbsp;
                                        Изпрати</>
                            }
                        </Button>
                    }
                    <Button variant="secondary" onClick={() => onChange(null)}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
