import {Report, PropertyValue, RefuelingReport} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";

export interface EditRefuelingReportComponentProps {
    report: RefuelingReport;
    fadromi: PropertyValue[],
    motocari: PropertyValue[],
    onChange: any;
}

interface EditRefuelingReportComponentState {
    formValues: Map<string, any>,
}


export class EditRefuelingReportComponent extends React.Component<EditRefuelingReportComponentProps, EditRefuelingReportComponentState> {

    constructor(props: EditRefuelingReportComponentProps) {
        super(props);

        const requestValues = props.report?.request_data ? Object.entries(JSON.parse(props.report.request_data)) : [];

        this.state = {
            formValues: new Map<string, any>(),
        }

        for (let [key, value] of requestValues) {
            this.state.formValues.set(key, value);
            if(this.props.onChange) this.props.onChange(key, value);
        }

        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleFormChange(event: any) {
        if (event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            this.setState(
                prevState => {
                    return {formValues: prevState.formValues.set(name, value)}
                }
            );

            if(this.props.onChange) this.props.onChange(name, value);
        }
    }

    renderFadroma(fCnt: number, idx: number) {
        if (idx < fCnt) {
            const fId = this.props.fadromi[idx].id;
            const fVal = this.props.fadromi[idx].value;
            return (
                <>
                    <td>
                        {fVal}
                    </td>
                    <td>
                        <input className="form-control" type="number"
                               name={"fadroma_" + fId}
                               value={this.state.formValues?.get('fadroma_' + fId)}
                               onChange={this.handleFormChange}/>
                    </td>
                </>
            );
        } else {
            return (<>
                <td colSpan={2}></td>
            </>);
        }
    }

    renderMotocar(fCnt: number, idx: number) {
        if (idx < fCnt) {
            const fId = this.props.motocari[idx].id;
            const fVal = this.props.motocari[idx].value;

            return (
                <>
                    <td>
                        {fVal}
                    </td>
                    <td>
                        <input className="form-control" type="number"
                               name={"motocar_" + fId}
                               value={this.state.formValues?.get('motocar_' + fId)}
                               onChange={this.handleFormChange}/>
                    </td>
                </>
            );
        } else {
            return (<>
                <td colSpan={2}></td>
            </>);
        }
    }

    renderLoadings() {
        const rows: any[] = [];
        const fCnt = this.props.fadromi.length;
        const mCnt = this.props.motocari.length;
        const totalRows = Math.max(fCnt, mCnt);

        for (let idx = 0; idx < totalRows; idx++) {
            rows.push(<tr>{this.renderFadroma(fCnt, idx)}{this.renderMotocar(mCnt, idx)}</tr>);
        }

        return rows;
    }

    render() {
        return (
            <>
                <BlockTitleComponent title={"Зареждане с гориво"}/>
                <table className={"table table-hover"}>
                    <thead>
                    <tr>
                        <th>Фадроми</th>
                        <th>кол(л.)</th>
                        <th>Мотокари</th>
                        <th>кол(бут.)</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        this.renderLoadings()
                    }
                    </tbody>
                </table>

                <BlockTitleComponent title={"Отчета е съставен от"}/>
                <div className="col-12 p-0 m-0 pb-3">
                    { this.props.report.authors && this.props.report.authors.trim().length > 0 ?
                        this.props.report.authors
                        :
                        <strong>Нов отчет или авторите не са запазени</strong>
                    }
                </div>

                <BlockTitleComponent title={"Бележки към отчета"}/>
                <div className="col-12 p-0 m-0">
                                <textarea className="form-control" rows={3} name="comments" onChange={this.handleFormChange}
                                          value={this.state.formValues.get('comments')}/>
                </div>
            </>
        )
    }
}
