import {API_CALL, Charging, HeaterReport, PropertyValue} from "../../types";
import React from "react";
import {FACTORY} from "../../factories";
import {HeaterCleanupComponent} from "./HeaterCleanupComponent";
import {HeaterChargingComponent} from "./HeaterChargingComponent";
import {BlockTitleComponent} from "../common/BlockTitleComponent";

export interface EditHeaterReportProperties {
    report: HeaterReport,
    shifts: PropertyValue[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
    onChange: any
}

interface HeaterReportState {
    api: API_CALL,
    report: HeaterReport,
    shifts: PropertyValue[],
    pelet_types: PropertyValue[],
    bag_types: PropertyValue[],
    formValues: Map<string, any>,
}

export class EditHeaterReportComponent extends React.Component<EditHeaterReportProperties, HeaterReportState> {
    constructor(props: EditHeaterReportProperties) {
        super(props);

        const requestValues = props.report?.request_data ? Object.entries(JSON.parse(props.report.request_data)) : [];

        this.state = {
            api: FACTORY.createApiCall(),
            formValues: new Map<string, any>(),
            report: {...props.report},
            shifts: [...props.shifts],
            pelet_types: [...props.pelet_types],
            bag_types: [...props.bag_types],
        }

        for (let [key, value] of requestValues) {
            this.state.formValues.set(key, value);
            this.props.onChange(key, value);
        }

        this.state.report.chargings = (this.state.formValues.get('chargings') || [])
            .filter((i: any) => i)
            .map(
                (i: any) => {
                    return {...i} as Charging
                }
            );
        this.props.onChange('chargings', this.state.report.chargings);

        this.state.report.cleanups = (this.state.formValues.get('cleanups') || [])
            .filter((i: any) => i)
            .map((i: any) => i.cleanupTime ? i.cleanupTime : i);
        this.props.onChange('cleanups', this.state.report.cleanups);

        this.handleFormChange = this.handleFormChange.bind(this);
        this.onChildChange = this.onChildChange.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )

        if (this.props.onChange) this.props.onChange(name, value);
    }

    onChildChange(key: string, items: any) {
        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(key, items)}
            }
        );
        if (this.props.onChange) this.props.onChange(key, items);
    }

    render() {
        return (
            <>
                <div className="form-row mb-3">
                    <div className="col-12 m-0 p-0">
                        <BlockTitleComponent title={"Почистване"}/>
                        <HeaterCleanupComponent report={this.state.report} onChange={this.onChildChange}/>
                    </div>
                </div>
                <div className="form-row mb-3">
                    <div className="col-12 m-0 p-0">
                        <BlockTitleComponent title={"Зареждане с пелети"}/>
                        <HeaterChargingComponent report={this.state.report.chargings}
                                                 pelet_types={this.props.pelet_types}
                                                 bag_types={this.props.bag_types}
                                                 onChange={this.onChildChange}/></div>
                </div>

                <div className="form-row mb-3">
                    <BlockTitleComponent title={"Бележки към отчета"}/>
                    <div className="col-12">
                                <textarea className="form-control" rows={3}
                                          name="comments" onChange={this.handleFormChange}
                                          value={this.state.formValues.get('comments')}/>
                    </div>
                </div>
            </>
        )
    }
}
