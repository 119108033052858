import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from "highcharts-react-official";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export interface MediaCardProductivityChartProps {
    productivity: number;
    width: number;
    height: number;
}

export default function MediaCardProductivityChart({productivity, width, height}: MediaCardProductivityChartProps) {

    const options = {
        chart: {
            type: "solidgauge",
            backgroundColor: 0,
            height: height,
            width: width
        },

        title: null,
        credits: {
            enabled: false
        },

        tooltip: {
            borderWidth: 0,
            backgroundColor: "none",
            shadow: false,
            style: {
                fontSize: "16px"
            },
            valueSuffix: "%",
            pointFormat:
                '{series.name}<br><span style="font-size:2em; color: {point.color}; font-weight: bold">{point.y}</span>',

        },

        pane: {
            startAngle: 0,
            endAngle: 360,
            background: [
                {
                    // Track for Move
                    outerRadius: "112%",
                    innerRadius: "88%",
                    backgroundColor: "rgba(187, 51, 44, 0.25)",
                    borderWidth: 0
                }
            ]
        },

        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: []
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: false
                },
                linecap: "round",
                stickyTracking: false,
                rounded: false
            }
        },

        series: [
            {
                name: "Move",
                type: "solidgauge",
                data: [
                    {
                        color: "rgb(187, 51, 44)",
                        radius: "112%",
                        innerRadius: "88%",
                        y: productivity
                    }
                ]
            }
        ]
    };


    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}