import {Modal, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import {CalendarElement} from "../../../pages/CalendarPage";
import {API_CALL} from "../../../types";
import {API} from "../../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../../common";

export interface CalendarElementEditModalProps {
    title: string;
    element: CalendarElement;
    onReload: () => any;
    onClose: () => any;
}


export function CalendarElementEditModal({title, element, onReload, onClose}: CalendarElementEditModalProps) {

    const [working, setWorking] = useState(false);
    const [formValues, setFormValues] = useState<Map<string, any>>(new Map<string, any>());

    useState(() => {
        setFormValues(new Map<string, any>(
            [["id", element.id], ["name", element.name], ["description", element.description]]));
    });

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    function saveValue() {
        API.saveCalendarElement(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    onReload();
                    onClose();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, {
                id: element.id,
                name: formValues.get('name'),
                mode: element.mode,
                description: formValues.get('description'),
            } as CalendarElement);
    }

    return (
        <Modal
            show={true}
            centered
            onHide={() => onClose()}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>{title}</Modal.Title>
                    </div>
                </div>
            </Modal.Header>

            <div className="modal-body">

                <form className={"form"}>
                    <div className="form-row">
                        <label className="form-label mb-0">Наименование</label>
                        <input type="text" className="form-control" onChange={handleFormChange} name={"name"}
                               value={formValues.get('name')}/>
                    </div>
                    <div className="form-row mt-2">
                        <label className="form-label mb-0">Описание</label>
                        <textarea className="form-control" onChange={handleFormChange} name={"description"} rows={5}>
                            {formValues.get('description')}</textarea>
                    </div>
                </form>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => saveValue()}>Запази</button>
                <button type="button" className="btn btn-secondary" onClick={() => onClose()}>Откажи</button>
            </div>
        </Modal>
    )
}
