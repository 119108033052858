import {AccountOperation, OPERATION_TYPE} from "../../types";
import React, {useState} from "react";
import {ReadOnlyDatePickerComponent} from "../common/ReadOnlyDatePickerComponent";
import moment from "moment";
import {Form} from "react-bootstrap";
import SelectAmountType from "../../common/SelectAmountType";

export interface EditAccountOperationProps {
    operation: AccountOperation;
    onSave: (operation: AccountOperation) => any;
    onRemove: () => any;
    onCancel: () => any;
}

export function EditAccountOperation(props: EditAccountOperationProps) {
    const initMap = function () {
        const m = new Map<string, any>();
        m.set('sales_id', props.operation.sales_id);
        m.set('account_type', (props.operation.is_avans === 1 ? 'AVANS_' : '') + props.operation.account_type);
        m.set('is_avans', props.operation.is_avans);
        m.set('timestamp', props.operation.timestamp);
        m.set('description', props.operation.description);
        m.set('amount', props.operation.amount);
        m.set('approvedPaletes', props.operation.approvedPaletes);
        m.set('approvedPaletesAmount', props.operation.approvedPaletesAmount);
        m.set('scrappedPaletes', props.operation.scrappedPaletes);
        m.set('amountType', props.operation.amountType);
        return m;
    }
    const [formValues, setFormValues] = useState(initMap());

    const saveItem = function () {
        props.onSave(
            {
                id: props.operation.id,
                sales_id: props.operation.sales_id,
                timestamp: formValues.get('timestamp'),
                account_type: formValues.get('account_type').replace('AVANS_', ''),
                operation: formValues.get('amount') > 0 ? OPERATION_TYPE.DEPOSIT : OPERATION_TYPE.WITHDRAW,
                description: formValues.get('description'),
                amount: Math.abs(formValues.get('amount')),
                amountType: formValues.get('amountType'),
                phone: formValues.get('phone'),
                approvedPaletes: formValues.get('approvedPaletes'),
                approvedPaletesAmount: formValues.get('approvedPaletesAmount'),
                scrappedPaletes: formValues.get('scrappedPaletes'),
                is_avans: formValues.get('account_type').startsWith('AVANS_') ? 1 : 0,
            } as AccountOperation
        );
    }

    const removeItem = function () {
        props.onRemove();
    }

    const cancelItem = function () {
        props.onCancel();
    }

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    return (
        <div className={"w-100"}>
            <div className="row mb-3">
                <div className="col">
                    <label>Дата:</label>
                    <ReadOnlyDatePickerComponent
                        classes={"ml-0 pl-0"}
                        value={formValues.get('timestamp')}
                        onChange={(date: Date) => {
                            formValues.set('timestamp', moment(date).format('DD.MM.YYYY'));
                            setFormValues(new Map<string, any>(formValues));
                        }}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label>Тип:</label>
                    <select className="form-control" name="account_type" required onChange={handleFormChange}
                            value={formValues.get('account_type')}>
                        <option value="AVANS_BANKA" selected={formValues.get('account_type') === "AVANS_BANKA"}>Аванс
                            банка
                        </option>
                        <option value="AVANS_CASH" selected={formValues.get('account_type') === "AVANS_CASH"}>Аванс
                            кеш
                        </option>
                        <option value="CREDIT" selected={formValues.get('account_type') === "CREDIT"}>Аванс кредитно
                        </option>
                        <option value="BANKA" selected={formValues.get('account_type') === "BANKA"}>Банка</option>
                        <option value="CASH" selected={formValues.get('account_type') === "CASH"}>Кеш</option>

                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label>Описание:</label>
                    <input className="form-control" type="text" name="description" required
                           value={formValues.get('description')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col">
                    <label>Сума:</label>
                    <input className="form-control" type="text" name="amount" required
                           onFocus={(event) => event.target.select()}
                           value={formValues.get('amount')}
                           onChange={handleFormChange}/>
                </div>
                {
                    props.operation.account_type === "CREDIT" &&
                    <div className={"col"}>
                        <label>Тип плащане:</label>
                        <SelectAmountType value={formValues.get('amountType')}
                                          onChange={value => formValues.set("amountType", value as string)} />
                    </div>
                }
            </div>

            {
                props.operation.account_type === "CREDIT" &&
                <div className="row mb-3">
                    <div className="col">
                        <label>Брой признати палети:</label>
                        <input className="form-control" type="text" name="approvedPaletes" required
                               onFocus={(event) => event.target.select()}
                               value={formValues.get('approvedPaletes')}
                               onChange={handleFormChange}/>
                    </div>
                    <div className="col">
                        <label>Признати палети цена:</label>
                        <input className="form-control" type="text" name="approvedPaletesAmount" required
                               onFocus={(event) => event.target.select()}
                               value={formValues.get('approvedPaletesAmount')}
                               onChange={handleFormChange}/>
                    </div>
                    <div className="col">
                        <label>Палети брак (бр):</label>
                        <input className="form-control" type="text" name="scrappedPaletes" required
                               onFocus={(event) => event.target.select()}
                               value={formValues.get('scrappedPaletes')}
                               onChange={handleFormChange}/>
                    </div>
                </div>
            }
            <div className="row mb-3">
                {
                    props.operation.sales_id ?
                        <div className="col text-end">
                            <button type={"button"} className={"btn btn-danger"} onClick={cancelItem}>Затвори</button>
                            &nbsp;
                        </div>
                        :
                        <>
                            <div className="col-auto">
                                <button type={"button"} className={"btn btn-primary"} onClick={saveItem}>Запази</button>
                                &nbsp;
                                <button type={"button"} className={"btn btn-secondary"} onClick={cancelItem}>Откажи
                                </button>
                            </div>
                            <div className="col text-end">
                                <button type={"button"} className={"btn btn-danger"} onClick={removeItem}>Изтрий
                                </button>
                                &nbsp;
                            </div>
                        </>
                }
            </div>
        </div>
    )
}
