import ReactPlayer from "react-player";
import React, {useState} from "react";
import MultimediaVideoTaifun from "./MultimediaVideoTaifun";
import MultimediaVideoBirthday from "./MultimediaVideoBirthday";
import MultimediaVideoEmployees from "./MultimediaVideoEmployees";

export interface MultimediaVideoProps {
    onEnded: () => any;
}

enum VideoMode {
    TAIFUN,
    BIRTHDAY,
    EMPLOYEES,
}

export default function MultimediaVideo(props: {onDone: () => void}) {
    const [videoMode, setVideoMode] = useState<VideoMode>(VideoMode.TAIFUN);

    return (
        <>
            {videoMode === VideoMode.TAIFUN && <MultimediaVideoTaifun onEnded={() => {
                setVideoMode(VideoMode.EMPLOYEES)
            }}/>}

            {videoMode === VideoMode.EMPLOYEES && <MultimediaVideoEmployees onEnded={() => {
                setVideoMode(VideoMode.BIRTHDAY)
            }}/>}

            {videoMode === VideoMode.BIRTHDAY && <MultimediaVideoBirthday onEnded={() => {
                //setVideoMode(VideoMode.TAIFUN)
                props.onDone();
            }}/>}
        </>
    )
}
