import {OrderingReport, PropertyValue} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ARRAY22, getShiftName, getTypeName} from "../../common";

export interface ViewOrderingReportProperties {
    report: OrderingReport,
    shifts: PropertyValue[],
    bricks_types: PropertyValue[],
}

interface OrderingReportState {
}

export class ViewOrderingReportComponent extends React.Component<ViewOrderingReportProperties, OrderingReportState> {

    constructor(props: ViewOrderingReportProperties) {
        super(props);
        this.state = {}
    }


    render() {
        const rd = JSON.parse(this.props.report.request_data);

        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>
                            </div>
                            <div className="col">
                                Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>
                            </div>
                            <div className="col-auto">
                                Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Производство"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        <th className={"w-100px"}>№ вагон</th>
                                        <th>Вид тухла</th>
                                        {/*<th>Вид тухла 2</th>*/}
                                        <th className={"w-120px"}>Брак екстр.</th>
                                        <th>Причина екструдер</th>
                                        <th className={"w-120px"}>Брак робот</th>
                                        </thead>
                                        <tbody>
                                        {
                                            ARRAY22.map(
                                                (i: number, idx: number) => {
                                                    return rd['brickType1_' + idx] && rd['brickType1_' + idx] > -1 ?
                                                        <tr key={idx}>
                                                            <td style={{width: "100px"}}>{i}</td>
                                                            <td>{getTypeName(this.props.bricks_types, rd['brickType1_' + idx])}</td>
                                                            {/*<td>{getTypeName(this.props.bricks_types, rd['brickType2_' + idx])}</td>*/}
                                                            <td className={"w-120px"}>{rd['waste_extr_' + idx] > 0 ? rd['waste_extr_' + idx] : ''}</td>
                                                            <td>
                                                                {
                                                                    (rd['extr_reason_suha_' + idx] ? 'сухи; ' : '') +
                                                                    (rd['extr_reason_mokra_' + idx] ? 'мокри; ' : '') +
                                                                    (rd['extr_reason_spukana_' + idx] ? 'спукани; ' : '')
                                                                }
                                                            </td>
                                                            <td className={"w-120px"}>{rd['waste_robot_' + idx] > 0 ? rd['waste_robot_' + idx] : ''}</td>
                                                        </tr>
                                                        : ''
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div className={"mb-3"}>
                                    <span className={"w-180px d-inline-block"}>Почистване: <strong>{ rd.cleaning ? 'ДА' : 'НЕ' }</strong></span>
                                    <span className={"w-240px d-inline-block"}>Изправност на робота: <strong>{ rd.robot_helth ? 'ДА' : 'НЕ' }</strong></span>
                                </div>
                                <div>
                                    {rd.comments || "Няма бележки към отчета"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
