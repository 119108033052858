import {RepairsReport, PropertyValue, REPAIR_TYPE} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {getShiftName, getTypeName} from "../../common";
import {$enum} from "ts-enum-util";

export interface ViewRepairsReportProperties {
    report: RepairsReport,
    shifts: PropertyValue[],
}

interface RepairsReportState {
}

export class ViewRepairsReportComponent extends React.Component<ViewRepairsReportProperties, RepairsReportState> {

    constructor(props: ViewRepairsReportProperties) {
        super(props);
        this.state = {}
    }


    render() {
        const rd = JSON.parse(this.props.report.request_data);

        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>
                            </div>
                            <div className="col">
                                Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>
                            </div>
                            <div className="col-auto">
                                Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Ремонти"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        <th>Отдел</th>
                                        <th>Начало</th>
                                        <th>Край</th>
                                        <th>Описание на ремонта</th>
                                        </thead>
                                        <tbody>
                                        {
                                            rd.repairs.map(
                                                (i: any, idx: number) =>
                                                    <tr key={idx}>
                                                        <td>{$enum(REPAIR_TYPE).getValueOrDefault(i.repairType, '?')}</td>
                                                        <td>{i.fromTime}</td>
                                                        <td>{ i.toTime }</td>
                                                        <td>{ i.comments }</td>
                                                    </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    {
                                        (!rd.repairs || rd.repairs.length === 0) &&
                                        <div>Не са извършени ремонти</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div>
                                    { rd.comments || "Няма бележки към отчета" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
