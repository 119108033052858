import React from "react";
import {Charging, ExtruderProduction, Repair, REPAIR_TYPE} from "../../types";
import moment, {Moment} from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {BlockTitleComponent} from "./BlockTitleComponent";
import {TimePickerComponent} from "./TimePickerComponent";
import {showErrorsInToast} from "../../common";
import {Modal} from "react-bootstrap";
import {$enum} from "ts-enum-util";

export interface RepairsProps {
    repairs: Repair[],
    onChange: any,
}

export interface RepairsState {
    items: Repair[],
    formValues: Map<string, any>,
    showFromPicker: boolean,
    showToPicker: boolean,
    editorVisible: boolean,
    editIndex: number,
}

export class RepairsComponent extends React.Component<RepairsProps, RepairsState> {
    constructor(props: RepairsProps) {
        super(props);

        this.state = {
            formValues: new Map<string, any>(),
            items: props.repairs || [],
            showFromPicker: false,
            showToPicker: false,
            editorVisible: false,
            editIndex: -1,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.addNewItem = this.addNewItem.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.editItem = this.editItem.bind(this);
        this.renderDataRow = this.renderDataRow.bind(this);
        this.renderFormRow = this.renderFormRow.bind(this);

        this.notifyParent(this.state.items);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    notifyParent(items: any) {
        if(this.props.onChange) {
            this.props.onChange('repairs', items);
        }
    }

    saveItem(event: any) {
        if(
            (!this.state.formValues.get('repairType')) ||
            (!this.state.formValues.get('fromTime')) ||
            (!this.state.formValues.get('toTime')) ||
            (!this.state.formValues.get('comments')) ||
            (this.state.formValues.get('comments').trim().length === 0)
        ) {
            toast.error('Всички полета са задължителни!', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: false,
            });
            return;
        }

        const repair: Repair = {
            repairType: this.state.formValues.get('repairType'),
            fromTime: this.state.formValues.get('fromTime'),
            toTime: this.state.formValues.get('toTime'),
            comments: this.state.formValues.get('comments')
        }
        const fv = this.state.formValues;
        fv.forEach((v,k) => fv.set(k, ""));

        let newItems: Repair[] = [];
        if(this.state.editIndex === -1) newItems = [...this.state.items, repair];
        else {
            newItems = [...this.state.items];
            newItems[this.state.editIndex] = repair;
        }

        this.setState({editorVisible: false, formValues: fv, items: newItems});
        this.notifyParent(newItems);
    }

    renderNoDataRow = function () {
        return (
            <tr>
                <td colSpan={5}>Няма въведени данни</td>
            </tr>
        )
    }

    renderDataRow(item: Repair, idx: number) {
        return (
            <tr key={idx}>
                <td>{$enum(REPAIR_TYPE).getValueOrDefault(item.repairType, '?')}</td>
                <td>{item.fromTime}</td>
                <td>{item.toTime}</td>
                <td>{item.comments}</td>
                <td className={"text-nowrap text-end"}>
                    <span className="btn btn-primary btn-light" onClick={() => this.editItem(idx)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                    <span className="btn btn-primary btn-light" onClick={() => this.removeItem(idx)}><FontAwesomeIcon icon="trash-alt"/></span>
                </td>
            </tr>
        )
    }

    renderFormRow() {
        return (
            <tr>
                <td>
                    <select className="form-control col w-100" name="repairType" required
                            value={this.state.formValues.get('repairType')}
                            onChange={(event: any) => {
                                this.setState({formValues: this.state.formValues.set('repairType', event.target.value)})
                            }}>
                        <option value="">-- Изберете --</option>
                        {
                            $enum(REPAIR_TYPE).map(
                                (value, key) =>
                                    <option value={key} key={key}>{value}</option>
                            )
                        }
                    </select>
                </td>
                <td className={"w-100px"}>
                    <input className="form-control" type="text" readOnly={true} name="fromTime" required
                           value={this.state.formValues.get('fromTime')}
                           onClick={() => this.setState({showToPicker: false, showFromPicker: !this.state.showFromPicker})}
                    />
                    {
                        this.state.showFromPicker && <TimePickerComponent
                            onSelect={(time: string) => this.setState({showFromPicker: false, formValues: this.state.formValues.set('fromTime', time)})}
                            value={this.state.formValues?.get('fromTime')}/>
                    }
                </td>
                <td className={"w-100px"}>
                    <input className="form-control" type="text" readOnly={true} name="toTime" required
                           value={this.state.formValues.get('toTime')}
                           onClick={() => this.setState({showFromPicker: false, showToPicker: !this.state.showToPicker})}
                    />
                    {
                        this.state.showToPicker && <TimePickerComponent
                            onSelect={(time: string) => this.setState({showToPicker: false, formValues: this.state.formValues.set('toTime', time)})}
                            value={this.state.formValues?.get('toTime')}/>
                    }
                </td>
                <td><textarea className="form-control" rows={1} name="comments" required
                              value={this.state.formValues.get('comments')}
                              onChange={this.handleFormChange}/></td>
            </tr>
        )
    }

    addNewItem() {
        this.setState({editorVisible: true, formValues: new Map<string, any>(), editIndex: -1});
    }

    editItem(idx: number) {
        const formValues: Map<string, any> = new Map<string, any>(Object.entries(this.state.items[idx]));

        this.setState({editorVisible: true, formValues: formValues, editIndex: idx});
    }

    removeItem(idx: number) {
        const _items = [...this.state.items];
        _items.splice(idx, 1);
        this.setState({items: _items});
        this.notifyParent(_items);
    }

    render() {
        return (
            <>
                <BlockTitleComponent title={"Извършени ремонти"} />
                <div className={"col-12"}>
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th style={{width: "8rem"}}>Отдел</th>
                            <th style={{width: "6rem"}}>От час</th>
                            <th style={{width: "6rem"}}>До час</th>
                            <th>Описание</th>
                            <th style={{width: "7rem"}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.items.length === 0 && this.renderNoDataRow()
                        }
                        {
                            this.state.items.map(
                                (i: Repair, idx: number) => this.renderDataRow(i, idx)
                            )
                        }
                        <td colSpan={9}>
                            <button className={"btn btn-outline-primary w-100 font-weight-bold"} onClick={this.addNewItem}>
                                Добави нов запис
                            </button>
                        </td>
                        </tbody>
                    </table>
                </div>


                <Modal
                    className={"modal-90 m-3"} centered
                    show={this.state.editorVisible}
                    onHide={() => this.setState({editorVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Добавяне/корекция</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <table className="table table-hover table-sm">
                            <thead>
                            <tr>
                                <th style={{width: "9rem"}}>Отдел</th>
                                <th style={{width: "6rem"}}>От час</th>
                                <th style={{width: "6rem"}}>До час</th>
                                <th>Описание</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.renderFormRow()
                            }
                            </tbody>
                        </table>

                    </Modal.Body>

                    <Modal.Footer>
                        <button type={"button"} className={"btn btn-secondary"} onClick={this.saveItem}>Запази</button>
                        <button type={"button"} className={"btn btn-primary"} onClick={() => this.setState({editorVisible: false})}>Откажи</button>
                    </Modal.Footer>
                </Modal>


            </>
        );
    }
}
