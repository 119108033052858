import {Button, InputGroup} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {CalendarElement, CalendarExecutor} from "../../../pages/CalendarPage";
import moment, {Moment} from "moment";
import {CALENDAR_MODE} from "../../../types";

export interface CALENDAR_FILTER {
    status: string | null;
    element: string | null;
    executor: string | null;
    plannedFrom: Moment | null;
    plannedTo: Moment | null;
    execFrom: Moment | null;
    execTo: Moment | null;
}

export interface CalendarFilterProps {
    mode: CALENDAR_MODE;
    executors: CalendarExecutor[];
    internals: CalendarExecutor[];
    elements: CalendarElement[];
    currentFilter: CALENDAR_FILTER | null;
    onFilterChanged: (filter: CALENDAR_FILTER) => any;
}

export const EMPTY_CALENDAR_FILTER = {
    status: '', element: '-1', executor: '-1', plannedFrom: null, plannedTo: null, execFrom: null, execTo: null,
}

export default function CalendarFilter({mode, executors, internals, elements, currentFilter, onFilterChanged}: CalendarFilterProps) {

    function clearFilter() {
        onFilterChanged(EMPTY_CALENDAR_FILTER);
    }

    return (
        <tr className={"bg-secondary-light"}>
            <td className={"text-center"}>
                <select className="form-control form-control-sm" value={currentFilter?.status || ''}
                        onChange={(v) => onFilterChanged({...currentFilter, status: v.target.value} as CALENDAR_FILTER)}>
                    <option value="">Всички</option>
                    <option value="Изпълнено">Изпълнено</option>
                    <option value="Провалено">Провалено</option>
                    <option value="Изместено">Изместено</option>
                </select>
            </td>
            <td>
                <select className="form-control form-control-sm" value={currentFilter?.element || '-1'}
                        onChange={(v) => onFilterChanged({...currentFilter, element: v.target.value} as CALENDAR_FILTER)}>
                    <option value="-1">Всички</option>
                    {
                        elements.map(e => <option key={e.id} value={e.id}>{e.name}</option>)
                    }
                </select>
            </td>
            <td>
                <select className="form-control form-control-sm" value={currentFilter?.executor || '-1'}
                        onChange={(v) => onFilterChanged({...currentFilter, executor: v.target.value} as CALENDAR_FILTER)}>
                    <option value={-1}>Всички</option>
                    <optgroup label="Вътрешни">
                        {internals.map(i => <option key={i.id} value={"i_" + i.id}>{i.name}</option>)}
                    </optgroup>
                    <optgroup label="Външни">
                        {executors.map(i => <option key={i.id} value={"e_" + i.id}>{i.name}</option>)}
                    </optgroup>
                </select>
            </td>
            <td></td>
            <td className={"text-center"}>
                <div className={"form-row"}>
                    <InputGroup>
                        <label>От:&nbsp;</label>
                        <ReactDatePicker className="form-control text-center" maxDate={currentFilter?.plannedTo?.toDate()}
                                         selected={currentFilter?.plannedFrom?.toDate()}
                                         onChange={(v: Date) => {
                                             onFilterChanged({...currentFilter, plannedFrom: v ? moment(v) : null} as CALENDAR_FILTER)
                                         }
                                         }
                                         dateFormat={'dd.MM.yyyy'}/>
                    </InputGroup>
                </div>
                <div className={"form-row"}>
                    <InputGroup>
                        <label>До:&nbsp;</label>
                        <ReactDatePicker className="form-control text-center" minDate={currentFilter?.plannedFrom?.toDate()}
                                         selected={currentFilter?.plannedTo?.toDate()}
                                         onChange={(v: Date) => {
                                             onFilterChanged({...currentFilter, plannedTo: v ? moment(v) : null} as CALENDAR_FILTER)
                                         }} dateFormat={'dd.MM.yyyy'}
                        />
                    </InputGroup>
                </div>
            </td>
            <td className={"text-center"}>
                <div className={"form-row"}>
                    <InputGroup>
                        <label>От:&nbsp;</label>
                        <ReactDatePicker className="form-control text-center" maxDate={currentFilter?.execTo?.toDate()}
                                         selected={currentFilter?.execFrom?.toDate()}
                                         onChange={(v: Date) => {onFilterChanged({...currentFilter, execFrom: v ? moment(v) : null} as CALENDAR_FILTER)}}
                                         dateFormat={'dd.MM.yyyy'}/>
                    </InputGroup>
                </div>
                <div className={"form-row"}>
                    <InputGroup>
                        <label>До:&nbsp;</label>
                        <ReactDatePicker className="form-control text-center" minDate={currentFilter?.execFrom?.toDate()}
                                         selected={currentFilter?.execTo?.toDate()}
                                         onChange={(v: Date) => {onFilterChanged({...currentFilter, execTo: v ? moment(v) : null} as CALENDAR_FILTER)}}
                                         dateFormat={'dd.MM.yyyy'}
                        />
                    </InputGroup>
                </div>
            </td>
            <td className={"text-end"}></td>
            <td className={"w-120px text-end"}>
                <Button size={"sm"} onClick={() => clearFilter()}><FontAwesomeIcon icon={faTimes}/></Button>&nbsp;
            </td>
        </tr>
    )
}
