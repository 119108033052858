import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";


interface LineChartProps {
    title: string,
    labels: string[],
    data: any,
}


export default function LineChart({title, labels, data}: LineChartProps) {
    const options = {
        chart: {
            type: 'line',
            height: '225px',
            zoom: 'x',
        },

        title: {
            text: title
        },

        yAxis: {
            title: {
                text: 'лева'
            }
        },

        legend: {
            enabled: false,
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
        },

        plotOptions: {
            line: {
                dataLabels: {
                    enabled: true
                }
            }
        },
    }


    const processData = function () {

        const _o = {
            ...options,
            xAxis: {
                categories: labels
            },
            series: data
        }

        return _o;
    }

    processData();

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={processData()}
            allowChartUpdate
        />
    );

}
