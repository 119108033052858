import React from "react";
import {API_CALL, BaseReportInfo, Charging, OvenProduction, OvenReport, PARAMETERS, PropertyValue, Repair, Report} from "../types";
import {getShiftName, isError, isLoading, isSuccess, mapToObject, showErrorsInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {OvenTempsComponent} from "../components/oven/OvenTempsComponent";
import {HeaterChargingComponent} from "../components/heater/HeaterChargingComponent";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {Modal} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {AUTOSAVE_INTERVAL} from "../index";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {ReportBaseInfoComponent} from "../components/common/ReportBaseInfoComponent";
import {OvenProductionComponent} from "../components/oven/OvenProductionComponent";
import {EditGrindingReportComponent} from "../components/grinding/EditGrindingReportComponent";
import {ReportsList} from "../components/common/ReportsListComponent";
import {ViewHeaterReportComponent} from "../components/heater/ViewHeaterReportComponent";
import {ViewOvenReportComponent} from "../components/oven/ViewOvenReportComponent";

interface OvenPageState {
    working: boolean,
    loading: number,
    selectedDate: moment.Moment,
    report: OvenReport | null,
    reports: OvenReport[],
    formValues: Map<string, any>,
    shifts: PropertyValue[],
    pelet_types: PropertyValue[],
    brick_types: PropertyValue[],
    bag_types: PropertyValue[],
    dialogConfirmDeleteVisible: boolean,
    isPreviewMode: boolean,
}

export class OvenPage extends React.Component<any, OvenPageState> {
    reportForDeleting: OvenReport | null = null;
    autoSaveTrigger: any = null;
    isPrint = false;

    constructor(props: any) {
        super(props);
        this.state = {
            working: false,
            loading: 0,
            selectedDate: moment(),
            report: null,
            reports: [],
            formValues: new Map<string, any>(),
            shifts: [],
            pelet_types: [],
            brick_types: [],
            bag_types: [],
            dialogConfirmDeleteVisible: false,
            isPreviewMode: false,
        }

        this.addReport = this.addReport.bind(this);
        this.saveReport = this.saveReport.bind(this);
        this.autoSaveReport = this.autoSaveReport.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.editReport = this.editReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.doDeleteReport = this.doDeleteReport.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.onChildChange = this.onChildChange.bind(this);
        this.onHeaterChildChange = this.onHeaterChildChange.bind(this);
    }


    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    setAutoSaveInterval() {
        this.autoSaveTrigger = window.setInterval(this.autoSaveReport, AUTOSAVE_INTERVAL);
    }

    clearAutoSaveInterval() {
        if (this.autoSaveTrigger) {
            window.clearInterval(this.autoSaveTrigger)
        }
    }

    componentDidMount() {
        this.loadParameters();
        this.loadReports();
        this.setAutoSaveInterval();
    }

    componentWillUnmount() {
        this.clearAutoSaveInterval();
    }


    loadParameters() {
        this.setState({loading: this.state.loading + 4});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        shifts: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.SHIFTS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        pelet_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.PELET_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        bag_types: apiCall.data.data.items?.values.filter(
                            (i: PropertyValue) => {
                                return i.description.usedInProduction;
                            }
                        )
                    });
                }
            }, PARAMETERS.BAG_TYPE
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        brick_types: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.BRICKS
        )
    }

    loadReports(newDate?: Moment) {
        API.getOven(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as OvenReport;
                        }
                    );
                    this.setState({reports: reports})
                }
            }, newDate ? newDate : this.state.selectedDate
        )
    }

    addReport() {
        this.loadReports();

        let report = {
            id: -1,
            base_info: {
                report_date: this.state.selectedDate.format('DD.MM.YYYY'),
                shift_id: -1,
                man_cnt: 1,
                from_time: '',
                to_time: ''
            }
        } as OvenReport;

        this.setState({report: report, formValues: new Map<string, any>(), isPreviewMode: false})
    }

    editReport(report: OvenReport, isPrint: boolean = false) {
        const fv = new Map<string, any>();

        const requestValues = report.request_data ? JSON.parse(report.request_data) : {};
        fv.set('comments', requestValues.comments);
        fv.set('base_info', requestValues.base_info);

        report.temps = (requestValues.temps || []);

        report.chargings = (requestValues.chargings || [])
            .filter((i: any) => i)
            .map(
                (i: any) => {
                    return {...i} as Charging
                }
            );

        report.heater_chargings = (requestValues.heater_chargings || [])
            .filter((i: any) => i)
            .map(
                (i: any) => {
                    return {...i} as Charging
                }
            );

        fv.set('chargings', report.chargings);
        fv.set('heater_chargings', report.heater_chargings);

        report.production = (requestValues.production || [])
            .map(
                (i: any) => {
                    return {...i} as OvenProduction
                }
            );
        fv.set('production', report.production);

        let isPreviewMode = false;
        if (isPrint || (report.user.id !== API.user?.id && !(API.user?.is_admin === 'Y'))) {
            isPreviewMode = true;
        }
        this.setState({report: report, formValues: fv, isPreviewMode: isPreviewMode});
    }

    autosaveTimeout: any = null;
    onChildChange(key: string, items: any) {
        const fv = this.state.formValues;

        if(fv.has(key)) {
            if(this.autosaveTimeout) window.clearTimeout(this.autosaveTimeout);
            this.autosaveTimeout = window.setTimeout(() => this.autoSaveReport(fv), 1000);
        }

        fv.set(key, items);
        this.setState({formValues: fv});
    }

    onHeaterChildChange(key: string, items: any) {
        const fv = this.state.formValues;

        if(fv.has("heater_" + key)) {
            if(this.autosaveTimeout) window.clearTimeout(this.autosaveTimeout);
            this.autosaveTimeout = window.setTimeout(() => this.autoSaveReport(fv), 1000);
        }

        fv.set("heater_" + key, items);
        this.setState({formValues: fv});
        this.autoSaveReport(fv);
    }


    saveReport() {
        this.doSaveReport(false);
    }

    saving = false;

    autoSaveReport(fv: Map<string, any>) {
        if (this.state && this.state.report) this.doSaveReport(true, fv);
    }

    doSaveReport(isAutosave: boolean = false, _fv: Map<string, any>|null = null) {
        const formValues = _fv ? _fv : this.state.formValues;
        if (
            !(formValues.get('base_info')?.shift_id > -1 &&
                formValues.get('base_info')?.man_cnt &&
                formValues.get('base_info')?.man_cnt > 0)
        ) return;

        const fv = mapToObject(formValues);

        if(this.saving) return;
        this.saving = true;
        API.saveOven(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.saving = false;
                    if (!isAutosave) {
                        this.setState({report: null});
                        this.loadReports();
                    } else {
                        let r = this.state.report;
                        if(r) r.id = apiCall.data.data.report.id;
                        else r = apiCall.data.data.report;
                        this.setState({report: r});
                    }
                }
                if (isError(apiCall)) {
                    this.saving = false;
                    if (!isAutosave) showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }

                this.setState({working: isLoading(apiCall)});
            }, fv
            , this.state.report?.id
        );
    }


    deleteReport(report: OvenReport) {
        this.reportForDeleting = report;
        this.setState({dialogConfirmDeleteVisible: true});
    }

    doDeleteReport() {
        API.deleteOven(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна следната грешка:');
                }
                if (isSuccess(apiCall)) {
                    this.loadReports();
                    this.setState({dialogConfirmDeleteVisible: false});
                }
            }, this.reportForDeleting?.id
        );
    }

    getShifts() {
        const shifts = this.state.shifts.filter(
            s => {
                return this.state.reports.filter(
                    r => {
                        return (this.state.report?.base_info) && (r.base_info.shift_id === s.id) &&
                            (s.id !== this.state.report.base_info.shift_id)
                    }
                ).length === 0
            }
        );
        return shifts;
    }

    render() {
        return (
            <div className="container" style={this.state.report ? {maxWidth: "100%", zoom: this.isPrint ? "75%" : "100%"} : {}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <>
                                        {
                                            this.state.report ?
                                                <span className="btn btn-secondary"
                                                      onClick={() => {
                                                          this.setState({report: null});
                                                          this.loadReports();
                                                      }}><FontAwesomeIcon icon={faArrowLeft}/></span>
                                                :
                                                <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                                    <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                        }
                                    </>
                            }

                        </div>
                        <h2 className="col">Пещ</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <h4 className="card-title">Дневни отчети</h4>
                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div>Зареждам данните ...</div>
                                }
                                {
                                    this.state.loading === 0 && !this.state.report &&
                                    <>
                                        <div className="card bg-light p-3 mb-3">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto"}>Отчети за дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            value={this.state.selectedDate.format('DD.MM.YYYY')}
                                                            onChange={(date: Date) => {
                                                                this.setState({selectedDate: moment(date)});
                                                                this.loadReports(moment(date));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }

                                <ReportsList
                                    working={!(this.state.loading === 0 && moment.isMoment(this.state.selectedDate) && !this.state.working && !this.state.report)}
                                    reports={this.state.reports}
                                    shifts={this.state.shifts}
                                    editReport={this.editReport}
                                    deleteReport={this.deleteReport}/>

                                {
                                    this.state.report && !this.state.isPreviewMode &&
                                    <>
                                        <ReportBaseInfoComponent
                                            onChange={(data: BaseReportInfo) => {
                                                const fv = this.state.formValues;
                                                fv.set('base_info', data)
                                                this.setState({formValues: fv});
                                                this.autoSaveReport(fv);
                                            }}
                                            shifts={this.getShifts()}
                                            addMiddleShift={false}
                                            reportInfo={this.state.report.base_info}
                                            reportName={"oven_reports"}
                                            reportId={this.state.report.id}
                                        />

                                        {
                                            this.state.formValues?.get('base_info')?.shift_id > -1 &&
                                            this.state.formValues?.get('base_info')?.man_cnt &&
                                            this.state.formValues?.get('base_info')?.man_cnt > 0 ?

                                                <>
                                                    <BlockTitleComponent title={"Производство (брой вагони по видове тухли)"}/>
                                                    <OvenProductionComponent
                                                        production = {this.state.report.production}
                                                        brick_types={this.state.brick_types}
                                                        onChange={this.onChildChange}
                                                    />

                                                    <BlockTitleComponent title={"Температурни показатели на пещ"}/>
                                                    <OvenTempsComponent
                                                        temps={this.state.report.temps}
                                                        onChange={this.onChildChange}
                                                    />


                                                    <BlockTitleComponent title={"Разход пелети пещ"}/>
                                                    <HeaterChargingComponent
                                                        report={this.state.report.chargings}
                                                        onChange={this.onChildChange}
                                                        bag_types={this.state.bag_types}
                                                        pelet_types={this.state.pelet_types}
                                                    />

                                                    <BlockTitleComponent title={"Разход пелети печка"}/>
                                                    <HeaterChargingComponent
                                                        report={this.state.report.heater_chargings}
                                                        onChange={this.onHeaterChildChange}
                                                        bag_types={this.state.bag_types}
                                                        pelet_types={this.state.pelet_types}
                                                    />

                                                    <BlockTitleComponent title={"Бележки към отчета"}/>
                                                    <textarea className="form-control" rows={3}
                                                              name="comments" onChange={this.handleFormChange}
                                                              value={this.state.formValues.get('comments')}/>
                                                </>
                                                :
                                                <div><strong>Моля, изберете работна смяна и въведете броя на служителите, за да продължите с отчета...</strong></div>
                                        }


                                    </>
                                }
                                {
                                    this.state.report && this.state.isPreviewMode &&
                                    <>
                                        <ViewOvenReportComponent
                                            report={this.state.report}
                                            shifts={this.state.shifts}
                                            pelet_types={this.state.pelet_types}
                                            bag_types={this.state.bag_types}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        {
                            moment.isMoment(this.state.selectedDate) ?
                                <>
                                    {
                                        this.state.working ?
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                            :
                                            <>
                                                {
                                                    this.state.report ?
                                                        !this.state.isPreviewMode &&
                                                        <>
                                                            <button className="btn btn-primary" onClick={this.saveReport}>Запази</button>
                                                            &nbsp;
                                                            <button className="btn btn-secondary" onClick={() => {
                                                                this.setState({report: null});
                                                                this.loadReports();
                                                            }}>Откажи
                                                            </button>
                                                        </>
                                                        :
                                                        this.state.reports.length < 2 &&
                                                        <>
                                                            <button className="btn btn-primary" onClick={this.addReport}>Добави нов</button>
                                                        </>
                                                }
                                            </>
                                    }
                                </>
                                :
                                <><span className="text-info">Моля, изберете дата...</span></>
                        }
                    </div>
                </div>

                <Modal
                    show={this.state.dialogConfirmDeleteVisible}
                    onHide={() => this.setState({dialogConfirmDeleteVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Внимание</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Моля, потвърдете изтриването!</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    <button type={"button"} className={"btn btn-secondary"} onClick={() => this.setState({dialogConfirmDeleteVisible: false})}>Откажи</button>
                                    <button type={"button"} className={"btn btn-primary"} onClick={this.doDeleteReport}>Изтрий</button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

}
