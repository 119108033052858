import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isLoading, isSuccess} from "../../common";
import {API} from "../../api";
import {API_CALL} from "../../types";
import useInterval from "../../hooks/useInterval";


export default function UserAlertsComponent() {

    const [dataLoaded, setDataLoaded] = useState(false);
    const [alerts, setAlerts] = useState([]);

    useInterval(() => {
        if (dataLoaded) return;
        setDataLoaded(true);
        API.getCalendarAlerts(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    setAlerts(apiCall.data.data.alerts);
                    setDataLoaded(false);
                }
            }
        )
    }, 600000);

    return (
        <>
            {
                alerts.length > 0 &&
                <span className={"bg-danger blink"}>&nbsp;
                    {
                        alerts.length > 1 ?
                            <>Имате {alerts.length} приближаващи или пропуснати събития! </> :
                            <>Имате {alerts.length} приближаващо или пропуснато събитие! </>
                    }
                </span>
            }
        </>
    );
}
