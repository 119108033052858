import React, {ChangeEvent} from "react";
import {HeaterReport} from "../../types";
import moment, {Moment} from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {TimePickerComponent} from "../common/TimePickerComponent";

export interface HeaterCleanupProps {
    report: HeaterReport,
    onChange: any,
}

export interface HeaterCleanupState {
    timeInput: string;
    times: string[];
    showTimePicker: boolean;
}

export class HeaterCleanupComponent extends React.Component<HeaterCleanupProps, HeaterCleanupState> {
    constructor(props: HeaterCleanupProps) {
        super(props);


        console.log(props);

        this.state = {
            times: props.report?.cleanups?.map(i => i) || [],
            timeInput: "",
            showTimePicker: false,
        }

        this.addClearupTime = this.addClearupTime.bind(this);
        this.removeClearupTime = this.removeClearupTime.bind(this);
        this.onNewTimeChange = this.onNewTimeChange.bind(this);
    }

    onNewTimeChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({timeInput: event.target.value});
    }

    notifyParent(times: any) {
        if (this.props.onChange) {
            this.props.onChange('cleanups', times);
        }
    }

    addClearupTime() {
        if (moment(this.state.timeInput, 'HH:mm').isValid()) {
            const newTime = this.state.timeInput;
            const _times = [...this.state.times, newTime];
            this.setState({times: _times, timeInput: ""});

            this.notifyParent(_times);
        } else {
            toast.error('"' + this.state.timeInput + '" не е валиден час!', {
                position: toast.POSITION.BOTTOM_CENTER
            });
        }
    }

    removeClearupTime(idx: number) {
        const _times = [...this.state.times];
        _times.splice(idx, 1);
        this.setState({times: _times});
        this.notifyParent(_times);
    }


    render() {
        return (
            <>
                <ul className="list-group list-group-horizontal d-flex justify-content-start flex-wrap">
                    {
                        this.state.times.map(
                            (t: string, idx: number) => <li className="list-group-item d-flex justify-content-between align-items-center" key={idx}>
                                {t}&nbsp;
                                <span className="btn btn-primary btn-light" onClick={() => {
                                    this.removeClearupTime(idx)
                                }}>
                                    <FontAwesomeIcon icon="times-circle"/>
                                </span>
                            </li>
                        )
                    }
                    <li className="list-group-item">
                        <div className="input-group">
                            <input className="form-control" type="text" readOnly={true} name="newClearUpTime"
                                   style={{width: this.state.showTimePicker ? "11rem" : "5rem"}} required
                                   value={this.state.timeInput}
                                   onClick={() => this.setState({showTimePicker: !this.state.showTimePicker})}
                            />
                            {
                                this.state.showTimePicker && <TimePickerComponent
                                    onSelect={
                                        (time: string) => {
                                            this.setState({showTimePicker: false, timeInput: time});

                                            if (moment(time, 'HH:mm').isValid()) {
                                                const _times = [...this.state.times, time];
                                                this.notifyParent(_times);
                                            }
                                        }
                                    }
                                    value={this.state.timeInput}/>
                            }

                            <div className="input-group-append">
                                &nbsp;<span className="btn btn-primary btn-light" onClick={this.addClearupTime}><FontAwesomeIcon icon="plus"/></span>
                            </div>
                        </div>
                    </li>
                </ul>


            </>
        );
    }
}
