import React from "react";
import {API_CALL, BaseReportInfo, PARAMETERS, PropertyValue, RefuelingReport, Report} from "../types";
import {getShiftName, isError, isLoading, isSuccess, mapToObject, showErrorsInToast, showMsgInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import {AUTOSAVE_INTERVAL} from "../index";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import {ReportBaseInfoComponent} from "../components/common/ReportBaseInfoComponent";
import {EditRefuelingReportComponent} from "../components/refueling/EditRefuelingReportComponent";
import {ReportsList} from "../components/common/ReportsListComponent";
import {ViewRefuelingReportComponent} from "../components/refueling/ViewRefuelingReportComponent";


interface RefuelingPageProps {
}

interface RefuelingPageState {
    working: boolean,
    loading: number,
    selectedDate: moment.Moment,
    formValues: Map<string, any>,
    report: RefuelingReport | null,
    reports: RefuelingReport[],
    shifts: PropertyValue[];
    fadromi: PropertyValue[];
    motocari: PropertyValue[];
    dialogConfirmDeleteVisible: boolean;
    isPreviewMode: boolean,
    version: number,
}

export class RefuelingPage extends React.Component<RefuelingPageProps, RefuelingPageState> {
    reportForDeleting?: RefuelingReport | null = null;
    autoSaveTrigger: any = null;


    constructor(props: RefuelingPageProps) {
        super(props);
        this.state = {
            working: true,
            loading: 0,
            selectedDate: moment(),
            formValues: new Map<string, any>(),
            report: null,
            reports: [],
            shifts: [],
            fadromi: [],
            motocari: [],
            dialogConfirmDeleteVisible: false,
            isPreviewMode: false,
            version: 0,
        }

        this.saveReport = this.saveReport.bind(this);
        this.autoSaveReport = this.autoSaveReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
        this.doDeleteReport = this.doDeleteReport.bind(this);
        this.loadReports = this.loadReports.bind(this);
        this.setAutoSaveInterval = this.setAutoSaveInterval.bind(this);
        this.clearAutoSaveInterval = this.clearAutoSaveInterval.bind(this);
        this.addReport = this.addReport.bind(this);
        this.editReport = this.editReport.bind(this);
        this.handleBaseInfoChange = this.handleBaseInfoChange.bind(this);


    }

    setAutoSaveInterval() {
        this.autoSaveTrigger = window.setInterval(this.autoSaveReport, AUTOSAVE_INTERVAL);
    }

    clearAutoSaveInterval() {
        if (this.autoSaveTrigger) {
            window.clearInterval(this.autoSaveTrigger)
        }
    }

    componentDidMount() {
        this.loadParameters();
        this.loadReports();
        this.setAutoSaveInterval();
    }

    componentWillUnmount() {
        this.clearAutoSaveInterval();
    }

    loadParameters() {
        this.setState({loading: this.state.loading + 3});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        shifts: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.SHIFTS
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        fadromi: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.FADROMI
        );
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        motocari: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.MOTOCARI
        )
    }

    loadReports(newDate?: Moment) {
        API.getRefueling(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    const reports = apiCall.data.data.reports.map(
                        (r: any) => {
                            return {
                                ...r,
                                base_info: {
                                    report_date: r.report_date,
                                    shift_id: r.shift_id,
                                    from_time: r.from_time,
                                    to_time: r.to_time,
                                    man_cnt: r.man_cnt,
                                }
                            } as RefuelingReport;
                        }
                    );
                    this.setState({reports: reports});
                    if(reports.length === 0) this.addReport();
                    if(reports.length === 1) this.editReport(reports[0], false);
                    if(reports.length > 1) this.setState({report: null});
                }
            }, newDate ? newDate : this.state.selectedDate
        )
    }

    addReport() {
        const report = {
            id: -1,
            base_info: {
                report_date: this.state.selectedDate.format('DD.MM.YYYY'),
                shift_id: -1,
                man_cnt: 1,
                from_time: '',
                to_time: ''
            }
        } as RefuelingReport;

        this.setState({report: report, formValues: new Map<string, any>(), isPreviewMode: false, version: this.state.version + 1})
    }

    editReport(report: RefuelingReport, isPrint: boolean = false) {
        let isPreviewMode = false;
        // if (isPrint || (report.user.id !== API.user?.id && !(API.user?.is_admin === 'Y'))) {
        if (isPrint) {
            isPreviewMode = true;
        }
        this.setState({report: report, formValues: new Map<string, any>(), isPreviewMode: isPreviewMode, version: this.state.version + 1});
    }

    saveReport() {
        this.doSaveReport(false);
    }

    autoSaveReport() {
        if (this.state.report) this.doSaveReport(true);
    }

    doSaveReport(isAutosave: boolean = false) {
        const fv: any = this.state.formValues;
        const bi: BaseReportInfo = {
            report_date: this.state.selectedDate.format('DD.MM.YYYY'),
            shift_id: 0,
            man_cnt: 1,
            from_time: '',
            to_time: '',
            comments: ''
        };

        fv.set('base_info', bi);
        fv.set('reportId', this.state.report?.id);

        API.saveRefueling(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    if (!isAutosave) {
                        this.setState({report: null});
                        this.loadReports();
                        showMsgInToast("Отчета е записан успешно!");
                    } else {
                        // @ts-ignore
                        this.state.report.id = apiCall.data.data.report.id;
                    }
                }
                if (isError(apiCall)) {
                    if (!isAutosave) showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }

                this.setState({working: isLoading(apiCall)});
            }, mapToObject(fv), this.state.report?.id
        );
    }

    deleteReport(report: RefuelingReport) {
        this.reportForDeleting = report;
        this.setState({dialogConfirmDeleteVisible: true});
    }

    doDeleteReport() {
        API.deleteRefueling(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна следната грешка:');
                }
                if (isSuccess(apiCall)) {
                    this.loadReports();
                    this.setState({dialogConfirmDeleteVisible: false});
                }
            }, this.reportForDeleting?.id
        );
    }

    handleBaseInfoChange(data: BaseReportInfo) {
        const fv = this.state.formValues;
        const bi = {...data} as BaseReportInfo;
        fv.set('base_info', bi);

        this.setState({formValues: fv});
    }

    render() {
        return (
            <div className="container">

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            {
                                (this.state.loading > 0) ?
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    :
                                    <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                        <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                            }

                        </div>
                        <h2 className="col">Заредено гориво</h2>
                        <div className="col-2">
                        </div>
                    </div>

                    <div className="card-body">
                        <h4 className="card-title">
                            {
                                this.state.report ?
                                    <>
                                        {
                                            this.state.report.id === -1 ? 'Добавяне' : 'Корекция'
                                        }
                                        &nbsp;на отчет
                                    </>
                                    :
                                    <>Зареждане с гориво</>
                            }
                        </h4>
                        <div className="row">
                            <div className="col">
                                {
                                    this.state.loading > 0 &&
                                    <div className={"m-3"}>Зареждам данните ...</div>
                                }
                                {
                                    // this.state.loading === 0 && !this.state.report &&
                                    <>
                                        <div className="card bg-light p-3 mb-3">
                                            <form className="form-inline">
                                                <div className={"text-nowrap row lh-2em "}>
                                                    <div className={"col-auto"}>Отчети за дата:&nbsp;</div>
                                                    <div className={"col-auto"}>
                                                        <ReadOnlyDatePickerComponent
                                                            value={this.state.selectedDate.format('DD.MM.YYYY')}
                                                            onChange={(date: Date) => {
                                                                this.setState({selectedDate: moment(date)});
                                                                this.loadReports(moment(date));
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </>
                                }

                                <ReportsList
                                    alwaysEdit={true}
                                    working={!(this.state.loading === 0 && moment.isMoment(this.state.selectedDate) && !this.state.working && !this.state.report)}
                                    reports={this.state.reports}
                                    shifts={this.state.shifts}
                                    editReport={this.editReport}
                                    deleteReport={this.deleteReport}
                                />

                                {
                                    this.state.working &&
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }

                                {
                                    this.state.report && !this.state.isPreviewMode &&
                                    <>
                                        {/*<ReportBaseInfoComponent*/}
                                        {/*    onChange={this.handleBaseInfoChange}*/}
                                        {/*    shifts={this.state.shifts.filter(*/}
                                        {/*        s => {*/}
                                        {/*            return this.state.reports.filter(*/}
                                        {/*                r => {*/}
                                        {/*                    return r.base_info.shift_id == s.id &&*/}
                                        {/*                        s.id != this.state.report?.base_info.shift_id*/}
                                        {/*                }*/}
                                        {/*            ).length === 0*/}
                                        {/*        }*/}
                                        {/*    )}*/}
                                        {/*    reportInfo={this.state.report.base_info}*/}
                                        {/*    hideManCount={true}*/}
                                        {/*    addMiddleShift={false}*/}
                                        {/*/>*/}
                                        {/*{*/}
                                        {/*    this.state.formValues?.get('base_info')?.shift_id > -1 ?*/}

                                        <EditRefuelingReportComponent
                                            key={this.state.version}
                                            fadromi={this.state.fadromi}
                                            motocari={this.state.motocari}
                                            report={this.state.report}
                                            onChange={(key: string, value: any) => {
                                                if(key !== 'base_info') this.setState({formValues: this.state.formValues.set(key, value)});
                                            }}
                                        />
                                        {/*        :*/}
                                        {/*        <div><strong>Моля, изберете работна смяна и въведете броя на служителите, за да продължите с отчета... {this.state.formValues?.get('base_info')?.shift_id}</strong></div>*/}
                                        {/*}*/}


                                    </>
                                }

                                {
                                    this.state.report && this.state.isPreviewMode &&
                                    <>
                                        <ViewRefuelingReportComponent
                                            report={this.state.report}
                                            shifts={this.state.shifts}
                                            fadromi={this.state.fadromi}
                                            motocari={this.state.motocari}
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="card-footer">
                        {
                            moment.isMoment(this.state.selectedDate) ?
                                <>
                                    {
                                        this.state.report ?
                                            <>
                                                {
                                                    this.state.working ?
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                        :
                                                        !this.state.isPreviewMode &&
                                                        <>
                                                            <button className="btn btn-primary" onClick={this.saveReport}>Запази</button>
                                                            &nbsp;
                                                            <button className="btn btn-secondary" onClick={() => {
                                                                this.setState({report: null});
                                                                this.loadReports();
                                                            }}>Откажи
                                                            </button>
                                                        </>
                                                }
                                            </>
                                            :
                                            this.state.reports.length < 2 &&
                                            <>
                                                <button className="btn btn-primary" onClick={this.addReport}>Добави нов</button>
                                            </>
                                    }
                                </>
                                :
                                <><span className="text-info">Моля, изберете дата...</span></>
                        }
                    </div>
                </div>

                <Modal
                    show={this.state.dialogConfirmDeleteVisible}
                    onHide={() => this.setState({dialogConfirmDeleteVisible: false})}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Внимание</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Моля, потвърдете изтриването!</p>
                    </Modal.Body>

                    <Modal.Footer>
                        {
                            this.state.working ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <>
                                    <button type={"button"} className={"btn btn-secondary"} onClick={() => this.setState({dialogConfirmDeleteVisible: false})}>Откажи</button>
                                    <button type={"button"} className={"btn btn-primary"} onClick={this.doDeleteReport}>Изтрий</button>
                                </>
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

}
