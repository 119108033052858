import React from "react";
import {API_CALL, PARAMETERS, PropertyValue, REPAIR_TYPE} from "../types";
import {isError, isLoading, isSuccess, mapToObject, showErrorsInToast, showMsgInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {BlockTitleComponent} from "../components/common/BlockTitleComponent";
import {ReadOnlyDatePickerComponent} from "../components/common/ReadOnlyDatePickerComponent";
import moment, {Moment} from "moment";
import * as queryString from "querystring";
import {RepairsReportPage} from "../components/reports/RepairsReportPage";
import {ProductionReportPage} from "../components/reports/ProductionReportPage";
import {BricksProducedReportPage} from "../components/reports/BricksProducedReportPage";
import {BricksWasteReportPage} from "../components/reports/BricksWasteReportPage";
import {FuelReportPage} from "../components/reports/FuelReportPage";
import {MaterialsReportPage} from "../components/reports/MaterialsReportPage";
import {CommentsReportPage} from "../components/reports/CommentsReportPage";
import {BricksWasteByTypeReportPage} from "../components/reports/BricksWasteByTypeReportPage";
import {PeletsSludgeSaldoReportPage} from "../components/financial/PeletsSludgeSaldoReportPage";


export interface ReportsPageProps {
    hideCard?: boolean,
    report?: string,
    periodFrom?: string,
    periodTo?: string,
    shiftId?: number,
}

interface ReportsPageState {
    loading: number,
    formValues: Map<string, any>,
    shifts: PropertyValue[],
}

interface RepairItem {
    type: REPAIR_TYPE,
    fromTime: string,
    toTime: string,
    comments: string,
}

export class ReportsPage extends React.Component<ReportsPageProps, ReportsPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            loading: 0,
            formValues: new Map<string, any>(),
            shifts: [],
        }

        this.loadData = this.loadData.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
        this.periodToday = this.periodToday.bind(this);
        this.selectDate = this.selectDate.bind(this);

        this.state.formValues.set('report', API.user?.id === 43 ? 'Часове за извършени ремонти' : 'Производителност за период');
        this.periodToday();
    }

    async componentDidMount() {
        this.loadParameters();

        this.periodToday();
        // @ts-ignore
        // let params = queryString.parse(this.props.location.search?.substr(1));
        // console.log(params, this.props);
    }

    periodToday() {
        this.selectDate('periodFrom', new Date());
        this.selectDate('periodTo', new Date());
        this.loadData();
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const fv = this.state.formValues;
        fv.set(name, value);
        this.setState({formValues: fv});
    }

    selectDate(dateFieldName: string, newDate: Date) {
        this.setState({formValues: this.state.formValues.set(dateFieldName, moment(newDate).format('DD.MM.YYYY'))});
    }


    loadParameters() {
        this.setState({loading: this.state.loading + 1});
        API.getParameterValues(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    this.setState({
                        loading: this.state.loading - 1,
                        shifts: apiCall.data.data.items?.values
                    });
                }
            }, PARAMETERS.SHIFTS
        );
    }

    loadData() {

        const fv = this.state.formValues;

        fv.set('activeReport', fv.get('report'));
        fv.set('activePeriodFrom', fv.get('periodFrom'));
        fv.set('activePeriodTo', fv.get('periodTo'));
        fv.set('activeShiftId', fv.get('shiftId'));

        this.setState({formValues: fv});
    }


    render() {

        const content = <>
            <div className="row">
                <div className="col">
                    {
                        this.state.loading > 0 &&
                        <div className={"m-3"}>Зареждам данните ...</div>
                    }
                </div>
            </div>

            <BlockTitleComponent title={"Параметри на справката"}/>
            <div className={"row mb-5"}>
                <div className={"col-auto ml-2 mr-4 pr-1"} style={{paddingTop: 7}}>
                    <label className={"justify-content-start"}>Изберете отчет:</label>
                    <select className="form-control w-100" name="report" required
                            value={this.state.formValues?.get('report')}
                            onChange={this.handleFormChange}>
                        {
                            API.user?.id === 43 ?
                                <option value="Часове за извършени ремонти">Часове за извършени ремонти</option>
                                :
                                <>
                                    <option value="Производителност за период">Производителност за период</option>
                                    <option value="Произведени тухли по вид">Произведени тухли по вид</option>
                                    <option value="Часове за извършени ремонти">Часове за извършени ремонти</option>
                                    <option value="Брой тухли брак по вид">Брой тухли брак по вид</option>
                                    <option value="Брой тухли брак по отдели">Брой тухли брак по отдели</option>
                                    <option value="Разход на гориво по машини">Разход на гориво по машини</option>
                                    <option value="Отчет разход суровини">Отчет разход суровини</option>
                                    <option value="Приход и разход на шлам и пелети за период">Приход и разход на шлам и пелети за
                                        период
                                    </option>
                                </>
                        }
                    </select>
                </div>

                <div className={"col-auto mr-1 pl-0 ml-0"}>
                    <form className="form-inline">
                        <div>
                            <div className={"col-12 mb-2"}>От дата:&nbsp;</div>
                            <div className={"col-auto"}>
                                <ReadOnlyDatePickerComponent
                                    onChange={(newDate: Date) => this.selectDate('periodFrom', newDate)}
                                    value={this.state.formValues.get('periodFrom')}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                <div className={"col-auto mr-1"}>
                    <form className="form-inline">
                        <div>
                            <div className={"col-12 mb-2"}>До дата:&nbsp;</div>
                            <div className={"col-auto"}>
                                <ReadOnlyDatePickerComponent
                                    onChange={(newDate: Date) => this.selectDate('periodTo', newDate)}
                                    value={this.state.formValues.get('periodTo')}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                {
                    !this.state.loading &&
                    <div className={"col-auto"} style={{paddingTop: 7}}>
                        <label className={"justify-content-start"}>Работна смяна:</label>
                        <select className="form-control w-100" name="shiftId" required
                                value={this.state.formValues?.get('shiftId')}
                                onChange={this.handleFormChange}>
                            <option value="">Всички смени</option>
                            {
                                this.state.shifts.map(
                                    (i: PropertyValue, idx: number) =>
                                        <option value={idx} key={idx}>{i.value}</option>
                                )
                            }
                            <option value="99">МЕЖДИННА СМЯНА</option>
                        </select>
                    </div>
                }
                <div className={"col-auto"}>
                    {
                        this.state.loading ?
                            <>
                                <label className={"col-form-label w-100"}>&nbsp; </label>
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </>
                            :
                            <>
                                <label className={"col-form-label w-100"}>&nbsp; </label>
                                <button className="btn btn-primary" onClick={this.loadData}>Калкулирай</button>
                            </>
                    }
                </div>
            </div>

            <ReportViewer
                report={this.state.formValues.get('activeReport')}
                periodFrom={this.state.formValues.get('activePeriodFrom')}
                periodTo={this.state.formValues.get('activePeriodTo')}
                shiftId={this.state.formValues.get('activeShiftId')}/></>

        if (this.props.hideCard) return content
        else
            return (
                <div className="container" style={{maxWidth: '100%'}}>

                    <div className="card shadow">

                        <div className="card-header text-center row">
                            <div className="col-2 text-start">
                                {
                                    (this.state.loading > 0) ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}><span
                                            className="btn btn-secondary"><FontAwesomeIcon
                                            icon={faArrowLeft}/></span></Link>
                                }

                            </div>
                            <h2 className="col">Статистика на дейността</h2>
                            <div className="col-2">
                            </div>
                        </div>

                        <div className="card-body">

                            {content}

                        </div>

                        <div className="card-footer">{<></>}</div>
                    </div>

                </div>
            );
    }

}

const ReportViewer = function (props: ReportsPageProps) {
    switch (props.report) {
        case 'Производителност за период' :
            return <ProductionReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>

        case 'Произведени тухли по вид' :
            return <BricksProducedReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>

        case 'Брой тухли брак по вид' :
            return <BricksWasteByTypeReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>

        case 'Брой тухли брак по отдели' :
            return <BricksWasteReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>

        case 'Часове за извършени ремонти' :
            return <RepairsReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>

        case 'Разход на гориво по машини' :
            return <FuelReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>

        case 'Отчет разход суровини' :
            return <MaterialsReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}
                shiftId={props.shiftId}/>
        case 'Приход и разход на шлам и пелети за период' :
            return <PeletsSludgeSaldoReportPage
                periodFrom={props.periodFrom}
                periodTo={props.periodTo}/>
    }

    return <></>
}
