import React, {useState} from "react";
import {Account} from "../../types";
import StatusAccountCard from "./StatusAccountCard";

export default function RenderRate({rate}: {rate: Array<Account>|null}) {
    const [readyCnt, setReadyCnt] = useState(0);

    if (!rate) return null;

    return (
        <>
            <h2 className={"mt-2 mb-1 text-center"} style={{color: "silver"}}>Екипът</h2>

            {
                rate.map(
                    item =>
                        <img
                            style={{display: 'none'}}
                            src={'http://erp.taifun-bg.com/api/user/' + item.id + '/photo'}
                            onLoad={() => setReadyCnt(readyCnt + 1)}
                        />
                )
            }

            {
                readyCnt === rate.length &&
                rate.map(
                    (u, i) =>
                        <StatusAccountCard user={u} position={i + 1}  key={u.id}/>
                )
            }
        </>
    )
}