import {Button, InputGroup, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {faEye, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CalendarElement, CalendarEvent, CalendarExecutor} from "../../../pages/CalendarPage";
import {CalendarEventEditModal} from "./CalendarEventEditModal";
import ReactDatePicker from "react-datepicker";
import {dateToStringFormatOrNull} from "../../../common";
import {CALENDAR_MODE} from "../../../types";
import CalendarFilter, {CALENDAR_FILTER} from "../filter/CalendarFilter";

export interface CalendarHistoryListProps {
    mode: CALENDAR_MODE;
    loading: boolean;
    events: CalendarEvent[];
    elements: CalendarElement[];
    executors: CalendarExecutor[];
    internals: CalendarExecutor[];
    onReload: () => any;
    currentFilter: CALENDAR_FILTER | null;
    onFilterChanged: (filter: CALENDAR_FILTER) => any;
}


export function CalendarHistoryList({mode, loading, events, elements, executors, internals, onReload, currentFilter, onFilterChanged}: CalendarHistoryListProps) {
    const ELEMENT_LABEL = mode === CALENDAR_MODE.MACHINES ? 'Машина/съоръжение' : 'Задача';

    const [editEvent, setEditEvent] = useState<CalendarEvent | null>(null);

    if (loading) return null;

    return (
        <>
            <div className={"row"} style={{opacity: (loading ? 0.3 : 1)}}>
                <div className={"col-12"}>
                    <Table bordered>
                        <thead>
                        <tr className={"bg-secondary text-light font-weight-bold"}>
                            <td className={"text-center"}>Статус</td>
                            <td>{ELEMENT_LABEL}</td>
                            <td>Изпълнител</td>
                            <td className={""}>Процедура<br/>Дейност</td>
                            <td className={"text-center w-180px"}>Планирано<br/>за дата</td>
                            <td className={"text-center w-180px"}>Изпълнено<br/>на дата</td>
                            <td className={"text-end"}>Цена</td>
                            <td className={"text-end"}>Операции</td>
                        </tr>
                        <CalendarFilter
                            mode={mode} elements={elements} executors={executors} internals={internals}
                            currentFilter={currentFilter} onFilterChanged={onFilterChanged}/>
                        </thead>
                        <tbody>
                        {
                            events.length === 0 &&
                            <tr>
                                <td colSpan={8}><strong>Няма добавени записи</strong></td>
                            </tr>
                        }
                        {
                            events.map(
                                e =>
                                    <tr key={e.id}>
                                        <td className={"text-center"}>{e.status}</td>
                                        <td>{e.element?.name}</td>
                                        <td>{e.executor?.name}</td>
                                        <td>{e.comments}</td>
                                        <td className={"text-center"}>{e.plan_date}</td>
                                        <td className={"text-center"}>{e.exec_date}</td>
                                        <td className={"text-end"}>{e.price} лв</td>
                                        <td className={"w-120px text-end"}>
                                            <Button size={"sm"} onClick={() => setEditEvent(e)}><FontAwesomeIcon icon={faEye}/></Button>&nbsp;
                                        </td>
                                    </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

            {
                editEvent &&
                <CalendarEventEditModal title={"Разглеждане на събитие"}
                                        event={editEvent}
                                        elements={elements}
                                        internals={internals}
                                        executors={executors}
                                        onReload={onReload}
                                        onClose={() => {
                                            setEditEvent(null)
                                        }}
                />
            }
        </>
    )
}
