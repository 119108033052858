import React from "react";
import '../Home.css';
import {API} from "../api";
import {API_CALL} from "../types";
import {isLoading, isSuccess} from "../common";
import {ModulesComponent} from "../components/homepage/ModulesComponent";
import {Card, Col, Row} from "react-bootstrap";
import moment from "moment";
import {ProductionCard} from "../components/homepage/ProductionCard";
import {SalesCard} from "../components/homepage/SalesCard";
import {IncidentsCard} from "../components/homepage/IncidentsCard";
import {PlanningCard} from "../components/homepage/PlanningCard";

interface HomePageState {
    loading: boolean,
    user: any
}

export default class HomePage extends React.Component<any, HomePageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            user: null
        }
    }

    componentDidMount() {
        API.getUserData(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.setState({user: apiCall.data.data.user});
                    if (apiCall.data.data.user.sorted.length === 1) {
                        window.location.href = apiCall.data.data.user.sorted[0].module.url + API.getAPITokenAsQuery();
                    }
                }
            }
        );

        API.getCurrentStatus(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    // console.log(apiCall.data);
                }
            }
        );
    }

    periodFrom = moment().format('DD.MM.YYYY');
    periodTo = moment().format('DD.MM.YYYY');

    render() {
        return (
            <div className="container mw-100">

                <Row className={"flex-nowrap"}>
                    <Col xs={"auto"}>
                        <Card>
                            <Card.Header className={"home-card-header main-modules-header main-modules-header-primary-text"}>
                                <Row className={"fw-bold"}>
                                    <Col><h2>Модули</h2></Col>
                                    <Col className={"text-end"}><h3>{moment().format('DD.MM.yyyy')}</h3></Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className={"home-card-body w-400px"}>
                                {
                                    !this.state.user &&
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                                {
                                    this.state.user &&
                                    <ModulesComponent modules={this.state.user.sorted}/>
                                }
                            </Card.Body>
                        </Card>
                    </Col>

                    {
                        API.user?.is_admin === 'Y' && <>

                            <Col className={"auto"}>
                                <Row className={"mb-4 w-100 p-0 m-0"}>
                                    <Col className={"text-center"}>
                                        <ProductionCard
                                            title={"Производство"}
                                            periodFrom={this.periodFrom} periodTo={this.periodTo} labelStyles={"font-size: 14px;"}/>
                                    </Col>
                                </Row>

                                <Row className={"mb-4 w-100 p-0 m-0"}>
                                    <Col>
                                        <SalesCard/>
                                    </Col>
                                </Row>

                                <Row className={"mb-4 w-100 p-0 m-0"}>
                                    <Col>
                                        <PlanningCard/>
                                    </Col>
                                    <Col>
                                        <IncidentsCard/>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    }

                </Row>

            </div>
        );
    }

}
