import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {CalendarElement, CalendarEvent, CalendarExecutor} from "../../../pages/CalendarPage";
import ReactDatePicker from "react-datepicker";
import {API_CALL, CALENDAR_MODE} from "../../../types";
import {API} from "../../../api";
import {
    dateToStringFormatOrNull,
    isError,
    isSuccess,
    showErrorsInToast,
    stringToDateFormatOrNull,
    translateError
} from "../../../common";
import moment from "moment";

export interface CalendarEventEditModalProps {
    title: string;
    event: CalendarEvent;
    elements: CalendarElement[];
    executors: CalendarExecutor[];
    internals: CalendarExecutor[];
    onReload: () => any;
    onClose: any;
}


export function CalendarEventEditModal({
                                           title,
                                           event,
                                           elements,
                                           executors,
                                           internals,
                                           onReload,
                                           onClose
                                       }: CalendarEventEditModalProps) {

    const ELEMENT_LABEL = event.mode === CALENDAR_MODE.MACHINES ? 'Машина/съоръжение' : 'Задача';
    const ELEMENT_EDITABLE = event.status === 'Не е започнато';
    const ELEMENT_IN_PROGRESS = event.status === 'Започнато';

    const [formValues, setFormValues] = useState<Map<string, any>>(new Map<string, any>());

    useEffect(() => {
        const plan_date = moment(event.plan_date, 'DD.MM.YYYY');
        const alert_date = moment(event.alert_date, 'DD.MM.YYYY');
        const exec_date = moment(event.exec_date, 'DD.MM.YYYY');
        const next_date = moment(event.next_date, 'DD.MM.YYYY');

        const fv = new Map<string, any>([
            ["element_id", event.element?.id],
            ["executor_id", (event.executor?.executor_type === 'Вътрешен' ? 'i_' : 'e_') + event.executor?.id],
            ["plan_date", plan_date.isValid() ? plan_date.toDate() : null],
            ["alert_date", alert_date.isValid() ? alert_date.toDate() : null],
            ["exec_date", exec_date.isValid() ? exec_date.toDate() : new Date()],
            ["next_date", next_date.isValid() ? next_date.toDate() : null],
            ["status", event.status],
            ["price", event.price],
            ["comments", event.comments],
            ["comments1", event.comments1],
            ["plan_next", next_date.isValid()],
            ["auto_renew", event.auto_renew],
            ["auto_renew_interval", event.auto_renew_interval],
            ["auto_renew_interval_type", event.auto_renew_interval_type],
        ]);

        setFormValues(fv);
    }, [event]);

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);

        setFormValues(new Map<string, any>(formValues));
    }

    const _handleFormChange = function (name: string, value: any) {
        formValues.set(name, value);

        if (name === "plan_date") {
            if (formValues.get('alert_date') >= value) {
                formValues.set('alert_date', null);
            }
        }

        setFormValues(new Map<string, any>(formValues));
    }

    function saveValue() {
        let executor = executors.find(e => "e_" + e.id === formValues.get('executor_id'));
        if (!executor) {
            executor = internals.find(e => "i_" + e.id === formValues.get('executor_id'));
        }
        if (formValues.get('plan_next') === false) formValues.set('next_date', null);

        const _event = {
            ...event,
            element: elements.find(e => e.id == formValues.get('element_id')),
            executor: executor,
            plan_date: dateToStringFormatOrNull(formValues.get('plan_date'), 'YYYY-MM-DD'),
            alert_date: dateToStringFormatOrNull(formValues.get('alert_date'), 'YYYY-MM-DD'),
            exec_date: dateToStringFormatOrNull(formValues.get('exec_date'), 'YYYY-MM-DD'),
            next_date: dateToStringFormatOrNull(formValues.get('next_date'), 'YYYY-MM-DD'),
            status: formValues.get('status'),
            price: formValues.get('price'),
            comments: formValues.get('comments'),
            comments1: formValues.get('comments1'),
            auto_renew: formValues.get('auto_renew'),
            auto_renew_interval: formValues.get('auto_renew_interval'),
            auto_renew_interval_type: formValues.get('auto_renew_interval_type'),
        } as any;

        API.saveCalendarEvent(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    onReload();
                    onClose();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Данните не бяха запазени поради следните грешки:', translateError)
                }
            }, _event
        )
    }

    function getPossibleStatuses() {
        const statusesList = ['Не е започнато', 'Започнато', 'Изпълнено', 'Провалено', 'Изместено'];
        let statuses: string[] = [];
        for (let $idx = statusesList.length - 1; $idx > -1; $idx--) {
            statuses.push(statusesList[$idx]);
            if (statusesList[$idx] === event.status) {
                if (event.status === 'Изпълнено' || event.status === 'Провалено' || event.status === 'Изместено') statuses = [event.status];
                break;
            }
        }
        return statuses.reverse();
    }

    return (
        <Modal
            show={true}
            centered
            onHide={() => onClose()}
            size={"lg"}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>{title}</Modal.Title>
                    </div>
                </div>
            </Modal.Header>

            <div className="modal-body">

                <form className={"form"}>

                    <div className="row">
                        <div className="col-6">
                            <div className="form-row row form-group">
                                <div className={"col-6"}>
                                    <label className="form-label mb-0 w-100">Планирано за дата</label>
                                    <ReactDatePicker className="form-control"
                                                     disabled={!ELEMENT_EDITABLE}
                                                     onChange={newValue => _handleFormChange('plan_date', newValue)}
                                                     selected={formValues.get('plan_date')}
                                                     dateFormat={'dd.MM.yyyy'}
                                    />
                                </div>
                                <div className={"col-6"}>
                                    <label className="form-label mb-0 w-100 float-left">Алармирай след дата</label>
                                    <ReactDatePicker className="form-control"
                                                     disabled={!ELEMENT_EDITABLE}
                                                     onChange={newValue => _handleFormChange('alert_date', newValue)}
                                                     selected={formValues.get('alert_date')}
                                                     maxDate={formValues.get('plan_date')}
                                                     dateFormat={'dd.MM.yyyy'}
                                    />
                                </div>
                            </div>
                            <div className="form-row row form-group mt-2 mb-2">
                                <div className={"col-auto"}>
                                    <label className="form-label mt-1 w-100 float-left">
                                        <input type={"checkbox"} value={1} name="auto_renew"
                                               onChange={handleFormChange}
                                               disabled={!(ELEMENT_EDITABLE || ELEMENT_IN_PROGRESS)}
                                               checked={formValues.get('auto_renew')}/>
                                        &nbsp;Подновавай на всеки:
                                    </label>
                                </div>
                                {
                                    formValues.get('auto_renew') &&
                                    <>
                                        <div className="col row">
                                            <div className={"col-5 pr-0 mr-0"}
                                                 style={{paddingRight: 0, paddingLeft: 0}}>
                                                <input type={"number"} className="form-control"
                                                       onChange={handleFormChange}
                                                       name={"auto_renew_interval"}
                                                       value={formValues.get('auto_renew_interval')}
                                                       disabled={!ELEMENT_EDITABLE}/>
                                            </div>
                                            <div className={"col"}>
                                                <select className="form-control"
                                                        onChange={handleFormChange} name={"auto_renew_interval_type"}
                                                        value={formValues.get('auto_renew_interval_type')}
                                                        disabled={!ELEMENT_EDITABLE}
                                                >
                                                    <option value={0}>ден</option>
                                                    <option value={1}>месец</option>
                                                    <option value={2}>година</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className="form-row row form-group">
                                <div className={"col"}>
                                    <label className="form-label mb-0">{ELEMENT_LABEL}</label>
                                    <select className="form-control" onChange={handleFormChange} name={"element_id"}
                                            value={formValues.get('element_id')}
                                            disabled={!ELEMENT_EDITABLE}
                                    >
                                        <option value={-1}></option>
                                        {elements.map(i =>
                                            <option key={i.id} value={i.id}
                                                    selected={i.id == formValues.get('element_id')}>{i.name}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="form-row row form-group">
                                <div className={"col"}>
                                    <label className="form-label mb-0">Изпълнител</label>
                                    <select className="form-control" onChange={handleFormChange} name={"executor_id"}
                                            value={formValues.get('executor_id')}
                                            disabled={!ELEMENT_EDITABLE}>
                                        <option value={-1}></option>
                                        <optgroup label="Вътрешни">
                                            {internals.map(i => <option key={i.id}
                                                                        value={"i_" + i.id}>{i.name}</option>)}
                                        </optgroup>
                                        <optgroup label="Външни">
                                            {executors.map(i => <option key={i.id}
                                                                        value={"e_" + i.id}>{i.name}</option>)}
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div className="form-row row form-group">
                                <div className="col">
                                    <label className="form-label mb-0">Планирана цена</label>
                                    <input type={"number"} className="form-control" onChange={handleFormChange}
                                           name={"price"} value={formValues.get('price')}
                                           disabled={!ELEMENT_EDITABLE}/>
                                </div>
                            </div>
                            <div className="form-row form-group">
                                <div className="col">
                                    <label className="form-label mb-0">Процедура/дейност</label>
                                    <textarea className="form-control" onChange={handleFormChange} name={"comments"}
                                              rows={3}
                                              value={formValues.get('comments')}
                                              disabled={!ELEMENT_EDITABLE}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-row row form-group">
                                <div className="col">
                                    <label className="form-label mb-0">Статус</label>
                                    <select className="form-control" onChange={handleFormChange} name={"status"}
                                            value={formValues.get('status')}
                                            disabled={getPossibleStatuses().length < 2}
                                    >
                                        {
                                            getPossibleStatuses().map(
                                                o => <option key={o} value={o}>{o}</option>
                                            )
                                        }
                                    </select>
                                </div>

                                {
                                    formValues.get('status') !== "Не е започнато" && formValues.get('status') !== "Започнато" &&
                                    <>
                                        <div className="form-row row form-group">
                                            <div className={"col-6"}>
                                                <label className="form-label mb-0 w-100 float-left">
                                                    {formValues.get('status')} на дата
                                                </label>
                                                <ReactDatePicker className="form-control"
                                                                 onChange={newValue => _handleFormChange('exec_date', newValue)}
                                                                 selected={formValues.get('exec_date')}
                                                                 dateFormat={'dd.MM.yyyy'}
                                                                 disabled={!(ELEMENT_EDITABLE || ELEMENT_IN_PROGRESS)}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row row form-group">
                                            <div className="col">
                                                <label className="form-label mb-0">Коментари и забележки</label>
                                                <textarea className="form-control" onChange={handleFormChange}
                                                          name={"comments1"} rows={3}
                                                          value={formValues.get('comments1')}
                                                          disabled={!(ELEMENT_EDITABLE || ELEMENT_IN_PROGRESS)}
                                                />
                                            </div>
                                        </div>

                                        {
                                            !formValues.get('auto_renew') &&
                                            <div className="form-row row form-group">
                                                <div className={"col-12"}>
                                                    <label className="form-label mb-0 w-100 float-left">
                                                        <input type={"checkbox"} value={1} name="plan_next"
                                                               onChange={handleFormChange}
                                                               disabled={!(ELEMENT_EDITABLE || ELEMENT_IN_PROGRESS)}
                                                               checked={formValues.get('plan_next')}/>
                                                        &nbsp;Планиране на следващо такова събитие
                                                    </label>
                                                </div>
                                            </div>
                                        }

                                        {
                                            (formValues.get('plan_next') === true) &&
                                            <div className="form-row row form-group">
                                                <div className={"col-6"}>
                                                    <label className="form-label mb-0 w-100 float-left">
                                                        Планирано за дата
                                                    </label>
                                                    <ReactDatePicker className="form-control"
                                                                     onChange={newValue => _handleFormChange('next_date', newValue)}
                                                                     selected={formValues.get('next_date')}
                                                                     dateFormat={'dd.MM.yyyy'}
                                                                     disabled={!(ELEMENT_EDITABLE || ELEMENT_IN_PROGRESS)}
                                                    />
                                                </div>
                                            </div>
                                        }

                                    </>
                                }
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div className="modal-footer">
                {
                    ELEMENT_EDITABLE || ELEMENT_IN_PROGRESS ?
                        <>
                            <button type="button" className="btn btn-primary" onClick={() => saveValue()}>Запази
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => onClose()}>Откажи
                            </button>
                        </> :
                        <>
                            <button type="button" className="btn btn-secondary" onClick={() => onClose()}>Затвори
                            </button>
                        </>
                }
            </div>
        </Modal>
    )
}

