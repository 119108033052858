import {ClientsPage} from "../../pages/ClientsPage";
import React from "react";
import {City, Client, Driver, Logistic} from "../../types";
import {LogisticsPage} from "../../pages/LogisticsPage";

export interface SelectLogisticProps {
    filter: Map<string, any>;
    onSelect: (logistic: Logistic, driver: Driver) => any;
    onClose: () => any;
}

export function SelectLogistic({filter, onSelect, onClose} : SelectLogisticProps) {
    return <>
        <div style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%",
            zIndex: 9998, backgroundColor: "gray", opacity: "0.5"
        }}/>

        <div className={"p-3"} style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%",
            zIndex: 9998, opacity: "1"
        }}>
            <LogisticsPage
                filter={filter}
                onSelect={onSelect}
                onClose={onClose}
            />
        </div>
    </>
}
