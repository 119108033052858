import {ExtruderReport, PropertyValue} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {getShiftName, getTypeName} from "../../common";

export interface ViewExtruderReportProperties {
    report: ExtruderReport,
    shifts: PropertyValue[],
    bricks_types: PropertyValue[],
    clay_reasons: PropertyValue[],
}

interface ExtruderReportState {
}

export class ViewExtruderReportComponent extends React.Component<ViewExtruderReportProperties, ExtruderReportState> {

    constructor(props: ViewExtruderReportProperties) {
        super(props);
        this.state = {}
    }


    render() {
        const rd = JSON.parse(this.props.report.request_data);
        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>
                            </div>
                            <div className="col">
                                Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>
                            </div>
                            <div className="col-auto">
                                Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Производство"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        <th>Час</th>
                                        <th>Вид тухла</th>
                                        <th>Вакуум</th>
                                        <th>Налягане от-до</th>
                                        <th>Брой вагонетки</th>
                                        <th>Брак-пластове</th>
                                        <th>Брак тухли1</th>
                                        <th>Причина	%</th>
                                        </thead>
                                        <tbody>
                                        {
                                            rd.production?.map(
                                                (i: any, idx: number) =>
                                                    i ?
                                                        <tr key={idx}>
                                                            <td>{i.time}</td>
                                                            <td>{ getTypeName(this.props.bricks_types, i.brick) }</td>
                                                            <td>{ i.vacuum }</td>
                                                            <td>{ i.pressure_from + ' - ' + i.pressure_to }</td>
                                                            <td>{ i.wagonsQty }</td>
                                                            <td>{ i.layers_waste }</td>
                                                            <td>{ i.waste }</td>
                                                            <td>{
                                                                Array(1, 2, 3).map(
                                                                    idx => <div>
                                                                        {
                                                                            i['reason' + idx] && i['reason' + idx] > -1 ?
                                                                                getTypeName(this.props.clay_reasons, i['reason' + idx]) +
                                                                                ' [ ' + (i['reason'+idx+'_percent'] || '?') + '% ]' : ''
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            </td>
                                                        </tr>
                                                        : null
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    {
                                        (!rd.production || rd.production.length === 0) &&
                                        <div>Не е извършено</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Корекция мущук"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        <th>Час</th>
                                        <th>Описание</th>
                                        </thead>
                                        <tbody>
                                        {
                                            rd.mushtuk?.map(
                                                (i: any, idx: number) =>
                                                    <tr key={idx}>
                                                        <td>{i.time}</td>
                                                        <td>{ i.description }</td>
                                                    </tr>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                    {
                                        (!rd.mushtuk || rd.mushtuk.length === 0) &&
                                        <div>Не е извършено</div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div>
                                    { rd.comments || "Няма бележки към отчета" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
