import {City, Client, Driver} from "../../types";
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";

export interface EditLogisticDriverProps {
    driver: Driver;
    onSave: (driver: Driver) => any;
    onCancel: () => any;
}

export function EditLogisticDriver(props: EditLogisticDriverProps) {

    const initMap = function () {
        const m = new Map<string, any>();
        m.set('name', props.driver.name);
        m.set('phone', props.driver.phone);
        m.set('reg_nomer', props.driver.reg_nomer);
        return m;
    }
    const [formValues, setFormValues] = useState(initMap());

    const saveItem = function () {
        props.onSave(
            {
                name: formValues.get('name'),
                phone: formValues.get('phone'),
                reg_nomer: formValues.get('reg_nomer')
            } as Driver
        );
    }

    const cancelItem = function () {
        props.onCancel();
    }

    const handleFormChange = function (event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        formValues.set(name, value);
        setFormValues(new Map<string, any>(formValues));
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <label>Име на шофьора:</label>
                    <input className="form-control" type="text" name="name" required
                           value={formValues.get('name')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label>Телефон:</label>
                    <input className="form-control" type="text" name="phone" required
                           value={formValues.get('phone')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <label>Регистрационен номер:</label>
                    <input className="form-control" type="text" name="reg_nomer" required
                           value={formValues.get('reg_nomer')}
                           onChange={handleFormChange}/>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <button type={"button"} className={"btn btn-primary"} onClick={saveItem}>Запази</button>&nbsp;
                    <button type={"button"} className={"btn btn-secondary"} onClick={cancelItem}>Откажи</button>
                </div>
            </div>
        </>
    )
}
