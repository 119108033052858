import React from "react";
import {API_CALL, Driver, Logistic} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "react-bootstrap";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast} from "../../common";
import {EditLogisticDriver} from "./EditLogisticDriver";

export interface EditLogisticDetailsProps {
    logistic: Logistic,
    onSave: any,
    onCancel: any;
}

export interface EditLogisticDetailsState {
    loading: boolean,
    editDriver: Driver | null,
    isNew: boolean,
    formValues: Map<string, any>,
}

export class EditLogisticDetails extends React.Component<EditLogisticDetailsProps, EditLogisticDetailsState> {
    constructor(props: EditLogisticDetailsProps) {
        super(props);
        this.state = {
            loading: false,
            editDriver: null,
            isNew: false,
            formValues: new Map<string, any>(),
        }

        this.state.formValues.set('name', this.props.logistic.name);
        this.state.formValues.set('drivers', this.props.logistic.drivers);

        this.handleFormChange = this.handleFormChange.bind(this);
        this.addDriverItem = this.addDriverItem.bind(this);
        this.editDriverItem = this.editDriverItem.bind(this);
        this.saveDriverItem = this.saveDriverItem.bind(this);
        this.removeDriverItem = this.removeDriverItem.bind(this);
        this.renderDriverRows = this.renderDriverRows.bind(this);
        this.saveLogistic = this.saveLogistic.bind(this);
        this.cancelLogistic = this.cancelLogistic.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({formValues: this.state.formValues.set(name, value)});
    }

    renderDriverRows() {
        return this.state.formValues.get('drivers').length == 0 ?
            <tr>
                <td colSpan={4}><strong>Няма добавени шофьори</strong></td>
            </tr>
            :
            this.state.formValues.get('drivers').map(
                (item: Driver, idx: number) => <tr key={idx}>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.reg_nomer}</td>
                    <td className={"text-nowrap text-end"}>
                        <span className="btn btn-primary btn-light" onClick={() => this.editDriverItem(idx)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                        <span className="btn btn-primary btn-light" onClick={() => this.removeDriverItem(idx)}><FontAwesomeIcon icon="trash-alt"/></span>
                    </td>
                </tr>
            );
    }

    addDriverItem() {
        this.setState({editDriver: {name: '', reg_nomer: ''} as Driver, isNew: true});
    }

    editDriverItem(idx: number) {
        this.setState({editDriver: this.state.formValues.get('drivers')[idx], isNew: false});
    }

    saveDriverItem(driver: Driver, isNew: boolean) {
        const drivers = this.state.formValues.get('drivers');
        if (isNew) drivers.push(driver);
        else {
            if (this.state.editDriver) {
                this.state.editDriver.name= driver.name;
                this.state.editDriver.phone= driver.phone;
                this.state.editDriver.reg_nomer = driver.reg_nomer;
            }
        }

        this.setState({formValues: this.state.formValues.set('drivers', drivers), editDriver: null});
    }

    removeDriverItem(idx: number) {
        const drivers = this.state.formValues.get('drivers');
        drivers.splice(idx, 1);

        this.setState({formValues: this.state.formValues.set('drivers', drivers)});
    }


    saveLogistic() {
        const c: Logistic = {
            id: this.props.logistic.id,
            name: this.state.formValues.get('name'),
            drivers: this.state.formValues.get('drivers'),
        };

        API.saveLogistic(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});
                if(isSuccess(apiCall)) {
                    this.props.onSave(apiCall.data.data);
                }
                if(isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Грешка');
                }
            }, c
        );
    }

    cancelLogistic() {
        this.props.onCancel();
    }


    render() {
        return ( this.state.loading ?
                <>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </>
                :
                <div>
                    <div className={"row mb-3 mt-0"}>
                        <div className={"col"}>
                            <label>Име на фирмата:</label>
                            <input className="form-control" type="text" name="name" required
                                   value={this.state.formValues.get('name')}
                                   onChange={this.handleFormChange}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <label>Шофьори:</label>
                            <table className={"table table-sm"}>
                                <thead>
                                <tr>
                                    <th>Име</th>
                                    <th>Телефон</th>
                                    <th>Рег. номер</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderDriverRows()}
                                <tr>
                                    <td colSpan={4}>
                                        <button className={"btn btn-outline-primary w-100 font-weight-bold"} onClick={this.addDriverItem}>
                                            Добави нов шофьор
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col">
                            <button type={"button"} className={"btn btn-primary"} onClick={this.saveLogistic}>Запази</button>&nbsp;
                            <button type={"button"} className={"btn btn-secondary"} onClick={this.cancelLogistic}>Откажи</button>
                        </div>
                    </div>

                    {
                        this.state.editDriver &&
                        <Modal
                            show={true}
                            onHide={() => {
                                this.setState({editDriver: null})
                            }}
                            style={{zIndex: 10030}}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{(this.state.isNew ? 'Добавяне' : 'Корекция')} на шофьор</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className={"modal-90 m-3"} style={{maxWidth: '30em'}}>
                                    <EditLogisticDriver
                                        driver={this.state.editDriver}
                                        onSave={driver => this.saveDriverItem(driver, this.state.isNew)}
                                        onCancel={() => this.setState({editDriver: null})}
                                    />
                                </div>
                            </Modal.Body>
                        </Modal>
                    }
                </div>
        );
    }

}
