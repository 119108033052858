import React from "react";
import moment, {Moment} from "moment";
import {InputGroup} from "react-bootstrap";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError,
    zeroToEmptyStringAsCurrency, zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsCurrencyWithSpaces
} from "../../common";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import LineChart from "./LineChart";

interface ProfitReportPageProps {
    selectedDate?: Moment;
}

interface ProfitReportPageState {
    loading: boolean,
    report: any,
    formValues: Map<string, any>,
}

export class ProfitReportPage extends React.Component<ProfitReportPageProps, ProfitReportPageState> {
    yearsList: number[] = [];
    monthsList: string[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    monthsCount: number[] = [6, 12, 24, 36, 48];

    constructor(props: any) {
        super(props);

        for (let i = new Date().getFullYear(); i > 2018; i--) this.yearsList.push(i);

        const fv = new Map<string, any>();
        fv.set('fromYear', moment().year());
        fv.set('fromMonth', 12);
        fv.set('monthCount', 12);

        this.state = {
            loading: true,
            report: {},
            formValues: fv,
        }

        this.handleFormChange = this.handleFormChange.bind(this);
        this.loadReports = this.loadReports.bind(this);
    }

    componentDidMount() {
        this.loadReports();
    }

    loadReports() {
        API.getProfitReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    this.setState({report: apiCall.data.data.report});
                }
            },
            this.state.formValues.get('fromYear'),
            this.state.formValues.get('fromMonth'),
            this.state.formValues.get('monthCount')
        );
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    render() {
        const me = this;

        const renderHeader = function () {
            const expenses = me.state.report.diff;
            if (!expenses || Object.keys(expenses).length === 0) return '';

            const years = Object.keys(expenses);

            return (
                <>
                    <thead className={"text-center"}>
                    <tr>
                        <th rowSpan={2}>Група</th>
                        {
                            years.map(
                                (y: any) => <th key={y} colSpan={Object.keys(expenses[y]).length}>{y}</th>
                            )
                        }

                        <th rowSpan={2}>Всичко</th>
                    </tr>
                    <tr>
                        {
                            years.map(
                                (y: any) => {
                                    return Object.keys(expenses[y]).sort((a, b) => {
                                        return a.localeCompare(b);
                                    }).map(
                                        (m: any) => <th key={y + '.' + m}>{m}</th>
                                    )
                                }
                            )
                        }
                    </tr>
                    </thead>
                </>
            )
        }

        const renderCells = function (exp: any, key: string, totalClass: string) {

            if (!exp) return;

            const years = Object.keys(exp);
            const cells: any[] = [];
            let total = 0;

            years.forEach(
                (y: any, idx: number) => {
                    const months = Object.keys(exp[y]);
                    months.sort((a, b) => a.localeCompare(b))
                        .forEach(
                            (m: any) => {
                                total += (key !== '' ? exp[y][m][key] : exp[y][m]) * 1
                                cells.push(
                                    <td className={"text-end"} key={key + '_' + 'cell_' + y + '.' + m}>
                                        {zeroToEmptyStringAsCurrencyWithSpaces(key !== '' ? exp[y][m][key] : exp[y][m])}
                                    </td>
                                );
                            }
                        );

                    if(idx == years.length - 1) {
                        cells.push(
                            <td className={"text-end " + totalClass} key={key + '_' + 'total_' + y}>
                                { zeroToEmptyStringAsCurrencyWithSpaces(total.toFixed(2))}
                            </td>
                        )
                    }
                }
            );
            return cells;
        }

        const getRowClasses = function (_type: any, is_cash = false) {
            if (_type.id === 999) return "bg-secondary text-light fw-bold";
            if (is_cash) return '';
            if (_type.parent_id === 0) return "bg-secondary-light fw-bold";
            return "";
        }


        const chartLabels: any = [];
        let chartData: any = [];

        const getChartLabels = function() {
            if(!me.state.report?.diff) return;

            let profit: number[] = [];
            let inc: number[] = [];
            let out: number[] = [];

            Object.keys(me.state.report.diff)
                .sort((a, b) => a.localeCompare(b))
                .forEach(
                    (y: string) => {
                        Object.keys(me.state.report.diff[y]).sort().forEach(
                            (m: string) => {
                                chartLabels.push(m);
                                profit.push(Math.round(parseFloat(me.state.report.diff[y][m])));
                                inc.push(Math.round(parseFloat(me.state.report.inc[y][m]['total'])));
                                out.push(Math.round(parseFloat(me.state.report.out[y][m]['total'])));
                            }
                        )
                    }
                )

            chartData= [{name: 'Печалба', data: profit, color: "#0dcaf0"},
                {name: 'Приходи', data: inc, color: "#198754"},
                {name: 'Разходи', data: out, color: "#dc3545"}];
        }

        getChartLabels();

        return (
            <div className="row">
                <div className="col">
                    {
                        <>
                            <div className="card bg-light p-3 mb-3">
                                <form className="form-inline">
                                    <div className={"lh-2em row text-nowrap"}>
                                        <div className={"col-auto"}>От месец/година:&nbsp;</div>
                                        <div className={"col-auto"}>
                                            <InputGroup>
                                                <select className="form-control w-50px"
                                                        onChange={e => this.state.formValues.set('fromMonth', Number(e.target.value))}>
                                                    {
                                                        this.monthsList.map(m =>
                                                            <option value={m} key={m}
                                                                    selected={Number(m) === this.state.formValues.get('fromMonth')}>{m}</option>
                                                        )
                                                    }
                                                </select>
                                                <select className="form-control w-100px"
                                                        onChange={e => this.state.formValues.set('fromYear', Number(e.target.value))}>
                                                    {this.yearsList.map(y =>
                                                        <option value={y} key={y}
                                                                selected={Number(y) === this.state.formValues.get('fromYear')}>{y}</option>)}
                                                </select>
                                            </InputGroup>
                                        </div>
                                        <div className={"col-auto w-160px"}>Брой месеци назад:&nbsp;</div>
                                        <div className={"col-auto w-120px"}>
                                            <select className="form-control"
                                                    onChange={e => this.state.formValues.set('monthCount', Number(e.target.value))}>
                                                {
                                                    this.monthsCount.map(m =>
                                                        <option value={m} key={m}
                                                                selected={Number(m) === this.state.formValues.get('monthCount')}>{m}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className={"col-auto w-120px"}>
                                            <button className="btn btn-primary" onClick={() => this.loadReports()}
                                                    type={"button"}>Покажи
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </>
                    }


                    {
                        this.state.loading &&
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    }

                    {
                        !this.state.loading &&
                        <>

                            <div style={{position: "absolute", display: "block", width: 600, height: 213,
                                right: 16, top: 180, overflow: "hidden", zIndex: 9000, backgroundColor: "InfoBackground"}}
                                 className={"shadow"}>
                                <LineChart title={""} labels={chartLabels}
                                           data={chartData} />
                            </div>

                            <div className={"w-100 overflow-auto"}>
                                <BlockTitleComponent title={"П Е Ч А Л Б А"}/>
                                <table className={"table table-bordered w-100 " +
                                    (this.state.formValues.get('monthCount') > 24 ? 'table-sm' : '')}
                                       style={{opacity: (this.state.loading ? 0.3 : 1)}}
                                >
                                    {
                                        renderHeader()
                                    }

                                    <tbody>
                                    <tr className={"fw-bold bg-primary-light"}>
                                        <td>Печалба</td>
                                        {
                                            renderCells(this.state.report.diff, '', '')
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={"w-100 overflow-auto mt-5"}>
                                <BlockTitleComponent title={"П Р И Х О Д И"}/>
                                <table className={"table table-bordered w-100 " +
                                    (this.state.formValues.get('monthCount') > 24 ? 'table-sm' : '')}
                                       style={{opacity: (this.state.loading ? 0.3 : 1)}}
                                >
                                    {
                                        renderHeader()
                                    }

                                    <tbody>
                                    <tr> <td>Тухли - БАНКА</td> { renderCells(this.state.report.inc, 'bricks_BANKA', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Тухли - КЕШ</td> { renderCells(this.state.report.inc, 'bricks_CASH', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Пелети - БАНКА</td> { renderCells(this.state.report.inc, 'pelets_BANKA', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Пелети - БАНКА чужд.</td> { renderCells(this.state.report.inc, 'pelets_BANKA_FOREIGN', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Пелети - КЕШ</td> { renderCells(this.state.report.inc, 'pelets_CASH', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Пелети - КЕШ чужд.</td> { renderCells(this.state.report.inc, 'pelets_CASH_FOREIGN', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Други - БАНКА</td> { renderCells(this.state.report.inc, 'others_BANKA', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr> <td>Други - КЕШ</td> { renderCells(this.state.report.inc, 'others_CASH', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    <tr className={"fw-bold bg-success bg-opacity-25"}> <td>ВСИЧКО</td>
                                        { renderCells(this.state.report.inc, 'total', 'fw-bold bg-success bg-opacity-25') } </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className={"w-100 overflow-auto mt-5"}>
                                <BlockTitleComponent title={"Р А З Х О Д И"}/>
                                <table className={"table table-bordered w-100 " +
                                    (this.state.formValues.get('monthCount') > 24 ? 'table-sm' : '')}
                                       style={{opacity: (this.state.loading ? 0.3 : 1)}}
                                >
                                    {
                                        renderHeader()
                                    }

                                    <tbody>
                                    <tr> <td>БАНКА</td> { renderCells(this.state.report.out, 'BANKA', 'fw-bold bg-danger bg-opacity-25') } </tr>
                                    <tr> <td>КЕШ</td> { renderCells(this.state.report.out, 'CASH', 'fw-bold bg-danger bg-opacity-25') } </tr>
                                    <tr className={"fw-bold bg-danger bg-opacity-25"}> <td>ВСИЧКО</td>
                                        { renderCells(this.state.report.out, 'total', 'fw-bold bg-danger bg-opacity-25') } </tr>
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }

                </div>
            </div>
        );
    }

}
