import React, {useEffect, useState} from "react";
import {API} from "../api";
import {Account, API_CALL, CALENDAR_MODE} from "../types";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Tab, Tabs} from "react-bootstrap";
import {CalendarElementsList} from "../components/calendar/elements/CalendarElementsList";
import {CalendarElementEditModal} from "../components/calendar/elements/CalendarElementEditModal";
import {CalendarExecutorsList} from "../components/calendar/executors/CalendarExecutorsList";
import {CalendarExecutorEditModal} from "../components/calendar/executors/CalendarExecutorEditModal";
import {CalendarEventEditModal} from "../components/calendar/events/CalendarEventEditModal";
import {CalendarEventsList} from "../components/calendar/events/CalendarEventsList";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../common";
import {CalendarHistoryList} from "../components/calendar/events/CalendarHistoryList";
import {CALENDAR_FILTER} from "../components/calendar/filter/CalendarFilter";

interface CalendarPageProps {
    mode: CALENDAR_MODE;
}

interface CalendarPageState {
    loading: boolean,
}

export const EMPTY_CALENDAR_ELEMENT_MACHINES: CalendarElement = {
    id: -1, mode: CALENDAR_MODE.MACHINES, name: '', description: ''
}

export const EMPTY_CALENDAR_ELEMENT_ADMINISTRATIVE: CalendarElement = {
    id: -1, mode: CALENDAR_MODE.ADMINISTRATIVE, name: '', description: ''
}

export const EMPTY_CALENDAR_ACTION: CalendarAction = {
    id: -1, name: '', description: '', is_mandatory: 'Задължително', action_interval_qty: 1, action_interval_type: 'ден', action_type: 'Еднократно',
    alarm_interval: 0, alarm_start: 0
}

export const EMPTY_CALENDAR_EXECUTOR_MACHINES: CalendarExecutor = {
    id: -1, mode: CALENDAR_MODE.MACHINES, name: '', description: '', executor_type: 'Външен', phone1: '', phone2: '', email: ''
}

export const EMPTY_CALENDAR_EXECUTOR_ADMISITRATIVE: CalendarExecutor = {
    id: -1, mode: CALENDAR_MODE.ADMINISTRATIVE, name: '', description: '', executor_type: 'Външен', phone1: '', phone2: '', email: ''
}

export const EMPTY_CALENDAR_EVENT_MACHINES: CalendarEvent = {
    id: -1,
    parent_id: null,
    next_event_id: null,
    mode: CALENDAR_MODE.MACHINES,
    element: EMPTY_CALENDAR_ELEMENT_MACHINES,
    action: EMPTY_CALENDAR_ACTION,
    executor: EMPTY_CALENDAR_EXECUTOR_MACHINES,
    plan_date: null,
    alert_date: null,
    exec_date: null,
    auto_renew: false,
    auto_renew_interval: 1,
    auto_renew_interval_type: 1,
    next_date: null,
    status: 'Не е започнато',
    price: 0,
    comments: '',
    comments1: ''
}

export const EMPTY_CALENDAR_EVENT_ADMINISTRATIVE: CalendarEvent = {
    id: -1,
    parent_id: null,
    next_event_id: null,
    mode: CALENDAR_MODE.ADMINISTRATIVE,
    element: EMPTY_CALENDAR_ELEMENT_ADMINISTRATIVE,
    action: EMPTY_CALENDAR_ACTION,
    executor: EMPTY_CALENDAR_EXECUTOR_ADMISITRATIVE,
    plan_date: null,
    alert_date: null,
    exec_date: null,
    auto_renew: false,
    auto_renew_interval: 1,
    auto_renew_interval_type: 1,
    next_date: null,
    status: 'Не е започнато',
    price: 0,
    comments: '',
    comments1: ''
}

export function CalendarPage({mode}: CalendarPageProps) {
    const defaultTab = "events";

    const EMPTY_CALENDAR_EXECUTOR = mode === CALENDAR_MODE.MACHINES ? EMPTY_CALENDAR_EXECUTOR_MACHINES : EMPTY_CALENDAR_EXECUTOR_ADMISITRATIVE;
    const EMPTY_CALENDAR_ELEMENT = mode === CALENDAR_MODE.MACHINES ? EMPTY_CALENDAR_ELEMENT_MACHINES : EMPTY_CALENDAR_ELEMENT_ADMINISTRATIVE;
    const EMPTY_CALENDAR_EVENT = mode === CALENDAR_MODE.MACHINES ? EMPTY_CALENDAR_EVENT_MACHINES : EMPTY_CALENDAR_EVENT_ADMINISTRATIVE;
    const PAGE_TITLE = "Календар - " + (mode === CALENDAR_MODE.MACHINES ? "машини и съоръжения" : "административни задачи");
    const TAB_TITLE = (mode === CALENDAR_MODE.MACHINES ? "Машини и съоръжения" : "Задачи");
    const BUTTON_TITLE = "Добави " + (mode === CALENDAR_MODE.MACHINES ? "машина" : "задача");


    const [loading, setLoading] = useState<number>(0);
    const [loadingExecutors, setLoadingExecutors] = useState(false);
    const [loadingElements, setLoadingElements] = useState(false);
    const [loadingInternals, setLoadingInternals] = useState(false);
    const [loadingEvents, setLoadingEvents] = useState(false);

    const [activeTab, setActiveTab] = useState<string>(defaultTab);

    const [elements, setElements] = useState<CalendarElement[]>([]);
    const [addElement, setAddElement] = useState(false);

    const [executors, setExecutors] = useState<CalendarExecutor[]>([]);
    const [addExecutor, setAddExecutor] = useState(false);

    const [internals, setInternals] = useState<CalendarExecutor[]>([]);

    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [addEvent, setAddEvent] = useState(false);

    const [eventsFilter, setEventsFilter] = useState<CALENDAR_FILTER | null>(null);
    const [historyFilter, setHistoryFilter] = useState<CALENDAR_FILTER | null>(null);

    useEffect(() => {
        let l = 0;
        if(loadingExecutors) l++;
        if(loadingElements) l++;
        if(loadingInternals) l++;
        if(loadingEvents) l++;

        setLoading(l);
    }, [loadingExecutors, loadingElements, loadingInternals, loadingEvents]);


    function saveElement(element: CalendarElement) {
        setElements(elements.concat([element]));
        setAddElement(false);
    }

    function saveEvent(event: CalendarEvent) {
        setEvents(events.concat([event]));
        setAddEvent(false);
    }


    function loadExecutors(withTrashed: boolean = false) {
        API.getCalendarExecutorsList(
            (apiCall: API_CALL) => {
                setLoadingExecutors(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setExecutors(apiCall.data.data.items);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, mode, withTrashed
        )
    }

    const loadElements = function (withTrashed: boolean = false) {
        API.getCalendarElementsList(
            (apiCall: API_CALL) => {
                setLoadingElements(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setElements(apiCall.data.data.items);
                }

                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Данните не бяха запазени поради следните грешки:", translateError);
                }
            }, mode, withTrashed
        )
    }

    const loadEvents = function (isHistory: boolean = false, reloadAll: boolean = true) {
        if(reloadAll) {
            loadElements(isHistory);
            loadExecutors(isHistory);
            loadInternals(isHistory);
        }

        setEvents([]);
        const filter = isHistory ? historyFilter : eventsFilter;

        API.getCalendarEventsList(
            (apiCall: API_CALL) => {
                setLoadingEvents(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setEvents(apiCall.data.data.items.map((i: any) => {
                        return {
                            ...i, auto_renew: (i.auto_renew === 1)
                        }
                    }));
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Възникна грешка", translateError);
                }
            }, mode, isHistory, {...filter,
                plannedFrom: filter?.plannedFrom?.format('YYYY-MM-DD'),
                plannedTo: filter?.plannedTo?.format('YYYY-MM-DD'),
                execFrom: filter?.execFrom?.format('YYYY-MM-DD'),
                execTo: filter?.execTo?.format('YYYY-MM-DD')}
        )
    }

    const loadInternals = function (withTrashed: boolean = false) {
        API.getCalendarInternalsList(
            (apiCall: API_CALL) => {
                setLoadingInternals(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    setInternals(apiCall.data.data.users
                        .map((u: Account) => {
                            return {
                                id: u.id,
                                name: u.name,
                                mode: mode,
                                executor_type: "Вътрешен"
                            } as CalendarExecutor
                        }));
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Възникна грешка", translateError)
                }
            }, mode, withTrashed
        )
    }


    function onTabChange(newTab: string) {
        setActiveTab(newTab);

        switch (newTab) {
            case 'events':
                loadEvents();
                break;
            case 'history':
                loadEvents(true);
                break;
            case 'elements':
                loadElements();
                break;
            case 'executors':
                loadExecutors();
                break;
        }
    }

    function onFilterChanged(filter: CALENDAR_FILTER) {
        const isHistory = activeTab !== defaultTab;
        isHistory ? setHistoryFilter(filter) : setEventsFilter(filter);
    }

    useEffect(() =>{
        loadEvents(activeTab !== defaultTab, true);
    }, [historyFilter, eventsFilter]);


    const render = () => {
        return (
            <>
                <div className="container daily-full-report" style={{maxWidth: "100%"}}>

                    <div className="card">

                        <div className="card-header text-center row">
                            <div className="col-2 text-start no-print">
                                {
                                    loading > 0 ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                            <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                                }

                            </div>
                            <h2 className="col">{PAGE_TITLE}</h2>
                            <div className="col-2">
                                <h2 className={"print text-end"}></h2>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className={"row mb-3"}>
                                <div className={"col-12"} style={{opacity: (loading > 0 ? 0.3 : 1)}}>
                                    <Tabs className={"w-100"} defaultActiveKey={defaultTab} onSelect={eventKey => onTabChange(eventKey || defaultTab)}>
                                        <Tab eventKey={defaultTab} title={"Планирани мероприятия"}>
                                            <CalendarEventsList
                                                currentFilter={eventsFilter}
                                                onFilterChanged={filter => onFilterChanged(filter)}
                                                events={events} executors={executors} elements={elements} internals={internals} loading={loading > 0}
                                                mode={mode} onReload={() => loadEvents()}/>
                                        </Tab>
                                        <Tab eventKey={"elements"} title={TAB_TITLE}>
                                            <CalendarElementsList elements={elements} loading={loading > 0} onReload={() => loadElements()}/>
                                        </Tab>
                                        <Tab eventKey={"executors"} title={"Външни изпълнители"}>
                                            <CalendarExecutorsList executors={executors} loading={loading > 0} onReload={() => loadExecutors()}/>
                                        </Tab>
                                        <Tab eventKey={"history"} title={"История"}>
                                            <CalendarHistoryList
                                                currentFilter={historyFilter}
                                                onFilterChanged={filter => onFilterChanged(filter)}
                                                events={events} executors={executors} elements={elements} internals={internals} loading={loading > 0}
                                                mode={mode} onReload={() => loadEvents(true)}/>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>

                        </div>

                        <div className="card-footer">
                            {
                                activeTab === defaultTab &&
                                <button type={"button"} className="btn btn-primary" onClick={() => setAddEvent(true)}>
                                    Добави мероприятие</button>
                            }
                            {
                                activeTab === "elements" &&
                                <button type={"button"} className="btn btn-primary" onClick={() => setAddElement(true)}>{BUTTON_TITLE} </button>
                            }
                            {
                                activeTab === "executors" &&
                                <button type={"button"} className="btn btn-primary" onClick={() => setAddExecutor(true)}>Добави изпълнител</button>
                            }
                        </div>

                    </div>
                </div>

                {
                    addElement &&
                    <CalendarElementEditModal title={"Добавяне на " + (mode === CALENDAR_MODE.MACHINES ? "машина / съоръжение" : "задача")}
                                              element={EMPTY_CALENDAR_ELEMENT}
                                              onReload={() => loadElements()}
                                              onClose={() => setAddElement(false)}/>
                }

                {
                    addExecutor &&
                    <CalendarExecutorEditModal title={"Добавяне на изпълнител"}
                                               executor={EMPTY_CALENDAR_EXECUTOR}
                                               onReload={() => loadExecutors()}
                                               onClose={() => setAddExecutor(false)}/>
                }

                {
                    addEvent &&
                    <CalendarEventEditModal title={"Добавяне на събитие"}
                                            event={EMPTY_CALENDAR_EVENT}
                                            elements={elements}
                                            executors={executors}
                                            internals={internals}
                                            onReload={() => loadEvents()}
                                            onClose={() => setAddEvent(false)}/>
                }
            </>
        )
    }

    return render();
}

export interface CalendarElement {
    id: number;
    mode: CALENDAR_MODE;
    name: string;
    description: string;
}

export interface CalendarExecutor {
    id: number;
    mode: CALENDAR_MODE;
    name: string;
    description?: string;
    executor_type: 'Вътрешен' | 'Външен' | 'Друго';
    phone1?: string;
    phone2?: string;
    email?: string;
}

export interface CalendarAction {
    id: number;
    name: string;
    description: string;
    is_mandatory: 'Задължително' | 'Препоръчително';
    action_type: 'Еднократно' | 'Периодично';
    action_interval_qty: number;
    action_interval_type: 'ден' | 'месец' | 'година';
    alarm_start: number;
    alarm_interval: number;
}

export interface CalendarEvent {
    id: number;
    parent_id: number | null;
    next_event_id: number | null;
    mode: CALENDAR_MODE;
    element: CalendarElement;
    action: CalendarAction;
    executor: CalendarExecutor;
    plan_date: Date | null;
    alert_date: Date | null;
    exec_date: Date | null;
    auto_renew: boolean;
    auto_renew_interval: number;
    auto_renew_interval_type: number;
    next_date: Date | null;
    status: 'Не е започнато' | 'Започнато' | 'Изпълнено' | 'Провалено' | 'Изместено';
    price: number;
    comments: string;
    comments1: string;
}
