import React from "react";
import {API_CALL} from "../../types";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, mapToObject} from "../../common";
import moment, {Moment} from "moment";
import {BricksProducedChart} from "./BricksProducedChart";


interface BricksProducedReportPageState {
    loading: boolean,
    periodFrom: string,
    periodTo: string,
    shiftId?: number,
    data: any | null
}


export class BricksProducedReportPage extends React.Component<ReportsPageProps, BricksProducedReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
            periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            shiftId: props.shiftId,
            loading: false,
            data: null
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({data: null});

        API.calcBricksProducedReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shift: this.props.shiftId,
            }
        )
    }

    processData(data: any) {
        const totals = new Map<string, any>();
        totals.set('value', 'Всичко за периода');

        const fromPeriod = moment(this.props.periodFrom, 'DD.MM.YYYY');
        const toPeriod = moment(this.props.periodTo, 'DD.MM.YYYY');
        toPeriod.add(1, 'days');

        while(fromPeriod.isBefore(toPeriod)) {
            let dayTotal = 0;
            data.bricks.forEach((d: any) => dayTotal += d[fromPeriod.format('DD.MM.YYYY')] ? d[fromPeriod.format('DD.MM.YYYY')] : 0 );

            totals.set(fromPeriod.format('DD.MM.YYYY'), dayTotal);
            fromPeriod.add(1, 'days');
        }

        data.bricks.push(mapToObject(totals));

        this.setState({data: data});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<BricksProducedReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
                periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
                shiftId: this.props.shiftId
            });
            this.loadData();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    !this.state.loading && this.state.data &&
                    <>
                        {
                            this.state.data.bricks.map(
                                (i: any, idx: number) =>
                                    <BricksProducedChart key={idx}
                                                         title={i.value}
                                                         periodFrom={this.state.periodFrom}
                                                         periodTo={this.state.periodTo}
                                                         shiftId={this.state.shiftId}
                                                         data={i}/>
                            )
                        }
                    </>

                }

            </>
        );
    }

}
