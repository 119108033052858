import {Card, Col, Table} from "react-bootstrap";
import React from "react";
import {ReportItem} from "../bonuses/BonusesControlModal";

export default function StatusOneDepartmentNotes(props: { title: string, data: Array<ReportItem> }) {

    let firstShift = "";
    let secondShift = "";

    props.data?.forEach(
        r => {
            if (r.shift === "ПЪРВА СМЯНА") {
                firstShift += r.comment + " ";
            } else {
                secondShift += r.comment + " ";
            }
        }
    );

    if (!(firstShift + secondShift)) return null;

    return <Col xs={12}>
        <Card bg={"light"} className={"h-100 mb-2 mt-1"}>
            <Card.Body>
                <Table className={"table-borderless m-0"} style={{minHeight: "180px"}}>
                    <tbody>
                    <tr>
                        <td rowSpan={2} className={"text-center w-50px align-middle bg-secondary-light shadow"}>
                <span
                    style={{fontSize: "32px", writingMode: "vertical-rl", rotate: "180deg"}}
                    className={"text-uppercase"}>{props.title}</span>
                        </td>
                        <td className={"text-uppercase w-300px text-end"}
                            style={{fontSize: "32px", lineHeight: "78px"}}>Първа смяна
                        </td>
                        <td style={{fontSize: "38px"}} className={"fw-bold"}>
                            <div
                                className={"border border-2 rounded shadow mb-1 p-2 " + (secondShift === "" ? "bg-secondary-light" : "bg-info")}>
                                {firstShift || "Няма бележки"}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className={"text-uppercase w-300px text-end"}
                            style={{fontSize: "32px", lineHeight: "78px"}}>
                            Втора смяна
                        </td>
                        <td style={{fontSize: "38px"}} className={"fw-bold"}>
                            <div
                                className={"border border-2 rounded shadow mb-1 p-2 " + (secondShift === "" ? "bg-secondary-light" : "bg-info")}>
                                {secondShift || "Няма бележки"}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </Col>;
}
