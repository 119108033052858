import React from "react";
import {$enum} from "ts-enum-util";
import {API_CALL, PropertyValue, REPAIR_TYPE} from "../../types";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {ReportsPageProps} from "../../pages/ReportsPage";
import {API} from "../../api";
import {isLoading, isSuccess, minutesToHours} from "../../common";
import moment, {Moment} from "moment";

const options = {
    chart: {
        type: 'column',
    },
    title: {
        align: 'left',
        useHTML: true,
        text: 'Изразходвано време за ремонти за период <b></b>',
    },
    subtitle: {
        floating: true,
        useHTML: true,
        align: 'left',
        text: ''
    },
    colors: ['#2f7ed8', '#f50000', '#f2f200'],
    tooltip: {
        enabled: false,
    },
    xAxis: {
        categories: ['Мелене(глина)', 'Екструдиране', 'Печка(пелети)', 'Редене', 'Пещ(пелети)', 'Разтоварване'],
        title: {
            text: null
        }
    },
    yAxis: {
        min: 0,
        max: 120,
        title: {
            text: 'Производителност (%)',
            align: 'high'
        },
        labels: {
            overflow: 'justify'
        },
        plotLines: [{value: 100, width: 2, color: 'rgba(0, 0, 0)', label: {useHTML: true, text: 'Стандарт', align: 'right'}}],
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: true,
                align: 'left',
                border: true,
                borderWidth: 1,
                shadow: true,
                verticalAlign: 'top',
                useHTML: true,
                x: 10,
                y: 10,
                formatter: function (): any {
                    // @ts-ignore
                    return this.point.label;
                }
            }
        }
    },
    legend: {
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 1,
        backgroundColor: '#FFFFFF',
        shadow: true
    },
    credits: {
        enabled: false
    },
    series: [{
        name: 'Производство',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Разход',
        data: [null, null, null, null, null, null]
    }, {
        name: 'Брак',
        data: [null, null, null, null, null, null]
    }]
}


interface RepairsReportPageState {
    loading: boolean,
    options: any,
    periodFrom: string,
    periodTo: string,
    shiftId?: number,
    repairs: RepairItem[],
    totals: Map<string, any>,
    sum: Map<string, any>,
}

interface RepairItem {
    type: REPAIR_TYPE,
    report_date: string,
    fromTime: string,
    toTime: string,
    comments: string,
}

export class RepairsReportPage extends React.Component<ReportsPageProps, RepairsReportPageState> {

    constructor(props: ReportsPageProps) {
        super(props);
        this.state = {
            periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
            periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
            shiftId: props.shiftId,
            loading: false,
            options: {...options},
            repairs: [],
            totals: new Map<string, any>(),
            sum: new Map<string, any>(),
        }

        this.loadData = this.loadData.bind(this);
        this.processData = this.processData.bind(this);
    }

    async componentDidMount() {
        this.loadData();
    }

    loadData() {
        API.calcRepairsReport(
            (apiCall: API_CALL) => {
                this.setState({loading: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    console.log(apiCall.data.data);
                    this.processData(apiCall.data.data.report);
                }
            }, {
                periodFrom: this.props.periodFrom,
                periodTo: this.props.periodTo,
                shift: this.props.shiftId,
            }
        )
    }

    processData(data: any) {
        const repairs: RepairItem[] = data.repairs.map(
            (r: any) => {
                return {...r} as RepairItem
            }
        );

        const totals = new Map<string, any>();
        const sum = new Map<string, any>();

        const fromPeriod = moment(this.props.periodFrom, 'DD.MM.YYYY');
        const toPeriod = moment(this.props.periodTo, 'DD.MM.YYYY').add(1, 'day');

        for (fromPeriod; fromPeriod.isBefore(toPeriod); fromPeriod.add(1, 'day')) {
            const dayData = new Map<string, any>();

            let total = 0;
            $enum(REPAIR_TYPE).forEach(
                _t => {
                    let totalMinutes = 0;
                    // debugger
                    repairs.filter(
                        item =>
                            item.report_date === fromPeriod.format('DD.MM.YYYY') &&
                            $enum(REPAIR_TYPE).getValueOrDefault(item.type, "").toLocaleUpperCase() === _t.toLocaleUpperCase()
                    ).forEach(
                        _r => {
                            const f = moment(_r.report_date + ' ' + _r.fromTime, 'DD.MM.YYYY hh:mm');
                            const t = moment(_r.report_date + ' ' + _r.toTime, 'DD.MM.YYYY hh:mm');
                            if (t.isBefore(f)) t.add(1, 'day');

                            totalMinutes += t.diff(f, 'minutes');
                        }
                    )
                    dayData.set(_t, totalMinutes);
                }
            );

            totals.set(fromPeriod.format('DD.MM.YYYY'), dayData);
            dayData.forEach(
                (value: any, key: string) => {
                    let curr = sum.get(key) || 0;
                    sum.set(key, value + curr);
                }
            );
        }

        this.setState({repairs: repairs, totals: totals, sum: sum});
    }

    componentDidUpdate(prevProps: Readonly<ReportsPageProps>, prevState: Readonly<RepairsReportPageState>, snapshot?: any) {
        if (
            (this.state.periodFrom !== this.props.periodFrom) ||
            (this.state.periodTo !== this.props.periodTo) ||
            (this.state.shiftId !== this.props.shiftId)
        ) {
            this.setState({
                periodFrom: (this.props.periodFrom || moment().format('DD.MM.YYYY')),
                periodTo: (this.props.periodTo || moment().format('DD.MM.YYYY')),
                shiftId: this.props.shiftId
            });
            this.loadData();
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col">
                        {
                            this.state.loading &&
                            <div className={"m-3"}>Зареждам данните ...</div>
                        }
                    </div>
                </div>

                {
                    this.state.repairs?.length > 0 &&
                    <>
                        <BlockTitleComponent title={(this.props.report || "Извършени ремонти") + " - продължителност по дни и по отдели"}/>
                        <table className={"table"}>
                            <thead>
                            <tr>
                                <th>Дата</th>
                                {
                                    $enum(REPAIR_TYPE).map(
                                        item => <th key={item} style={{textAlign: "right"}}>{item}</th>
                                    )
                                }
                            </tr>
                            </thead>
                            <tbody>
                            <tr className={"bg-light"}>
                                <td>Всичко</td>
                                {
                                    Array.from(this.state.sum.values()).map(
                                        (v: any, idx: any) => <td key={idx} align={"right"}>{minutesToHours(v, "ч.")}</td>
                                    )
                                }
                            </tr>
                            {
                                Array.from(this.state.totals.keys()).map(
                                    (k: string) => <tr>
                                        <td>{k}</td>
                                        {
                                            $enum(REPAIR_TYPE).map(
                                                item => <td align={"right"}>{minutesToHours(this.state.totals.get(k).get(item), "ч.")}</td>
                                            )
                                        }
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </>

                }


                <BlockTitleComponent title={(this.props.report || "Извършени ремонти") + " - подробен списък"}/>
                {
                    this.state.repairs?.length > 0 ?
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Отдел</th>
                                <th>Дата</th>
                                <th>От час</th>
                                <th>До час</th>
                                <th>Коментар</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.repairs.sort(
                                    (i1, i2) => {
                                        const _i1 = moment(i1.report_date, 'DD.MM.YYYY');
                                        const _i2 = moment(i2.report_date, 'DD.MM.YYYY');

                                        return _i1.isBefore(_i2) ? -1 :
                                            (_i1.isAfter(_i2) ? 1 : 0);
                                    }
                                ).map(
                                    (r: any, idx: number) => {
                                        return <tr key={idx}>
                                            <td>{$enum(REPAIR_TYPE).getValueOrDefault(r.type)?.toLocaleUpperCase()}</td>
                                            <td>{r.report_date}</td>
                                            <td>{r.fromTime}</td>
                                            <td>{r.toTime}</td>
                                            <td>{r.comments}</td>
                                        </tr>
                                    }
                                )
                            }
                            </tbody>
                        </table>
                        :
                        <div className={"w-100"}>
                            Няма извършени ремонти.
                        </div>
                }
            </>
        );
    }
}
