import {Report, PropertyValue, REPAIR_TYPE} from "../../types";
import React from "react";
import {BlockTitleComponent} from "../common/BlockTitleComponent";
import {getShiftName, getTypeName} from "../../common";
import {$enum} from "ts-enum-util";

export interface ViewRefuelingReportProperties {
    report: Report,
    shifts: PropertyValue[],
    fadromi: PropertyValue[],
    motocari: PropertyValue[],
}

interface RefuelingReportState {
}

export class ViewRefuelingReportComponent extends React.Component<ViewRefuelingReportProperties, RefuelingReportState> {

    constructor(props: ViewRefuelingReportProperties) {
        super(props);
        this.state = {}
    }


    render() {
        const rd = JSON.parse(this.props.report.request_data);

        return (
            <>
                <div className="card shadow">
                    <div className={"card-body"}>
                        <div className="form-row mb-3">
                            <div className="col">
                                Дата:<br/><strong>{this.props.report.base_info.report_date}</strong>
                            </div>
                            <div className="col">
                                {/*Смяна:<br/><strong>{getShiftName(this.props.shifts, this.props.report.base_info.shift_id)}</strong>*/}
                            </div>
                            <div className="col">
                                {/*Брой служители:<br/><strong>{this.props.report.base_info.man_cnt}</strong>*/}
                            </div>
                            <div className="col-auto">
                                {/*Попълнил отчета:<br/><strong>{this.props.report.user.name}</strong>*/}
                            </div>
                        </div>
                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Фадроми (литри)"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        {
                                            this.props.fadromi.map(
                                                i => <th key={i.id}>{i.value}</th>
                                            )
                                        }
                                        </thead>
                                        <tbody>
                                        {
                                            this.props.fadromi.map(
                                                i => <td key={i.id} align={"right"}>{rd['fadroma_' + i.id] || ''}</td>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Мотокари (бутилки)"}/>
                                <div className={"row ml-2 mr-2"}>
                                    <table className={"table-sm table-bordered w-100"}>
                                        <thead>
                                        {
                                            this.props.motocari.map(
                                                i => <th key={i.id}>{i.value}</th>
                                            )
                                        }
                                        </thead>
                                        <tbody>
                                        {
                                            this.props.motocari.map(
                                                i => <td key={i.id} align={"right"}>{rd['motocar_' + i.id] || ' '}</td>
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="form-row mb-3">
                            <div className="col-12 m-0 p-0">
                                <BlockTitleComponent title={"Бележки към отчета"}/>
                                <div>
                                    { rd.comments || "Няма бележки към отчета" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
