import React from "react";
import {getShiftName} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PropertyValue, Report} from "../../types";
import {API} from "../../api";

export interface BlockTitleProps {
    alwaysEdit?: boolean,
    working: boolean,
    reports: Report[],
    shifts: PropertyValue[],
    editReport: any,
    deleteReport: any,
}

export const ReportsList = function (props: BlockTitleProps) {
    return (
        props.working ? null :
            <>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>Смяна</th>
                        <th>Брой служители</th>
                        <th>Попълнил отчета</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.reports?.length === 0 ?
                            <>
                                <tr>
                                    <td colSpan={4}>Няма отчети за тази дата.</td>
                                </tr>
                            </>
                            :
                            <>
                                {
                                    props.reports.map(
                                        (m, idx) => <tr key={idx}>
                                            <td>{getShiftName(props.shifts, m.base_info?.shift_id)}</td>
                                            <td>{m.base_info.man_cnt}</td>
                                            <td>{m.user.name}</td>
                                            <td align={"right"}>
                                                {
                                                    (m.user.id === API.user?.id || API.user?.is_admin === 'Y' || props.alwaysEdit) &&
                                                    <>
                                                        <button type={"button"} className={"btn btn-secondary btn-sm"}
                                                                onClick={() => {
                                                                    props.editReport(m)
                                                                }}>
                                                            <FontAwesomeIcon icon={"pen"}/>
                                                        </button>
                                                        &nbsp;
                                                        <button type={"button"} className={"btn btn-secondary btn-sm"}
                                                                onClick={() => props.deleteReport(m)}>
                                                            <FontAwesomeIcon icon={"trash-alt"}/>
                                                        </button>
                                                        &nbsp;
                                                    </>
                                                }

                                                <button type={"button"} className={"btn btn-secondary btn-sm"}
                                                        onClick={() => {
                                                            props.editReport(m, true)
                                                        }}>
                                                    <FontAwesomeIcon icon={"eye"}/>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            </>
                    }
                    </tbody>
                </table>
            </>
    )
}
