import React, {useState} from "react";
import {City, Client} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export interface ClientsListProps {
    loading: boolean;
    clients: Client[];
    onEditClient: (client: Client) => any;
    onViewClientAccount: (client: Client) => any;
    onRemoveClient: (client: Client) => any;
    onSelect?: (client: Client, city: City) => any;
}

export function ClientsList(props: ClientsListProps) {

    const renderData = function () {

        if (props.clients.length == 0) return <tr>
            <td colSpan={5}><strong>Няма намерени клиенти.</strong></td>
        </tr>;

        return props.clients.map(
            item => <tr key={item.id}>
                <td>{item.name}</td>
                <td>
                    <ul className={"nav nav-pills nav-stacked"}>
                        {item.cities.map((i, idx) => <li key={idx}>
                            <a className={"btn btn-outline-secondary btn-sm"}
                               onClick={() => {
                                   if (props.onSelect) props.onSelect(item, i);
                               }}>
                                {i.city} ({i.phone})
                            </a>
                        </li>)}
                    </ul>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-2 " + (item.balance_avans_bank < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_avans_bank || '0.00'}
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-2 " + (item.balance_avans_cash < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_avans_cash || '0.00'}
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-2 " + (item.balance_credit < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_credit || '0.00'}
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-1 " + (item.balance_bank < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_bank || '0.00'}
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-1 " + (item.balance_cash < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_cash || '0.00'}
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-1 " + (item.balance_logistics < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_logistics || '0.00'}
                    </div>
                </td>
                <td>
                    <div className={"p-1 w-100 text-end text-light mr-1 " + (item.balance_palet < 0 ? "bg-danger" : "bg-success")}>
                        {item.balance_palet || 0}
                    </div>
                </td>
                <td className={"text-end"}>
                    <span className="btn btn-sm btn-primary btn-light" onClick={() => props.onViewClientAccount(item)}><FontAwesomeIcon icon="hand-holding-usd"/></span> &nbsp;
                    <span className="btn btn-sm btn-primary btn-light" onClick={() => props.onEditClient(item)}><FontAwesomeIcon icon="pen"/></span> &nbsp;
                    <span className="btn btn-sm btn-primary btn-light" onClick={() => props.onRemoveClient(item)}><FontAwesomeIcon icon="trash-alt"/></span>
                </td>
            </tr>
        );
    }

    return (
        <>
            {
                props.loading ?
                    <tr>
                        <td colSpan={5}>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    :
                    renderData()
            }
        </>
    );
}
