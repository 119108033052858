import React from 'react';
import {HeaderComponent} from './HeaderComponent';
import {FooterComponent} from './FooterComponent';
import {ToastContainer} from 'react-toastify';

interface LayoutProps {
    children?: React.ReactNode;
    tabs?: Array<string>;
    activeTab?: number;
}

export default function LayoutComponent(props: LayoutProps) {
    return (
        <>
            <nav className="page-header navbar fixed-top navbar-light text-light d-print-none"
                 style={{backgroundColor: "black", height: "90px"}}>
                <div className="container-fluid" style={{height: "62px", borderBottom: "solid 3px #FFF"}}>
                    <HeaderComponent {...props} />
                </div>
                <div style={{
                    height: "5px",
                    width: "100%",
                    backgroundColor: "#000",
                    borderBottom: "solid 3px #bb332c",
                    marginTop: "5px"
                }}>
                </div>
            </nav>

            <div className="page-content">
                {props.children}
            </div>

            {/*<nav className="page-footer navbar fixed-bottom navbar-light text-light"*/}
            {/*     style={{backgroundColor: "black"}}>*/}
            {/*    <div className="container-fluid">*/}
            {/*        <FooterComponent/>*/}
            {/*    </div>*/}
            {/*</nav>*/}

            <ToastContainer/>
        </>
    );
}
