import { Button, CloseButton, Col, Dropdown, FormSelect, InputGroup, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../api";
import {
    isError,
    isLoading,
    isSuccess,
    mapToObject,
    objectToMap,
    showErrorsInToast,
    showMsgInToast,
    stringToCurrency,
    translateError
} from "../../common";
import { API_CALL, MONTHS_NAMES } from "../../types";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { BonusConfigComponent } from "./BonusConfigComponent";
import { BonusCurrentComponent } from "./BonusCurrentComponent";
import moment from "moment";
import { ConfirmDeleteModal } from "../../common/ConfirmDeleteModal";
import ModalBackdrop from "../../common/ModalBackdrop";
import {BonusExclusionsComponent, ExclusionItem} from "./BonusExclusionsComponent";

export interface MessageRecipient {
    id: number;
    user: string;
}

export interface ReportItem {
    shift: string;
    comment: string;
    p1: number,
    p2: number,
    p3: number,
    p6: number,
    p4: number,
    pGrinding: number,
    pExtruder: number,
    pHeater: number,
    pOrdering: number,
    pOven: number,
    pUnloading: number,
    v1: number,
    v2: number,
    v3: number,
    v6: number,
    vGrinding: number,
    v4: number,
    vExtruder: number,
    vHeater: number,
    vOrdering: number,
    vOven: number,
    vUnloading: number,
    bonus: number,
    minPerc: number,
    isArchived: number,
}

export interface BonusUser {
    id: number,
    name: string,
    position: string,
    config: Map<string, string>,
    maxBonus: number,
    minBonus: number,
}

export interface Report {
    id: number,
    name: string,
    position: string,
    report: any,
    maxBonus: number,
    minBonus: number,
}

export const BONUS_ADMIN_USERS = [50, 53, 55];

export default function BonusesControlModal({ visible, onHide }: { visible: boolean; onHide: () => any; }) {
    const [activeKey, setActiveKey] = useState("overview");
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false)
    const [newData, setNewData] = useState<Array<BonusUser>>([]);
    const [month, setMonth] = useState((moment().month()).toString());
    const [year, setYear] = useState(moment().year().toString());
    const [yearMonth, setYearMonth] = useState(month + year);
    const [archBtnLabel, setArchBtnLabel] = useState('Архивирай отчета');
    const [archBtnSavingLabel, setArchBtnSavingLabel] = useState('Архивирам отчета...');
    const [dialogConfirmArchVisible, setDialogConfirmArchVisible] = useState(false);

    const [days, setDays] = useState<string[]>([]);
    const [items, setItems] = useState<ReportItem[]>([]);
    const [current, setCurrent] = useState<ReportItem | null>(null);
    const [reports, setReports] = useState<Array<Report>>([]);
    const [users, setUsers] = useState<Array<BonusUser>>([]);
    const [copyData, setCopyData] = useState<Array<BonusUser>>([]);

    const [exclusions, setExclusions] = useState<Array<ExclusionItem>>([]);

    const API_PATH_CONFIG = 'admin/bonuses/config';
    const API_PATH_CURRENT = 'admin/bonuses/current';
    const API_PATH_EXCLUSIONS = 'admin/bonuses/exclusions';

    let yearsList = [];
    for (let i = 0; i < 3; i++) yearsList.push((moment().year() - i).toString());

    const loadConfig = () => {
        setLoading(true);
        API.doGetRequest(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    if (apiCall.data.data.users) {
                        const uList = apiCall.data.data.users.map(
                            (u: any) => {
                                return {
                                    id: u.user.id,
                                    name: u.user.name,
                                    position: u.user.position,
                                    minBonus: stringToCurrency(u.min_bonus) || 0,
                                    maxBonus: stringToCurrency(u.max_bonus) || 0,
                                    config: objectToMap(JSON.parse(u.bonus_config))
                                } as BonusUser
                            }
                        )
                        setUsers(uList);
                        setNewData([]);
                    } else setUsers([]);
                }
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError)
            }, API_PATH_CONFIG + '/' + (parseInt(month) + 1).toString() + '/' + year, {}
        );
    }

    const loadData = () => {
        setLoading(true);
        API.doGetRequest(
            (apiCall: API_CALL) => {
                if (isSuccess(apiCall)) {
                    if (apiCall.data.data.reports) {
                        setReports(apiCall.data.data.reports.map(
                            (r: any) => {
                                return {
                                  ...r
                                } as Report
                            }
                        ));
                    } else setReports([]);

                    loadConfig()
                    loadExclusions();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка', translateError)
                }
            }, API_PATH_CURRENT + '/' + (parseInt(month) + 1).toString() + '/' + year, {}
        );
    }

    useEffect(() => {
        if (year && month) loadData()
    }, [year, month]);

    function saveConfig() {
        if (newData && !saving) {
            const data = newData.map(i => { return {...i, config: mapToObject(i.config)}});

            API.doPostRequest(
                (apiCall: API_CALL) => {
                    setSaving(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        showMsgInToast('Параметрите са запазени усешно!');
                        loadData();
                    }
                    if (isError(apiCall)) {
                        showErrorsInToast(apiCall, 'Възникна грешка:', translateError)
                    }
                }, API_PATH_CONFIG + '/' + (parseInt(month) + 1).toString() + '/' + year, data
            )
        }
    }

    function archiveReport() {
        if (current) {
            current.isArchived = 1 - current.isArchived;
            API.doGetRequest(
                (apiCall: API_CALL) => {
                    setSaving(isLoading(apiCall));
                    if (isSuccess(apiCall)) {
                        showMsgInToast('Отчета е ' + (current?.isArchived === 1 ? 'архивиран' : 'разархивиран') + ' успешно!');
                        setCurrent({ ...current });
                        setDialogConfirmArchVisible(false);
                    }
                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError)
                }, API_PATH_CURRENT + '&archive=' + current?.isArchived, {}
            );
        }
    }


    useEffect(() => {
        if (current?.isArchived === 1) {
            setArchBtnLabel('Разархивирай');
            setArchBtnSavingLabel('Разархивирам...');
        } else {
            setArchBtnLabel('Архивирай');
            setArchBtnSavingLabel('Архивирам...');
        }
    }, [current]);

    function loadReports() {
        setYearMonth('');
    }

    useEffect(() => {
        if (yearMonth === '') setYearMonth(month + year)
    }, [yearMonth])

    function copyConfig() {
        setCopyData(users);
    }


    function postConfig() {
        setUsers(copyData);
    }

    function loadExclusions() {
        API.doGetRequest(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    const ex: Array<ExclusionItem> = apiCall.data.data.exclusions || [];

                    let firstDate = moment('01.' + (parseInt(month)+1).toString() + '.' + year, 'DD.MM.YYYY').startOf("day");
                    const lastDate = moment(firstDate).endOf("month");
                    while(firstDate <= lastDate) {
                        const fd = firstDate.format('DD.MM.YYYY');
                        const item = ex.find(e => e.forDate == fd);
                        if(!item) {
                            ex.push({
                                forDate: fd, hasEmergency: 0, hasMultipleBricks: 0
                            })
                        }
                        firstDate = firstDate.add(1, "day");
                    }

                    setExclusions(ex.sort(
                        (e1, e2) => e1.forDate.localeCompare(e2.forDate)
                    ));
                }
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError)
            }, API_PATH_EXCLUSIONS + '/' + (parseInt(month) + 1).toString() + '/' + year, {}
        );
    }

    function saveExclusions() {
        const data: Array<ExclusionItem> = exclusions.filter(
            e => e.hasMultipleBricks === 1 || e.hasEmergency === 1
        );

        API.doPostRequest(
            (apiCall: API_CALL) => {
                setSaving(isLoading(apiCall));
                if (isSuccess(apiCall)) {
                    showMsgInToast('Изключенията са запазени усешно!');
                    loadData();
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, 'Възникна грешка:', translateError)
                }
            }, API_PATH_EXCLUSIONS + '/' + (parseInt(month) + 1).toString() + '/' + year, {exclusions: data}
        )
    }

    return (
        <>
            <Modal animation={true} show={visible} className={"modal-90"}>
                <Modal.Header>
                    <Modal.Title>
                        <FontAwesomeIcon icon={faCoins} /> Бонус
                    </Modal.Title>
                    <Row>
                        <Col style={{ fontSize: 14, textAlign: "right" }}>
                            <Tabs variant="pills" activeKey={month}
                                onSelect={(k) => setMonth(k || '0')}
                            >
                                {
                                    MONTHS_NAMES.map(
                                        (m, i) => <Tab title={m} eventKey={i} key={i} />
                                    )
                                }
                            </Tabs>
                        </Col>
                        <Col xs="auto">
                            <FormSelect className="form-control w-100px" value={year}
                                onChange={(e) => setYear(e.currentTarget.value)}
                            >
                                {yearsList.map(y =>
                                    <option value={y} key={y}>{y}</option>)}
                            </FormSelect>
                        </Col>
                        <Col xs="auto">
                            <CloseButton onClick={() => onHide()} style={{ height: 30 }} />
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={activeKey}
                        onSelect={k => setActiveKey(k || 'overview')}
                    >
                        <Tab eventKey={"overview"} title={"Текущо състояние"}>
                            {
                                activeKey === "overview" && yearMonth !== '' && !loading &&
                                <BonusCurrentComponent reports={reports}/>
                            }
                        </Tab>
                        {
                            BONUS_ADMIN_USERS.indexOf(API.user?.id || -1) > -1 &&
                            <Tab eventKey={"config"} title={"Параметри"}>
                                <BonusConfigComponent users={users} onChangeData={(newData) => setNewData(newData)}/>
                            </Tab>
                        }
                        {
                            [...BONUS_ADMIN_USERS, 4].indexOf(API.user?.id || -1) > -1 &&
                            <Tab eventKey={"exclusions"} title={"Изключения"}>
                                <BonusExclusionsComponent  exclusions={exclusions} onChangeData={(newExcl) => setExclusions(newExcl)} />
                            </Tab>
                        }
                    </Tabs>

                    {
                        loading && <div className={"m-3"}>Зареждам данните ...</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        <Row className={"w-100"}>
                            {
                                activeKey === "config" &&
                                <Col>
                                    <Button variant={"outline-secondary"} onClick={copyConfig}>
                                        Копирай
                                    </Button>&nbsp;
                                    {
                                        copyData && copyData.length > 0 &&
                                        <Button variant={"outline-secondary"} onClick={postConfig}>
                                            Постави
                                        </Button>
                                    }
                                </Col>
                            }
                            <Col className={"text-end"}>
                                {
                                    activeKey === "config" &&
                                    <Button variant={"primary"} onClick={saveConfig}>
                                        {
                                            !saving ? 'Запази параметрите' : 'Запазвам промените...'
                                        }
                                    </Button>
                                }
                                {
                                    activeKey === "exclusions" &&
                                    <Button variant={"primary"} onClick={saveExclusions}>
                                        {
                                            !saving ? 'Запази изключенията' : 'Запазвам изключенията...'
                                        }
                                    </Button>
                                }
                                &nbsp;<Button variant={"secondary"} onClick={onHide}>
                                    Затвори
                                </Button>
                            </Col>
                        </Row>
                    }

                </Modal.Footer>
            </Modal>

            {
                dialogConfirmArchVisible &&
                <ModalBackdrop>
                    <ConfirmDeleteModal msg={'Моля, потвърдете ' + (current?.isArchived === 1 ? 'разархивирането' : 'архивирането.')} working={saving} confirmLabel={current?.isArchived === 1 ? 'Разархивирай' : 'Архивирай'}
                        onConfirm={() => archiveReport()}
                        onReject={() => setDialogConfirmArchVisible(false)}
                    />
                </ModalBackdrop>
            }
        </>
    )
}
