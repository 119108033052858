import {ClientsPage} from "../../pages/ClientsPage";
import React from "react";
import {City, Client} from "../../types";

export interface SelectClientProps {
    filter: Map<string, any>;
    onSelect: (client: Client, city: City) => any;
    onClose: () => any;
}

export function SelectClient({filter, onSelect, onClose} : SelectClientProps) {
    return <>
        <div style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%",
            zIndex: 9998, backgroundColor: "gray", opacity: "0.5"
        }}/>

        <div className={"p-3"} style={{
            position: "fixed", top: "0", left: "0", width: "100%", height: "100%",
            zIndex: 9998, opacity: "1"
        }}>
            <ClientsPage
                filter={filter}
                onSelect={onSelect}
                onClose={onClose}
            />
        </div>
    </>
}
