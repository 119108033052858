import {Button, CloseButton, Modal, Tab, Table, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faMailBulk, faPaperPlane, faPen, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import MessageContentModal from "./MessageContentModal";
import {Message} from "./UserMessagesComponent";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, showMsgInToast, translateError} from "../../common";
import {API_CALL} from "../../types";
import ModalBackdrop from "../../common/ModalBackdrop";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";

export interface MessageRecipient {
    id: number;
    user: string;
}

export default function MessageControlModal({visible, onHide}: { visible: boolean; onHide: () => any; }) {

    const [activeKey, setActiveKey] = useState("received");
    const [loading, setLoading] = useState(0);
    const [received, setReceived] = useState<Array<Message> | null>(null);
    const [sended, setSended] = useState<Array<Message> | null>(null);
    const [recipients, setRecipients] = useState<Array<MessageRecipient>>([]);
    const [editMessage, setEditMessage] = useState<Message | null>(null);
    const [removeMessage, setRemoveMessage] = useState<Message | null>(null);

    const loadMessages = () => {
        API.getReceivedMessages(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) setLoading(1);
                if (isSuccess(apiCall)) {
                    setLoading(0);
                    setReceived(apiCall.data.data.messages);
                }
                if(isError(apiCall)) setReceived(null);
            }
        )
        API.getSendedMessages(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) setLoading(1);
                if (isSuccess(apiCall)) {
                    setLoading(0);
                    setSended(apiCall.data.data.messages);
                }
                if(isError(apiCall)) setSended(null);
            }
        )
        API.getAllUsersNotSigned(
            (apiCall: API_CALL) => {
                if(isSuccess(apiCall)) {
                    setRecipients(apiCall.data.data.users.sort(
                        (u1: MessageRecipient, u2: MessageRecipient) =>
                            u1.user > u2.user ? 1 :
                                (u1.user < u2.user ? -1 : 0))
                        .filter((u: MessageRecipient) => u.user !== 'DEV')
                    );
                }
            }
        )
    }

    useEffect(() => {
        loadMessages()

        const timer = window.setInterval(() => {
            loadMessages()
        }, 30000);
        return () => {
            window.clearInterval(timer);
        };
    }, [])

    function removeMail(m: Message, showToast = true) {
        API.removeMessage(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall) ? 1 : 0);
                if(isSuccess(apiCall)) {
                    loadMessages();
                    if(showToast) showMsgInToast('Съобщението е изтрито успешно!');
                    setRemoveMessage(null);
                }
                if(isError(apiCall)) showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
            }, m.message_id || -1
        );
    }

    function viewMail(m: Message) {
        API.readMessage((apiCall: API_CALL) => {}, m.id || -1);
        m.is_readed = 1;
        setEditMessage(m);
    }

    function editMail(m: Message) {
        setEditMessage(m);
    }

    function saveMessage() {
        if(editMessage?.message_id) {
            removeMail(editMessage, false);
        }

        API.sendMessage(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall) ? 1 : 0);
                if(isSuccess(apiCall)) {
                    setEditMessage(null);
                    loadMessages();
                    showMsgInToast('Съобщението е изпратено успешно!');
                }
                if(isError(apiCall)) showErrorsInToast(apiCall, 'ГРЕШКА', translateError);
            }, editMessage?.message.title || '', editMessage?.message.content || '', editMessage?.receiver_id || -1
        );
    }

    function newMessage() {
        setEditMessage(
            {
                sender_id: API.user?.id,
                message: {
                    title: '',
                    content: '',
                }
            } as Message
        )
    }

    const _received = received?.filter(m => m.is_deleted === 0) || [];

    return (
        <>
            <Modal size={"xl"} animation={true} show={visible}>
                { editMessage && <ModalBackdrop /> }
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faMailBulk}/> Съобщения</Modal.Title>
                    <CloseButton onClick={() => onHide()}/>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={activeKey}
                        onSelect={k => setActiveKey(k || 'received')}
                    >
                        <Tab eventKey={"received"} title={"Получени съобщения"}>
                            {
                                received && <>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Дата</th>
                                            <th>Изпратено от</th>
                                            <th>Тема</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            _received.length === 0 &&
                                            <tr>
                                                <td colSpan={4}>Няма получени съобщения</td>
                                            </tr>
                                        }
                                        {
                                            _received
                                                .filter(m => m.is_deleted === 0)
                                                .map(
                                                m => <tr key={m.id} className={m.is_readed === 0 ? "fw-bold" : ""}>
                                                    <td>{m.message.send_date}</td>
                                                    <td>{m.sender?.name}</td>
                                                    <td>{m.message.title}</td>
                                                    <td className={"text-end"}>
                                                        <Button onClick={() => viewMail(m)} size={"sm"}>
                                                            <FontAwesomeIcon icon={faEye}/>
                                                        </Button>&nbsp;
                                                        <Button onClick={() => setRemoveMessage(m)} size={"sm"}>
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </>
                            }

                        </Tab>
                        <Tab eventKey={"sended"} title={"Изпратени съобщения"}>
                            {
                                sended && <>
                                    <Table>
                                        <thead>
                                        <tr>
                                            <th>Дата</th>
                                            <th>Изпратено до</th>
                                            <th>Тема</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            sended.length === 0 &&
                                            <tr>
                                                <td colSpan={4}>Няма изпратени съобщения</td>
                                            </tr>
                                        }
                                        {
                                            sended.map(
                                                m => <tr key={m.id}>
                                                    <td>{m.message?.send_date}</td>
                                                    <td>{m.receiver?.name}</td>
                                                    <td>{m.message?.title}</td>
                                                    <td className={"text-end"}>
                                                        <Button onClick={() => editMail(m)} size={"sm"}>
                                                            <FontAwesomeIcon icon={faPen}/>
                                                        </Button>&nbsp;
                                                        <Button onClick={() => setRemoveMessage(m)} size={"sm"}>
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        </tbody>
                                    </Table>
                                </>

                            }
                        </Tab>
                    </Tabs>

                    {
                        loading > 0 && <div className={"m-3"}>Зареждам данните ...</div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => newMessage()}>
                        <FontAwesomeIcon icon={faPaperPlane} />&nbsp;Изпрати съобщение
                    </Button>&nbsp;
                    <Button variant={"secondary"} onClick={onHide}>
                        Затвори
                    </Button>
                </Modal.Footer>
            </Modal>


            <MessageContentModal message={editMessage} onChange={(m) => { setEditMessage(m) }}
                                 onSave={editMessage && editMessage.sender_id == API.user?.id ? saveMessage : undefined}
                                 working={loading > 0}
                                 recipients={recipients}
            />

            {
                removeMessage &&
                <ConfirmDeleteModal working={loading > 0} onConfirm={() => removeMail(removeMessage)}
                                    onReject={() => setRemoveMessage(null)} />
            }
        </>
    )
}
