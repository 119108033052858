import React from "react";
import {API_CALL, BaseReportInfo, PropertyValue, Report} from "../../types";
import {TimePickerComponent} from "./TimePickerComponent";
import {ReadOnlyDatePickerComponent} from "./ReadOnlyDatePickerComponent";
import moment from "moment";
import {isLoading, isSuccess, mapToObject, showErrorsListInToast} from "../../common";
import {API} from "../../api";

export interface ReportBaseInfoComponentProps {
    reportInfo: BaseReportInfo;
    shifts: PropertyValue[];
    reportName: string;
    reportId: number;
    addMiddleShift?: boolean;
    hideManCount?: boolean;
    onChange: any;
}

interface ReportBaseInfoComponentState {
    formValues: Map<string, any>;
    showFromPicker: boolean;
    showToPicker: boolean;
    working: boolean;
}

export class ReportBaseInfoComponent extends React.Component<ReportBaseInfoComponentProps, ReportBaseInfoComponentState> {
    constructor(props: any) {
        super(props);

        this.state = {
            formValues: new Map<string, any>(),
            showFromPicker: false,
            showToPicker: false,
            working: false,
        }

        this.state.formValues.set('report_date', this.props.reportInfo?.report_date);
        this.state.formValues.set('shift_id', this.props.reportInfo?.shift_id);
        this.state.formValues.set('from_time', this.props.reportInfo?.from_time);
        this.state.formValues.set('to_time', this.props.reportInfo?.to_time);
        this.state.formValues.set('man_cnt', this.props.reportInfo?.man_cnt);

        this.handleFormChange = this.handleFormChange.bind(this);
        this.validate = this.validate.bind(this);
        this.validate(this.state.formValues);
    }

    handleFormChange(event: any) {
        const fv = new Map<string, any>(this.state.formValues);
        if (event) {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            const name = target.name;

            fv.set(name, value);
            this.validate(fv);
        }
    }

    validate(fv: Map<string, any>) {
        API.getReportCount(
            (apiCall: API_CALL) => {
                this.setState({working: isLoading(apiCall)});

                if (isSuccess(apiCall)) {
                    if(apiCall.data.data.count == 0) {
                        this.setState({formValues: fv});
                        this.props.onChange(mapToObject(fv));
                    } else {
                        showErrorsListInToast('Внимание', ['Вече има отчет за избраните дата и смяна.']);
                    }
                }
            }, this.props.reportId, this.props.reportName, fv.get('report_date'), fv.get('shift_id')
        )
    }

    render() {
        return (
            <form className="form-inline mb-3">
                <div className={"row w-100 mr-0"}>
                    <div className={"col-3"}>
                        <label className={"justify-content-start"}>Отчет за дата:</label>

                        <ReadOnlyDatePickerComponent
                            value={this.state.formValues.get("report_date")}
                            onChange={
                                (newDate: any) => {
                                    const _date = (moment(newDate).format('DD.MM.YYYY'));
                                    const fv = new Map<string, any>(this.state.formValues);
                                    fv.set("report_date", _date);
                                    this.validate(fv);
                                }
                            }
                        />
                    </div>
                    <div className={"col-3"}>
                        <label className={"justify-content-start"}>Работна смяна:</label>
                        <select className="form-control w-100" name="shift_id" required
                                value={this.state.formValues?.get('shift_id')}
                                onChange={this.handleFormChange}>
                            <option value="-1">-- Изберете --</option>
                            {
                                this.props.shifts.map(
                                    (i: PropertyValue, idx: number) =>
                                        <option value={i.id} key={idx}>{i.value}</option>
                                )
                            }
                            {
                                this.props.addMiddleShift &&
                                <option value="99">МЕЖДИННА СМЯНА</option>
                            }
                        </select>
                    </div>
                    <div className={"col-4 row"}>
                        {
                            this.state.formValues.get("shift_id") == '99' &&
                            <>
                                <div className="col-6">
                                    <label className="form-label justify-content-start w-100 text-nowrap">
                                        Начало:</label>
                                    <input className="form-control w-100" type="text" name="from_time" readOnly={true}
                                           required
                                           value={this.state.formValues?.get('from_time')}
                                           onChange={this.handleFormChange}
                                           onClick={() => this.setState({showFromPicker: !this.state.showFromPicker, showToPicker: false})}
                                    />
                                    {
                                        this.state.showFromPicker && <TimePickerComponent
                                            onSelect={(time: string) => this.setState({showFromPicker: false, formValues: this.state.formValues.set('from_time', time)})}
                                            value={this.state.formValues?.get('from_time')}/>
                                    }
                                </div>
                                <div className={"col-6"}>
                                    <label className="form-label justify-content-start w-100 text-nowrap">
                                        Край:</label>
                                    <input className="form-control w-100" type="text" name="to_time" readOnly={true}
                                           required
                                           value={this.state.formValues?.get('to_time')}
                                           onChange={this.handleFormChange}
                                           onClick={() => this.setState({showToPicker: !this.state.showToPicker, showFromPicker: false})}
                                    />
                                    {
                                        this.state.showToPicker && <TimePickerComponent
                                            onSelect={(time: string) => this.setState({showToPicker: false, formValues: this.state.formValues.set('to_time', time)})}
                                            value={this.state.formValues?.get('to_time')}/>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    <div className={"col-2"}>
                        {
                            !this.props.hideManCount &&
                            <>
                                <label className="form-label justify-content-start w-100 text-nowrap">
                                    Брой служители:</label>
                                <input className="form-control" type="number" name="man_cnt"
                                       required
                                       value={this.state.formValues?.get('man_cnt')}
                                       onChange={this.handleFormChange}
                                />
                            </>
                        }
                    </div>
                    {
                        this.state.working &&
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                </div>
            </form>
        )
    }
}
