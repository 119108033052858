import {Col, Row} from "react-bootstrap";
import MediaScreenCard from "./MediaScreenCard";
import React, {useEffect, useState} from "react";
import {Account} from "../../types";
import moment from "moment";
import {Animated} from "react-animated-css";
import {StatusSwitchTabInterval} from "../../App";

export interface StatusAccountCardProps {
    position: number;
    user: Account | null;
}

export default function StatusAccountCard({position, user}: StatusAccountCardProps) {
    const [inAnim, setInAnim] = useState(false);
    const [ready, setReady] = useState(false);
    const src = 'http://erp.taifun-bg.com/api/user/' + user?.id + '/photo';

    window.setTimeout(() => setInAnim(false), StatusSwitchTabInterval - 1000 - position * 250)
    return (
        <>
            <img
                style={{display: 'none'}}
                src={src}
                onLoad={() => {
                    window.setTimeout(() => {
                        setInAnim(true)
                        setReady(true)
                    }, position * 250)
                }}
            />

            {
                user && ready &&
                <Animated animationIn="fadeInDown" animationOut="fadeOutDown" isVisible={inAnim}>
                    <MediaScreenCard>
                        {/*<div className={"media-screen-card-content-header"}>*/}
                        {/*    <Row className={"fw-bold"}>*/}
                        {/*        <Col className={"text-end"}>#{position}</Col>*/}
                        {/*    </Row>*/}
                        {/*</div>*/}
                        <div>
                            <Row>
                                <Col className={"text-center col-5 d-block"}>
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            background: "url(" + src + ")",
                                            backgroundSize: "cover",
                                            // backgroundPosition: "center",
                                            // backgroundPositionY: "30px",
                                            backgroundRepeat: "no-repeat",
                                            display: "block"
                                        }}>
                                    </div>

                                    {/*<img src={"http://erp.taifun-bg.com/api/user/"+user.id+"/photo?rnd=61"} style={{width: "100%", borderRadius: 5}}/>*/}
                                </Col>
                                <Col>
                                    <div style={{height: 150}}>
                                        <div style={{height: 90}}>
                                            {user.name.toUpperCase()}
                                        </div>
                                        <div>
                                            {user.position.toLowerCase()}
                                        </div>
                                        {
                                            user.first_date &&
                                            <div>
                                                {
                                                    moment().diff(moment(user.first_date, 'DD.MM.YYYY'), "years")
                                                }
                                                &nbsp;год. трудов стаж
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </MediaScreenCard>
                </Animated>
            }</>
    )
}