import {Card, Col, Row} from "react-bootstrap";
import {getShiftName} from "../../common";
import React from "react";
import {PropertyValue} from "../../types";

export default function StatusCardHeader({report, shifts} : {report: any; shifts: Array<PropertyValue>}) {
    return (
        <div className={"media-screen-card-content-header"}>
            <Row className={"fw-bold"}>
                <Col>{report.report_date}</Col>
                <Col className={"text-end"} >{getShiftName(shifts, report.shift_id)}</Col>
            </Row>
        </div>
    )
}