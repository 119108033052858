import {Modal, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Employee} from "./Form76Employees";
import {API_CALL} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError, zeroToEmptyString, zeroToEmptyStringAsCurrency} from "../../common";
import {Division} from "./Form76Divisions";
import {API} from "../../api";

export interface Form76EmployeeEditModalProps {
    d: Employee | null;
    onSave: (newValue: Employee) => any;
    onClose: any;
}


export function Form76EmployeeEditModal(props: Form76EmployeeEditModalProps) {

    const [loading, setLoading] = useState(false);
    const [divisions, setDivisions] = useState<Division[]>([]);

    const [division_id, setDivisionId] = useState<number>(props.d ? props.d.division_id : -1);
    const [name, setName] = useState<string>(props.d ? props.d.name : '');
    const [rate, setRate] = useState<number>(props.d ? props.d.rate : 0);
    const [rateNS, setRateNS] = useState<number>(props.d ? props.d.rateNS : 0);

    useEffect(() => {
        API.getForm76Divisions(
            (apiCall: API_CALL) => {
                setLoading(isLoading(apiCall));

                if (isError(apiCall)) showErrorsInToast(apiCall, 'Грешка', translateError);

                if (isSuccess(apiCall)) {
                    setDivisions(apiCall.data.data.divisions);
                    if (division_id === -1) setDivisionId(apiCall.data.data.divisions[0].id);
                }
            }
        );
    }, []);

    function saveValue() {
        props.onSave({...props.d, division_id: division_id, name: name, rate: rate, rateNS: rateNS} as Employee);
    }

    return (
        <Modal
            show={true}
            centered
            onHide={() => props.onClose()}
        >
            <Modal.Header closeButton>
                <div className={"row w-100"}>
                    <div className="col">
                        <Modal.Title>{props.d ? 'Kорекция' : 'Добавяне'} на служител</Modal.Title>
                    </div>
                </div>
            </Modal.Header>

            <div className="modal-body">

                <form>
                    <div className="mb-3">
                        <label className="form-label">Изберете отдел:</label><br/>
                        {
                            divisions.length > 0 &&
                            <select className="form-control"
                                    onChange={e => setDivisionId(Number(e.target.value))}>
                                {
                                    divisions.map(
                                        d => <option value={d.id} selected={d.id === division_id}>{d.name}</option>)
                                }
                            </select>
                        }
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Име на служителя:</label>
                        <input type="text" className="form-control" onChange={e => setName(e.target.value)}
                               value={name}/>
                    </div>
                    {
                        API.user?.is_owner === 'Y' &&
                        <div className="mb-3">
                            <label className="form-label">Часова ставка (Първа смяна):</label>
                            <input type="number" className="form-control" onChange={e => setRate(Number(e.target.value) || 0)}
                                   value={zeroToEmptyString(rate.toString())}/>
                        </div>
                    }
                    {
                        API.user?.is_owner === 'Y' &&
                        <div className="mb-3">
                            <label className="form-label">Часова ставка (Нощна смяна):</label>
                            <input type="number" className="form-control" onChange={e => setRateNS(Number(e.target.value) || 0)}
                                   value={zeroToEmptyString(rateNS?.toString())}/>
                        </div>
                    }
                </form>

            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => saveValue()}>Запази</button>
                <button type="button" className="btn btn-secondary" onClick={() => props.onClose()}>Откажи</button>
            </div>
        </Modal>
    )
}
