import {Card, Col, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import CardHeader from "react-bootstrap/CardHeader";
import {API} from "../../api";
import {API_CALL} from "../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import {CalendarEvent} from "../../pages/CalendarPage";
import moment from "moment/moment";

export interface StatusOneCalendarTaskProps {
    mode: "MACHINES" | "ADMINISTRATIVE";
}

export default function StatusOneCalendarTask(props: StatusOneCalendarTaskProps) {
    const cardTitle = props.mode === "MACHINES" ? "МАШИНИ И СЪОРЪЖЕНИЯ" : "АДМИНИСТРАТИВНИ ЗАДАЧИ";
    const [events, setEvents] = useState<CalendarEvent[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<CalendarEvent[]>([]);
    const [loadingEvents, setLoadingEvents] = useState(false);

    useEffect(() => {
        API.getCalendarEventsList(
            (apiCall: API_CALL) => {
                setLoadingEvents(isLoading(apiCall));

                if (isSuccess(apiCall)) {
                    const _events = apiCall.data.data.items.map((i: any) => {
                        return {
                            ...i, auto_renew: (i.auto_renew === 1)
                        }
                    });
                    // _events.splice(0, 5);
                    setEvents(_events);
                }
                if (isError(apiCall)) {
                    showErrorsInToast(apiCall, "Възникна грешка", translateError);
                }
            }, props.mode, false, {}
        )
    }, []);

    useEffect(() => {
        const fiftheenDays = moment().add(15, "days");
        if (events) {
            setFilteredEvents(events.filter(value => moment(value.plan_date!, 'DD.MM.YYYY').isBefore(fiftheenDays)));
        }
    }, [events])

    function getClasses(e: CalendarEvent) {
        if (!e) return "";

        if (e.status === "Започнато") return "bg-success text-light";

        if (e.plan_date && moment(e.plan_date, 'DD.MM.YYYY').toDate() <= new Date()) return "bg-danger text-light";

        if (e.alert_date && moment(e.alert_date, 'DD.MM.YYYY').toDate() <= new Date()) return "bg-warning";

        return "";
    }

    return <Col xs={12}>
        <Card bg={"light"} className={"h-100 mb-2 mt-1"}>
            {/*<CardHeader>*/}
            {/*    <h2>{ cardTitle }</h2>*/}
            {/*</CardHeader>*/}
            <Card.Body>
                <Table className={"fw-bold"}>
                    {
                        !loadingEvents && <>
                            {
                                filteredEvents.length > 0 ?
                                    filteredEvents.map(
                                        value => <tr key={value.id} className={getClasses(value)}
                                                     style={{fontSize: "28px", lineHeight: "56px"}}>
                                            <td> {value.status} </td>
                                            <td> {value.element.name} </td>
                                            <td> {value.executor.name} </td>
                                            <td> {value.comments} </td>
                                            <td> {value.plan_date} </td>
                                        </tr>
                                    )
                                    :
                                    <tr style={{fontSize: "38px", lineHeight: "56px"}}>
                                        <td className={"text-center"}>
                                            <strong>Няма предстоящи задачи за следващите 15 дена.</strong>
                                        </td>
                                    </tr>

                            }
                            </>
                    }
                </Table>
            </Card.Body>
        </Card>
    </Col>;
}
