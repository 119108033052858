import React from "react";
import {API_CALL} from "../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment, {Moment} from "moment";
import {API} from "../api";
import {Link} from "react-router-dom";
import {PATHS} from "../paths";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Form76Month} from "../components/form76/form76Month";
import {Form76Divisions} from "../components/form76/Form76Divisions";
import {Form76Employees} from "../components/form76/Form76Employees";

interface Form76PageProps {
    selectedDate?: Moment;
}

interface Form76PageState {
    activeTab: string,
}

export class Form76Page extends React.Component<Form76PageProps, Form76PageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            activeTab: 'tab0',
        }

        this.renderTabPanel = this.renderTabPanel.bind(this);
    }

    renderTabPanel() {
        return (
            <ul className="nav nav-pills">
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab0' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab0"})}
                       href="#">Форма 76</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab1' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab1"})}
                       href="#">Служители</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (this.state.activeTab === 'tab2' ? "active" : "")}
                       onClick={() => this.setState({activeTab: "tab2"})}
                       href="#">Отдели</a>
                </li>
            </ul>
        );
    }

    render() {
        return (
            <div className="container" style={{maxWidth: "100%"}}>

                <div className="card shadow">

                    <div className="card-header text-center row">
                        <div className="col-2 text-start">
                            <Link to={PATHS.home + API.getAPITokenAsQuery()}>
                                <span className="btn btn-secondary"><FontAwesomeIcon icon={faArrowLeft}/></span></Link>
                        </div>
                        <h2 className="col">Форма 76</h2>
                        <div className="col-auto">
                            {
                                this.renderTabPanel()
                            }
                        </div>
                    </div>

                    {this.state.activeTab === 'tab0' && <Form76Month/>}
                    {this.state.activeTab === 'tab1' && <Form76Employees/>}
                    {this.state.activeTab === 'tab2' && <Form76Divisions/>}

                </div>

            </div>
        );
    }

}
