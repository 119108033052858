import {Property, PropertyValue, PropertyDescriptor, PropertyDescriptorType, API_CALL} from "../../types";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {API} from "../../api";
import {isError, isLoading, isSuccess, mapToObject} from "../../common";


interface ValueEditProps {
    property: Property,
    value: PropertyValue,
    onSave: any
}

interface ValueEditState {
    formValues: Map<string, any>,
    working: boolean,
}

export class ValueEditModalComponent extends React.Component<ValueEditProps, ValueEditState> {

    constructor(props: ValueEditProps) {
        super(props);

        this.state = {
            formValues: new Map<string, any>(),
            working: false,
        }

        this.setState({formValues: this.state.formValues.set("value", props.value?.value)});
        if (Array.isArray(this.props.property.descriptor))
            this.props.property.descriptor.forEach(
                d => {
                    this.setState({formValues: this.state.formValues.set(d.name, props.value?.description[d.name] || "")});
                }
            )


        this.handleFormChange = this.handleFormChange.bind(this);
        this.notifyParent = this.notifyParent.bind(this);
        this.cancel = this.cancel.bind(this);
        this.save = this.save.bind(this);
    }

    handleFormChange(event: any) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState(
            prevState => {
                return {formValues: prevState.formValues.set(name, value)}
            }
        )
    }

    notifyParent(save: boolean) {
        if (!save) {
            if (this.props.onSave) {
                this.props.onSave(false);
            }
        } else {
            const data = this.state.formValues;
            if (data) {
                const me = this;
                const valId = me.props.value.id;
                const propId = me.props.property.id;

                // console.log({...mapToObject(data), propertyId: propId, id: valId}); return;

                API.setParameterValues(
                    (apiCall: API_CALL) => {
                        me.setState({'working': isLoading(apiCall)});
                        if (isSuccess(apiCall)) {
                            if (me.props.onSave) {
                                me.props.onSave(true);
                            }
                        }
                        if (isError(apiCall)) {
                            if (me.props.onSave) {
                                me.props.onSave(false);
                            }
                        }
                    },
                    {...mapToObject(data), propertyId: propId, id: valId}
                );
            }

        }
    }

    cancel() {
        this.notifyParent(false);
    }

    save() {
        if (this.state.formValues.get('value').trim().length === 0) {
            alert('Моля, въведете стойност');
        } else {
            this.notifyParent(true);
        }
    }

    renderInput(idx: number, d: PropertyDescriptor, t: string) {

        return (
            <div className="mb-3" key={idx}>
                <label className="form-label">{d.title}</label>
                <input type={t} className="form-control" name={d.name} onChange={this.handleFormChange} value={this.state.formValues.get(d.name)}/>
            </div>
        )
    }

    renderStringInput(idx: number, d: PropertyDescriptor) {
        return this.renderInput(idx, d, "text");
    }

    renderNumberInput(idx: number, d: PropertyDescriptor) {
        return this.renderInput(idx, d, "number");
    }

    renderBooleanInput(idx: number, d: PropertyDescriptor) {
        return (
            <div className="mb-3" key={idx}>
                <input type="checkbox" name={d.name} onChange={this.handleFormChange}
                       value={'Y'}
                       id={d.name}
                       checked={this.state.formValues.get(d.name) === "true" || this.state.formValues.get(d.name) === true}/>
                <label className="form-label" htmlFor={d.name}>&nbsp;{d.title}</label>
            </div>
        );
    }

    renderFormInput(idx: number, d: PropertyDescriptor) {
        switch (d.type) {
            case PropertyDescriptorType.string:
                return this.renderStringInput(idx, d);
            case PropertyDescriptorType.number:
                return this.renderNumberInput(idx, d);
            case PropertyDescriptorType.boolean:
                return this.renderBooleanInput(idx, d);
        }

        return (
            <>Not found!</>
        )
    }

    render() {
        return (
            <>
                <div className={"modal"} style={{display: "block"}}>
                    <div className="modal-dialog">
                        <div className="modal-content bg-light shadow">
                            <div className="modal-header">
                                <h5 className="modal-title">{this.props.property.name}</h5>
                                <span className={"pointer"} onClick={this.cancel}><FontAwesomeIcon icon="window-close"/></span>
                            </div>
                            <div className="modal-body">

                                <form>
                                    <div className="mb-3">
                                        <label className="form-label">Стойност</label>
                                        <input type="text" placeholder={"Нова стойност"} className="form-control" name={"value"} onChange={this.handleFormChange}
                                               value={this.state.formValues.get("value")}/>
                                    </div>
                                    {
                                        (Array.isArray(this.props.property.descriptor)) ?
                                            this.props.property.descriptor.map((d: PropertyDescriptor, idx: number) => this.renderFormInput(idx, d))
                                            :
                                            null
                                    }
                                </form>

                            </div>
                            <div className="modal-footer">
                                {
                                    this.state.working ?
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                        :
                                        <>
                                            <button type="button" className="btn btn-primary" onClick={this.save}>Запази</button>
                                            <button type="button" className="btn btn-secondary" onClick={this.cancel}>Откажи</button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
