import {Button, Table} from "react-bootstrap";
import React, {useState} from "react";
import {faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CalendarElement, CalendarEvent, CalendarExecutor} from "../../../pages/CalendarPage";
import {CalendarEventEditModal} from "./CalendarEventEditModal";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import {API_CALL, CALENDAR_MODE} from "../../../types";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../../common";
import {API} from "../../../api";
import moment from "moment";
import CalendarFilter, {CALENDAR_FILTER} from "../filter/CalendarFilter";

export interface CalendarEventsListProps {
    mode: CALENDAR_MODE;
    loading: boolean;
    events: CalendarEvent[];
    elements: CalendarElement[];
    executors: CalendarExecutor[];
    internals: CalendarExecutor[];
    onReload: () => any;
    currentFilter: CALENDAR_FILTER|null;
    onFilterChanged: (filter: CALENDAR_FILTER) => any;
}


export function CalendarEventsList({mode, loading, events, elements, executors, internals, onReload, currentFilter, onFilterChanged}: CalendarEventsListProps) {
    const ELEMENT_LABEL = mode === CALENDAR_MODE.MACHINES ? 'Машина/съоръжение' : 'Задача';

    const [working, setWorking] = useState(false);
    const [editEvent, setEditEvent] = useState<CalendarEvent | null>(null);
    const [deleteEvent, setDeleteEvent] = useState<CalendarEvent | null>(null);

    function onSaveEvent() {
        setEditEvent(null);
        onReload();
    }

    function doDeleteEvent() {
        API.deleteCalendarEvent(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if(isSuccess(apiCall)) { onReload(); setDeleteEvent(null); }
                if(isError(apiCall)) showErrorsInToast(apiCall, "Възникна грешка", translateError);
            }, deleteEvent?.id || -1
        )
    }

    function getClasses(e: CalendarEvent) {
        if(!e) return "";

        if(e.status === "Започнато") return "bg-success text-light";

        if(e.plan_date && moment(e.plan_date, 'DD.MM.YYYY').toDate() <= new Date()) return "bg-danger text-light";

        if(e.alert_date && moment(e.alert_date, 'DD.MM.YYYY').toDate() <= new Date()) return "bg-warning";

        return "";
    }

    if(loading) return null;

    return (
        <>
            <div className={"row"} style={{opacity: (loading ? 0.3 : 1)}}>
                <div className={"col-12"}>
                    <Table bordered>
                        <thead>
                        <tr className={"bg-secondary text-light font-weight-bold"}>
                            <td className={"text-center"}>Статус</td>
                            <td>{ELEMENT_LABEL}</td>
                            <td>Изпълнител</td>
                            <td>Процедура<br/>Дейност</td>
                            <td className={"text-center"}>Планирано<br/>за дата</td>
                            <td className={"text-center"}>Алармирай<br/>след дата</td>
                            <td className={"text-end"}>Цена</td>
                            <td className={"text-end"}>Операции</td>
                        </tr>
                        <CalendarFilter
                            mode={mode} elements={elements} executors={executors} internals={internals}
                            currentFilter={currentFilter} onFilterChanged={onFilterChanged}/>
                        </thead>
                        <tbody>
                        {
                            events.length === 0 &&
                            <tr>
                                <td colSpan={8}><strong>Няма добавени записи</strong></td>
                            </tr>
                        }
                        {
                            events.map(
                                e =>
                                    <tr key={e.id} className={getClasses(e)}>
                                        <td className={"text-center"}>{e.status}</td>
                                        <td>{e.element?.name}</td>
                                        <td>{e.executor?.name}</td>
                                        <td>{e.comments}</td>
                                        <td className={"text-center"}>{e.plan_date}</td>
                                        <td className={"text-center"}>{e.alert_date}</td>
                                        <td className={"text-end"}>{e.price} лв</td>
                                        <td className={"w-120px text-end"}>
                                            <Button size={"sm"} onClick={() => setEditEvent(e)}><FontAwesomeIcon icon={faPen}/></Button>&nbsp;
                                            <Button size={"sm"} onClick={() => setDeleteEvent(e)}><FontAwesomeIcon icon={faTrash}/></Button>
                                        </td>
                                    </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </div>

            {
                editEvent &&
                <CalendarEventEditModal title={"Корекция на събитие"}
                                        event={editEvent}
                                        elements={elements}
                                        internals={internals}
                                        executors={executors}
                                        onReload={onReload}
                                        onClose={() => {setEditEvent(null)}}
                />
            }

            {
                deleteEvent &&
                <ConfirmDeleteModal working={working} onConfirm={doDeleteEvent} onReject={() => setDeleteEvent(null)}/>
            }
        </>
    )
}
