import React from "react";
import useInterval from "../../hooks/useInterval";
import {Col, Row, } from "react-bootstrap";
import {STAY_DURATION, titleStyle} from "../../pages/StatusPage";
import StatusOneCalendarTask from "./StatusOneCalendarTask";
import moment from "moment/moment";


export default function CalendarEventsPart(props: { onDone: () => void }) {
    useInterval(() => props.onDone(), STAY_DURATION);

    let topPosition = -500;
    useInterval(() => {
        window.scrollTo(0, topPosition < 0 ? 0 : topPosition);
        topPosition += 1;
    }, 10);

    const _statusPeriodFrom = moment().subtract(1, 'day').format('DD.MM.YYYY');

    return <>
        <Row className={"border-top mt-3 align-items-start align-content-start"}>
            <Col xs={9} style={{height: "65px", marginBottom: "10px"}}
                 className={"border-bottom border-light "}>
                <span style={titleStyle}>ПРЕДСТОЯЩИ ЗАДАЧИ</span>
            </Col>

            <Col xs={3} style={{textAlign: "center", height: "65px", marginBottom: "10px"}}
                 className={"border-bottom border-light text-end"}>
                <span style={titleStyle}>{_statusPeriodFrom}</span>
            </Col>
        </Row>

        <Row className={"align-items-start align-content-start"}>
            <StatusOneCalendarTask mode={"MACHINES"} />
            {/*<StatusOneCalendarTask mode={"ADMINISTRATIVE"} />*/}
        </Row>
    </>;
}
